import React from 'react'
import { Routes, Route } from "react-router-dom";

import { ScrollToTop } from '../components';

//Pages
import Blank from '../pages/Blank';
import Home from '../pages/Home';
import HomeEcommerce from '../pages/HomeEcommerce';
import HomeProject from '../pages/HomeProject';
import HomeMarketing from '../pages/HomeMarketing';
import HomeNFT from '../pages/HomeNFT';

import AukissChart from '../pages/Statistics/AukissChart';
import MusicChart from '../pages/Statistics/MusicChart';



// apps
import AppCalendar from '../pages/AppCalendar';
import KanbanBasic from '../pages/kanban/KanbanBasic';
import KanbanCustom from '../pages/kanban/KanbanCustom';
import Chats from '../pages/apps/chat/Chats';
import Inbox from '../pages/apps/mailbox/Inbox';

// user manage
import UserList from '../pages/user-manage/UserList';
import UserAdd from '../pages/user-manage/UserAdd';
// import UserCards from '../pages/user-manage/UserCards';
import UserProfile from '../pages/user-manage/UserProfile';
import UserEdit from '../pages/user-manage/UserEdit';

// admin
import Profile from '../pages/admin/Profile';
import ProfileSettings from '../pages/admin/ProfileSettings';

// ecommerce
import Products from '../pages/ecommerce/Products';
import Categories from '../pages/ecommerce/Categories';
import AddProduct from '../pages/ecommerce/AddProduct';
import EditProduct from '../pages/ecommerce/EditProduct';
import AddCategory from '../pages/ecommerce/AddCategory';
import EditCategory from '../pages/ecommerce/EditCategory';

// ui elements
import Accordion from '../pages/components/Accordion';
import Alert from '../pages/components/Alert';
import Badge from '../pages/components/Badge';
import Breadcrumb from '../pages/components/Breadcrumb';
import Buttons from '../pages/components/Buttons';
import ButtonGroup from '../pages/components/ButtonGroup';
import Cards from '../pages/components/Cards';
import Carousel from '../pages/components/Carousel';
import CloseButton from '../pages/components/CloseButton';
import Collapse from '../pages/components/Collapse';
import Dropdowns from '../pages/components/Dropdowns';
import ListGroup from '../pages/components/ListGroup';
import Modal from '../pages/components/Modal';
import Tabs from '../pages/components/Tabs';
import Offcanvas from '../pages/components/Offcanvas';
import Pagination from '../pages/components/Pagination';
import Placeholders from '../pages/components/Placeholders';
import Popovers from '../pages/components/Popovers';
import Progress from '../pages/components/Progress';
import Spinners from '../pages/components/Spinners';
import Toasts from '../pages/components/Toasts';
import Tooltips from '../pages/components/Tooltips';

// utilities
import Misc from '../pages/utilities/Misc';
import Typography from '../pages/utilities/Typography';
import Images from '../pages/utilities/Images';
import Tables from '../pages/utilities/Tables';
import Background from '../pages/utilities/Background';
import Borders from '../pages/utilities/Borders';
import Colors from '../pages/utilities/Colors';
import Flex from '../pages/utilities/Flex';
import Sizing from '../pages/utilities/Sizing';
import Spacing from '../pages/utilities/Spacing';

// layout
import Breakpoints from '../pages/layout/Breakpoints';
import Containers from '../pages/layout/Containers';
import Gutters from '../pages/layout/Gutters';
// fomrs
import FormControl from '../pages/forms/FormControl';
import FormSelect from '../pages/forms/FormSelect';
import DateTime from '../pages/forms/DateTime';
import FormUpload from '../pages/forms/FormUpload';
import InputGroup from '../pages/forms/InputGroup';
import FloatingLabels from '../pages/forms/FloatingLabels';
import ChecksRadios from '../pages/forms/ChecksRadios';
import FormRange from '../pages/forms/FormRange';
import FormValidation from '../pages/forms/FormValidation';
import FormLayout from '../pages/forms/FormLayout';
import QuillPreview from '../pages/forms/editors/QuillPreview';
import TinymcePreview from '../pages/forms/editors/TinymcePreview';

// other pages
import DataTablePreview from '../pages/DataTablePreview';
import ChartsPreview from '../pages/ChartsPreview';
import Sweetalert from '../pages/Sweetalert';

// auths pages
import AuthRegister from '../pages/auths/AuthRegister';
import AuthLogin from '../pages/auths/AuthLogin';
import AuthReset from '../pages/auths/AuthReset';

import NotFound from '../pages/error/NotFound';
import IconsPreview from '../pages/IconsPreview';

// magazine pages
import MagazineList from '../pages/magazine/MagazineList';
import MagazineAdd from '../pages/magazine/MagazineAdd';
import MagazineProfile from '../pages/magazine/MagazineProfile';
import MagazineEdit from '../pages/magazine/MagazineEdit';

// tag pages
import TagList from '../pages/tag-manage/TagList';
import TagAdd from '../pages/tag-manage/TagAdd';
import TagEdit from '../pages/tag-manage/TagEdit';

// mag category pages
import MagCategoryList from '../pages/magcategory/MagCategoryList';
import MagCategoryAdd from '../pages/magcategory/MagCategoryAdd';
import MagCategoryEdit from '../pages/magcategory/MagCategoryEdit';

// actor pages
import ActorList from '../pages/actor-manage/ActorList';
import AddActor from '../pages/actor-manage/AddActor';
import ViewActor from '../pages/actor-manage/ViewActor';
import EditActor from '../pages/actor-manage/EditActor';

// movie pages
import MovieList from '../pages/movie-manage/MovieList';
import MovieAdd from '../pages/movie-manage/MovieAdd';
import MovieEdit from '../pages/movie-manage/MovieEdit';
import MovieView from '../pages/movie-manage/MovieView';

// series pages
import SeriesList from '../pages/series-manage/SeriesList';
import AddSeries from '../pages/series-manage/AddSeries';
import EditSeries from '../pages/series-manage/EditSeries';
import ViewSeries from '../pages/series-manage/ViewSeries';

// Radio pages
import RadioList from '../pages/radio-manage/RadioList';
import RadioAdd from '../pages/radio-manage/RadioAdd';
import RadioEdit from '../pages/radio-manage/RadioEdit';
import RadioView from '../pages/radio-manage/RadioView';

// LiveTV pages
import LiveTVList from '../pages/livetv-manage/LiveTVList';
import LiveTVAdd from '../pages/livetv-manage/LiveTVAdd';
import LiveTVEdit from '../pages/livetv-manage/LiveTVEdit';
import LiveTVView from '../pages/livetv-manage/LiveTVView';

// Kanban aukiss pages
import KanbanAukiss from '../pages/kanban/KanbanAukiss';
import KanbanView from '../pages/kanban/KanbanView';
import KanbanEdit from '../pages/kanban/KanbanEdit';

// Assignee pages
import AssigneeList from '../pages/Assignee/AssigneeList';
import AddAssignee from '../pages/Assignee/AddAssignee';
import EditAssigne from '../pages/Assignee/EditAssigne';


// configurations
import Weblandingpage from '../pages/configuration-manage/weblandingpage';
import Addweblandingpage from '../pages/configuration-manage/addweblandingpage';
import Editweblandingpage from '../pages/configuration-manage/editweblandingpage';
import Viewweblandingpage from '../pages/configuration-manage/viewweblandingpage';
import StatusList from '../pages/configuration-manage/StatusList';
import AddStatus from '../pages/configuration-manage/AddStatus';
import EditStatus from '../pages/configuration-manage/EditStatus';
import PayPalList from '../pages/configuration-manage/PayPalList';
import AddPayPal from '../pages/configuration-manage/AddPayPal';
import EditPayPal from '../pages/configuration-manage/EditPayPal';
import SubscriptionList from '../pages/configuration-manage/SubscriptionList';
import AddSubcription from '../pages/configuration-manage/AddSubscription';
import EditSubscription from '../pages/configuration-manage/EditSubscription';
import AboutList from '../pages/configuration-manage/AboutList';
import AddAbout from '../pages/configuration-manage/AddAbout';
import EditAbout from '../pages/configuration-manage/EditAbout';
import GettingStarted from '../pages/configuration-manage/GettingStarted';
import AddGettingStarted from '../pages/configuration-manage/AddGettingStarted';
import EditGettingStarted from '../pages/configuration-manage/EditGettingStarted';
import PricingList from '../pages/configuration-manage/PricingList';
import AddPricing from '../pages/configuration-manage/AddPricing';
import EditPricing from '../pages/configuration-manage/EditPricing';
import SalesList from '../pages/configuration-manage/SalesList';
import AddSales from '../pages/configuration-manage/AddSales';
import EditSales from '../pages/configuration-manage/EditSales';
import GuideList from '../pages/configuration-manage/GuideList';
import AddGuide from '../pages/configuration-manage/AddGuide';
import EditGuide from '../pages/configuration-manage/EditGuide';

//Subscription
import PaidSubscription from '../pages/Subscription/PaidSubscription';
import FreeSubscription from '../pages/Subscription/FreeSubscription';

// Fundtransfer
import FundTransferList from '../pages/fund-transfer/FundTransferList';
import FundTransferAdd from '../pages/fund-transfer/FundTransferAdd';
import FundTransferStatus from '../pages/fund-transfer/FundTransferStatus';

// radio category
import RadioCategoryEdit from '../pages/radio-category/RadioCategoryEdit';
import RadiCategoryAdd from '../pages/radio-category/RadiCategoryAdd';
import RadioCategoryList from '../pages/radio-category/RadioCategoryList';

// tv category
import TvCategory from '../pages/movie-manage/TvCategory';
import TvCategoryAdd from '../pages/movie-manage/TvCategoryAdd';
import TvCategoryEdit from '../pages/movie-manage/TvCategoryEdit';

// movie category
import MovieCategory from '../pages/movie-manage/MovieCategory';
import MovieCategoryAdd from '../pages/movie-manage/MovieCategoryAdd';
import MovieCategoryEdit from '../pages/movie-manage/MovieCategoryEdit';

// push message
import PushMessage from '../pages/push-message-manage/PushMessage';
import AddPushMessage from '../pages/push-message-manage/AddPushMessage';


// adverts
import AdvertsList from '../pages/Adverts-manage/AdvertsList';
import AddAdvertsList from '../pages/Adverts-manage/AddAdvertsList';
import EditAdverts from '../pages/Adverts-manage/EditAdverts';






function Router() {
  return (
    <ScrollToTop>
      <Routes>
          <Route path="blank" element={<Blank />} />
          <Route path="/" element={<HomeProject/>} />
          <Route path="/home" element={<HomeProject />} />
          <Route path="home-ecommerce" element={<HomeEcommerce />} />
          <Route path="home-project" element={<Home />} />
          <Route path="home-marketing" element={<HomeMarketing />} />
          <Route path="home-nft" element={<HomeNFT />} />
          <Route path="home-aukisschart" element={<AukissChart />} />
          <Route path="home-musicchart" element={<MusicChart />} />
          

          <Route path="fundtransfer">
            <Route path="fundtransfer-list" element={<FundTransferList />} />
            <Route path="fundtransfer-add" element={<FundTransferAdd />} />
            <Route path="fundtransfer-edit/:id" element={<FundTransferStatus />} />
          </Route>

          <Route path="apps">
            <Route path="calendar" element={<AppCalendar />} />
            <Route path="kanban/basic" element={<KanbanBasic />} />
            <Route path="kanban/custom" element={<KanbanCustom />} />
            <Route path="kanbanaukiss-list" element={<KanbanAukiss />} />
            <Route path="kanbanaukiss-view/:idd/:labels" element={<KanbanView />} />
            <Route path="kanbanaukiss-edit/:idd/:labels" element={<KanbanEdit />} />
            <Route path="chats" element={<Chats />} />
            <Route path="inbox" element={<Inbox />} />            
          </Route>

          <Route path="user-manage">
            <Route path="user-list" element={<UserList />} />
            <Route path="user-add" element={<UserAdd />} />
            <Route path="user-profile/:id" element={<UserProfile />} />
            <Route path="user-edit/:id" element={<UserEdit />} />
          </Route>

          <Route path="magazine-manage">
            <Route path="magazine-list" element={<MagazineList />} />
            <Route path="magazine-add" element={<MagazineAdd />} />
            <Route path="magazine-profile/:id" element={<MagazineProfile />} />
            <Route path="magazine-edit/:id" element={<MagazineEdit />} />
          </Route>

           <Route path="tag-manage">
            <Route path="tag-list" element={<TagList />} />
            <Route path="tag-add" element={<TagAdd />} />
            <Route path="tag-edit/:id" element={<TagEdit />} />
          </Route>

          <Route path="assignee-manage">
            <Route path="assignee-list" element={<AssigneeList />} />
            <Route path="assignee-add" element={<AddAssignee />} />
            <Route path="assignee-edit/:id" element={<EditAssigne />} />
          </Route>

          <Route path="pushmessage-manage">
            <Route path="pushmessage-list" element={<PushMessage />} />
            <Route path="pushmessage-add" element={<AddPushMessage />} />
          </Route>

          <Route path="ads-manage">
            <Route path="ads-list" element={<AdvertsList />} />
            <Route path="ads-add" element={<AddAdvertsList />} />
            <Route path="ads-edit/:id" element={<EditAdverts />} />
          </Route>



          <Route path="magcategory-manage">
            <Route path="magcategory-list" element={<MagCategoryList />} />
            <Route path="magcategory-add" element={<MagCategoryAdd />} />
            <Route path="magcategory-edit/:id" element={<MagCategoryEdit />} />
          </Route>

          <Route path="radiocategory-manage">
            <Route path="radiocategory-list" element={<RadioCategoryList />} />
            <Route path="radiocategory-add" element={<RadiCategoryAdd />} />
            <Route path="radiocategory-edit/:id" element={<RadioCategoryEdit />} />
          </Route>

          <Route path="tvcategory-manage">
            <Route path="tvcategory-list" element={<TvCategory />} />
            <Route path="tvcategory-add" element={<TvCategoryAdd />} />
            <Route path="tvcategory-edit/:id" element={<TvCategoryEdit />} />
          </Route>
          
          <Route path="actor-manage">
            <Route path="actor-list" element={<ActorList />} />
            <Route path="actor-add" element={<AddActor />} />
            <Route path="actor-edit/:id" element={<EditActor />} />
            <Route path="actor-view/:id" element={<ViewActor />} />
          </Route>

          <Route path="movie-manage">
            <Route path="movie-list" element={<MovieList />} />
            <Route path="movie-add" element={<MovieAdd />} />
            <Route path="movie-edit/:id" element={<MovieEdit />} />
            <Route path="movie-view/:id" element={<MovieView />} />
            <Route path="movie-category" element={<MovieCategory />} />
            <Route path="movie-category-add" element={<MovieCategoryAdd />} />
            <Route path="movie-category-edit/:id" element={<MovieCategoryEdit />} />

          </Route>

          <Route path="series-manage">
            <Route path="series-list" element={<SeriesList />} />
            <Route path="series-add" element={<AddSeries />} />
            <Route path="series-edit/:id" element={<EditSeries />} />
            <Route path="series-view/:id" element={<ViewSeries />} />
          </Route>

          <Route path="radio-manage">
            <Route path="radio-list" element={<RadioList />} />
            <Route path="radio-add" element={<RadioAdd />} />
            <Route path="radio-edit/:id" element={<RadioEdit />} />
            <Route path="radio-view/:id" element={<RadioView />} />
          </Route>

          <Route path="livetv-manage">
            <Route path="livetv-list" element={<LiveTVList />} />
            <Route path="livetv-add" element={<LiveTVAdd />} />
            <Route path="livetv-edit/:id" element={<LiveTVEdit />} />
            <Route path="livetv-view/:id" element={<LiveTVView />} />
          </Route>

          <Route path="subscription-manage">
            <Route path="paid-subscription" element={<PaidSubscription />} />
            <Route path="free-subscription" element={<FreeSubscription />} />
           
          </Route>

       

          <Route path="configuration-manage">
            <Route path="weblandingpage" element={<Weblandingpage />} />
            <Route path="weblandingpage-add" element={<Addweblandingpage />} />
            <Route path="weblandingpage-edit/:id" element={<Editweblandingpage />} />
            <Route path="weblandingpage-view/:id" element={<Viewweblandingpage />} />
            <Route path="status-list" element={<StatusList />} />
            <Route path="status-add" element={<AddStatus />} />
            <Route path="status-edit/:id" element={<EditStatus />} />
            <Route path="paypal-list" element={<PayPalList />} />
            <Route path="paypal-add" element={<AddPayPal />} />
            <Route path="paypal-edit/:id" element={<EditPayPal />} />
            <Route path="subscription-list" element={<SubscriptionList />} />
            <Route path="subscription-add" element={<AddSubcription />} />
            <Route path="subscription-edit/:id" element={<EditSubscription />} />
            <Route path="about-list" element={<AboutList />} />
            <Route path="about-add" element={<AddAbout />} />
            <Route path="about-edit/:id" element={<EditAbout />} />
            <Route path="gettingstarted-list" element={<GettingStarted />} />
            <Route path="gettingstarted-add" element={<AddGettingStarted />} />
            <Route path="gettingstarted-edit/:id" element={<EditGettingStarted />} />

            <Route path="pricing-list" element={<PricingList />} />
            <Route path="pricing-add" element={<AddPricing />} />
            <Route path="pricing-edit/:id" element={<EditPricing />} />

            <Route path="sales-list" element={<SalesList />} />
            <Route path="sales-add" element={<AddSales />} />
            <Route path="sales-edit/:id" element={<EditSales />} />


            <Route path="guide-list" element={<GuideList />} />
            <Route path="guide-add" element={<AddGuide />} />
            <Route path="guide-edit/:id" element={<EditGuide />} />

          </Route>



          <Route path="admin">
            <Route path="profile" element={<Profile />} />
            <Route path="profile-settings" element={<ProfileSettings />} />
          </Route>

          <Route path="ecommerce">
            <Route path="products" element={<Products />} />
            <Route path="categories" element={<Categories />} />
            <Route path="add-product" element={<AddProduct />} />
            <Route path="edit-product/:id" element={<EditProduct />} />
            <Route path="add-category" element={<AddCategory />} />
            <Route path="edit-category/:id" element={<EditCategory />} />
          </Route>

          <Route path="ui-elements">
            <Route path="accordion" element={<Accordion />} />
            <Route path="alert" element={<Alert />} />
            <Route path="badge" element={<Badge />} />
            <Route path="breadcrumb" element={<Breadcrumb />} />
            <Route path="buttons" element={<Buttons />} />
            <Route path="button-group" element={<ButtonGroup />} />
            <Route path="cards" element={<Cards />} />
            <Route path="carousel" element={<Carousel />} />
            <Route path="close-button" element={<CloseButton />} />
            <Route path="collapse" element={<Collapse />} />
            <Route path="dropdowns" element={<Dropdowns />} />
            <Route path="list-group" element={<ListGroup />} />
            <Route path="modal" element={<Modal />} />
            <Route path="tabs" element={<Tabs />} />
            <Route path="offcanvas" element={<Offcanvas />} />
            <Route path="pagination" element={<Pagination />} />
            <Route path="placeholders" element={<Placeholders />} />
            <Route path="popovers" element={<Popovers />} />
            <Route path="progress" element={<Progress />} />
            <Route path="spinners" element={<Spinners />} />
            <Route path="toasts" element={<Toasts />} />
            <Route path="tooltips" element={<Tooltips />} />
          </Route>

          <Route path="utilities">
            <Route path="misc" element={<Misc />} />
            <Route path="typography" element={<Typography />} />
            <Route path="images" element={<Images />} />
            <Route path="tables" element={<Tables />} />
            <Route path="background" element={<Background />} />
            <Route path="borders" element={<Borders />} />
            <Route path="colors" element={<Colors />} />
            <Route path="flex" element={<Flex />} />
            <Route path="sizing" element={<Sizing />} />
            <Route path="spacing" element={<Spacing />} />
          </Route>

          <Route path="layout">
            <Route path="breakpoints" element={<Breakpoints />} />
            <Route path="containers" element={<Containers />} />
            <Route path="gutters" element={<Gutters />} />
          </Route>

          <Route path="forms">
            <Route path="form-control" element={<FormControl />} />
            <Route path="form-select" element={<FormSelect />} />
            <Route path="date-time" element={<DateTime />} />
            <Route path="form-upload" element={<FormUpload />} />
            <Route path="input-group" element={<InputGroup />} />
            <Route path="floating-labels" element={<FloatingLabels />} />
            <Route path="checks-radios" element={<ChecksRadios />} />
            <Route path="form-range" element={<FormRange />} />
            <Route path="form-validation" element={<FormValidation />} />
            <Route path="form-layout" element={<FormLayout />} />
          </Route>

          <Route path="editors">
            <Route path="quill" element={<QuillPreview />} />
            <Route path="tinymce" element={<TinymcePreview />} />
          </Route>

          <Route path="data-table" element={<DataTablePreview />} />
          <Route path="charts" element={<ChartsPreview />} />
          <Route path="sweetalert" element={<Sweetalert />} />

          <Route path="auths">
            <Route path="auth-register" element={<AuthRegister />} />
            <Route path="auth-login" element={<AuthLogin />} />
            <Route path="auth-reset" element={<AuthReset />} />
          </Route>

          <Route path="icons" element={<IconsPreview />} />
          <Route path="not-found" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
      </Routes>
    </ScrollToTop>
    
  )
}

export default Router;
