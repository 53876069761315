import React, { useState, useEffect, useMemo } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
// import Swal from "sweetalert2/src/sweetalert2.js";
// import Avatar from "@mui/material/Avatar";
import {
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
  LinkListItem,
} from "../../components";
// import { toInitials } from "../../utilities";
import { rootURL, PayStackLiveKey } from "../utilities/axios-config";
// import { successMessage, failedMessage } from "../utilities/alert-config";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";

function FundTransferList() {
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [localSearch, setLocalSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(200);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [amountLeft, setamountLeft] = useState(0);
  const [currencyLeft, setcurrencyLeft] = useState("");
  const userId = localStorage.getItem("id");

  //   const theme = "danger-soft";

  const handleChangePage = (event, newPage) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      setLoading(true);
      fetch(`${rootURL}/fundtransfer_cpanel?page=${newPage}`, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setTableData(row?.data?.data);
            setPage(row?.data?.current_page);
            setCount(row?.data?.total);
            setRowsPerPage(row?.data?.per_page);

            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      setLoading(true);
      fetch(
        `${rootURL}/fundtransfer_cpanel?per_page=${event.target.value}`,
        config
      )
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setTableData(row?.data?.data);
            setPage(row?.data?.current_page);
            setCount(row?.data?.total);
            setRowsPerPage(row?.data?.per_page);

            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableRecords = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/fundtransfer_cpanel`;
      if (searchText) {
        url += `?searchtext=${searchText}`;
      }

      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
          
            setTableData(row?.data?.data);
            setPage(row?.data?.current_page);
            setCount(row?.data?.total);
            setRowsPerPage(row?.data?.per_page);

            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchBalance = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${PayStackLiveKey}`,
          "Content-Type": "application/json",
        },
      };

      setLoading(true);

      let url = `https://api.paystack.co/balance`;
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.status === true) {
            // console.log("row", row);
            // eslint-disable-next-line
            row?.data.map((arr) => {
              setamountLeft(arr?.balance);
              setcurrencyLeft(arr?.currency);
            });

            // successMessage(row?.message);

            setLoading(false);
          } else if (row?.status === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  function dateConvert(date) {
    return new Date(date).toISOString().slice(0, 10);
  }

  const debounce = () => {
    let timeoutID;
    return (e) => {
      setLocalSearch(e.target.value);
      clearTimeout(timeoutID);
      timeoutID = setTimeout(() => {
        setSearchText(e.target.value);
        // only make api call after user finish typing
      }, 1000);
    };
  };

  const handleSearch = useMemo(() => debounce(), []);

  useEffect(() => {
    tableRecords();
    fetchBalance();
    // eslint-disable-next-line
  }, [searchText]);

  return (
    <Layout title="FundTransfer List" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Fund Transfer List</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/fundtransfer/fundtransfer-list">
                    Fund Transfer Manage
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Fund Transfer
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                {/* <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={handleShowModal}
                >
                  <Icon name="plus" />
                  <span>Add</span>
                </Button> */}
                {userId === "19" || userId === "64" ? (
                  <Link
                    to={`/fundtransfer/fundtransfer-add`}
                    className="btn btn-soft btn-primary"
                  >
                    <Icon name="youtube-round"></Icon>
                    <span>Send Money</span>
                  </Link>
                ) : (
                  ""
                )}
              </li>
              {/* <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleShowModal}
                >
                  <Icon name="plus" />
                  <span>Add User</span>
                </Button>
              </li> */}
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          <div className="data-table-wrapper">
            <div className="data-table-top">
              <div className="data-table-search">
                <input
                  className="form-control"
                  placeholder="Search by FundTransfer"
                  value={localSearch}
                  type="text"
                  onChange={handleSearch}
                />
              </div>
              <div className="data-table-action-wrap">
                <div className="data-table-select">
                  {currencyLeft} {amountLeft / 100}
                </div>
                {/* {actions && <Export data={data} />}
                <div className="data-table-select">
                    <select 
                        className="form-select" 
                        onChange={(e) => setShowItemPerPage(e.target.value)}
                        value={showItemPerPage}
                        >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="25">25</option>
                    </select>
                    <span className="text">Per page</span>
                </div> */}
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {loading ? (
                      <TableCell component="th" colSpan="4" align="center">
                        {" "}
                        <CircularProgress size={20} thickness={4} />
                        {"LOADING...... "}
                      </TableCell>
                    ) : (
                      <>
                        <TableCell align="left">TYPE</TableCell>
                        <TableCell align="center">NAME</TableCell>
                        <TableCell align="center">ACCOUNT NUMBER</TableCell>
                        <TableCell align="center">BANK CODE</TableCell>
                        <TableCell align="center">CURRENCY</TableCell>
                        <TableCell align="center">AMOUNT</TableCell>
                        <TableCell align="center">FEE CHARGED</TableCell>
                        <TableCell align="center">RECEIPIENT CODE</TableCell>
                        <TableCell align="center">TRANSFER CODE</TableCell>
                        <TableCell align="center">MESSAGE</TableCell>
                        <TableCell align="center">CREATED BY</TableCell>
                        <TableCell align="center">DATE/TIME</TableCell>
                        <TableCell align="center">STATUS</TableCell>
                        <TableCell align="center">ACTION</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row?.type}
                      </TableCell>
                      <TableCell align="center">{row?.name ?? ""}</TableCell>

                      <TableCell align="center">
                        {row?.account_number ?? ""}
                      </TableCell>
                      <TableCell align="center">
                        {row?.bank_code ?? ""}
                      </TableCell>
                      <TableCell align="center">
                        {row?.currency ?? ""}
                      </TableCell>
                      <TableCell align="center">{row?.amount ?? ""}</TableCell>
                      <TableCell align="center">{row?.fee_charged ?? ""}</TableCell>
                      <TableCell align="center">
                        {row?.recipient_code ?? ""}
                      </TableCell>
                      <TableCell align="center">
                        {row?.transfer_code ?? ""}
                      </TableCell>
                      <TableCell align="center">{row?.message ?? ""}</TableCell>
                      <TableCell align="center">
                        {row?.user?.first_name ?? " "}{" "}
                        {row?.user?.last_name ?? " "}
                      </TableCell>

                      <TableCell align="center">
                        {dateConvert(row?.created_at) ?? ""}
                      </TableCell>

                      <TableCell align="center">
                        {" "}
                        <span
                          className={`badge text-bg-${
                            row?.status === "success"
                              ? "success-soft"
                              : row?.status === "pending"
                              ? "warning-soft"
                              : row?.status === 4
                              ? "secondary-soft"
                              : "primary-soft"
                          }`}
                        >
                          {row?.status ?? ""}
                        </span>
                      </TableCell>
                      <TableCell>
                        <div className="text-end w-100">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              className="dropdown-menu-sm"
                              as={CustomDropdownMenu}
                              align="end"
                            >
                              <div className="dropdown-content py-1">
                                <LinkList className="link-list-hover-bg-primary link-list-md">
                                  <LinkListItem
                                    to={`/fundtransfer/fundtransfer-edit/${row.id}`}
                                  >
                                    <Icon name="eye"></Icon>
                                    <span>View Status</span>
                                  </LinkListItem>
                                </LinkList>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="data-table-bottom">
              <TablePagination
                rowsPerPageOptions={[50, 100, 150, 200, 250]}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </Card>
      </Block>
    </Layout>
  );
}

export default FundTransferList;
