import React, { useState, useEffect, useMemo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Card, Dropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import Swal from "sweetalert2/src/sweetalert2.js";
import Avatar from "@mui/material/Avatar";
import {
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
  LinkListItem,
} from "../../components";
// import { toInitials } from "../../utilities";
import { rootURL } from "../utilities/axios-config";
// import { successMessage, failedMessage } from "../utilities/alert-config";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";

function LiveTVList() {
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [localSearch, setLocalSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(1000);
  const [rowsPerPage, setRowsPerPage] = React.useState(500);
  //   const theme = "danger-soft";

  const handleDelete = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Once deleted, You won't be able to revert this LiveTV! ${row?.name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        Swal.fire("Deleted", "You successfully deleted this file", "success");
        const userToken = localStorage.getItem("userToken");
        const config = {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        };
        setLoading(true);
        fetch(`${rootURL}/livetvremovecpanel/${row?.id}`, config)
          .then((row) => row.json())
          .then((row) => {
            if (row?.success === true) {
              tableRecords();

              setLoading(false);
            } else if (row?.success === false) {
              setLoading(false);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        Swal.fire("Cancelled", "Your data is still intact", "info");
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      setLoading(true);
      fetch(`${rootURL}/all_livetv_cpanel?page=${newPage}`, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setTableData(row?.data?.data);
            setPage(row?.data?.current_page);
            setCount(row?.data?.total);
            setRowsPerPage(row?.data?.per_page);

            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      setLoading(true);
      fetch(
        `${rootURL}/all_livetv_cpanel?per_page=${event.target.value}`,
        config
      )
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setTableData(row?.data?.data);
            setPage(row?.data?.current_page);
            setCount(row?.data?.total);
            setRowsPerPage(row?.data?.per_page);

            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableRecords = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/all_livetv_cpanel`;
      if (searchText) {
        url += `?searchtext=${searchText}`;
      }

      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setTableData(row?.data?.data);
            setPage(row?.data?.current_page);
            setCount(row?.data?.total);
            setRowsPerPage(row?.data?.per_page);

            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  function dateConvert(date) {
    return new Date(date).toISOString().slice(0, 10);
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const answer = Array.from(tableData);
    const [removed] = answer.splice(result.source.index, 1);
    answer.splice(result.destination.index, 0, removed);

    tableUpdateOrder(answer);
    setTableData(answer);
  }

  const tableUpdateOrder = async (answer) => {
    const result = [];
    // eslint-disable-next-line
    answer.map((test, index) => {
    
      result.push({
        id: test?.id,
        order_id: index + 1,
      });
    });

   
    const livetvObj = {
      result,
    };
    const userToken = localStorage.getItem("userToken");
    fetch(`${rootURL}/updatelivtvordercpanel`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(livetvObj),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result)
        if (result.success === true) {
          tableRecords();
        } else if (result.success === false) {
        }
      })
      .catch((error) => {
    
      })
      .finally(() => {});
  };

  const debounce = () => {
    let timeoutID;
    return (e) => {
      setLocalSearch(e.target.value);
      clearTimeout(timeoutID);
      timeoutID = setTimeout(() => {
        setSearchText(e.target.value);
        // only make api call after user finish typing
      }, 1000);
    };
  };

  const handleSearch = useMemo(() => debounce(), []);

  useEffect(() => {
    tableRecords();
    // eslint-disable-next-line
  }, [searchText]);

  return (
    <Layout title="LiveTV List" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Live TV List</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/livetv-manage/livetv-list">Live TV Manage</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Live TV
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                {/* <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={handleShowModal}
                >
                  <Icon name="plus" />
                  <span>Add</span>
                </Button> */}
                <Link
                  to={`/livetv-manage/livetv-add`}
                  className="btn btn-soft btn-primary"
                >
                  <Icon name="youtube-round"></Icon>
                  <span>Add Live TV</span>
                </Link>
              </li>
              {/* <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleShowModal}
                >
                  <Icon name="plus" />
                  <span>Add User</span>
                </Button>
              </li> */}
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          <div className="data-table-wrapper">
            <div className="data-table-top">
              <div className="data-table-search">
                <input
                  className="form-control"
                  placeholder="Search by Radio"
                  value={localSearch}
                  type="text"
                  onChange={handleSearch}
                />
              </div>
              <div className="data-table-action-wrap">
                {/* {actions && <Export data={data} />}
                <div className="data-table-select">
                    <select 
                        className="form-select" 
                        onChange={(e) => setShowItemPerPage(e.target.value)}
                        value={showItemPerPage}
                        >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="25">25</option>
                    </select>
                    <span className="text">Per page</span>
                </div> */}
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {loading ? (
                      <TableCell component="th" colSpan="4" align="center">
                        {" "}
                        <CircularProgress size={20} thickness={4} />
                        {"LOADING...... "}
                      </TableCell>
                    ) : (
                      <>
                        <TableCell align="left">COVER</TableCell>
                        <TableCell align="center">TITLE</TableCell>
                        <TableCell align="center">ORDER ID</TableCell>
                        <TableCell align="center">DATE/TIME</TableCell>
                        <TableCell align="center">STATUS</TableCell>
                        <TableCell align="center">ACTION</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="one">
                    {(provided, snapshot) => (
                      <TableBody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                          backgroundColor: snapshot.isDraggingOver
                            ? "white"
                            : "white",
                        }}
                      >
                        {tableData.map((row, index) => (
                          <Draggable
                            draggableId={row.id.toString()}
                            key={row.id}
                            index={index}
                          >
                            {(provided) => (
                              <TableRow
                                key={row.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <TableCell component="th" scope="row">
                                  <Avatar
                                    alt="Livetv Cover"
                                    src={row?.image ?? ""}
                                    sx={{ width: 66, height: 66 }}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  {row?.name ?? ""}
                                </TableCell>
                                <TableCell align="center">
                                  {row?.order_id ?? ""}
                                </TableCell>

                                <TableCell align="center">
                                  {dateConvert(row?.created_at) ?? ""}
                                </TableCell>

                                <TableCell align="center">
                                  {" "}
                                  <span
                                    className={`badge text-bg-${
                                      row.post_state?.id === 1
                                        ? "success-soft"
                                        : row?.post_state?.id === 3
                                        ? "warning-soft"
                                        : row?.post_state?.id === 4
                                        ? "secondary-soft"
                                        : "primary-soft"
                                    }`}
                                  >
                                    {row.post_state?.name ?? ""}
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <div className="text-end w-100">
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        size="sm"
                                        as={CustomDropdownToggle}
                                        className="btn btn-sm btn-icon btn-zoom me-n1"
                                      >
                                        <Icon name="more-v"></Icon>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu
                                        className="dropdown-menu-sm"
                                        as={CustomDropdownMenu}
                                        align="end"
                                      >
                                        <div className="dropdown-content py-1">
                                          <LinkList className="link-list-hover-bg-primary link-list-md">
                                            <LinkListItem
                                              to={`/livetv-manage/livetv-edit/${row.id}`}
                                            >
                                              <Icon name="edit"></Icon>
                                              <span>Edit</span>
                                            </LinkListItem>

                                            <LinkListItem
                                              to={`/livetv-manage/livetv-view/${row.id}`}
                                            >
                                              <Icon name="eye"></Icon>
                                              <span>View Details</span>
                                            </LinkListItem>
                                            <Button
                                              onClick={() => handleDelete(row)}
                                            >
                                              <Icon name="trash"></Icon>
                                              <span>Delete</span>
                                            </Button>
                                          </LinkList>
                                        </div>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
            <div className="data-table-bottom">
              <TablePagination
                rowsPerPageOptions={[500, 1000, 1500, 2000, 2500]}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </Card>
      </Block>
    </Layout>
  );
}

export default LiveTVList;
