import { useState, useEffect } from "react";
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AWS from "aws-sdk";
import { Card, Form, Row, Col } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/default";
// import Avatar from "@mui/material/Avatar";
import Block from "../../components/Block/Block";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
// import DateTimePicker from "react-datetime-picker";
// import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import { makeStyles } from "@mui/styles";
// import { useTheme } from "@mui/material/styles";
// import Paper from "@mui/material/Paper";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
// import Box from "@mui/material/Box";
// import EditIcon from "@mui/icons-material/Edit";
// import Grid from "@mui/material/Grid";
// import IconButton from "@mui/material/IconButton";
// import DeleteIcon from "@mui/icons-material/Delete";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
// import InputLabel from '@mui/material/InputLabel';
// import useMediaQuery from "@mui/material/useMediaQuery";
// import Stack from "@mui/material/Stack";
//import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
//import dayjs from "dayjs";
import { successMessage, failedMessage } from "../utilities/alert-config";
//import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
//import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import //Select,
//Quill,
//FileUpload,
//Tags,
//ImageUpload,
//DatePicker,
// Tinymce,
"../../components";
import {
  rootURL,
  awsID,
  awsBucketName,
  awsSECRET,
} from "../utilities/axios-config";
// import Checkbox from "@mui/material/Checkbox";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const Input = styled("input")({
//   display: "none",
// });

// const useStyles = makeStyles(() => ({
//   tableHeaderCell: {
//     fontWeight: "bold",
//   },
//   item: {
//     padding: 5,
//   },
//   backgroundColor: {
//     backgroundColor: "#2CB23B",
//   },
// }));

function EditAdvert() {
  const history = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  // const [name, setname] = useState("");
  const [ad_type, setad_type] = useState("");
  const [description, setdescription] = useState("");
  const [post_state_id, setpost_state_id] = useState("");
  // const [link, setlink] = useState("");
  const [adUrl, setadUrl] = useState("");
  const [ad_date, setad_date] = useState(new Date());
  // const [ad_time, setad_time] = useState("");
  const [imagelink, setimagelink] = useState(
    "/images/avatar/avatar-placeholder.jpg"
  );
  const [loading2, setloading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [progress, setProgress] = useState(0);
  const [poststatus, setpoststatus] = useState([]);
  const [scheduledate, setscheduledate] = useState("");
  const [schstatus, setschstatus] = useState(false);

  //const today = new Date().toISOString().slice(0, 10);

  function create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxx4xxyxx".replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  const adType = [
    "Magazine-Top-Header-Ad1",
    "Magazine-Business-Ad1",
    "Magazine-Business-Ad2",
    "Magazine-Politics-Ad1",
    "Magazine-IndustryPulse-Ad1",
    "Magazine-IndustryPulse-Ad2",
    "Magazine-Technology-Ad1",
    "Magazine-Health-Ad1",
    "Magazine-Travel-Ad1",
    "Magazine-Food-Ad1",
    "Magazine-Sports-Ad1",
    "Magazine-Lifestyle-Ad1",
    "Magazine-Tv-Ad1",
    "Magazine-LatestArticle-Ad1",
    "Magazine-LatestArticle-Ad2",
    "Magazine-AfricanCarribean-Ad1",
    "Series-Top-Header-Ad1",
    "Movies-Top-Header-Ad1",
    "Radio-Top-Header-Ad1",
  ];


  const tableAdvertsShow = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/view_ads_cpanel/${id}`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
           
            setad_type(row?.data?.ad_type ?? "");
            setadUrl(row?.data?.adUrl ?? "");
            setimagelink(row?.data?.image ?? "");

            const todaynew = new Date(row?.data?.ad_expire)
            .toISOString()
            .slice(0, 10);

            setad_date(todaynew ?? "");
            setdescription(row?.data?.description ?? "");
            setpost_state_id(row?.data?.post_state_id ?? "");

             if(row?.data?.scheduledate === null){
              setscheduledate("")
            }else{
               const todaynew = new Date(row?.data?.scheduledate)
              .toISOString()
              .slice(0, 10);
             setscheduledate(todaynew ?? "");
             setschstatus(true);
            }


           

            setLoading(false);
          } else if (row?.success === false) {
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableStatus = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/poststatus`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setpoststatus(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    tableStatus();
    tableAdvertsShow();
    // eslint-disable-next-line
  }, [id]);

  const handleStatus = (event) => {
    setpost_state_id(event.target.value);
    setschstatus(false);
    if (event.target.value === 4) {
      setschstatus(true);
    }
  };

  const selectedFile = (e) => {
    const image = e.target.files[0];
    if (image) {
      setLoading3(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + image.name, // File name you want to save as in S3
        Body: image,
        ContentType: image.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading3(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setimagelink(photoUrl);
        setLoading3(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress) {
        let uploaded = Math.round((progress.loaded / progress.total) * 100);
        setProgress(uploaded);
      });
    }
  };

  const handleTypes = (event) => {
    setad_type(event.target.value);
  };

  // const handleAdDate = (e) => {
  //   setad_date(e);
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userToken = localStorage.getItem("userToken");
    setloading2(true);
    const radioObj = {
      id,
      ad_type,
      adUrl,
      imagelink,
      ad_date,
      description,
      post_state_id,
      scheduledate,
    };
    // console.log("radioObj", radioObj);

    fetch(`${rootURL}/update_ads_cpanel`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(radioObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");

          setTimeout(() => {
            history("/ads-manage/ads-list");
          }, 3000);
        } else if (result.success === false) {
          setloading2(false);
          failedMessage(result.message);
        }
      })
      .catch((error) => {
        setloading2(false);
        failedMessage("Internal Server Error");
      })
      .finally(() => {
        setloading2(false);
      });
  };

  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress size={70} thickness={4} />
          </div>
        </>
      ) : (
        <>
          <Layout title="Add Radio" content="container">
            <Block.Head>
              <Block.HeadBetween>
                <Block.HeadContent>
                  <Block.Title tag="h2">Add Ad</Block.Title>
                  <nav>
                    <ol className="breadcrumb breadcrumb-arrow mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/ads-manage/ads-list">Ad</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Add Ad
                      </li>
                    </ol>
                  </nav>
                </Block.HeadContent>
              </Block.HeadBetween>
            </Block.Head>

            <Block>
              <Form onSubmit={handleSubmit}>
                <Row className="g-gs">
                  <Col xxl="9">
                    <div className="gap gy-4">
                      <div className="gap-col">
                        <Card className="card-gutter-md">
                          <Card.Body>
                            <Row className="g-gs">
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label>Cover</Form.Label>
                                  <div className="form-control-wrap">
                                    <div className="image-upload-wrap d-flex flex-column align-items-center">
                                      {loading3 ? (
                                        <div>Upload progress {progress}%</div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="media media-huge border">
                                        <img
                                          id="image-result"
                                          className="w-100 h-100"
                                          src={imagelink}
                                          alt="avatar"
                                        />
                                      </div>
                                      <div className="pt-3">
                                        <input
                                          className="upload-image"
                                          data-target="image-result"
                                          id="change-avatar"
                                          type="file"
                                          max="1"
                                          hidden
                                          onChange={selectedFile}
                                        />
                                        <label
                                          htmlFor="change-avatar"
                                          className="btn btn-md btn-primary"
                                          disabled={loading3}
                                        >
                                          {loading3
                                            ? "Uploading ..."
                                            : "Upload"}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-note mt-3">
                                    Set the article thumbnail image. Only *.png,
                                    *.jpg and *.jpeg image files are accepted.
                                  </div>
                                </Form.Group>
                              </Col>

                              {/* <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="productname">
                                    Ad Name
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="name"
                                      placeholder="Ad Name"
                                      value={name}
                                      onChange={(e) => setname(e.target.value)}
                                    />
                                  </div>
                                </Form.Group>
                              </Col> */}

                              <Col lg="12">
                                <div className="form-control-wrap">
                                  <FormControl fullWidth size="small">
                                    <Form.Label htmlFor="status_id">
                                      Ad Type *
                                    </Form.Label>
                                    <Select
                                      labelId="demo-select-small"
                                      id="demo-simple-select"
                                      value={ad_type ?? ""}
                                      label="Audio type"
                                      onChange={handleTypes}
                                      required
                                    >
                                      {adType.map((types) => (
                                        <MenuItem key={types} value={types}>
                                          {types}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="adUrl">
                                    Ad url
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="adUrl"
                                      placeholder="Ad Url"
                                      value={adUrl}
                                      onChange={(e) => setadUrl(e.target.value)}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="scheduledate">
                                    Ad Date
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    {/* <DateTimePicker onChange={handleAdDate} value={ad_date} /> */}
                                    <input
                                      type="date"
                                      id="ad_date"
                                      name="ad_date"
                                      value={ad_date}
                                      onChange={(newValue) => {
                                        setad_date(newValue.target.value);
                                      }}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label>Description *</Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      as="textarea"
                                      rows="3"
                                      value={description}
                                      placeholder=""
                                      required
                                      onChange={(e) =>
                                        setdescription(e.target.value)
                                      }
                                    ></Form.Control>
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <div className="form-control-wrap">
                                  <FormControl fullWidth size="small">
                                    <Form.Label htmlFor="status_id">
                                      Status *
                                    </Form.Label>
                                    <Select
                                      labelId="demo-select-small"
                                      id="demo-simple-select"
                                      value={post_state_id ?? ""}
                                      label="Status"
                                      onChange={handleStatus}
                                      required
                                    >
                                      {poststatus.map((status) => (
                                        <MenuItem
                                          key={status.id}
                                          value={status.id}
                                        >
                                          {loading === false ? status.name : ""}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </Col>

                              {schstatus ? (
                                <Col lg="6">
                                  <Form.Group className="form-group">
                                    <Form.Label htmlFor="scheduledate">
                                      Schedule Date
                                    </Form.Label>
                                    <div className="form-control-wrap">
                                      <input
                                        type="date"
                                        id="scheduledate"
                                        name="scheduledate"
                                        value={scheduledate}
                                        onChange={(newValue) => {
                                          setscheduledate(
                                            newValue.target.value
                                          );
                                        }}
                                      />
                                    </div>
                                  </Form.Group>
                                </Col>
                              ) : (
                                ""
                              )}
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>

                      <div className="gap-col">
                        <ul className="d-flex align-items-center gap g-3">
                          <li>
                            <Button type="submit" variant="contained">
                              {loading2 ? "Saving...." : "Save"}
                            </Button>
                          </li>
                          <li>
                            <Link
                              to="/ads-manage/ads-list"
                              className="btn border-0"
                            >
                              Cancel
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Block>
          </Layout>
        </>
      )}
    </>
  );
}

export default EditAdvert;
