import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import Layout from "../layout/default";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Icon,
  Block,
  BlockTitle,
  BlockHead,
  BlockHeadBetween,
  BlockHeadContent,
  DatePicker,
  TimePicker,
} from "../components";

import { monthList } from "../utilities";
//today, yesterday, currentMonth,
import toTwelve from "../utilities/toTwelve";

import {
  Popover,
  OverlayTrigger,
  Modal,
  Row,
  Col,
  Button,
  Card,
  Form,
} from "react-bootstrap";
import Choices from "choices.js";

import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import { successMessage, failedMessage } from "./utilities/alert-config";
import { rootURL } from "./utilities/axios-config";
import TextField from "@mui/material/TextField";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function renderEventContent(eventInfo) {
  const timeText = eventInfo.timeText,
    title = eventInfo.event._def.title,
    description = eventInfo.event._def.extendedProps.description,
    view = eventInfo.view.type,
    popover = (
      <Popover className="event-popover">
        <Popover.Header as="h3">{title}</Popover.Header>
        <Popover.Body>{description}</Popover.Body>
      </Popover>
    );
  return (
    <>
      {timeText && view === "dayGridMonth" && (
        <div className="fc-daygrid-event-dot"></div>
      )}
      {timeText && <div className="fc-event-time">{timeText}</div>}
      <div className="fc-event-title">{title}</div>
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="top"
        overlay={popover}
      >
        <div
          className="fc-event-popover"
          style={{ position: "absolute", inset: "0" }}
        ></div>
      </OverlayTrigger>
    </>
  );
}

function EventCategory({ value, ...props }) {
  const eventCategory = useRef(null);
  useLayoutEffect(() => {
    let choicesCat = new Choices(eventCategory.current, {
      silent: true,
      allowHTML: false,
      searchEnabled: true,
      placeholder: true,
      placeholderValue: null,
      searchPlaceholderValue: "Search",
      shouldSort: false,
      choices: [
        { value: "event-primary", label: "Company" },
        { value: "event-success", label: "Other Events" },
        { value: "event-info", label: "Production/Shooting Dates" },
        { value: "event-warning", label: "Meeting" },
        { value: "event-danger", label: "Aukiss Events" },
        { value: "event-dark", label: "Private" },
        { value: "event-primary-soft", label: "Auctions" },
        { value: "event-success-soft", label: "Networking events" },
        { value: "event-info-soft", label: "Product launches" },
        { value: "event-warning-soft", label: "Fundrising" },
        { value: "event-danger-soft", label: "Sponsored" },
        { value: "event-dark-soft", label: "Sports events" },
      ],
      callbackOnCreateTemplates: function (template) {
        return {
          item: ({ classNames }, data) => {
            return template(`
                        <div class="${classNames.item} ${
              data.highlighted
                ? classNames.highlightedState
                : classNames.itemSelectable
            } ${
              data.placeholder ? classNames.placeholder : ""
            }" data-item data-id="${data.id}" data-value="${data.value}" ${
              data.active ? 'aria-selected="true"' : ""
            } ${data.disabled ? 'aria-disabled="true"' : ""}>
                            <span class="fc-select-dot fc-${
                              data.value
                            }"></span> ${data.label}
                        </div>
                        `);
          },
          choice: ({ classNames }, data) => {
            return template(`
                        <div class="${classNames.item} ${
              classNames.itemChoice
            } ${
              data.disabled
                ? classNames.itemDisabled
                : classNames.itemSelectable
            }" data-select-text="${this.config.itemSelectText}" data-choice ${
              data.disabled
                ? 'data-choice-disabled aria-disabled="true"'
                : "data-choice-selectable"
            } data-id="${data.id}" data-value="${data.value}" ${
              data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
            }>
                            <span class="fc-select-dot fc-${
                              data.value
                            }"></span> ${data.label}
                        </div>
                        `);
          },
        };
      },
    });
    choicesCat.setChoiceByValue(value);
  });
  return <Form.Select ref={eventCategory} {...props}></Form.Select>;
}

function AppCalendar() {
  // eslint-disable-next-line
  const modalForm = useRef(null);
  const selectEvent = [];
  const formDefault = {
    opened: false,
  };
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(selectEvent);
  const [previewModal, setPreviewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [formModal, setFormModal] = useState(formDefault);
  const [loading, setloading] = useState(false);
  const [assigneeData, setassigneeData] = useState([]);
  const [assignee, setassignee] = useState([]);

  const tableRecords = async () => {
    try {
      setloading(true);
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/all_calendar_cpanel`;

      //setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            // console.log(row?.data)
            //setItems(row?.data);
            setEvents(row?.data);
            //successMessage(row?.message);
            //setLoading(false);
            setloading(false);
          } else if (row?.success === false) {
            failedMessage(row?.message);
            setloading(false);
            //setLoading(false);
          }
        })
        .finally(() => {
          //setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableRecordAssigne = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/assme_cpanel`;

      //setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            //setItems(row?.data);
            setassigneeData(row?.data);
            // successMessage(row?.message);
            //setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            //setLoading(false);
          }
        })
        .finally(() => {
          // setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // Mock an API call.
    tableRecords();
    tableRecordAssigne();
    //buildAndSave(kanbanCustomData);
    // eslint-disable-next-line
  }, []);

  function closePreviewModal() {
    setPreviewModal(false);
  }

  function openDeleteModal() {
    setPreviewModal(false);
    setDeleteModal(true);
  }

  function closeDeleteModal() {
    setDeleteModal(false);
    setSelectedEvent(selectEvent);
  }

  function openAddModal() {
    const updateFormModal = {
      ...formModal,
      opened: true,
      modalTitle: "Add Event",
      action: "add",
      eventTitle: "",
      eventStartTime: "",
      eventStartDate: "",
      eventEndTime: "",
      eventEndDate: "",
      eventDescription: "",
      eventCategory: "",
      assignee: [],
    };
    setFormModal(updateFormModal);
  }

  function openEditModal() {
    setPreviewModal(false);
    const updateFormModal = {
      ...formModal,
      opened: true,
      modalTitle: "Edit Event",
      action: "edit",
      eventTitle: selectedEvent.title,
      eventStartTime: selectedEvent.startTime,
      eventStartDate: selectedEvent.startDate,
      eventEndTime: selectedEvent.endTime,
      eventEndDate: selectedEvent.endDate,
      eventDescription: selectedEvent.description,
      eventCategory: selectedEvent.className,
      assignee: selectedEvent.assignee,
    };

    setFormModal(updateFormModal);
  }

  function closeFormModal() {
    const updateFormModal = { ...formModal, opened: false };
    setFormModal(updateFormModal);
  }

  const globalFcStyle = `
        .fc-list-event-title {
          position:relative;
        }
    `;

  function handleEventClick(eventInfo) {
    // console.log("event", eventInfo)
    let title = eventInfo.event._def.title;
    let description = eventInfo.event._def.extendedProps.description;
    let start = eventInfo.event._instance.range.start;
    let startDate =
      start.getFullYear() +
      "-" +
      String(start.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(start.getDate()).padStart(2, "0");
    let startDateEng =
      String(start.getDate()).padStart(2, "0") +
      " " +
      monthList[start.getMonth()] +
      " " +
      start.getFullYear();
    let startTime = start.toUTCString().split(" ");
    startTime = startTime[startTime.length - 2];
    startTime = startTime === "00:00:00" ? "" : startTime;
    let end = eventInfo.event._instance.range.end;
    let endDate =
      end.getFullYear() +
      "-" +
      String(end.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(end.getDate()).padStart(2, "0");
    let endDateEng =
      String(end.getDate()).padStart(2, "0") +
      " " +
      monthList[end.getMonth()] +
      " " +
      end.getFullYear();
    let endTime = end.toUTCString().split(" ");
    endTime = endTime[endTime.length - 2];
    endTime = endTime === "00:00:00" ? "" : endTime;
    let className = eventInfo.event._def.ui.classNames[0].slice(3);
    let eventId = eventInfo.event._def.publicId;
    let assigneeInfo = eventInfo.event._def.extendedProps.assignee;
    let assignee = [];
    if (
      assigneeInfo === false ||
      assigneeInfo === null ||
      assigneeInfo === []
    ) {
      assignee = [];
    } else {
      assignee = assigneeInfo;
    }

    const currentEvent = {
      ...selectedEvent,
      id: eventId,
      title: title,
      description: description,
      className: className,
      startTime: startTime,
      startDate: startDate,
      startDateEng: startDateEng,
      endTime: endTime,
      endDate: endDate,
      endDateEng: endDateEng,
      assignee: assignee,
    };
    setSelectedEvent(currentEvent);
    setassignee(assignee);
    // console.log("edit", currentEvent);
    setPreviewModal(true);
  }

  function handleDeleteEvent() {
    const updatedEvents = events.filter((item) => item.id !== selectedEvent.id);
    setEvents(updatedEvents);
    setDeleteModal(false);
    // console.log("delete", selectedEvent?.id);
    setSelectedEvent(selectEvent);
    setloading(true);
    const userToken = localStorage.getItem("userToken");
    const config = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "application/json",
      },
    };
    // setLoading(true);
    fetch(`${rootURL}/delete_calendar_cpanel/${selectedEvent?.id}`, config)
      .then((row) => row.json())
      .then((row) => {
        if (row?.success === true) {
          tableRecords();
          setloading(false);
          //setLoading(false);
        } else if (row?.success === false) {
          setloading(false);
          //setLoading(false);
        }
      })
      .finally(() => {
        //setLoading(false);
      });
  }

  function handleFormSubmit(event) {
    event.preventDefault();

    let newTitle = modalForm.current.querySelector("#eventTitle").value;
    let neWStartDate = modalForm.current.querySelector("#eventStartDate").value;
    let newStartTime = modalForm.current.querySelector("#eventStartTime").value;
    let neWEndDate = modalForm.current.querySelector("#eventEndDate").value;
    let newEndTime = modalForm.current.querySelector("#eventEndTime").value;
    let newDescription =
      modalForm.current.querySelector("#eventDescription").value;
    let neWclassName = modalForm.current.querySelector("#eventCategory").value;
    let newEvent = {
      id:
        selectedEvent.id ||
        "added-event-id-" + Math.floor(Math.random() * 9999999),
      title: newTitle,
      start: neWStartDate + (newStartTime ? "T" + newStartTime + "Z" : ""),
      end: neWEndDate + (newEndTime ? "T" + newEndTime + "Z" : ""),
      className: `fc-${neWclassName}`,
      description: newDescription,
      assignee: assignee,
    };



    // console.log("newEvent", newEvent);
    const userToken = localStorage.getItem("userToken");

    fetch(`${rootURL}/add_calendar_cpanel`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newEvent),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");

          window.location.reload();
        } else if (result.success === false) {
          failedMessage(
            result?.error?.title +
              " " +
              result?.error?.description +
              " " +
              result?.error?.end +
              " " +
              result?.error?.start +
              " " +
              result?.error?.className
          );
        }
      })
      .catch((error) => {
        setloading(false);
        failedMessage(error);
      })
      .finally(() => {});
  }

  function handleEditEvent(e) {
    const updatedEvents = events.filter((item) => item.id !== selectedEvent.id);
    setEvents(updatedEvents);

    // console.log("updated", updatedEvents);
    closeFormModal();
  }

  function handleAddEvent(e) {
    closeFormModal();
  }

  const handleAssigneeChange = (event) => {
    setassignee(event);
  };

  return (
    <>
      <Layout title="Calendar App" content="container">
        <BlockHead page>
          <BlockHeadBetween>
            <BlockHeadContent>
              <BlockTitle>Calendar</BlockTitle>
              <p>Aukiss Calendar</p>
            </BlockHeadContent>
            <BlockHeadContent tools>
              <ul className="d-flex gap g-3 justify-content-center">
                <li>
                  <Button variant="primary" action="add" onClick={openAddModal}>
                    <Icon name="plus"></Icon>
                    <span>Add Event</span>
                  </Button>
                </li>
              </ul>
            </BlockHeadContent>
          </BlockHeadBetween>
        </BlockHead>
        <Block>
          {loading ? (
            <CircularProgress size={20} thickness={4} />
          ) : (
            <>
              <Card>
                <Card.Body>
                  <style>{globalFcStyle}</style>
                  <FullCalendar
                    events={events}
                    plugins={[
                      dayGridPlugin,
                      timeGridPlugin,
                      interactionPlugin,
                      bootstrapPlugin,
                      listPlugin,
                    ]}
                    initialView="dayGridMonth"
                    timeZone="UTC"
                    themeSystem="bootstrap"
                    headerToolbar={{
                      left: "title prev,next",
                      center: null,
                      right:
                        "today dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                    }}
                    height={800}
                    contentHeight={780}
                    aspectRatio={3}
                    editable={true}
                    droppable={true}
                    views={{
                      dayGridMonth: {
                        dayMaxEventRows: 2,
                      },
                    }}
                    eventContent={renderEventContent}
                    eventClick={handleEventClick}
                  />
                </Card.Body>
              </Card>
            </>
          )}
        </Block>
      </Layout>
      <Modal show={previewModal} onHide={closePreviewModal}>
        <Modal.Header closeButton>
          <Modal.Title className="event-title" as="h4">
            {selectedEvent.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row className="g-3">
              <Col xs="6">
                <h6 className="overline-title">Start Time</h6>
                <p className="event-start small">
                  {selectedEvent.startDateEng}{" "}
                  {selectedEvent.startTime &&
                    ` - ${toTwelve(selectedEvent.startTime)}`}
                </p>
              </Col>
              <Col xs="6">
                <h6 className="overline-title">End Time</h6>
                <p className="event-end small">
                  {selectedEvent.endDateEng}{" "}
                  {selectedEvent.endTime &&
                    ` - ${toTwelve(selectedEvent.endTime)}`}
                </p>
              </Col>
              <Col xs="12">
                <h6 className="overline-title">Event Details</h6>
                <p className="event-description small">
                  {selectedEvent.description}
                </p>
              </Col>

              <Col xs="12">
                <h6 className="overline-title">Event Assignees</h6>
                {selectedEvent?.assignee && (
                  <p className="event-description small">
                    {selectedEvent?.assignee[0]?.firstName}{" "}
                    {selectedEvent?.assignee[0]?.lastName}
                    <br />
                    {selectedEvent?.assignee[1]?.firstName}{" "}
                    {selectedEvent?.assignee[1]?.lastName}
                    <br />
                    {selectedEvent?.assignee[2]?.firstName}{" "}
                    {selectedEvent?.assignee[2]?.lastName}
                    <br />
                    {selectedEvent?.assignee[3]?.firstName}{" "}
                    {selectedEvent?.assignee[3]?.lastName}
                    <br />
                    {selectedEvent?.assignee[4]?.firstName}{" "}
                    {selectedEvent?.assignee[4]?.lastName}
                    <br />
                    {selectedEvent?.assignee[5]?.firstName}{" "}
                    {selectedEvent?.assignee[5]?.lastName}
                    <br />
                    {selectedEvent?.assignee[6]?.firstName}{" "}
                    {selectedEvent?.assignee[6]?.lastName}
                    <br />
                    {selectedEvent?.assignee[7]?.firstName}{" "}
                    {selectedEvent?.assignee[7]?.lastName}
                    <br />
                    {selectedEvent?.assignee[8]?.firstName}{" "}
                    {selectedEvent?.assignee[8]?.lastName}
                    <br />
                    {selectedEvent?.assignee[9]?.firstName}{" "}
                    {selectedEvent?.assignee[9]?.lastName}
                    <br />
                    {selectedEvent?.assignee[10]?.firstName}{" "}
                    {selectedEvent?.assignee[10]?.lastName}
                    <br />
                    {selectedEvent?.assignee[11]?.firstName}{" "}
                    {selectedEvent?.assignee[11]?.lastName}
                    <br />
                    {selectedEvent?.assignee[12]?.firstName}{" "}
                    {selectedEvent?.assignee[12]?.lastName}
                    <br />
                    {selectedEvent?.assignee[13]?.firstName}{" "}
                    {selectedEvent?.assignee[13]?.lastName}
                    <br />
                    {selectedEvent?.assignee[14]?.firstName}{" "}
                    {selectedEvent?.assignee[14]?.lastName}
                    <br />
                    {selectedEvent?.assignee[15]?.firstName}{" "}
                    {selectedEvent?.assignee[15]?.lastName}
                    <br />
                    {selectedEvent?.assignee[16]?.firstName}{" "}
                    {selectedEvent?.assignee[16]?.lastName}
                    <br />
                    {selectedEvent?.assignee[17]?.firstName}{" "}
                    {selectedEvent?.assignee[17]?.lastName}
                    <br />
                    {selectedEvent?.assignee[18]?.firstName}{" "}
                    {selectedEvent?.assignee[18]?.lastName}
                    <br />
                    {selectedEvent?.assignee[19]?.firstName}{" "}
                    {selectedEvent?.assignee[19]?.lastName}
                    <br />
                    {selectedEvent?.assignee[20]?.firstName}{" "}
                    {selectedEvent?.assignee[20]?.lastName}
                  </p>
                )}
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={openDeleteModal}>
            Delete
          </Button>
          <Button variant="primary" onClick={openEditModal}>
            Edit Event
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={deleteModal} onHide={closeDeleteModal}>
        <div className="modal-content">
          <div className="modal-body p-5 text-center">
            <div className="media media-rounded media-xxl media-middle mx-auto text-bg-danger mb-4">
              <em className="icon ni ni-cross"></em>
            </div>
            <h3>Are You Sure ?</h3>
            <p className="small">
              This event data will be removed permanently.
            </p>
            <ul className="d-flex gap g-3 justify-content-center pt-1">
              <li>
                <Button variant="success" onClick={handleDeleteEvent}>
                  Yes Delete it!
                </Button>
              </li>
              <li>
                <Button
                  variant="danger"
                  className="btn-soft"
                  onClick={closeDeleteModal}
                >
                  Cancel
                </Button>
              </li>
            </ul>
          </div>
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0 p-3"
            onClick={closeDeleteModal}
            aria-label="Close"
          ></button>
        </div>
      </Modal>

      <Modal show={formModal.opened} onHide={closeFormModal} size="lg">
        <Form ref={modalForm} onSubmit={handleFormSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="event-title" as="h4">
              {formModal.modalTitle}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="g-gs">
              <Col xs="12">
                <Form.Group>
                  <Form.Label htmlFor="eventTitle">Event Title</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="eventTitle"
                      type="text"
                      defaultValue={formModal.eventTitle}
                      placeholder="Event Title"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group>
                  <Form.Label htmlFor="eventStartDate">Event Starts</Form.Label>
                  <Row className="g-2">
                    <Col xs="7">
                      <div className="form-control-wrap">
                        <DatePicker
                          id="eventStartDate"
                          value={formModal.eventStartDate}
                          placeholder="yyyy-mm-dd"
                        />
                      </div>
                    </Col>
                    <Col xs="5">
                      <div className="form-control-wrap">
                        <TimePicker
                          id="eventStartTime"
                          value={formModal.eventStartTime}
                          placeholder="hh:mm"
                        />
                      </div>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group>
                  <Form.Label htmlFor="eventEndDate">Event Ends</Form.Label>
                  <Row className="g-2">
                    <Col xs="7">
                      <div className="form-control-wrap">
                        <DatePicker
                          id="eventEndDate"
                          value={formModal.eventEndDate}
                          placeholder="yyyy-mm-dd"
                        />
                      </div>
                    </Col>
                    <Col xs="5">
                      <div className="form-control-wrap">
                        <TimePicker
                          id="eventEndTime"
                          value={formModal.eventEndTime}
                          placeholder="hh:mm"
                        />
                      </div>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col xs="12">
                <Form.Group>
                  <Form.Label htmlFor="eventDescription">
                    Event Description
                  </Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="eventDescription"
                      as="textarea"
                      placeholder="Event Description"
                      defaultValue={formModal.eventDescription}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col xs="12">
                <Form.Group className="fc-category-select">
                  <Form.Label htmlFor="eventCategory">
                    Event Category
                  </Form.Label>
                  <div className="form-control-wrap">
                    <EventCategory
                      id="eventCategory"
                      value={formModal.eventCategory}
                      placeholder="Select Category"
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col xs="12">
                <Form.Group className="fc-category-select">
                  <Form.Label htmlFor="eventCategory">
                    Event Assignee
                  </Form.Label>
                  <div className="form-control-wrap">
                    <Autocomplete
                      multiple
                      fullWidth
                      id="checkboxes-tags-demo"
                      value={assignee}
                      required
                      options={assigneeData}
                      onChange={(event, newValue) => {
                        handleAssigneeChange(newValue);
                      }}
                      disableCloseOnSelect
                      getOptionLabel={(option) =>
                        option.firstName + "-" + option.lastName
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.firstName + "-" + option.lastName}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Assignee"
                          placeholder="Select Assignee"
                        />
                      )}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={closeFormModal}>
              Discard
            </Button>
            {formModal.action === "add" && (
              <Button variant="primary" type="submit" onClick={handleAddEvent}>
                Add Event
              </Button>
            )}
            {formModal.action === "edit" && (
              <Button variant="primary" type="submit" onClick={handleEditEvent}>
                Update Event
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default AppCalendar;
