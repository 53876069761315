import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
// import { Icon } from "../../components";
// import { toInitials } from "../../utilities";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
// import FormControl from "@mui/material/FormControl";
import { rootURL } from "../utilities/axios-config";
import { successMessage, failedMessage } from "../utilities/alert-config";
import CircularProgress from '@mui/material/CircularProgress';

function RadioCategoryEdit() {
  const { id } = useParams();
  const history = useNavigate();
  const [name, setname] = useState("");
  
 
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userToken = localStorage.getItem("userToken");
    setLoading2(true);
    let slug = name;
    let description = name;
    const userObj = {
      id,
      name,
      slug,
      description,
    };

    fetch(`${rootURL}/updateradiocategory`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");
          setTimeout(() => {
            history(`/radiocategory-manage/radiocategory-list`);
          }, 3000);
        } else if (result.success === false) {
          setLoading2(false);
          failedMessage(result.message.email);
        }
      })
      .catch((error) => {
        setLoading2(false);
        failedMessage("Internal Server Error");
      })
      .finally(() => {
        setLoading2(false);
      });
  };



  const tableUser = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/radiocategoryshow/${id}`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setname(row?.data?.name);
            setLoading(false);
          } else if (row?.success === false) {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          failedMessage("Internal Server Error");
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  // grabs the id form the url and loads the corresponding data
  useEffect(() => {
  
    tableUser();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress size={70} thickness={4} />
          </div>
        </>
      ) : (
        <>
          <Layout title="Category Edit" content="container">
            <Block.Head>
              <Block.HeadBetween>
              <Block.HeadContent>
                  <div className="d-flex flex-column flex-md-row align-items-md-center">
                    </div>
                </Block.HeadContent>
              
                <Block.HeadContent>
                  <ul className="d-flex gap g-2">
                    <li className="d-none d-md-block">
                      <Link
                        to={`/radiocategory-manage/radiocategory-list`}
                        className="btn btn-soft btn-primary"
                      >
                        {/* <Icon name="user"></Icon> */}
                        <span>Back</span>
                      </Link>
                    </li>
                    <li className="d-md-none">
                      <Link
                        to={`/radiocategory-manage/radiocategory-list`}
                        className="btn btn-soft btn-primary btn-icon"
                      >
                        {/* <Icon name="user"></Icon> */}
                      </Link>
                    </li>
                  </ul>
                </Block.HeadContent>
              </Block.HeadBetween>
            </Block.Head>
            <Block>
              <Card className="card-gutter-md">
                <Card.Body>
                  <div className="bio-block">
                    <h4 className="bio-block-title mb-4">Edit Category</h4>
                    <Form onSubmit={handleSubmit}>
                      <Row className="g-3">
                        <Col lg="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="name">
                               Name *
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="text"
                                id="name"
                                value={name ?? ""}
                                placeholder="Name"
                                required
                                onChange={(e) => setname(e.target.value)}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        
                        <Col lg="12">
                          <Button variant="primary" type="submit">
                            {loading2 ? "Updating...." : "Update"}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </Block>
          </Layout>
        </>
      )}
    </>
  );
}

export default RadioCategoryEdit;
