import { useState, useEffect } from "react";
import "../../assets/scss/libs/editors/quill.scss";

// import AWS from "aws-sdk";
import { Card, Form, Row, Col } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/default";

import Block from "../../components/Block/Block";
import CircularProgress from "@mui/material/CircularProgress";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
// import FormControl from "@mui/material/FormControl";
// import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
// import Stack from "@mui/material/Stack";
//import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
//import dayjs from "dayjs";
import { successMessage, failedMessage } from "../utilities/alert-config";
//import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
//import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import //Select,
//Quill,
//FileUpload,
//Tags,
//ImageUpload,
//DatePicker,
// Tinymce,
// {Icon} from
"../../components";
import { rootURL } from "../utilities/axios-config";
// import Checkbox from "@mui/material/Checkbox";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const Input = styled("input")({
//   display: "none",
// });

function EditSubscription() {
  const { id } = useParams();
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [plan_name, setplan_name] = useState("");
  const [sub_name, setsub_name] = useState("");
  const [description, setdescription] = useState("");
  const [amount, setamount] = useState("");
  const [amount_sub, setamount_sub] = useState("");
  const [plan, setplan] = useState("");


  const [loading2, setloading2] = useState(false);

  const tableActorShow = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/subscriptiontypesshow/${id}`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setplan_name(row?.data?.plan_name);
            setsub_name(row?.data?.sub_name);
            setdescription(row?.data?.description);
            setamount(row?.data?.amount);
            setamount_sub(row?.data?.amount_sub);
            setplan(row?.data?.plan);
          

            setLoading(false);
          } else if (row?.success === false) {
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    tableActorShow();
    // eslint-disable-next-line
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userToken = localStorage.getItem("userToken");
    setloading2(true);
    const actorObj = {
      id,
      plan_name,
      sub_name,
      description,
      amount,
      amount_sub,
      plan
    };

    fetch(`${rootURL}/updatesubscriptiontypes`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(actorObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");

          setTimeout(() => {
            history("/configuration-manage/subscription-list");
          }, 3000);
        } else if (result.success === false) {
          setloading2(false);
          failedMessage(result.message);
        }
      })
      .catch((error) => {
        setloading2(false);
        failedMessage("Internal Server Error");
      })
      .finally(() => {
        setloading2(false);
      });
  };

  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress size={70} thickness={4} />
          </div>
        </>
      ) : (
        <>
          <Layout title="Edit Subscription" content="container">
            <Block.Head>
              <Block.HeadBetween>
                <Block.HeadContent>
                  <Block.Title tag="h2">Edit Subscription</Block.Title>
                  <nav>
                    <ol className="breadcrumb breadcrumb-arrow mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/configuration-manage/subscription-list">
                        Subscription
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Edit Subscription
                      </li>
                    </ol>
                  </nav>
                </Block.HeadContent>
              </Block.HeadBetween>
            </Block.Head>

            <Block>
              <Form onSubmit={handleSubmit}>
                <Row className="g-gs">
                  <Col xxl="9">
                    <div className="gap gy-4">
                      <div className="gap-col">
                        <Card className="card-gutter-md">
                          <Card.Body>
                            <Row className="g-gs">
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="plan_name">
                                    Plan Name *
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="plan_name"
                                      placeholder=" Plan Name"
                                      value={plan_name}
                                      required
                                      onChange={(e) =>
                                        setplan_name(e.target.value)
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="sub_name">
                                    Sub Name
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      as="textarea"
                                      rows="2"
                                      placeholder="Sub Name"
                                      value={sub_name}
                                      onChange={(e) =>
                                        setsub_name(e.target.value)
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="description">Description</Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      as="textarea"
                                      rows="2"
                                      placeholder="Description"
                                      value={description}
                                      onChange={(e) => setdescription(e.target.value)}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="amount">Amount</Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      as="textarea"
                                      rows="2"
                                      placeholder="Amount"
                                      value={amount}
                                      onChange={(e) => setamount(e.target.value)}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>


                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="amount_sub">Amount Sub</Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      as="textarea"
                                      rows="2"
                                      placeholder="Amount Sub"
                                      value={amount_sub}
                                      onChange={(e) => setamount_sub(e.target.value)}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="plan">Plan</Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      as="textarea"
                                      rows="2"
                                      placeholder="Plan"
                                      value={plan}
                                      onChange={(e) => setplan(e.target.value)}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>

                      <div className="gap-col">
                        <ul className="d-flex align-items-center gap g-3">
                          <li>
                            <Button type="submit" variant="contained">
                              {loading2 ? "Updating...." : "Update"}
                            </Button>
                          </li>
                          <li>
                            <Link
                              to="/configuration-manage/subscription-list"
                              className="btn border-0"
                            >
                              Cancel
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Block>
          </Layout>
        </>
      )}
    </>
  );
}

export default EditSubscription;
