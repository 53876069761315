import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon } from "../../components";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
// import FormControl from "@mui/material/FormControl";
import { rootURL } from "../utilities/axios-config";
import { successMessage, failedMessage } from "../utilities/alert-config";

function AddSales() {
  const history = useNavigate();
  const [title, settitle] = useState("");
  const [message, setmessage] = useState("");


  //const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userToken = localStorage.getItem("userToken");
    setLoading2(true);

    const tagObj = {
      title,
      message
    };

    fetch(`${rootURL}/add_sales_cpanel`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tagObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");
          //   console.log("data", result)
          setTimeout(() => {
            history(`/configuration-manage/sales-list`);
          }, 3000);
        } else if (result.success === false) {
          setLoading2(false);
          failedMessage(result.message);
        }
      })
      .catch((error) => {
        setLoading2(false);
        failedMessage("Internal Server Error");
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  // grabs the id form the url and loads the corresponding data
  useEffect(() => {}, []);

  return (
    <Layout title="Add Sales Questions" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
          
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex gap g-2">
              <li className="d-none d-md-block">
                <Link
                  to={`/configuration-manage/sales-list`}
                  className="btn btn-soft btn-primary"
                >
                  <Icon name="user"></Icon>
                  <span>Sales Questions List</span>
                </Link>
              </li>
              <li className="d-md-none">
                <Link
                  to={`/configuration-manage/sales-list`}
                  className="btn btn-soft btn-primary btn-icon"
                >
                  <Icon name="user"></Icon>
                </Link>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">Add Sales Questions</h4>
              <Form onSubmit={handleSubmit}>
                <Row className="g-3">
                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="title">Title *</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="title"
                          value={title}
                          placeholder="Title"
                          required
                          onChange={(e) => settitle(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="message">Message *</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="message"
                          value={message}
                          placeholder="Message"
                          required
                          onChange={(e) => setmessage(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>


                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {loading2 ? "Saving...." : "Save"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  );
}

export default AddSales;
