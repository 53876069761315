import { useState, useEffect } from "react";
import AWS from "aws-sdk";
import { Card, Form, Row, Col } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/default";

import Block from "../../components/Block/Block";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
// import Stack from "@mui/material/Stack";
//import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
//import dayjs from "dayjs";
import { successMessage, failedMessage } from "../utilities/alert-config";
//import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
//import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import //Select,
//Quill,
//FileUpload,
//Tags,
//ImageUpload,
//DatePicker,
// Tinymce,
"../../components";
import {
  rootURL,
  awsID,
  awsBucketName,
  awsSECRET,
} from "../utilities/axios-config";
// import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const Input = styled("input")({
//   display: "none",
// });

function KanbanEdit() {
  const { idd, labels } = useParams();
  const history = useNavigate();
  const [loading, setloading] = useState("");
  const [label, setlabel] = useState("");
  const [theme, settheme] = useState("");
  const [assignee, setassignee] = useState([]);
  const [priority, setpriority] = useState("");
  const [title, settitle] = useState("");
  const [content, setcontent] = useState("");
  const [duedate, setduedate] = useState("");
  const [id, setid] = useState("");
  const [ids, setids] = useState("");
  const [comment, setcomment] = useState("");
  const [user_id, setuser_id] = useState("");
  const [assigneeData, setassigneeData] = useState([]);
  const [picture, setpicture] = useState(
    "/images/avatar/avatar-placeholder.jpg"
  );
  // const [loading5, setLoading5] = useState(false);
  // const [progress3, setProgress3] = useState(false);
  // const [user_id, setuser_id] = useState("");
  const labelData = ["Open", "In Progress", "In Review", "Issues", "Completed"];
  const priorityData = ["High", "Medium", "Low"];
  // const containerClass = "kanban-custom-board";

  //const today = new Date().toISOString().slice(0, 10);

  const [loading2, setloading2] = useState(false);
  // const [loading3, setLoading3] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [progress, setProgress] = useState(0);
  // const [progress2, setProgress2] = useState(0);

  const tableAukissBoardShow = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };

      let url = "";
      let labs = "";
      if (labels === "InProgress") {
        let labeld = "In Progress";
        url = `${rootURL}/view_kanboard_cpanel/${idd}/${labeld}`;
        labs = labeld;
      } else if (labels === "InReview") {
        let labeld = "In Review";
        url = `${rootURL}/view_kanboard_cpanel/${idd}/${labeld}`;
        labs = labeld;
      } else {
        let labeld = labels;
        url = `${rootURL}/view_kanboard_cpanel/${idd}/${labeld}`;
        labs = labeld;
      }

      setloading2(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            // console.log("data info", row?.data);
            // eslint-disable-next-line
            row?.data?.map((arr) => {
            // console.log("arr", arr.items);
              setpicture(arr?.items?.picture ?? "");
              setlabel(arr?.items?.label ?? labs);
              settitle(arr?.items?.title ?? "");
              setcontent(arr?.items?.content ?? "");
              setassignee(arr?.items?.assignee_new ?? []);
              setduedate(arr?.items?.duedate ?? "");
              setpriority(arr?.items?.priority ?? "");
              setcomment(arr?.items?.comment ?? "");
              settheme(arr?.items?.theme ?? "");
              setid(idd);
              setids(arr?.items?.ids ?? "");
              setuser_id(arr?.items?.user_id ?? "");
            });

            setloading2(false);
          } else if (row?.success === false) {
            setloading2(false);
          }
        })
        .finally(() => {
          setloading2(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableRecordAssigne = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/assme_cpanel`;

      //setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            //setItems(row?.data);
            setassigneeData(row?.data);
            // successMessage(row?.message);
            //setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            //setLoading(false);
          }
        })
        .finally(() => {
          // setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  function create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxx4xxyxx".replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  const handleLabelChange = (event) => {
    setlabel(event.target.value);
    if (event.target.value === "Open") {
      settheme("kanban-light");
      setids("item-1");
    } else if (event.target.value === "In Progress") {
      settheme("kanban-primary");
      setids("item-2");
    } else if (event.target.value === "In Review") {
      settheme("kanban-warning");
      setids("item-3");
    } else if (event.target.value === "Issues") {
      settheme("kanban-danger");
      setids("item-4");
    } else if (event.target.value === "Completed") {
      settheme("kanban-success");
      setids("item-5");
    }
  };

  const handleAssigneeChange = (event) => {
    setassignee(event);
  };

  const handlePriorityChange = (event) => {
    setpriority(event.target.value);
  };

  const selectedFile = (e) => {
    const image = e.target.files[0];
    if (image) {
      setLoading5(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + image.name, // File name you want to save as in S3
        Body: image,
        ContentType: image.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading5(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setpicture(photoUrl);
        setLoading5(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress) {
        let uploaded = Math.round((progress.loaded / progress.total) * 100);
        setProgress(uploaded);
      });
    }
  };

  useEffect(() => {
    tableAukissBoardShow();
    tableRecordAssigne();
    // eslint-disable-next-line
  }, [idd, labels]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userToken = localStorage.getItem("userToken");
    const radioObj = {
      label,
      theme,
      id,
      ids,
      title,
      content,
      assignee,
      duedate,
      priority,
      comment,
      picture,
      user_id
    };
    // console.log('radioObj', radioObj)
    setloading(true);
    fetch(`${rootURL}/update_kanboard_cpanel`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(radioObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");

          setTimeout(() => {
           history("/apps/kanbanaukiss-list");
          }, 3000);
        } else if (result.success === false) {
          setloading(false);
          failedMessage(result.message);
        }
      })
      .catch((error) => {
        setloading(false);
        failedMessage("Internal Server Error");
      })
      .finally(() => {
        setloading(false);
      });
  };

  return (
    <>
      {loading2 ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress size={70} thickness={4} />
          </div>
        </>
      ) : (
        <>
          <Layout title="Edit Aukiss Board" content="container">
            <Block.Head>
              <Block.HeadBetween>
                <Block.HeadContent>
                  <Block.Title tag="h2">Edit Aukiss Board</Block.Title>
                  <nav>
                    <ol className="breadcrumb breadcrumb-arrow mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/apps/kanbanaukiss-list">Aukiss Board</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Edit Board
                      </li>
                    </ol>
                  </nav>
                </Block.HeadContent>
              </Block.HeadBetween>
            </Block.Head>

            <Block>
              <Form onSubmit={handleSubmit}>
                <Row className="g-gs">
                  <Col xxl="9">
                    <div className="gap gy-4">
                      <div className="gap-col">
                        <Card className="card-gutter-md">
                          <Card.Body>
                            <Row className="g-gs">
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label>Cover</Form.Label>
                                  <div className="form-control-wrap">
                                    <div className="image-upload-wrap d-flex flex-column align-items-center">
                                      {loading5 ? (
                                        <div>Upload progress {progress}%</div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="media media-huge border">
                                        <img
                                          id="image-result1add"
                                          className="w-100 h-100"
                                          src={picture}
                                          alt="avatar"
                                        />
                                      </div>
                                      <div className="pt-3">
                                        <input
                                          className="upload-image"
                                          data-target="image-result1add"
                                          id="change-avatar1add"
                                          type="file"
                                          max="1"
                                          hidden
                                          onChange={selectedFile}
                                        />
                                        <label
                                          htmlFor="change-avatar1add"
                                          className="btn btn-md btn-primary"
                                          disabled={loading5}
                                        >
                                          {loading5
                                            ? "Uploading ..."
                                            : "Upload"}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-note mt-3">
                                    Set the article thumbnail image. Only *.png,
                                    *.jpg and *.jpeg image files are accepted.
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <FormControl fullWidth>
                                    <Form.Label htmlFor="productname">
                                      Label *
                                    </Form.Label>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={label}
                                      required
                                      label="Select Label"
                                      onChange={handleLabelChange}
                                    >
                                      {labelData.map((label) => (
                                        <MenuItem key={label} value={label}>
                                          {label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Form.Group>
                              </Col>
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="productname">
                                    Title *
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="name"
                                      placeholder="Title"
                                      value={title}
                                      required
                                      onChange={(e) => {
                                        settitle(e.target.value);
                                        let ids = create_UUID();
                                        setid(ids);
                                      }}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="productname">
                                    Description *
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      as="textarea"
                                      rows="3"
                                      value={content}
                                      required
                                      placeholder=""
                                      onChange={(e) =>
                                        setcontent(e.target.value)
                                      }
                                    ></Form.Control>
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <div className="form-control-wrap">
                                  <FormControl fullWidth size="small">
                                    <Form.Label htmlFor="status_id">
                                      Assignee *
                                    </Form.Label>
                                    <Autocomplete
                                      multiple
                                      fullWidth
                                      id="checkboxes-tags-demo"
                                      value={assignee}
                                      required
                                      options={assigneeData}
                                      onChange={(event, newValue) => {
                                        handleAssigneeChange(newValue);
                                      }}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) =>
                                        option.firstName + "-" + option.lastName
                                      }
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option.firstName +
                                            "-" +
                                            option.lastName}
                                        </li>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Assignee"
                                          placeholder="Select Assignee"
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </div>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="productname">
                                    Due Date
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <input
                                      type="date"
                                      id="duedate"
                                      name="duedate"
                                      value={duedate}
                                      onChange={(newValue) => {
                                        setduedate(newValue.target.value);
                                      }}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="productname">
                                    Priority *
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={priority}
                                      required
                                      label="Select Priority"
                                      onChange={handlePriorityChange}
                                    >
                                      {priorityData.map((label) => (
                                        <MenuItem key={label} value={label}>
                                          {label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="productname">
                                    Comment
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      as="textarea"
                                      rows="3"
                                      value={comment}
                                      placeholder=""
                                      onChange={(e) =>
                                        setcomment(e.target.value)
                                      }
                                    ></Form.Control>
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>

                      <div className="gap-col">
                        <ul className="d-flex align-items-center gap g-3">
                          <li>
                            <Button type="submit" variant="contained">
                              {loading ? "Updating...." : "Update"}
                            </Button>
                          </li>
                          <li>
                            <Link
                              to="/apps/kanbanaukiss-list"
                              className="btn border-0"
                            >
                              Cancel
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Block>
          </Layout>
        </>
      )}
    </>
  );
}

export default KanbanEdit;
