import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyAWQUpOZ0O3yIQ_rwME22xFqCwlHXc6zfM",
    authDomain: "aukiss-c01c6.firebaseapp.com",
    databaseURL: "https://aukiss-c01c6.firebaseio.com",
    projectId: "aukiss-c01c6",
    storageBucket: "aukiss-c01c6.appspot.com",
    messagingSenderId: "299451668761",
    appId: "1:299451668761:web:cbc25e03b49b88d2ec8d88",
    measurementId: "G-89KPB4BMXK"
  };


export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);