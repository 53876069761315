import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Tab, Nav, Card} from "react-bootstrap";
import parse from "html-react-parser";

import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import {
  Image,
  Icon,
  Schedule,
  Media,
} from "../../components";

import { toInitials } from "../../utilities";
import { rootURL } from "../utilities/axios-config";
import CircularProgress from "@mui/material/CircularProgress";

function UserProfilePage() {
  const { id } = useParams();
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [email, setemail] = useState("");
  const [user_name, setuser_name] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [address, setaddress] = useState("");
  const [role, setrole] = useState("");
  const [facebook_url, setfacebook_url] = useState("");
  const [instagram_url, setinstagram_url] = useState("");
  const [twitter_url, settwitter_url] = useState("");
  const [youtube_url, setyoutube_url] = useState("");
  const [profile, setprofile] = useState("");
  const [photo, setphoto] = useState("");
  const [unique_user_id, setunique_user_id] = useState("");
  const [user_registered, setuser_registered] = useState("");
  const [lastsignin, setlastsignin] = useState("");
  const theme = "danger-soft";
  const avatar = "/images/avatar/c.jpg";
  const [loading, setLoading] = useState(false);
  const [paymentHistory, setpaymentHistory] = useState([]);

  const tableUser = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/userprofile/${id}`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setfirst_name(row?.data?.first_name);
            setlast_name(row?.data?.last_name);
            setemail(row?.data?.email);
            setuser_name(row?.data?.name);
            setphone_number(row?.data?.phone_number);
            setaddress(row?.data?.address);
            setrole(row?.data?.role);
            setfacebook_url(row?.data?.facebook_url);
            setinstagram_url(row?.data?.instagram_url);
            settwitter_url(row?.data?.twitter_url);
            setyoutube_url(row?.data?.youtube_url);
            setprofile(row?.data?.profile);
            setphoto(row?.data?.photo);
            setpaymentHistory(row?.paymentHistory);
            setunique_user_id(row?.data?.unique_user_id);
            setuser_registered(row?.data?.created_at);
            setlastsignin(row?.data?.lastsignin);
            setLoading(false);
          } else if (row?.success === false) {
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };


  // function dateConvert(date) {
  //  if(date){
  //   return new Date(date)?.toISOString().slice(0, 10);
  //  }else{
  //   return "";
  //  }
  // }

  // grabs the id form the url and loads the corresponding data
  useEffect(() => {
    tableUser();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress size={70} thickness={4} />
          </div>
        </>
      ) : (
        <>
          <Layout title="Users Profile" content="container">
            <Block.Head>
              <Block.HeadBetween className="align-items-start">
                <Block.HeadContent>
                  <div className="d-flex flex-column flex-md-row align-items-md-center">
                    <Media size="huge" shape="circle" variant={theme && theme}>
                      {photo ? (
                        <Image
                          src={photo ?? avatar}
                          staticImage
                          thumbnail
                          alt="user"
                        />
                      ) : (
                        <span className="fw-medium">
                          {" "}
                          {toInitials(first_name ?? "")}
                          {toInitials(last_name ?? "")}
                        </span>
                      )}
                    </Media>
                    <div className="mt-3 mt-md-0 ms-md-3">
                      <h3 className="title mb-1">
                        {first_name ?? ""} {last_name ?? ""}
                      </h3>
                      <span className="small">{role ?? ""}</span>
                      <ul className="nk-list-option pt-1">
                        <li>
                          <Icon name="map-pin"></Icon>
                          <span className="small">{address ?? ""}</span>
                        </li>
                        <li>
                          <Icon name="building"></Icon>
                          <span className="small">{phone_number ?? ""}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Block.HeadContent>
                <Block.HeadContent>
                  {/* <div className="d-flex gap g-3">
                        <div className="gap-col">
                            <div className="box-dotted py-2">
                                <div className="d-flex align-items-center">
                                    <div className="h4 mb-0">{user.followers}k</div>
                                    <span className="change up ms-1 small">
                                        <Icon name="arrow-down"></Icon>
                                    </span>
                                </div>
                                <div className="smaller">Followers</div>
                            </div>
                        </div>
                        <div className="gap-col">
                            <div className="box-dotted py-2">
                                <div className="d-flex align-items-center">
                                    <div className="h4 mb-0">{user.following}k</div>
                                    <span className="change up ms-1 small">
                                        <Icon name="arrow-up"></Icon>
                                    </span>
                                </div>
                                <div className="smaller">Following</div>
                            </div>
                        </div>
                    </div> */}
                </Block.HeadContent>
              </Block.HeadBetween>
            </Block.Head>

            <Tab.Container defaultActiveKey="tabOne">
              <Block.HeadBetween>
                <div className="gap-col">
                  <Nav variant="pills" className="nav-pills-border gap g-3">
                    <Nav.Item>
                      <Nav.Link eventKey="tabOne">Overview</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                            <Nav.Link>Project</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link>Documents</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link>Disabled</Nav.Link>
                        </Nav.Item> */}
                  </Nav>
                </div>
                <div className="gap-col">
                  <ul className="d-flex gap g-2">
                    <li className="d-none d-md-block">
                      <Link
                        to={`/user-manage/user-edit/${id}`}
                        className="btn btn-soft btn-primary"
                      >
                        <Icon name="edit"></Icon>
                        <span>Edit Profile</span>
                      </Link>
                    </li>
                    <li className="d-md-none">
                      <Link
                        to={`/user-manage/user-edit/${id}`}
                        className="btn btn-soft btn-primary btn-icon"
                      >
                        <Icon name="edit"></Icon>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Block.HeadBetween>

              <Block className="mt-4">
                <Tab.Content>
                  <Tab.Pane eventKey="tabOne">
                    <Card className="card-gutter-md">
                      <div className="card-row card-row-lg col-sep col-sep-lg">
                        <div className="card-aside">
                          <Card.Body>
                            <div className="bio-block">
                              <h4 className="bio-block-title">Details</h4>
                              <ul className="list-group list-group-borderless small">
                                <li className="list-group-item">
                                  <span className="title fw-medium w-40 d-inline-block">
                                    Unique Code:
                                  </span>
                                  <span className="text">{unique_user_id ?? ""}</span>
                                </li>
                                <li className="list-group-item">
                                  <span className="title fw-medium w-40 d-inline-block">
                                    User Name:
                                  </span>
                                  <span className="text">
                                    {user_name ?? ""} 
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  <span className="title fw-medium w-40 d-inline-block">
                                    Full Name:
                                  </span>
                                  <span className="text">
                                    {first_name ?? ""} {last_name ?? ""}
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  <span className="title fw-medium w-40 d-inline-block">
                                    Email:
                                  </span>
                                  <span className="text">{email ?? ""}</span>
                                </li>
                                <li className="list-group-item">
                                  <span className="title fw-medium w-40 d-inline-block">
                                    Address:
                                  </span>
                                  <span className="text">{address ?? ""}</span>
                                </li>
                                <li className="list-group-item">
                                  <span className="title fw-medium w-40 d-inline-block">
                                    Last Seen Date
                                  </span>
                                  <span className="text">
                                    {lastsignin ?? ""}
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  <span className="title fw-medium w-40 d-inline-block">
                                    Joining Date
                                  </span>
                                  <span className="text">
                                    {user_registered ?? ""}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            {/* <div className="bio-block">
                                            <h4 className="bio-block-title mb-2">Skills</h4>
                                            <ul className="d-flex flex-wrap gap gx-1">
                                                {user.skills.map((item) => {
                                                    return (
                                                        <li key={item}>
                                                            <Link to="/user-manage/user-profile" className="badge text-bg-secondary-soft">{item}</Link>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div> */}
                            <div className="bio-block">
                              <h4 className="bio-block-title mb-3">Social</h4>
                              <ul className="d-flex flex-wrap gap g-2">
                                <li>
                                  <Media
                                    size="sm"
                                    to={twitter_url ?? ""}
                                    className="text-bg-info"
                                  >
                                    <Icon name="twitter"></Icon>
                                  </Media>
                                </li>

                                <li>
                                  <Media
                                    size="sm"
                                    to={instagram_url ?? ""}
                                    className="text-bg-danger"
                                  >
                                    <Icon name="instagram"></Icon>
                                  </Media>
                                </li>

                                <li>
                                  <Media
                                    size="sm"
                                    to={facebook_url ?? ""}
                                    className="text-bg-info"
                                  >
                                    <Icon name="facebook-fill"></Icon>
                                  </Media>
                                </li>

                                <li>
                                  <Media
                                    size="sm"
                                    to={youtube_url ?? ""}
                                    className="text-bg-danger"
                                  >
                                    <Icon name="youtube"></Icon>
                                  </Media>
                                </li>
                              </ul>
                            </div>
                          </Card.Body>
                        </div>
                        <div className="card-content col-sep">
                          <Card.Body>
                            <div className="bio-block">
                              <h4 className="bio-block-title">About Me</h4>
                              {parse(profile ?? "")}
                              {/* <Row className="g-gs pt-2">
                                                <Col lg="6">
                                                    <div className="small">Designation:</div>
                                                    <h5 className="small">{user.designation}</h5>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="small">Website:</div>
                                                    <h5 className="small">{user.website}</h5>
                                                </Col>
                                            </Row> */}
                            </div>
                          </Card.Body>
                          <Card.Body>
                            <div className="bio-block">
                              <h4 className="bio-block-title">
                                Payment History
                              </h4>
                              {id && (
                                <Schedule className="mt-4">
                                  {paymentHistory.map((item, index) => {
                                    return (
                                      <Schedule.Item
                                        symbol="active"
                                        key={index}
                                        contentClass={`${
                                          item?.type === "file"
                                            ? "nk-schedule-content-no-border"
                                            : item?.type === "alert"
                                            ? "nk-schedule-content-no-border flex-grow-1"
                                            : ""
                                        }`}
                                      >
                                        <span className="h6">
                                          Subscription:{" "}
                                          {item?.user_subs_plan?.plan_name}
                                        </span>
                                        <div className="h6">
                                          Amount: $ {item?.user_subs_plan?.amount}
                                        </div>
                                        <span className="h6">
                                          Expired At: {(item?.expired_at)}
                                        </span>
                                        {/* <div className="h6">
                                          Purchase Id: {item?.purchase_id}
                                        </div>
                                        <div className="h6">
                                          Product Id: {item?.product_id}
                                        </div> */}
                                        <div className="h6">
                                          Purchase Date: {(item?.purchase_date)}
                                        </div>

                                        <div className="h6">
                                          Payment Type: {item?.p_type}
                                        </div>
                                      </Schedule.Item>
                                    );
                                  })}
                                </Schedule>
                              )}
                            </div>
                          </Card.Body>
                        </div>
                      </div>
                    </Card>
                  </Tab.Pane>
                </Tab.Content>
              </Block>
            </Tab.Container>
          </Layout>
        </>
      )}
    </>
  );
}

export default UserProfilePage;
