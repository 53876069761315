import axios from "axios";
const userToken = localStorage.getItem('userToken');

let rootURL = "https://api.aukiss.tv/api";
let awsID = "AKIA6ODVADTHMK6LOFUU";
let awsSECRET = "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";
let awsBucketName = "aukisscontent";
let PayStackLiveKey = "sk_live_ce52560aa2a3c9b7d3f391d48015230734814c52";

const authFetch = axios.create({
    baseURL: rootURL
});


authFetch.interceptors.request.use(
    (config)=>{
        config.headers.common['Authorization'] = `Bearer ${userToken}`
    },
    (error)=>{
        return Promise.reject(error);
    }
);

authFetch.interceptors.response.use(
    (response) => {
        return response;
    },
    (error)=> {
        console.log('Response Error', error.response);
        // if(error.response.status === 401){
        //     console.log('Authentication Error', error.response.data.message);
        // }

        return Promise.reject(error)
    }
);


export {rootURL, authFetch, awsID, awsSECRET, awsBucketName,PayStackLiveKey}