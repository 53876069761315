import { useState, useEffect } from "react";
// import ReactPlayer from "react-player";
import { Player, ControlBar } from "video-react";
import "video-react/dist/video-react.css";
import AWS from "aws-sdk";
import { Card, Form, Row, Col } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/default";
import { makeStyles } from "@mui/styles";
import Block from "../../components/Block/Block";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import "./movieplayer.scss";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
// import Stack from "@mui/material/Stack";
//import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
//import dayjs from "dayjs";
import { successMessage, failedMessage } from "../utilities/alert-config";
//import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
//import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
// import Form from 'react-bootstrap/Form';

//import //Select,
//Quill,
//FileUpload,
//Tags,
//ImageUpload,
//DatePicker,
// Tinymce,
// {Icon} from
// "../../components";

import {
  rootURL,
  awsID,
  awsBucketName,
  awsSECRET,
} from "../utilities/axios-config";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    fontWeight: "bold",
  },
  item: {
    padding: 5,
  },
  backgroundColor: {
    backgroundColor: "#2CB23B",
  },
}));

function MovieView() {
  const { id } = useParams();
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [image, setimage] = useState("/images/avatar/avatar-placeholder.jpg");
  const [trailler_url, settrailler_url] = useState("");
  const [video_url, setvideo_url] = useState("");
  const [hori_image, sethori_image] = useState(
    "/images/avatar/avatar-placeholder.jpg"
  );
  const [sub_title_trailler_url, setsub_title_trailler_url] = useState("");
  const [sub_title_video_url, setsub_title_video_url] = useState("");
  const [post_title, setpost_title] = useState("");
  const [post_excerpt, setpost_excerpt] = useState("");
  const [movireleasedate, setmovireleasedate] = useState("");
  const [movie_time_duration, setmovie_time_duration] = useState("");
  const [movie_censor_rating, setmovie_censor_rating] = useState("");
  const [content_type, setcontent_type] = useState("");
  const contents = ["Paid", "Free"];
  const [catalog_visibility, setcatalog_visibility] = useState(false);
  const [moviefeats, setmoviefeats] = useState(false);
  const [cats_ids, setcats_ids] = useState(false);
  const [newly_cats_id, setnewly_cats_id] = useState(false);
  const [act_id, setact_id] = useState(false);
  const [drama_id, setdrama_id] = useState(false);
  const [recommended_movies, setrecommended_movies] = useState([]);
  const [recommended_moviesData, setrecommended_moviesData] = useState([]);
  const [imdb_id, setimdb_id] = useState("");
  const [tmdb_id, settmdb_id] = useState("");
  const [categ, setcateg] = useState([]);
  const [categData, setcategData] = useState([]);
  const [youtube_link, setyoutube_link] = useState("");
  const [country, setcountry] = useState([]);
  const [scheduledate, setscheduledate] = useState("");
  const [main_header, setmain_header] = useState(false);
  const [docs_id, setdocs_id] = useState(false);
  const [urban_class_id, seturban_class_id] = useState(false);
  const [kuma_id, setkuma_id] = useState(false);
  const [comdy_id, setcomdy_id] = useState(false);
  const [middle_header, setmiddle_header] = useState(false);
  const [persons, setpersons] = useState([]);
  const [castdependants, setcastdependants] = useState([]);
  const [castname, setcastname] = useState("");
  const [cast, setcast] = useState({});
  const [character, setcharacter] = useState("");
  const [crewdependants, setcrewdependants] = useState([]);
  const [crewname, setcrewname] = useState("");
  const [crew, setcrew] = useState({});
  const [crewcharacter, setcrewcharacter] = useState("");
  const [post_state_id, setpost_state_id] = useState("");
  const [poststatus, setpoststatus] = useState([]);
  const [postnotification, setpostnotification] = useState(false);

  //const today = new Date().toISOString().slice(0, 10);

  const [loading2, setloading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [loading6, setLoading6] = useState(false);
  const [loading7, setLoading7] = useState(false);
  const [loading8, setLoading8] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progress2, setProgress2] = useState(0);
  const [progress3, setProgress3] = useState(0);
  const [progress4, setProgress4] = useState(0);
  const [progress5, setProgress5] = useState(0);
  const [progress6, setProgress6] = useState(0);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [indexValue, setIndexValue] = useState("");
  const [indexValue2, setIndexValue2] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  const tableMovieShow = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/movieshow/${id}`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setpost_title(row?.data?.post_title ?? "");
            setpost_excerpt(row?.data?.post_excerpt ?? "");
            if (row?.data?.catalog_visibility === null) {
              setcatalog_visibility(false);
            } else if (row?.data?.catalog_visibility === 1) {
              setcatalog_visibility(true);
            } else {
              setcatalog_visibility(false);
            }
            setcontent_type(row?.data?.content_type ?? "");
            setcastdependants(row?.data?.p_cast?.castdependants ?? []);
            setcrewdependants(row?.data?.p_crew?.crewdependants ?? []);
            if (row?.data?.cats_ids === null) {
              setcats_ids(false);
            } else if (row?.data?.cats_ids === 1) {
              setcats_ids(true);
            } else {
              setcats_ids(false);
            }

            if (row?.data?.newly_cats_id === null) {
              setnewly_cats_id(false);
            } else if (row?.data?.newly_cats_id === 2) {
              setnewly_cats_id(true);
            } else {
              setnewly_cats_id(false);
            }

            if (row?.data?.docs_id === null) {
              setdocs_id(false);
            } else if (row?.data?.docs_id === 4) {
              setdocs_id(true);
            } else {
              setdocs_id(false);
            }

            if (row?.data?.urban_class_id === null) {
              seturban_class_id(false);
            } else if (row?.data?.urban_class_id === 5) {
              seturban_class_id(true);
            } else {
              seturban_class_id(false);
            }

            if (row?.data?.kuma_id === null) {
              setkuma_id(false);
            } else if (row?.data?.kuma_id === 6) {
              setkuma_id(true);
            } else {
              setkuma_id(false);
            }

            if (row?.data?.comdy_id === null) {
              setcomdy_id(false);
            } else if (row?.data?.comdy_id === 7) {
              setcomdy_id(true);
            } else {
              setcomdy_id(false);
            }

            if (row?.data?.act_id === null) {
              setact_id(false);
            } else if (row?.data?.act_id === 8) {
              setact_id(true);
            } else {
              setact_id(false);
            }

            if (row?.data?.drama_id === null) {
              setdrama_id(false);
            } else if (row?.data?.drama_id === 9) {
              setdrama_id(true);
            } else {
              setdrama_id(false);
            }

            if (row?.data?.main_header === null) {
              setmain_header(false);
            } else if (row?.data?.main_header === 1) {
              setmain_header(true);
            } else {
              setmain_header(false);
            }

            setmovie_censor_rating(row?.data?.movie_censor_rating ?? "");
            setmovie_time_duration(row?.data?.movie_time_duration ?? "");
            const today = new Date(row?.data?.movireleasedate)
              .toISOString()
              .slice(0, 10);
            setmovireleasedate(today ?? "");
            if (row?.data?.moviefeats === null) {
              setmoviefeats(false);
            } else if (row?.data?.moviefeats === 1) {
              setmoviefeats(true);
            } else {
              setmoviefeats(false);
            }
            setimdb_id(row?.data?.imdb_id ?? "");
            if (
              row?.data?.recommended_movies === false ||
              row?.data?.recommended_movies === null ||
              row?.data?.recommended_movies === []
            ) {
              setrecommended_movies([]);
            } else {
              setrecommended_movies(row?.data?.recommended_movies ?? []);
            }

            if (
              row?.data?.categ === false ||
              row?.data?.categ === null ||
              row?.data?.categ === []
            ) {
              setcateg([]);
            } else {
              setcateg(row?.data?.categ ?? []);
            }

            if (
              row?.data?.country === false ||
              row?.data?.country === null ||
              row?.data?.country === []
            ) {
              setcountry([]);
            } else {
              setcountry(row?.data?.country ?? []);
            }

            settmdb_id(row?.data?.tmdb_id ?? "");
            const todaynew = new Date(row?.data?.scheduledate)
              .toISOString()
              .slice(0, 10);
            setscheduledate(todaynew ?? "");
            const status = +Number(row?.data?.post_state_id.toString());
            setpost_state_id(status);
            if (row?.data?.middle_header === null) {
              setmiddle_header(false);
            } else if (row?.data?.middle_header === 1) {
              setmiddle_header(true);
            } else {
              setmiddle_header(false);
            }
            setvideo_url(row?.data?.video_url ?? "");
            settrailler_url(row?.data?.trailler_url ?? "");
            setsub_title_trailler_url(row?.data?.sub_title_trailler_url ?? "");
            setsub_title_video_url(row?.data?.sub_title_video_url ?? "");
            setimage(row?.data?.image ?? "");
            sethori_image(row?.data?.hori_image ?? "");
            if (row?.data?.postnotification === null) {
              setpostnotification(false);
            } else if (row?.data?.postnotification === 1) {
              setpostnotification(true);
            } else {
              setpostnotification(false);
            }

            setLoading(false);
          } else if (row?.success === false) {
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  function create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxx4xxyxx".replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClickOpen3 = () => {
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleClickOpen4 = () => {
    setOpen4(true);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  const handleDeleteClick = (row, index) => {
    const newList = Object.assign([], castdependants);
    newList.splice(index, 1);
    setcastdependants(newList);
  };

  const handleEditClick = (row, index) => {
    // console.log("log", row)
    setcastname(row?.cast?.name);
    setcharacter(row?.character);
    handleClickOpen2();
    setIndexValue(index);
  };

  const handleDeleteClickCrew = (row, index) => {
    const newList = Object.assign([], crewdependants);
    newList.splice(index, 1);
    setcrewdependants(newList);
  };

  const handleEditClickCrew = (row, index) => {
    setcrewname(row?.crew?.name);
    setcrewcharacter(row?.crewcharacter);
    handleClickOpen4();
    setIndexValue2(index);
  };

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    " Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Côte d'Ivoire",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    " Congo(Congo - Brazzaville)",
    " Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    " Czechia(Czech Republic)",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    " Equatorial Guinea",
    "Eritrea",
    "Estonia",
    " Eswatini(fmr. 'Swaziland')",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea - Bissau",
    "Guyana",
    "Haiti",
    " Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar(formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    " New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    " North Korea",
    " North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    " Saint Kitts and Nevis",
    "Saint Lucia",
    " Saint Vincent and the Grenadines",
    "Samoa",
    " San Marino",
    "Sao Tome and Principe",
    " Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    " Solomon Islands",
    "Somalia",
    "  South Africa",
    " South Korea",
    "South Sudan",
    "Spain",
    " Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    " Timor - Leste",
    "Togo",
    "Tonga",
    " Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    " United Kingdom",
    " United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const onSubmitCrew = (event) => {
    event.preventDefault();
    setcrewdependants([
      ...crewdependants,
      {
        crew,
        crewcharacter,
      },
    ]);
    handleClose3();
  };

  const onSubmitCast = (event) => {
    event.preventDefault();

    setcastdependants([
      ...castdependants,
      {
        cast,
        character,
      },
    ]);

    handleClose();
  };

  const onSubmitCastEdit = (event) => {
    event.preventDefault();
    const newList = Object.assign([], castdependants);
    const data = { ...newList[indexValue] };

    data.cast.id = cast?.id ?? "";
    data.cast.name = cast?.name ?? "";
    data.cast.alsoknownas = cast?.alsoknownas ?? "";
    data.cast.birthday = cast?.birthday ?? "";
    data.cast.deathday = cast?.deathday ?? "";
    data.cast.person_info = cast?.person_info ?? "";
    data.cast.photo = cast?.photo ?? "";
    data.cast.place_of_birth = cast?.place_of_birth ?? "";
    data.character = character;
    newList[indexValue] = data;
    setcastdependants(newList);

    handleClose2();
  };

  const onSubmitCrewEdit = (event) => {
    const newList = Object.assign([], crewdependants);
    const data = { ...newList[indexValue2] };

    data.crew.id = crew?.id ?? "";
    data.crew.name = crew?.name ?? "";
    data.crew.alsoknownas = crew?.alsoknownas ?? "";
    data.crew.birthday = crew?.birthday ?? "";
    data.crew.deathday = crew?.deathday ?? "";
    data.crew.person_info = crew?.person_info ?? "";
    data.crew.photo = crew?.photo ?? "";
    data.crew.place_of_birth = crew?.place_of_birth ?? "";
    data.crewcharacter = crewcharacter;
    newList[indexValue2] = data;
    setcrewdependants(newList);

    handleClose4();
  };

  const handleCountryChange = (event) => {
    setcountry(event);
  };

  const handleCastChange = (event) => {
    setcastname(event);

    const newFilter = persons.filter((value) =>
      (value?.name || "").toLowerCase().includes((event || "").toLowerCase())
    );
    newFilter.forEach((option) => {
      if (option.name === event) {
        setcast({
          id: option?.id,
          name: option?.name,
          alsoknownas: option?.alsoknownas,
          birthday: option?.birthday,
          deathday: option?.deathday,
          person_info: option?.person_info,
          photo: option?.photo,
          place_of_birth: option?.place_of_birth,
        });
      }
    });
  };

  const handleCrewChange = (event) => {
    setcrewname(event);
    const newFilter = persons.filter((value) =>
      (value?.name || "").toLowerCase().includes((event || "").toLowerCase())
    );
    newFilter.forEach((option) => {
      if (option.name === event) {
        setcrew({
          id: option?.id,
          name: option?.name,
          alsoknownas: option?.alsoknownas,
          birthday: option?.birthday,
          deathday: option?.deathday,
          person_info: option?.person_info,
          photo: option?.photo,
          place_of_birth: option?.place_of_birth,
        });
      }
    });
  };

  const handleCategoryChange = (event) => {
    setcateg(event);
  };

  const handleRecommendedChange = (event) => {
    setrecommended_movies(event);
  };

  const tableStatus = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/poststatus`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setpoststatus(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableActors = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/actorsdata`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setpersons(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableRecommendation = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/movieRecommendation`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setrecommended_moviesData(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableCategory = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/tvCategory`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setcategData(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    tableStatus();
    tableRecommendation();
    tableCategory();
    tableActors();
    tableMovieShow();
    // eslint-disable-next-line
  }, [id]);

  const handleStatus = (event) => {
    setpost_state_id(event.target.value);
  };

  const handleContentType = (event) => {
    setcontent_type(event.target.value);
  };

  const selectedFile = (e) => {
    const image = e.target.files[0];
    if (image) {
      setLoading3(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + image.name, // File name you want to save as in S3
        Body: image,
        ContentType: image.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading3(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setimage(photoUrl);
        setLoading3(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress) {
        let uploaded = Math.round((progress.loaded / progress.total) * 100);
        setProgress(uploaded);
      });
    }
  };

  const selectedFileHoriImage = (e) => {
    const image = e.target.files[0];
    if (image) {
      setLoading4(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + image.name, // File name you want to save as in S3
        Body: image,
        ContentType: image.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading4(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        sethori_image(photoUrl);
        setLoading4(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress2) {
        let uploaded = Math.round((progress2.loaded / progress2.total) * 100);
        setProgress2(uploaded);
      });
    }
  };

  const selectedFileVideoMovie = (e) => {
    const video = e.target.files[0];
    if (video) {
      setLoading5(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + video.name, // File name you want to save as in S3
        Body: video,
        ContentType: video.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading5(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setvideo_url(photoUrl);
        setLoading5(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress3) {
        let uploaded = Math.round((progress3.loaded / progress3.total) * 100);
        setProgress3(uploaded);
      });
    }
  };

  const selectedFileTraillerVideo = (e) => {
    const video = e.target.files[0];
    if (video) {
      setLoading6(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + video.name, // File name you want to save as in S3
        Body: video,
        ContentType: video.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading6(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        settrailler_url(photoUrl);
        setLoading6(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress4) {
        let uploaded = Math.round((progress4.loaded / progress4.total) * 100);
        setProgress4(uploaded);
      });
    }
  };

  const selectedFileSubtitleVideo = (e) => {
    const video = e.target.files[0];
    if (video) {
      setLoading7(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + video.name, // File name you want to save as in S3
        Body: video,
        ContentType: video.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading7(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setsub_title_video_url(photoUrl);
        setLoading7(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress5) {
        let uploaded = Math.round((progress5.loaded / progress5.total) * 100);
        setProgress5(uploaded);
      });
    }
  };

  const selectedFileSubTrailler = (e) => {
    const video = e.target.files[0];
    if (video) {
      setLoading8(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + video.name, // File name you want to save as in S3
        Body: video,
        ContentType: video.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading8(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setsub_title_trailler_url(photoUrl);
        setLoading8(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress6) {
        let uploaded = Math.round((progress6.loaded / progress6.total) * 100);
        setProgress6(uploaded);
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userToken = localStorage.getItem("userToken");
    setloading2(true);
    // let mov = [];
    // let series = [];

    let p_cast = {
      castdependants: castdependants,
    };

    let p_crew = {
      crewdependants: crewdependants,
    };

    const movieObj = {
      id,
      image,
      hori_image,
      video_url,
      trailler_url,
      sub_title_video_url,
      sub_title_trailler_url,
      post_title,
      post_excerpt,
      movireleasedate,
      movie_time_duration,
      movie_censor_rating,
      content_type,
      catalog_visibility,
      moviefeats,
      cats_ids,
      newly_cats_id,
      act_id,
      drama_id,
      recommended_movies,
      imdb_id,
      tmdb_id,
      categ,
      youtube_link,
      country,
      scheduledate,
      main_header,
      docs_id,
      urban_class_id,
      kuma_id,
      comdy_id,
      middle_header,
      p_cast,
      p_crew,
      post_state_id,
      postnotification,
    };
    console.log("obj", movieObj);

    fetch(`${rootURL}/updatemovies`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(movieObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");

          setTimeout(() => {
            history("/movie-manage/movie-list");
          }, 3000);
        } else if (result.success === false) {
          setloading2(false);
          failedMessage(result.message);
        }
      })
      .catch((error) => {
        setloading2(false);
        failedMessage("Internal Server Error");
      })
      .finally(() => {
        setloading2(false);
      });
  };

  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress size={70} thickness={4} />
          </div>
        </>
      ) : (
        <>
          <Layout title="View Movie" content="container">
            <Block.Head>
              <Block.HeadBetween>
                <Block.HeadContent>
                  <Block.Title tag="h2">View Movie</Block.Title>
                  <nav>
                    <ol className="breadcrumb breadcrumb-arrow mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/movie-manage/movie-list">Movie</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        View Movie
                      </li>
                    </ol>
                  </nav>
                </Block.HeadContent>
                {/* <Block.HeadContent>
                    <ul className="d-flex">
                        <li>
                            <Link to="/actor-manage/actor-list" className="btn btn-primary btn-md d-md-none">
                                <Icon name="eye"/>
                                <span>Back</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/actor-manage/actor-list" className="btn btn-primary d-none d-md-inline-flex">
                                <Icon name="eye"/>
                                <span>Back</span>
                            </Link>
                        </li>
                    </ul>
                </Block.HeadContent> */}
              </Block.HeadBetween>
            </Block.Head>

            <Block>
              <Form onSubmit={handleSubmit}>
                <Row className="g-gs">
                  <Col xxl="12">
                    <div className="gap gy-4">
                      <div className="gap-col">
                        <Card className="card-gutter-md">
                          <Card.Body>
                            <Row className="g-gs">
                              <Col lg="4">
                                <Form.Group className="form-group">
                                  <Form.Label>Images</Form.Label>
                                  <div className="form-control-wrap">
                                    <div className="image-upload-wrap d-flex flex-column align-items-center">
                                      <div>Cover Image</div>
                                      {loading3 ? (
                                        <div>Upload progress {progress}%</div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="media media-huge border">
                                        <img
                                          id="image-result"
                                          className="w-100 h-100"
                                          src={image}
                                          alt="avatar"
                                        />
                                      </div>
                                      <div className="pt-3">
                                        <input
                                          className="upload-image"
                                          data-target="image-result"
                                          id="change-avatar"
                                          type="file"
                                          max="1"
                                          hidden
                                          onChange={selectedFile}
                                        />
                                        {/* <label
                                          htmlFor="change-avatar"
                                          className="btn btn-md btn-primary"
                                          disabled={loading3}
                                        >
                                          {loading3
                                            ? "Uploading ..."
                                            : "Upload"}
                                        </label> */}
                                      </div>
                                      <br />

                                      <div>Horizontal Image</div>
                                      {loading4 ? (
                                        <div>Upload progress {progress2}%</div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="media media-huge border">
                                        <img
                                          id="image-result2"
                                          className="w-100 h-100"
                                          src={hori_image}
                                          alt="avatar"
                                        />
                                      </div>
                                      <div className="pt-3">
                                        <input
                                          className="upload-image"
                                          data-target="image-result2"
                                          id="change-avatar2"
                                          type="file"
                                          max="1"
                                          hidden
                                          onChange={selectedFileHoriImage}
                                        />
                                        {/* <label
                                          htmlFor="change-avatar2"
                                          className="btn btn-md btn-primary"
                                          disabled={loading4}
                                        >
                                          {loading4
                                            ? "Uploading ..."
                                            : "UploadHori"}
                                        </label> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-note mt-3">
                                    Set the article thumbnail image. Only *.png,
                                    *.jpg and *.jpeg image files are accepted.
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="4">
                                <Form.Group className="form-group">
                                  <Form.Label>Videos</Form.Label>
                                  <div className="form-control-wrap">
                                    <div className="image-upload-wrap d-flex flex-column align-items-center">
                                      <div>Movie Video</div>
                                      {loading5 ? (
                                        <div>Upload progress {progress3}%</div>
                                      ) : (
                                        ""
                                      )}

                                      <div className="media media-huge border">
                                        <div className="player-wrapper">
                                          <Player
                                            src={video_url}
                                           
                                          >
                                            <ControlBar
                                              autoHide={false}
                                              className="my-class"
                                            />
                                          </Player>
                                        </div>
                                      </div>

                                      <div className="pt-3">
                                        <input
                                          className="upload-image"
                                          data-target="image-result3"
                                          id="change-avatar3"
                                          type="file"
                                          max="1"
                                          hidden
                                          onChange={selectedFileVideoMovie}
                                        />
                                        {/* <label
                                          htmlFor="change-avatar3"
                                          className="btn btn-md btn-primary"
                                          disabled={loading5}
                                        >
                                          {loading5
                                            ? "Uploading ..."
                                            : "UploadVideo"}
                                        </label> */}
                                      </div>
                                      <br />
                                      <div>Trailler Video</div>
                                      {loading6 ? (
                                        <div>Upload progress {progress4}%</div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="media media-huge border">
                                        <div className="player-wrapper">
                                          <Player src={trailler_url}>
                                            <ControlBar
                                              autoHide={false}
                                              className="my-class"
                                            />
                                          </Player>
                                        </div>
                                      </div>
                                      <div className="pt-3">
                                        <input
                                          className="upload-image"
                                          data-target="image-result4"
                                          id="change-avatar4"
                                          type="file"
                                          max="1"
                                          hidden
                                          onChange={selectedFileTraillerVideo}
                                        />
                                        {/* <label
                                          htmlFor="change-avatar4"
                                          className="btn btn-md btn-primary"
                                          disabled={loading6}
                                        >
                                          {loading6
                                            ? "Uploading ..."
                                            : "UploadTrailler"}
                                        </label> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-note mt-3">
                                    Set the movie thumbnail video.
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="4">
                                <Form.Group className="form-group">
                                  <Form.Label>SubTitle Videos</Form.Label>
                                  <div className="form-control-wrap">
                                    <div className="image-upload-wrap d-flex flex-column align-items-center">
                                      <div>Movie Subtitle Video</div>
                                      {loading7 ? (
                                        <div>Upload progress {progress5}%</div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="media media-huge border">
                                        <div className="player-wrapper">
                                          <Player src={sub_title_video_url}>
                                            <ControlBar
                                              autoHide={false}
                                              className="my-class"
                                            />
                                          </Player>
                                        </div>
                                      </div>
                                      <div className="pt-3">
                                        <input
                                          className="upload-image"
                                          data-target="image-result5"
                                          id="change-avatar5"
                                          type="file"
                                          max="1"
                                          hidden
                                          onChange={selectedFileSubtitleVideo}
                                        />
                                        {/* <label
                                          htmlFor="change-avatar5"
                                          className="btn btn-md btn-primary"
                                          disabled={loading7}
                                        >
                                          {loading7
                                            ? "Uploading ..."
                                            : "UploadSubVideo"}
                                        </label> */}
                                      </div>
                                      <br />

                                      <div>Trailler Video Subtitle</div>
                                      {loading8 ? (
                                        <div>Upload progress {progress6}%</div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="media media-huge border">
                                        <div className="player-wrapper">
                                          <Player src={sub_title_trailler_url}>
                                            <ControlBar
                                              autoHide={false}
                                              className="my-class"
                                            />
                                          </Player>
                                        </div>
                                      </div>
                                      <div className="pt-3">
                                        <input
                                          className="upload-image"
                                          data-target="image-result6"
                                          id="change-avatar6"
                                          type="file"
                                          max="1"
                                          hidden
                                          onChange={selectedFileSubTrailler}
                                        />
                                        {/* <label
                                          htmlFor="change-avatar6"
                                          className="btn btn-md btn-primary"
                                          disabled={loading8}
                                        >
                                          {loading8
                                            ? "Uploading ..."
                                            : "UploadSubTrailler"}
                                        </label> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-note mt-3">
                                    Set the subtitle thumbnail video.
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="post_title">
                                    Title *
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="post_title"
                                      placeholder="Title"
                                      disabled
                                      value={post_title}
                                      required
                                      onChange={(e) =>
                                        setpost_title(e.target.value)
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label>Description *</Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      as="textarea"
                                      rows="3"
                                      value={post_excerpt}
                                      disabled
                                      required
                                      placeholder=""
                                      onChange={(e) =>
                                        setpost_excerpt(e.target.value)
                                      }
                                    ></Form.Control>
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="movireleasedate">
                                    Movie Release Date *
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <input
                                      type="date"
                                      id="movireleasedate"
                                      name="movireleasedate"
                                      value={movireleasedate}
                                      disabled
                                      required
                                      onChange={(newValue) => {
                                        setmovireleasedate(
                                          newValue.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="movie_time_duration">
                                    Movie Time Duration *
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="movie_time_duration"
                                      placeholder="Movie Time Duration"
                                      required
                                      value={movie_time_duration}
                                      disabled
                                      onChange={(e) =>
                                        setmovie_time_duration(e.target.value)
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="movie_censor_rating">
                                    Movie Censor Rating
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="movie_censor_rating"
                                      placeholder="Movie Censor Rating"
                                      disabled
                                      value={movie_censor_rating}
                                      onChange={(e) =>
                                        setmovie_censor_rating(e.target.value)
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="6">
                                <div className="form-control-wrap">
                                  <FormControl fullWidth size="small">
                                    <Form.Label htmlFor="content_type">
                                      Content Type
                                    </Form.Label>
                                    <Select
                                      labelId="demo-select-small"
                                      id="demo-simple-select"
                                      value={content_type ?? ""}
                                      label="Status"
                                      onChange={handleContentType}
                                      disabled
                                    >
                                      {contents.map((type) => (
                                        <MenuItem key={type} value={type}>
                                          {loading === false ? type : ""}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </Col>

                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="imdb_id">
                                    Imdb ID
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="imdb_id"
                                      placeholder="Imdb ID"
                                      disabled
                                      value={imdb_id}
                                      onChange={(e) =>
                                        setimdb_id(e.target.value)
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="tmdb_id">
                                    Tmdb ID
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="tmdb_id"
                                      placeholder="Tmdb ID"
                                      disabled
                                      value={tmdb_id}
                                      onChange={(e) =>
                                        settmdb_id(e.target.value)
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="baseprice">
                                    Recommended Movies
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Autocomplete
                                      multiple
                                      fullWidth
                                      id="checkboxes-tags-demo"
                                      value={recommended_movies}
                                      options={recommended_moviesData}
                                      disabled
                                      onChange={(event, newValue) => {
                                        handleRecommendedChange(newValue);
                                      }}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) =>
                                        option?.post_title
                                      }
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option?.post_title}
                                        </li>
                                      )}
                                      isOptionEqualToValue={(option, value) =>
                                        option?.id === value?.id
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Recommended Movie"
                                          placeholder="Select Recommended Movie"
                                        />
                                      )}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="discount-price">
                                    Category *
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Autocomplete
                                      multiple
                                      fullWidth
                                      id="checkboxes-tags-demo"
                                      value={categ}
                                      required
                                      options={categData}
                                      disabled
                                      onChange={(event, newValue) => {
                                        handleCategoryChange(newValue);
                                      }}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) => option.name}
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option?.name}
                                        </li>
                                      )}
                                      isOptionEqualToValue={(option, value) =>
                                        option?.id === value?.id
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Category"
                                          placeholder="Select Category"
                                        />
                                      )}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="country">
                                    Countries not to see content
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Autocomplete
                                      multiple
                                      fullWidth
                                      id="checkboxes-tags-demo"
                                      value={country}
                                      options={countries}
                                      disabled
                                      onChange={(event, newValue) => {
                                        handleCountryChange(newValue);
                                      }}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) => option}
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option}
                                        </li>
                                      )}
                                      isOptionEqualToValue={(option, value) =>
                                        option?.id === value?.id
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Country"
                                          placeholder="Select Country"
                                        />
                                      )}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="youtube_link">
                                    Youtube Link
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="youtube_link"
                                      placeholder="Youtube Link"
                                      disabled
                                      value={youtube_link}
                                      onChange={(e) =>
                                        setyoutube_link(e.target.value)
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="scheduledate">
                                    Schedule Date
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <input
                                      type="date"
                                      id="scheduledate"
                                      name="scheduledate"
                                      value={scheduledate}
                                      disabled
                                      onChange={(newValue) => {
                                        setscheduledate(newValue.target.value);
                                      }}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="6">
                                <div className="form-control-wrap">
                                  <FormControl fullWidth size="small">
                                    <Form.Label htmlFor="status_id">
                                      Status *
                                    </Form.Label>
                                    <Select
                                      labelId="demo-select-small"
                                      id="demo-simple-select"
                                      value={post_state_id ?? ""}
                                      disabled
                                      label="Status"
                                      onChange={handleStatus}
                                      required
                                    >
                                      {poststatus.map((status) => (
                                        <MenuItem
                                          key={status.id}
                                          value={status.id}
                                        >
                                          {loading === false ? status.name : ""}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="MAIN HEADER"
                                  control={
                                    <Checkbox
                                      checked={main_header}
                                      onChange={(e) => {
                                        setmain_header(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="DOCUMENTARY"
                                  control={
                                    <Checkbox
                                      checked={docs_id}
                                      onChange={(e) => {
                                        setdocs_id(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="URBAN CLASSICS"
                                  control={
                                    <Checkbox
                                      checked={urban_class_id}
                                      onChange={(e) => {
                                        seturban_class_id(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>
                              <Col lg="6">
                                <FormControlLabel
                                  label="KUMAWOOD"
                                  control={
                                    <Checkbox
                                      checked={kuma_id}
                                      onChange={(e) => {
                                        setkuma_id(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="COMEDY"
                                  control={
                                    <Checkbox
                                      checked={comdy_id}
                                      onChange={(e) => {
                                        setcomdy_id(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="MIDDLE HEADER"
                                  control={
                                    <Checkbox
                                      checked={middle_header}
                                      onChange={(e) => {
                                        setmiddle_header(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="CATALOG VISIBILITY (LANDING PAGE)"
                                  control={
                                    <Checkbox
                                      checked={catalog_visibility}
                                      onChange={(e) => {
                                        setcatalog_visibility(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="FEATURED"
                                  control={
                                    <Checkbox
                                      checked={moviefeats}
                                      onChange={(e) => {
                                        setmoviefeats(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="NEWLY ADDED CONTENT"
                                  control={
                                    <Checkbox
                                      checked={cats_ids}
                                      onChange={(e) => {
                                        setcats_ids(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="NEWEST MOVIES"
                                  control={
                                    <Checkbox
                                      checked={newly_cats_id}
                                      onChange={(e) => {
                                        setnewly_cats_id(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="ACTIONS"
                                  control={
                                    <Checkbox
                                      checked={act_id}
                                      onChange={(e) => {
                                        setact_id(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="DRAMA"
                                  control={
                                    <Checkbox
                                      checked={drama_id}
                                      onChange={(e) => {
                                        setdrama_id(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>
                              <Col lg="6">
                                <FormControlLabel
                                  label="POST NOTIFICATION TO THE APP"
                                  control={
                                    <Checkbox
                                      checked={postnotification}
                                      onChange={(e) => {
                                        setpostnotification(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="12">
                                <Box
                                  display="flex"
                                  flexDirection="row-reverse"
                                  justifyContent="space-between"
                                >
                                  <Button
                                    variant="contained"
                                    onClick={handleClickOpen}
                                    size="sm"
                                    disabled
                                    className={classes.item}
                                  >
                                    Add Cast
                                  </Button>
                                </Box>

                                <br />
                                <Grid
                                  container
                                  spacing={4}
                                  justify="flex-start"
                                >
                                  <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                      <Table aria-label="caption table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              #
                                            </TableCell>

                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Cast
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Character
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Actions
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>

                                        <TableBody>
                                          {castdependants.map((row, index) => (
                                            <TableRow key={index}>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {index + 1}
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {row?.cast?.name ?? ""}
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {row?.character ?? ""}
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                <IconButton
                                                  aria-label="edit"
                                                  disabled
                                                  color="primary"
                                                  onClick={() =>
                                                    handleEditClick(row, index)
                                                  }
                                                >
                                                  <EditIcon />
                                                </IconButton>

                                                <IconButton
                                                  aria-label="delete"
                                                  color="error"
                                                  disabled
                                                  onClick={() =>
                                                    handleDeleteClick(
                                                      row,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                </Grid>
                              </Col>

                              <Col lg="12">
                                <Box
                                  display="flex"
                                  flexDirection="row-reverse"
                                  justifyContent="space-between"
                                >
                                  <Button
                                    variant="contained"
                                    onClick={handleClickOpen3}
                                    size="sm"
                                    disabled
                                    className={classes.item}
                                  >
                                    Add Crew
                                  </Button>
                                </Box>

                                <br />
                                <Grid
                                  container
                                  spacing={4}
                                  justify="flex-start"
                                >
                                  <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                      <Table aria-label="caption table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              #
                                            </TableCell>

                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Crew
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Character
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Actions
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>

                                        <TableBody>
                                          {crewdependants.map((row, index) => (
                                            <TableRow key={index}>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {index + 1}
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {row?.crew?.name ?? ""}
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {row?.crewcharacter ?? ""}
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                <IconButton
                                                  aria-label="edit"
                                                  disabled
                                                  color="primary"
                                                  onClick={() =>
                                                    handleEditClickCrew(
                                                      row,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <EditIcon />
                                                </IconButton>

                                                <IconButton
                                                  aria-label="delete"
                                                  disabled
                                                  color="error"
                                                  onClick={() =>
                                                    handleDeleteClickCrew(
                                                      row,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                </Grid>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>

                      <div className="gap-col">
                        <ul className="d-flex align-items-center gap g-3">
                          <li>
                            <Button type="submit" variant="contained" disabled>
                              {loading2 ? "Updating...." : "Update"}
                            </Button>
                          </li>
                          <li>
                            <Link
                              to="/movie-manage/movie-list"
                              className="btn border-0"
                            >
                              Cancel
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  {/* <Col xxl="3">
                    <Card className="card-gutter-md">
                      <Card.Body>
                        <Row className="g-gs">
                          <Col className="col-12">
                           
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col> */}
                </Row>
              </Form>
              {/* cast Add */}
              <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{ fontSize: "1rem" }}
                  >
                    Add Cast
                  </DialogTitle>
                  <Form onSubmit={onSubmitCast}>
                    <DialogContent>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Autocomplete
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          value={castname}
                          onChange={(event, newValue) => {
                            handleCastChange(newValue);
                          }}
                          options={persons.map((option) => option.name)}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Cast"
                              placeholder="Select Cast"
                            />
                          )}
                        />
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="character"
                          variant="outlined"
                          id="character"
                          label="Character"
                          value={character}
                          onChange={(e) => setcharacter(e.target.value)}
                          fullWidth
                        />
                      </Form.Group>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.item}
                        autoFocus
                        onClick={handleClose}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.item}
                      >
                        Add Cast
                      </Button>
                    </DialogActions>
                  </Form>
                </div>
              </Dialog>

              {/* cast Edit */}

              <Dialog
                fullScreen={fullScreen}
                open={open2}
                onClose={handleClose2}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{ fontSize: "1rem" }}
                  >
                    Edit Cast
                  </DialogTitle>
                  <Form onSubmit={onSubmitCastEdit}>
                    <DialogContent>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Autocomplete
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          value={castname}
                          onChange={(event, newValue) => {
                            handleCastChange(newValue);
                          }}
                          options={persons.map((option) => option.name)}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Cast"
                              placeholder="Select Cast"
                            />
                          )}
                        />
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="character"
                          variant="outlined"
                          id="character"
                          label="Character"
                          value={character}
                          onChange={(e) => setcharacter(e.target.value)}
                          fullWidth
                        />
                      </Form.Group>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.item}
                        autoFocus
                        onClick={handleClose2}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.item}
                      >
                        Update Cast
                      </Button>
                    </DialogActions>
                  </Form>
                </div>
              </Dialog>

              {/* crew Add */}
              <Dialog
                fullScreen={fullScreen}
                open={open3}
                onClose={handleClose3}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{ fontSize: "1rem" }}
                  >
                    Add Crew
                  </DialogTitle>
                  <Form onSubmit={onSubmitCrew}>
                    <DialogContent>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Autocomplete
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          value={crewname}
                          onChange={(event, newValue) => {
                            handleCrewChange(newValue);
                          }}
                          options={persons.map((option) => option.name)}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Crew"
                              placeholder="Select Crew"
                            />
                          )}
                        />
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="crewcharacter"
                          variant="outlined"
                          id="crewcharacter"
                          label="Character"
                          value={crewcharacter}
                          onChange={(e) => setcrewcharacter(e.target.value)}
                          fullWidth
                        />
                      </Form.Group>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.item}
                        autoFocus
                        onClick={handleClose3}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.item}
                      >
                        Add Crew
                      </Button>
                    </DialogActions>
                  </Form>
                </div>
              </Dialog>

              {/* crew Edit */}

              <Dialog
                fullScreen={fullScreen}
                open={open4}
                onClose={handleClose4}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{ fontSize: "1rem" }}
                  >
                    Edit Crew
                  </DialogTitle>
                  <Form onSubmit={onSubmitCrewEdit}>
                    <DialogContent>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Autocomplete
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          value={crewname}
                          onChange={(event, newValue) => {
                            handleCrewChange(newValue);
                          }}
                          options={persons.map((option) => option.name)}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Crew"
                              placeholder="Select Crew"
                            />
                          )}
                        />
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="crewcharacter"
                          variant="outlined"
                          id="crewcharacter"
                          label="Character"
                          value={crewcharacter}
                          onChange={(e) => setcrewcharacter(e.target.value)}
                          fullWidth
                        />
                      </Form.Group>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.item}
                        autoFocus
                        onClick={handleClose4}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.item}
                      >
                        Update Crew
                      </Button>
                    </DialogActions>
                  </Form>
                </div>
              </Dialog>
            </Block>
          </Layout>
        </>
      )}
    </>
  );
}

export default MovieView;
