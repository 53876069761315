import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon } from "../../components";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
// import FormControl from "@mui/material/FormControl";
import { rootURL } from "../utilities/axios-config";
import { successMessage, failedMessage } from "../utilities/alert-config";

function AddSubscription() {
  const history = useNavigate();
  const [plan_name, setplan_name] = useState("");
  const [sub_name, setsub_name] = useState("");
  const [description, setdescription] = useState("");
  const [amount, setamount] = useState("");
  const [amount_sub, setamount_sub] = useState("");
  const [plan, setplan] = useState("");

  //const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userToken = localStorage.getItem("userToken");
    setLoading2(true);

    const tagObj = {
      plan_name,
      sub_name,
      description,
      amount,
      amount_sub,
      plan
    };

    fetch(`${rootURL}/add_subscriptiondata_cpanel`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tagObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");
          //   console.log("data", result)
          setTimeout(() => {
            history(`/configuration-manage/subscription-list`);
          }, 3000);
        } else if (result.success === false) {
          setLoading2(false);
          failedMessage(result.message);
        }
      })
      .catch((error) => {
        setLoading2(false);
        failedMessage("Internal Server Error");
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  // grabs the id form the url and loads the corresponding data
  useEffect(() => {}, []);

  return (
    <Layout title="Subscription Add" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
          
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex gap g-2">
              <li className="d-none d-md-block">
                <Link
                  to={`/configuration-manage/subscription-list`}
                  className="btn btn-soft btn-primary"
                >
                  <Icon name="user"></Icon>
                  <span>Subscription List</span>
                </Link>
              </li>
              <li className="d-md-none">
                <Link
                  to={`/configuration-manage/subscription-list`}
                  className="btn btn-soft btn-primary btn-icon"
                >
                  <Icon name="user"></Icon>
                </Link>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">Add Subscription</h4>
              <Form onSubmit={handleSubmit}>
                <Row className="g-3">
                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="plan_name">Plan Name *</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="plan_name"
                          value={plan_name}
                          placeholder="Name"
                          required
                          onChange={(e) => setplan_name(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="sub_name">Sub Name *</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="sub_name"
                          value={sub_name}
                          placeholder="Sub Name"
                          required
                          onChange={(e) => setsub_name(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="description">Description *</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="description"
                          value={description}
                          placeholder="Description"
                          required
                          onChange={(e) => setdescription(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="amount">Amount *</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="amount"
                          value={amount}
                          placeholder="Amount"
                          required
                          onChange={(e) => setamount(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="amount_sub">Amount Sub *</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="amount_sub"
                          value={amount_sub}
                          placeholder="Amount_sub"
                          required
                          onChange={(e) => setamount_sub(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="plan">Plan *</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="plan"
                          value={plan}
                          placeholder="Plan"
                          required
                          onChange={(e) => setplan(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {loading2 ? "Saving...." : "Save"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  );
}

export default AddSubscription;
