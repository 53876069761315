const iconsData = [
    'nioboard',
    'graphql',
    'node-js',
    'node',
    'svelte',
    'typescript',
    'vue',
    'centos',
    'covid',
    'fedora',
    'hot-fill',
    'hot',
    'linux-server',
    'linux',
    'note-add-fill',
    'repeat-fill',
    'tranx-fill',
    'ubuntu',
    'virus',
    'b-chrome',
    'b-edge',
    'b-firefox',
    'b-ie',
    'b-opera',
    'b-safari',
    'b-si',
    'b-uc',
    'brick-fill',
    'brick',
    'col-3s',
    'col-4s',
    'col-2s',
    'comments',
    'dot-sq',
    'dot',
    'footer',
    'header',
    'heading',
    'layout-alt-fill',
    'layout-alt',
    'layout-fill1',
    'layout1',
    'list-index-fill',
    'list-index',
    'list-thumb-alt-fill',
    'list-thumb-alt',
    'list-thumb-fill',
    'list-thumb',
    'masonry-fill',
    'masonry',
    'menu-circled',
    'menu-squared',
    'notice',
    'pen2',
    'property-blank',
    'property-add',
    'property-alt',
    'property-remove',
    'property',
    'puzzle-fill',
    'puzzle',
    'quote-left',
    'quote-right',
    'row-mix',
    'row-view1',
    'sidebar-r',
    'text2',
    'tile-thumb-fill',
    'tile-thumb',
    'view-col-fill',
    'view-col-sq',
    'view-col',
    'view-col2',
    'view-col3',
    'view-cols-fill',
    'view-cols-sq',
    'view-cols',
    'view-grid-fill',
    'view-grid-sq',
    'view-grid-wd',
    'view-grid',
    'view-grid2-wd',
    'view-grid3-wd',
    'view-group-fill',
    'view-group-wd',
    'view-list-fill',
    'view-list-sq',
    'view-list-wd',
    'view-list',
    'view-panel-fill',
    'view-panel-sq',
    'view-panel',
    'view-row-fill',
    'view-row-sq',
    'view-row-wd',
    'view-row',
    'view-x1',
    'view-x2',
    'view-x3',
    'view-x4',
    'view-x5',
    'view-x6',
    'view-x7',
    'dashlite',
    'dashlite-circle',
    'dashlite-alt',
    'master-card',
    'paypal',
    'visa-alt',
    'coin-eur',
    'coin-gbp',
    'sign-ada-alt',
    'sign-bch-alt',
    'sign-bgp-alt',
    'sign-bnb-alt',
    'sign-brl-alt',
    'sign-btc-alt',
    'sign-cc-alt',
    'sign-cc-alt2',
    'sign-chf-alt',
    'sign-cny-alt',
    'sign-czk-alt',
    'sign-dash-alt',
    'sign-dkk-alt',
    'sign-eos-alt',
    'sign-eth-alt',
    'sign-eur-alt2',
    'sign-euro-alt',
    'sign-gbp-alt2',
    'sign-hkd-alt',
    'sign-idr-alt',
    'sign-inr-alt',
    'sign-jpy-alt',
    'sign-kr-alt',
    'sign-ltc-alt',
    'sign-ltc',
    'sign-mxn-alt',
    'sign-mxr-alt',
    'sign-myr-alt',
    'sign-paypal-alt',
    'sign-paypal-full',
    'sign-php-alt',
    'sign-pln-alt',
    'sign-rub-alt',
    'sign-sek-alt',
    'sign-sgd-alt',
    'sign-kobo-alt',
    'sign-steem-alt',
    'sign-steller-alt',
    'sign-stripe-fulll',
    'sign-thb-alt',
    'sign-trx-alt',
    'sign-try-alt',
    'sign-usd-alt',
    'sign-usd-alt2',
    'sign-usdc-alt',
    'sign-usdt-alt',
    'sign-visa-alt',
    'sign-vnd-alt',
    'sign-waves-alt',
    'sign-xem-alt',
    'sign-xrp-new-alt',
    'sign-xrp-old-alt',
    'sign-zcash-alt',
    'chevron-left',
    'chevron-right',
    'chevron-up',
    'chevron-down',
    'chevron-left-round',
    'chevron-right-round',
    'chevron-up-round',
    'chevron-down-round',
    'chevron-left-round-fill',
    'chevron-right-round-fill',
    'chevron-up-round-fill',
    'chevron-down-round-fill',
    'chevron-left-c',
    'chevron-right-c',
    'chevron-up-c',
    'chevron-down-c',
    'chevron-left-fill-c',
    'chevron-right-fill-c',
    'chevron-up-fill-c',
    'chevron-down-fill-c',
    'chevron-left-circle',
    'chevron-right-circle',
    'chevron-up-circle',
    'chevron-down-circle',
    'chevron-left-circle-fill',
    'chevron-right-circle-fill',
    'chevron-up-circle-fill',
    'chevron-down-circle-fill',
    'caret-left',
    'caret-right',
    'caret-up',
    'caret-down',
    'caret-left-fill',
    'caret-right-fill',
    'caret-up-fill',
    'caret-down-fill',
    'sort',
    'sort-up',
    'sort-down',
    'sort-fill',
    'sort-up-fill',
    'sort-down-fill',
    'sort-v',
    'swap-v',
    'swap',
    'arrow-left-round',
    'arrow-right-round',
    'arrow-up-round',
    'arrow-down-round',
    'arrow-left-round-fill',
    'arrow-right-round-fill',
    'arrow-up-round-fill',
    'arrow-down-round-fill',
    'arrow-left-c',
    'arrow-right-c',
    'arrow-up-c',
    'arrow-down-c',
    'arrow-left-fill-c',
    'arrow-right-fill-c',
    'arrow-up-fill-c',
    'arrow-down-fill-c',
    'arrow-left-circle',
    'arrow-right-circle',
    'arrow-up-circle',
    'arrow-down-circle',
    'arrow-left-circle-fill',
    'arrow-up-circle-fill',
    'arrow-down-circle-fill',
    'arrow-right-circle-fill',
    'chevrons-left',
    'chevrons-right',
    'chevrons-up',
    'chevrons-down',
    'first',
    'last',
    'back-ios',
    'forward-ios',
    'upword-ios',
    'downward-ios',
    'back-alt',
    'forward-alt',
    'upword-alt',
    'downward-alt',
    'back-alt-fill',
    'forward-alt-fill',
    'upword-alt-fill',
    'downward-alt-fill',
    'arrow-long-left',
    'arrow-long-right',
    'arrow-long-up',
    'arrow-long-down',
    'arrow-left',
    'arrow-right',
    'arrow-up',
    'arrow-down',
    'arrow-up-left',
    'arrow-up-right',
    'arrow-down-left',
    'arrow-down-right',
    'arrow-to-left',
    'arrow-to-right',
    'arrow-to-up',
    'arrow-to-down',
    'arrow-from-left',
    'arrow-from-right',
    'arrow-from-up',
    'arrow-from-down',
    'curve-down-left',
    'curve-up-right',
    'curve-up-left',
    'curve-down-right',
    'curve-left-up',
    'curve-right-up',
    'curve-left-down',
    'curve-right-down',
    'back-arrow',
    'forward-arrow',
    'back-arrow-fill',
    'forward-arrow-fill',
    'navigate',
    'navigate-up',
    'navigate-fill',
    'navigate-up-fill',
    'send',
    'send-alt',
    'unfold-less',
    'unfold-more',
    'exchange-v',
    'exchange',
    'expand',
    'shrink',
    'focus',
    'maximize',
    'minimize',
    'maximize-alt',
    'minimize-alt',
    'shuffle',
    'cross-sm',
    'cross',
    'cross-round',
    'cross-circle',
    'cross-c',
    'cross-round-fill',
    'cross-circle-fill',
    'cross-fill-c',
    'na',
    'check',
    'check-thick',
    'done',
    'check-round',
    'check-circle',
    'check-c',
    'check-round-fill',
    'check-circle-fill',
    'check-fill-c',
    'check-circle-cut',
    'check-round-cut',
    'bullet',
    'circle',
    'square',
    'square-c',
    'bullet-fill',
    'circle-fill',
    'square-fill',
    'square-fill-c',
    'plus-sm',
    'minus-sm',
    'plus',
    'minus',
    'plus-round',
    'minus-round',
    'plus-circle',
    'minus-circle',
    'plus-c',
    'minus-c',
    'plus-round-fill',
    'plus-circle-fill',
    'minus-round-fill',
    'minus-circle-fill',
    'plus-fill-c',
    'minus-fill-c',
    'plus-medi',
    'plus-medi-fill',
    'equal-sm',
    'equal',
    'calc',
    'search',
    'zoom-out',
    'zoom-in',
    'play',
    'play-fill',
    'play-circle',
    'play-circle-fill',
    'pause',
    'pause-fill',
    'pause-circle',
    'pause-circle-fill',
    'stop',
    'stop-fill',
    'stop-circle',
    'stop-circle-fill',
    'rewind',
    'forward',
    'rewind-fill',
    'forward-fill',
    'step-back',
    'step-forward',
    'vol-off',
    'vol-no',
    'vol-half',
    'vol',
    'mic',
    'mic-off',
    'video',
    'video-off',
    'video-fill',
    'loader',
    'power',
    'signout',
    'signin',
    'upload',
    'download',
    'alert-circle',
    'alert',
    'caution',
    'report',
    'alert-c',
    'alert-circle-fill',
    'alert-fill',
    'caution-fill',
    'report-fill',
    'alert-fill-c',
    'info-i',
    'info',
    'info-fill',
    'help',
    'help-fill',
    'archived',
    'archive',
    'unarchive',
    'archived-fill',
    'archive-fill',
    'unarchive-fill',
    'bag',
    'bag-fill',
    'bell',
    'bell-off',
    'bell-fill',
    'bell-off-fill',
    'wifi',
    'wifi-off',
    'live',
    'signal',
    'bluetooth',
    'blank-alt',
    'blank',
    'blankf-fill',
    'block-over',
    'book-read',
    'book',
    'book-fill',
    'bulb-fill',
    'bulb',
    'calendar-alt-fill',
    'calendar-alt',
    'calendar-booking-fill',
    'calendar-booking',
    'calendar-check-fill',
    'calendar-check',
    'calendar-fill',
    'calendar',
    'calender-date-fill',
    'calender-date',
    'call',
    'call-alt',
    'call-alt-fill',
    'call-fill',
    'camera-fill',
    'camera',
    'capsule',
    'capsule-fill',
    'cards',
    'cards-fill',
    'cart',
    'cart-fill',
    'cc',
    'cc-alt',
    'cc-alt2',
    'cc-secure',
    'cc-new',
    'cc-off',
    'cc-fill',
    'cc-alt-fill',
    'cc-alt2-fill',
    'cc-secure-fill',
    'msg-circle',
    'chat-circle',
    'msg',
    'chat',
    'question-alt',
    'question',
    'msg-circle-fill',
    'chat-circle-fill',
    'msg-fill',
    'chat-fill',
    'clip-h',
    'clip-v',
    'clip',
    'link-alt',
    'unlink',
    'unlink-alt',
    'link-h',
    'link-v',
    'link',
    'clipboard',
    'clipboad-check',
    'clipboard-fill',
    'clipboad-check-fill',
    'clock',
    'clock-fill',
    'cloud',
    'upload-cloud',
    'download-cloud',
    'cloud-fill',
    'contact',
    'contact-fill',
    'coffee',
    'coffee-fill',
    'box-view',
    'col-view',
    'sidebar',
    'layout',
    'table-view',
    'layout2',
    'row-view',
    'dot-box',
    'layout-fill',
    'box-view-fill',
    'sidebar-fill',
    'table-view-fill',
    'dot-box-fill',
    'template',
    'browser',
    'toolbar',
    'browser-fill',
    'toolbar-fill',
    'template-fill',
    'box',
    'package',
    'layer',
    'layers',
    'panel',
    'server',
    'layer-fill',
    'layers-fill',
    'package-fill',
    'panel-fill',
    'server-fill',
    'color-palette',
    'color-palette-fill',
    'copy',
    'copy-fill',
    'crop-alt',
    'crop',
    'target',
    'crosshair',
    'crosshair-fill',
    'db-fill',
    'db',
    'hard-drive',
    'cpu',
    'disk',
    'pen',
    'edit-alt',
    'pen-fill',
    'edit-alt-fill',
    'pen-alt-fill',
    'edit-fill',
    'edit',
    'external-alt',
    'external',
    'eye-alt',
    'eye-alt-fill',
    'eye',
    'eye-fill',
    'eye-off',
    'eye-off-fill',
    'file',
    'file-minus',
    'file-plus',
    'file-remove',
    'file-check',
    'file-code',
    'file-docs',
    'file-img',
    'file-doc',
    'file-pdf',
    'file-xls',
    'file-zip',
    'file-download',
    'file-text',
    'files',
    'file-fill',
    'file-minus-fill',
    'file-plus-fill',
    'file-remove-fill',
    'file-check-fill',
    'file-text-fill',
    'files-fill',
    'folder',
    'folder-minus',
    'folder-plus',
    'folder-remove',
    'folder-check',
    'folder-list',
    'folders',
    'folder-fill',
    'folders-fill',
    'filter-alt',
    'sort-line',
    'filter-fill',
    'filter',
    'flag',
    'flag-fill',
    'notify',
    'dashboard',
    'dashboard-fill',
    'grid-sq',
    'grid',
    'grid-c',
    'grid-alt',
    'grid-plus',
    'grid-add-c',
    'grid-fill',
    'grid-fill-c',
    'grid-alt-fill',
    'grid-plus-fill',
    'grid-add-fill-c',
    'grid-box-alt-fill',
    'grid-box-alt',
    'grid-box',
    'grid-box-fill',
    'grid-line',
    'menu-alt-left',
    'menu-alt-r',
    'menu-alt',
    'menu-center',
    'menu-left',
    'menu-right',
    'menu',
    'trend-up',
    'trend-down',
    'line-chart-down',
    'line-chart-up',
    'line-chart',
    'bar-chart',
    'bar-chart-alt',
    'chart-up',
    'chart-down',
    'growth',
    'growth-fill',
    'bar-chart-fill',
    'bar-c',
    'bar-fill-c',
    'pie',
    'pie-alt',
    'pie-fill',
    'activity',
    'activity-alt',
    'activity-round',
    'activity-round-fill',
    'meter',
    'speed',
    'happy',
    'sad',
    'meh',
    'happy-fill',
    'sad-fill',
    'meh-fill',
    'home',
    'home-alt',
    'home-fill',
    'img',
    'img-fill',
    'inbox',
    'inbox-in',
    'inbox-out',
    'inbox-fill',
    'inbox-in-fill',
    'inbox-out-fill',
    'link-group',
    'lock',
    'lock-alt',
    'lock-fill',
    'lock-alt-fill',
    'unlock',
    'unlock-fill',
    'mail',
    'emails',
    'mail-fill',
    'emails-fill',
    'map-pin',
    'location',
    'map',
    'map-pin-fill',
    'list',
    'list-ol',
    'align-center',
    'align-justify',
    'align-left',
    'align-right',
    'list-check',
    'list-round',
    'card-view',
    'list-fill',
    'save',
    'save-fill',
    'move',
    'scissor',
    'text',
    'text-a',
    'bold',
    'italic',
    'underline',
    'percent',
    'at',
    'hash',
    'code',
    'code-download',
    'terminal',
    'cmd',
    'sun',
    'sun-fill',
    'moon-fill',
    'moon',
    'light',
    'light-fill',
    'more-v',
    'more-h',
    'more-h-alt',
    'more-v-alt',
    'music',
    'movie',
    'offer',
    'offer-fill',
    'opt-alt',
    'opt',
    'opt-dot-alt',
    'opt-dot',
    'opt-dot-fill',
    'opt-alt-fill',
    'user-alt',
    'user-alt-fill',
    'user',
    'users',
    'user-add',
    'user-remove',
    'user-check',
    'user-cross',
    'account-setting',
    'account-setting-alt',
    'user-list',
    'user-fill',
    'users-fill',
    'user-add-fill',
    'user-remove-fill',
    'user-check-fill',
    'user-cross-fill',
    'account-setting-fill',
    'user-list-fill',
    'user-circle',
    'user-circle-fill',
    'user-c',
    'user-fill-c',
    'user-round',
    'printer',
    'printer-fill',
    'laptop',
    'monitor',
    'tablet',
    'mobile',
    'undo',
    'redo',
    'reload-alt',
    'reload',
    'regen-alt',
    'regen',
    'invest',
    'history',
    'update',
    'repeat',
    'repeat-v',
    'tranx',
    'reply-all',
    'reply',
    'reply-fill',
    'reply-all-fill',
    'notes',
    'note-add',
    'notes-alt',
    'article',
    'text-rich',
    'todo',
    'report-profit',
    'reports-alt',
    'reports',
    'task',
    'note-add-c',
    'task-c',
    'todo-fill',
    'note-add-fill-c',
    'task-fill-c',
    'scan-fill',
    'scan',
    'qr',
    'money',
    'coins',
    'coin',
    'coin-alt',
    'coin-alt-fill',
    'setting-alt-fill',
    'setting-alt',
    'setting-fill',
    'setting',
    'share-alt',
    'share-fill',
    'share',
    'network',
    'rss',
    'shield',
    'shield-star',
    'shield-check',
    'shield-alert',
    'shield-off',
    'security',
    'policy',
    'shield-alert-fill',
    'shield-check-fill',
    'shield-fill',
    'shield-half',
    'shield-star-fill',
    'policy-fill',
    'spark',
    'spark-off',
    'spark-fill',
    'spark-off-fill',
    'wallet',
    'wallet-alt',
    'wallet-in',
    'wallet-out',
    'wallet-saving',
    'wallet-fill',
    'star',
    'star-half',
    'star-half-fill',
    'star-fill',
    'star-round',
    'heart',
    'heart-fill',
    'swap-alt-fill',
    'swap-alt',
    'thumbs-down',
    'thumbs-up',
    'tag',
    'tag-alt',
    'tags',
    'tag-fill',
    'tag-alt-fill',
    'tags-fill',
    'bookmark',
    'bookmark-fill',
    'label',
    'label-fill',
    'piority',
    'piority-fill',
    'label-alt',
    'label-alt-fill',
    'ticket-alt',
    'ticket',
    'ticket-minus',
    'ticket-plus',
    'ticket-alt-fill',
    'ticket-fill',
    'ticket-minus-fill',
    'ticket-plus-fill',
    'toggle-off',
    'toggle-on',
    'trash-alt',
    'trash-empty',
    'trash',
    'trash-fill',
    'trash-empty-fill',
    'delete-fill',
    'delete',
    'alarm-alt',
    'alarm',
    'bugs',
    'building',
    'building-fill',
    'headphone',
    'headphone-fill',
    'aperture',
    'help-alt',
    'award',
    'briefcase',
    'gift',
    'globe',
    'umbrela',
    'truck',
    'sign-usd',
    'sign-dollar',
    'sign-mxn',
    'sign-sgd',
    'sign-euro',
    'sign-eur',
    'sign-gbp',
    'sign-pound',
    'sign-thb',
    'sign-inr',
    'sign-jpy',
    'sign-yen',
    'sign-cny',
    'sign-kobo',
    'sign-chf',
    'sign-vnd',
    'sign-php',
    'sign-brl',
    'sign-idr',
    'sign-czk',
    'sign-hkd',
    'sign-kr',
    'sign-dkk',
    'sign-nok',
    'sign-sek',
    'sign-rub',
    'sign-myr',
    'sign-pln',
    'sign-try',
    'sign-waves',
    'waves',
    'sign-trx',
    'tron',
    'sign-xem',
    'nem',
    'sign-mxr',
    'monero',
    'sign-usdc',
    'sign-steller',
    'sign-steem',
    'sign-usdt',
    'tether',
    'sign-btc',
    'bitcoin',
    'sign-bch',
    'bitcoin-cash',
    'sign-bnb',
    'binance',
    'sign-ada',
    'sign-zcash',
    'sign-eth',
    'ethereum',
    'sign-dash',
    'dash',
    'sign-xrp-old',
    'ripple-old',
    'sign-eos',
    'eos',
    'sign-xrp',
    'ripple',
    'american-express',
    'jcb',
    'cc-jcb',
    'mc',
    'cc-mc',
    'discover',
    'cc-discover',
    'visa',
    'cc-visa',
    'cc-paypal',
    'cc-stripe',
    'amazon-pay',
    'amazon-pay-fill',
    'google-pay',
    'google-pay-fill',
    'apple-pay',
    'apple-pay-fill',
    'angular',
    'react',
    'laravel',
    'html5',
    'css3-fill',
    'css3',
    'js',
    'php',
    'python',
    'bootstrap',
    'ebay',
    'google-wallet',
    'google-drive',
    'google-play-store',
    'android',
    'blogger-fill',
    'blogger',
    'hangout',
    'apple-store',
    'apple-store-ios',
    'stripe',
    'apple',
    'microsoft',
    'windows',
    'amazon',
    'paypal-alt',
    'airbnb',
    'adobe',
    'mailchimp',
    'dropbox',
    'digital-ocean',
    'slack',
    'slack-hash',
    'stack-overflow',
    'soundcloud',
    'blackberry',
    'spotify',
    'kickstarter',
    'houzz',
    'vine',
    'yelp',
    'yoast',
    'envato',
    'wordpress',
    'wp',
    'wordpress-fill',
    'elementor',
    'joomla',
    'megento',
    'git',
    'github',
    'github-round',
    'github-circle',
    'dribbble',
    'dribbble-round',
    'behance',
    'behance-fill',
    'flickr',
    'flickr-round',
    'medium',
    'medium-round',
    'reddit',
    'reddit-round',
    'reddit-circle',
    'google',
    'facebook-f',
    'facebook-fill',
    'facebook-circle',
    'instagram',
    'instagram-round',
    'linkedin',
    'linkedin-round',
    'twitter',
    'twitter-round',
    'pinterest',
    'pinterest-round',
    'pinterest-circle',
    'tumblr',
    'tumblr-round',
    'skype',
    'viber',
    'whatsapp',
    'whatsapp-round',
    'snapchat',
    'snapchat-fill',
    'telegram',
    'telegram-circle',
    'youtube-line',
    'youtube',
    'youtube-fill',
    'youtube-round',
    'vimeo',
    'vimeo-fill'
];

export default iconsData;

