import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon } from "../../components";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { rootURL } from "../utilities/axios-config";
import { successMessage, failedMessage } from "../utilities/alert-config";

function UserAddPage() {
  const history = useNavigate();
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [email, setemail] = useState("");
  const [user_name, setuser_name] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [address, setaddress] = useState("");
  const [password, setpassword] = useState("");
  const [role_id, setrole_id] = useState("");
  const [role, setrole] = useState("");
  const [facebook_url, setfacebook_url] = useState("");
  const [instagram_url, setinstagram_url] = useState("");
  const [twitter_url, settwitter_url] = useState("");
  const [youtube_url, setyoutube_url] = useState("");
  const [profile, setprofile] = useState("");
  const [roleData, setroleData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userToken = localStorage.getItem("userToken");
    setLoading2(true);

    const userObj = {
      first_name,
      last_name,
      email,
      user_name,
      phone_number,
      address,
      password,
      role_id,
      role,
      facebook_url,
      instagram_url,
      twitter_url,
      youtube_url,
      profile,
    };

    fetch(`${rootURL}/adduser`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");
          //   console.log("data", result)
          setTimeout(() => {
            history(`/user-manage/user-profile/${result?.data?.id}`);
          }, 3000);
        } else if (result.success === false) {
          setLoading2(false);
          failedMessage(result.message.email);
        }
      })
      .catch((error) => {
        setLoading2(false);
        failedMessage("Internal Server Error");
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  const handleRole = (event) => {
    setrole_id(event.target.value);
    const newFilter = roleData.filter((row) =>
      row.id.toString().includes(event.target.value.toString())
    );
    newFilter.forEach((option) => {
      if (option.id === event.target.value) {
        setrole(option?.name);
      }
    });
  };

  const tableRecords = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/userrole`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setroleData(row?.data);
            setLoading(false);
          } else if (row?.success === false) {
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  // grabs the id form the url and loads the corresponding data
  useEffect(() => {
    tableRecords();
  }, []);

  return (
    <Layout title="Users Add" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            {/* <div className="d-flex flex-column flex-md-row align-items-md-center">
                        <Media size="huge" shape="circle" variant={theme && theme}>
                            {
                                <Image src={avatar} staticImage thumbnail alt="user"/> 
                              
                            }
                        </Media>
                        <div className="mt-3 mt-md-0 ms-md-3">
                            <h3 className="title mb-1">{user.name}</h3>
                            <span className="small">{user.role}</span>
                            <ul className="nk-list-option pt-1">
                                <li><Icon name="map-pin"></Icon><span className="small">{user.address}</span></li>
                                <li><Icon name="building"></Icon><span className="small">{user.company}</span></li>
                            </ul>
                        </div>
                    </div> */}
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex gap g-2">
              <li className="d-none d-md-block">
                <Link
                  to={`/user-manage/user-list`}
                  className="btn btn-soft btn-primary"
                >
                  <Icon name="user"></Icon>
                  <span>User List</span>
                </Link>
              </li>
              <li className="d-md-none">
                <Link
                  to={`/user-manage/user-list`}
                  className="btn btn-soft btn-primary btn-icon"
                >
                  <Icon name="user"></Icon>
                </Link>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">Add User</h4>
              <Form onSubmit={handleSubmit}>
                <Row className="g-3">
                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="first_name">First Name *</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="first_name"
                          value={first_name}
                          placeholder="First name"
                          required
                          onChange={(e) => setfirst_name(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="last_name">Last Name *</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="last_name"
                          value={last_name}
                          placeholder="Last name"
                          required
                          onChange={(e) => setlast_name(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="email">Email address *</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="email"
                          placeholder="Email address"
                          required
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="user_name">User Name *</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="user_name"
                          value={user_name}
                          placeholder="User name"
                          required
                          onChange={(e) => setuser_name(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="address">Address *</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="address"
                          value={address}
                          placeholder="e.g. California, United States"
                          required
                          onChange={(e) => setaddress(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="phone_number">
                        PhoneNumber *
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="phone_number"
                          value={phone_number}
                          placeholder="e.g. 0244901234"
                          required
                          onChange={(e) => setphone_number(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="phone_number">Password *</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="password"
                          value={password}
                          placeholder=""
                          required
                          onChange={(e) => setpassword(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="6">
                    <div className="form-control-wrap">
                      <FormControl fullWidth size="small">
                        <Form.Label htmlFor="role_id">Role *</Form.Label>
                        <Select
                          labelId="demo-select-small"
                          id="demo-simple-select"
                          value={role_id}
                          label="Role"
                          onChange={handleRole}
                          required
                        >
                          {roleData.map((sales) => (
                            <MenuItem key={sales.id} value={sales.id}>
                              {loading === false ? sales.name : ""}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Col>
                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="facebook_url">
                        Facebook Url
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="facebook_url"
                          placeholder="Facebook url"
                          value={facebook_url}
                          onChange={(e) => setfacebook_url(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="instagram_url">
                        Instagram Url
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="instagram_url"
                          placeholder="Instagram url"
                          value={instagram_url}
                          onChange={(e) => setinstagram_url(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="twitter_url">Twitter Url</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="twitter_url"
                          placeholder="Twitter url"
                          value={twitter_url}
                          onChange={(e) => settwitter_url(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="youtube_url">Youtube Url</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="youtube_url"
                          placeholder="Youtube url"
                          value={youtube_url}
                          onChange={(e) => setyoutube_url(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label>About Me</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          as="textarea"
                          rows="3"
                          value={profile}
                          placeholder="On the other hand, we denounce with righteous indignation"
                          onChange={(e) => setprofile(e.target.value)}
                        ></Form.Control>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {loading2 ? "Saving...." : "Save"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  );
}

export default UserAddPage;
