import React, { useState, useEffect } from "react";
import { Card, Dropdown, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { makeStyles } from "@mui/styles";
// useMemo
// import IconButton from "@mui/material/IconButton";
// import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2/src/sweetalert2.js";
// import Avatar from "@mui/material/Avatar"; Button
import Button from "@mui/material/Button";
import {
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
  //   LinkListItem,
} from "../../components";
// import { toInitials } from "../../utilities";
import { rootURL } from "../utilities/axios-config";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { successMessage, failedMessage } from "../utilities/alert-config";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    fontWeight: "bold",
  },
  item: {
    padding: 5,
  },
  backgroundColor: {
    backgroundColor: "#2CB23B",
  },
}));

function PaidSubscription() {
  const [tableData, setTableData] = useState([]);
  // const [searchText, setSearchText] = useState("");
  const [localSearch, setLocalSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [freeSubcriptions, setfreeSubcriptions] = useState("");
  const [userid, setuserid] = useState("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [paymentId, setpaymentId] = useState("");
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(200);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [open, setOpen] = useState(false);
  const [indexValue, setIndexValue] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const freeSubData = [
    "One Week Free Subscription",
    "One Month Free Subscription",
    "Five Month Free Subscription",
    "One Year Free Subscription",
  ];
  //   const theme = "danger-soft";

  const handleDelete = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Once deleted, You won't be able to revert this data! ${row?.user?.first_name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        Swal.fire("Deleted", "You successfully deleted this file", "success");
        const userToken = localStorage.getItem("userToken");
        const config = {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        };
        setLoading(true);
        fetch(`${rootURL}/freesubscriptionremovecpanel/${row?.id}`, config)
          .then((row) => row.json())
          .then((row) => {
            if (row?.success === true) {
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            } else if (row?.success === false) {
            }
          })
          .finally(() => {});
      } else {
        Swal.fire("Cancelled", "Your data is still intact", "info");
      }
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      setLoading(true);
      fetch(`${rootURL}/paidsubscription_cpanel?page=${newPage}`, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setTableData(row?.data?.data);
            setPage(row?.data?.current_page);
            setCount(row?.data?.total);
            setRowsPerPage(row?.data?.per_page);

            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      setLoading(true);
      fetch(
        `${rootURL}/paidsubscription_cpanel?per_page=${event.target.value}`,
        config
      )
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setTableData(row?.data?.data);
            setPage(row?.data?.current_page);
            setCount(row?.data?.total);
            setRowsPerPage(row?.data?.per_page);

            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditClick = (row, index) => {
    // console.log("row", row)
    setuserid(row?.user_id ?? "");
    let names = row?.user?.first_name + " " + row?.user?.last_name;
    setname(names);
    setemail(row?.user?.email);
    setpaymentId(row?.id);
    handleClickOpen();
    setIndexValue(index);
  };

  const tableRecords = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/paidsubscription_cpanel`;
      // if (searchText) {
      //   url += `?searchtext=${searchText}`;
      // }

      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setTableData(row?.data?.data);
            setPage(row?.data?.current_page);
            setCount(row?.data?.total);
            setRowsPerPage(row?.data?.per_page);

            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubscriptionChange = (event) => {
    setfreeSubcriptions(event);
  };

  const onSubmitSubEdit = (event) => {
    event.preventDefault();
    handleClose();
    const data = {
      userid,
      paymentId,
      email,
      name,
      freeSubcriptions,
      indexValue,
    };
    const userToken = localStorage.getItem("userToken");

    fetch(`${rootURL}/add_freesubscription_cpanel`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");
          // console.log('result',result);

          setTimeout(() => {
            window.location.reload();
            // tableRecords();
          }, 3000);
        } else if (result.success === false) {
          // setloading2(false);
          failedMessage(result.message);
        }
      })
      .catch((error) => {
        // setloading2(false);
        failedMessage("Internal Server Error");
      })
      .finally(() => {
        // setloading2(false);
      });
  };

  // function dateConvert(date) {
  //   return new Date(date).toISOString().slice(0, 10);
  // }

  // const debounce = () => {
  //   let timeoutID;
  //   return (e) => {
  //     setLocalSearch(e.target.value);
  //     clearTimeout(timeoutID);
  //     timeoutID = setTimeout(() => {
  //       setSearchText(e.target.value);
  //       // only make api call after user finish typing
  //     }, 1000);
  //   };
  // };

  // const handleSearch = useMemo(() => debounce(), []);

  useEffect(() => {
    tableRecords();
    // eslint-disable-next-line
  }, []);

  const handleSearch = async (event) => {
    setLocalSearch(event.target.value ?? '');
   
    const newFilter = tableData.filter((value) =>
      value?.user?.email.toLowerCase().includes(event?.target?.value.toLowerCase())
    );
    if (event?.target?.value === "") {
      window.location.reload();
      // setTableData(tableData);
    } else {
  
      setTableData(newFilter);
    }
  };

  return (
    <Layout title="Paid Subscription" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Paid Subscription</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/subscription-manage/paid-subscription">
                    Paid Subscription Manage
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Paid Subscription
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                {/* <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={handleShowModal}
                >
                  <Icon name="plus" />
                  <span>Add</span>
                </Button> */}
                {/* <Link
                  to={`/configuration-manage/status-add`}
                  className="btn btn-soft btn-primary"
                >
                  <Icon name="youtube-round"></Icon>
                  <span>Add Status</span>
                </Link> */}
              </li>
              {/* <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleShowModal}
                >
                  <Icon name="plus" />
                  <span>Add User</span>
                </Button>
              </li> */}
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          <div className="data-table-wrapper">
            <div className="data-table-top">
              <div className="data-table-search">
                <TextField
                  id="outlined-basic"
                  label="Search by Email"
                  variant="outlined"
                  value={localSearch}
                  onChange={(searchVal) => handleSearch(searchVal)}
                />
                {/* <input
                  className="form-control"
                  placeholder="Search by Email"
                  // value={localSearch}
                  type="text"
                  onChange={handleSearch}
                /> */}
              </div>
              <div className="data-table-action-wrap">
                {/* {actions && <Export data={data} />}
                <div className="data-table-select">
                    <select 
                        className="form-select" 
                        onChange={(e) => setShowItemPerPage(e.target.value)}
                        value={showItemPerPage}
                        >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="25">25</option>
                    </select>
                    <span className="text">Per page</span>
                </div> */}
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {loading ? (
                      <TableCell component="th" colSpan="4" align="center">
                        {" "}
                        <CircularProgress size={20} thickness={4} />
                        {"LOADING...... "}
                      </TableCell>
                    ) : (
                      <>
                        <TableCell align="left">User/Email</TableCell>

                        <TableCell align="left">Subscription</TableCell>
                        <TableCell align="left">Payment Type</TableCell>
                        <TableCell align="left">Purchased Date</TableCell>

                        <TableCell align="center">Date Expired On</TableCell>

                        <TableCell align="center">ACTION</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row, index) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row?.user?.first_name + " " + row?.user?.last_name ??
                          ""}
                        <br />
                        {row?.user?.email ?? ""}
                      </TableCell>

                      <TableCell>
                        {row?.user_subs_plan?.plan_name ?? ""}
                      </TableCell>

                      <TableCell>
                        {row?.p_type ?? ""}
                      </TableCell>

                      <TableCell>
                        {(row?.purchase_date) ?? row?.created_at}
                      </TableCell>

                      <TableCell align="center">
                        {(row?.expired_at) ?? ""}
                      </TableCell>

                      <TableCell>
                        <div className="text-end w-100">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              className="dropdown-menu-sm"
                              as={CustomDropdownMenu}
                              align="end"
                            >
                              <div className="dropdown-content py-1">
                                <LinkList className="link-list-hover-bg-primary link-list-md">
                                  {/* <LinkListItem
                                    to={`/configuration-manage/status-edit/${row.id}`}
                                  >
                                    <Icon name="edit"></Icon>
                                    <span>Edit</span>
                                  </LinkListItem> */}

                                  <Button onClick={() => handleDelete(row)}>
                                    <Icon name="trash"></Icon>
                                    <span>Delete</span>
                                  </Button>
                                  <Button
                                    onClick={() => handleEditClick(row, index)}
                                  >
                                    <Icon name="edit"></Icon>
                                    <span>Edit</span>
                                  </Button>
                                </LinkList>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="data-table-bottom">
              <TablePagination
                rowsPerPageOptions={[50, 100, 150, 200, 250]}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </Card>

        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth
        >
          <div style={{ padding: "1rem 2rem" }}>
            <DialogTitle
              id="responsive-dialog-title"
              style={{ fontSize: "1rem" }}
            >
              Free Subscriptions
            </DialogTitle>
            <Form onSubmit={onSubmitSubEdit}>
              <DialogContent>
                <Form.Group controlId="formFile" className="mb-3">
                  <Autocomplete
                    fullWidth
                    disablePortal
                    id="combo-box-demo"
                    value={freeSubcriptions}
                    onChange={(event, newValue) => {
                      handleSubscriptionChange(newValue);
                    }}
                    options={freeSubData.map((option) => option)}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Free Subscriptions"
                        placeholder="Select Subscription"
                      />
                    )}
                  />
                  <br /> <br />
                  <TextField
                    autoComplete="off"
                    type="text"
                    name="email"
                    variant="outlined"
                    id="email"
                    label="Email"
                    disabled
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                    fullWidth
                  />
                  <br /> <br />
                  <TextField
                    autoComplete="off"
                    type="text"
                    name="name"
                    variant="outlined"
                    id="name"
                    label="FullName"
                    disabled
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    fullWidth
                  />
                </Form.Group>
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  className={classes.item}
                  autoFocus
                  onClick={handleClose}
                  color="error"
                >
                  Cancel
                </Button>
                <Button
                  autoFocus
                  variant="contained"
                  type="submit"
                  size="large"
                  className={classes.item}
                >
                  Update
                </Button>
              </DialogActions>
            </Form>
          </div>
        </Dialog>
      </Block>
    </Layout>
  );
}

export default PaidSubscription;
