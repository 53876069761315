import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import Layout from "../../layout/default";
import AWS from "aws-sdk";
import Block from "../../components/Block/Block";
import { Image, Icon, Media } from "../../components";
import { toInitials } from "../../utilities";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import {
  rootURL,
  awsID,
  awsBucketName,
  awsSECRET,
} from "../utilities/axios-config";
import { successMessage, failedMessage } from "../utilities/alert-config";
import CircularProgress from "@mui/material/CircularProgress";

function UserEditPage() {
  const { id } = useParams();
  const history = useNavigate();
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [email, setemail] = useState("");
  const [user_name, setuser_name] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [address, setaddress] = useState("");
  const [role_id, setrole_id] = useState("");
  const [role, setrole] = useState("");
  const [facebook_url, setfacebook_url] = useState("");
  const [instagram_url, setinstagram_url] = useState("");
  const [twitter_url, settwitter_url] = useState("");
  const [youtube_url, setyoutube_url] = useState("");
  const [profile, setprofile] = useState("");
  const [photo, setphoto] = useState("");
  const theme = "danger-soft";
  const avatar = "/images/avatar/c.jpg";

  const [roleData, setroleData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading4, setLoading4] = useState(false);
  // const [progress2, setProgress2] = useState(0);
  const userRolesLink = localStorage.getItem("role");

  function create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxx4xxyxx".replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userToken = localStorage.getItem("userToken");
    setLoading2(true);

    const userObj = {
      id,
      first_name,
      last_name,
      email,
      user_name,
      phone_number,
      address,
      role_id,
      role,
      facebook_url,
      instagram_url,
      twitter_url,
      youtube_url,
      profile,
    };

    fetch(`${rootURL}/updateuser`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");
          setTimeout(() => {
            history(`/user-manage/user-profile/${result?.data?.id}`);
          }, 3000);
        } else if (result.success === false) {
          setLoading2(false);
          failedMessage(result.message.email);
        }
      })
      .catch((error) => {
        setLoading(false);
        failedMessage("Internal Server Error");
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  const handleRole = (event) => {
    setrole_id(event.target.value);
    const newFilter = roleData.filter((row) =>
      row.id.toString().includes(event.target.value.toString())
    );
    newFilter.forEach((option) => {
      if (option.id === event.target.value) {
        setrole(option?.name);
      }
    });
  };

  const tableRecords = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/userrole`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setroleData(row?.data);
            setLoading(false);
          } else if (row?.success === false) {
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableUser = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/usershow/${id}`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setfirst_name(row?.data?.first_name);
            setlast_name(row?.data?.last_name);
            setemail(row?.data?.email);
            setuser_name(row?.data?.name);
            setphone_number(row?.data?.phone_number);
            setaddress(row?.data?.address);
            setrole_id(row?.data?.role_id);
            setrole(row?.data?.role);
            setfacebook_url(row?.data?.facebook_url);
            setinstagram_url(row?.data?.instagram_url);
            settwitter_url(row?.data?.twitter_url);
            setyoutube_url(row?.data?.youtube_url);
            setprofile(row?.data?.profile);
            setphoto(row?.data?.photo);
            setLoading(false);
          } else if (row?.success === false) {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          failedMessage("Internal Server Error");
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const selectedFileAudio = (e) => {
    const audio = e.target.files[0];
    if (audio) {
      setLoading4(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + audio.name, // File name you want to save as in S3
        Body: audio,
        ContentType: audio.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading4(false);
        }

        let photoUrl = data.Location;
        setphoto(photoUrl);
        handlePicUpload(photoUrl);
        setLoading4(false);
      }).on("httpUploadProgress", function (progress2) {
        // eslint-disable-next-line
        let uploaded = Math.round((progress2.loaded / progress2.total) * 100);
        // setProgress2(uploaded);
      });
    }
  };

  const handlePicUpload = async (photo) => {
    const userToken = localStorage.getItem("userToken");
    setLoading2(true);

    const userObj = {
      id,
      photo,
    };

    fetch(`${rootURL}/updatepicture`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");
        } else if (result.success === false) {
          setLoading2(false);
          failedMessage(result.message.email);
        }
      })
      .catch((error) => {
        setLoading(false);
        failedMessage("Internal Server Error");
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  // grabs the id form the url and loads the corresponding data
  useEffect(() => {
    tableRecords();
    tableUser();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress size={70} thickness={4} />
          </div>
        </>
      ) : (
        <>
          <Layout title="Users Edit" content="container">
            <Block.Head>
              <Block.HeadBetween>
                <Block.HeadContent>
                  <div className="d-flex flex-column flex-md-row align-items-md-center">
                    <Media size="huge" shape="circle" variant={theme && theme}>
                      {photo ? (
                        <Image
                          src={photo ?? avatar}
                          staticImage
                          thumbnail
                          alt="user"
                        />
                      ) : (
                        <span className="fw-medium">
                          {toInitials(first_name ?? "")}
                          {toInitials(last_name ?? "")}
                        </span>
                      )}
                    </Media>
                    <div className="mt-3 mt-md-0 ms-md-3">
                      <h3 className="title mb-1">
                        {first_name ?? ""} {last_name ?? ""}
                      </h3>
                      <span className="small">{role ?? ""}</span>
                      <ul className="nk-list-option pt-1">
                        <li>
                          <Icon name="map-pin"></Icon>
                          <span className="small">{address ?? ""}</span>
                        </li>
                        <li>
                          <Icon name="building"></Icon>
                          <span className="small">{phone_number ?? ""}</span>
                        </li>
                      </ul>
                      <div className="pt-3">
                        <input
                          className="upload-image"
                          data-target="image-result2"
                          id="change-avatar2"
                          type="file"
                          max="1"
                          hidden
                          onChange={selectedFileAudio}
                        />
                        <label
                          htmlFor="change-avatar2"
                          className="btn btn-md btn-primary"
                          disabled={loading4}
                        >
                          {loading4 ? "Uploading ..." : "Upload"}
                        </label>
                      </div>
                    </div>
                  </div>
                </Block.HeadContent>
                <Block.HeadContent>
                  <ul className="d-flex gap g-2">
                    <li className="d-none d-md-block">
                      <Link
                        to={`/user-manage/user-profile/${id}`}
                        className="btn btn-soft btn-primary"
                      >
                        <Icon name="user"></Icon>
                        <span>View Profile</span>
                      </Link>
                    </li>
                    <li className="d-md-none">
                      <Link
                        to={`/user-manage/user-profile/${id}`}
                        className="btn btn-soft btn-primary btn-icon"
                      >
                        <Icon name="user"></Icon>
                      </Link>
                    </li>
                  </ul>
                </Block.HeadContent>
              </Block.HeadBetween>
            </Block.Head>
            <Block>
              <Card className="card-gutter-md">
                <Card.Body>
                  <div className="bio-block">
                    <h4 className="bio-block-title mb-4">Edit Profile</h4>
                    <Form onSubmit={handleSubmit}>
                      <Row className="g-3">
                        <Col lg="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="first_name">
                              First Name *
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="text"
                                id="first_name"
                                value={first_name ?? ""}
                                placeholder="First name"
                                required
                                onChange={(e) => setfirst_name(e.target.value)}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="last_name">
                              Last Name *
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="text"
                                id="last_name"
                                value={last_name ?? ""}
                                placeholder="Last name"
                                required
                                onChange={(e) => setlast_name(e.target.value)}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="email">
                              Email address *
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="text"
                                id="email"
                                placeholder="Email address"
                                required
                                value={email ?? ""}
                                onChange={(e) => setemail(e.target.value)}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="user_name">
                              User Name *
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="text"
                                id="user_name"
                                value={user_name ?? ""}
                                placeholder="User name"
                                required
                                onChange={(e) => setuser_name(e.target.value)}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="address">Address *</Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="text"
                                id="address"
                                value={address ?? ""}
                                placeholder="e.g. California, United States"
                                required
                                onChange={(e) => setaddress(e.target.value)}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="phone_number">
                              PhoneNumber *
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="text"
                                id="phone_number"
                                value={phone_number ?? ""}
                                placeholder="e.g. 0244901234"
                                required
                                onChange={(e) =>
                                  setphone_number(e.target.value)
                                }
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        {userRolesLink === "3" ? (
                          <Col lg="6">
                            <div className="form-control-wrap">
                              <FormControl fullWidth size="small">
                                <Form.Label htmlFor="role_id">
                                  Role *
                                </Form.Label>
                                <Select
                                  labelId="demo-select-small"
                                  id="demo-simple-select"
                                  value={role_id ?? ""}
                                  label="Role"
                                  onChange={handleRole}
                                  required
                                >
                                  {roleData.map((sales) => (
                                    <MenuItem key={sales.id} value={sales.id}>
                                      {loading === false ? sales.name : ""}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col lg="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="facebook_url">
                              Facebook Url
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="text"
                                id="facebook_url"
                                placeholder="Facebook url"
                                value={facebook_url ?? ""}
                                onChange={(e) =>
                                  setfacebook_url(e.target.value)
                                }
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="instagram_url">
                              Instagram Url
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="text"
                                id="instagram_url"
                                placeholder="Instagram url"
                                value={instagram_url ?? ""}
                                onChange={(e) =>
                                  setinstagram_url(e.target.value)
                                }
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="twitter_url">
                              Twitter Url
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="text"
                                id="twitter_url"
                                placeholder="Twitter url"
                                value={twitter_url ?? ""}
                                onChange={(e) => settwitter_url(e.target.value)}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="youtube_url">
                              Youtube Url
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="text"
                                id="youtube_url"
                                placeholder="Youtube url"
                                value={youtube_url ?? ""}
                                onChange={(e) => setyoutube_url(e.target.value)}
                              />
                            </div>
                          </Form.Group>
                        </Col>

                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Label>About Me</Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                as="textarea"
                                rows="3"
                                value={profile ?? ""}
                                placeholder="On the other hand, we denounce with righteous indignation"
                                onChange={(e) => setprofile(e.target.value)}
                              ></Form.Control>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg="12">
                          <Button variant="primary" type="submit">
                            {loading2 ? "Updating...." : "Update"}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </Block>
          </Layout>
        </>
      )}
    </>
  );
}

export default UserEditPage;
