import { useState, useEffect } from "react";
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// import AWS from "aws-sdk";
import { Card, Form, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../layout/default";
// import Avatar from "@mui/material/Avatar";
import Block from "../../components/Block/Block";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
// import DateTimePicker from "react-datetime-picker";
// import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import { makeStyles } from "@mui/styles";
// import { useTheme } from "@mui/material/styles";
// import Paper from "@mui/material/Paper";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
// import Box from "@mui/material/Box";
// import EditIcon from "@mui/icons-material/Edit";
// import Grid from "@mui/material/Grid";
// import IconButton from "@mui/material/IconButton";
// import DeleteIcon from "@mui/icons-material/Delete";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
// import InputLabel from '@mui/material/InputLabel';
// import useMediaQuery from "@mui/material/useMediaQuery";
// import Stack from "@mui/material/Stack";
//import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
//import dayjs from "dayjs";
import { successMessage, failedMessage } from "../utilities/alert-config";
//import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
//import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import //Select,
//Quill,
//FileUpload,
//Tags,
//ImageUpload,
//DatePicker,
// Tinymce,
"../../components";
import {
  rootURL,
  // awsID,
  // awsBucketName,
  // awsSECRET,
} from "../utilities/axios-config";
// import Checkbox from "@mui/material/Checkbox";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const Input = styled("input")({
//   display: "none",
// });

// const useStyles = makeStyles(() => ({
//   tableHeaderCell: {
//     fontWeight: "bold",
//   },
//   item: {
//     padding: 5,
//   },
//   backgroundColor: {
//     backgroundColor: "#2CB23B",
//   },
// }));

function AddPushMessage() {
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [name, setname] = useState("");
  const [notification_type, setnotification_type] = useState("");
  const [message, setmessage] = useState("");
  const [page_type, setpage_type] = useState("");
  // const [link, setlink] = useState("");
  const [movie, setmovie] = useState("");
  const [movie_id, setmovie_id] = useState("");
  const [tvshows, settvshows] = useState("");
  const [tvshow_id, settvshow_id] = useState("")
  const [magazine, setmagazine] = useState("");
  const [magazine_id, setmagazine_id] = useState("");
  
  const [radio, setradio] = useState("");
  const [radio_id,setradio_id] = useState("");
  const [livetv, setlivetv] = useState("");
  const [livetv_id, setlivetv_id] = useState("");
  
  const [selectedMovie, setSelectedMovie] = useState([]);
  const [selectedTvShow, setSelectetvshow] = useState([]);
  const [selectedMagazines, setSelectedMagazines] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState([]);
  const [selectedLiveTv, setSelectedLiveTv] = useState([]);

  const [loading2, setloading2] = useState(false);
  // const [loading3, setLoading3] = useState(false);
  // const [progress, setProgress] = useState(0);
  // const [poststatus, setpoststatus] = useState([]);
  // const [scheduledate, setscheduledate] = useState("");
  // const [schstatus, setschstatus] = useState(false);

  //const today = new Date().toISOString().slice(0, 10);

  const notificationtypes = ["All Users"];
  const pageTypes = [
    "home",
    "movies",
    "tvshows",
    "livetv",
    "radio",
    "magazine",
  ];

  const tableMovieRecommendation = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/allmovierecommendation_cpanel`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setSelectedMovie(row?.data);

            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableShowRecommendation = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/alltvshowrecommendation_cpanel`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            // console.log("arr", arr)
            setSelectetvshow(row?.data);

            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableMagazineRecommendation = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/allmagazinerecommendation_cpanel`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            // console.log("arr", arr)
            setSelectedMagazines(row?.data);

            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableRadioRecommendation = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/allradiorecommendation_cpanel`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setSelectedRadio(row?.data);

            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableLiveTvRecommendation = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/alllivetvrecommendation_cpanel`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setSelectedLiveTv(row?.data);

            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    tableMovieRecommendation();
    tableShowRecommendation();
    tableMagazineRecommendation();
    tableRadioRecommendation();
    tableLiveTvRecommendation();
  }, []);

  // const handleStatus = (event) => {
  //   setpost_state_id(event.target.value);
  //   setschstatus(false);
  //   if (event.target.value === 4) {
  //     setschstatus(true);
  //   }
  // };

  // const selectedFile = (e) => {
  //   const image = e.target.files[0];
  //   if (image) {
  //     setLoading3(true);
  //     const s3 = new AWS.S3({
  //       accessKeyId: awsID,
  //       secretAccessKey: awsSECRET,
  //     });

  //     const params = {
  //       Bucket: awsBucketName,
  //       Key: create_UUID() + image.name, // File name you want to save as in S3
  //       Body: image,
  //       ContentType: image.type,
  //       ACL: "public-read",
  //     };

  //     s3.upload(params, function (err, data) {
  //       if (err) {
  //         console.log(err, "failure");
  //         setLoading3(false);
  //       }
  //       // console.log("success");

  //       // console.log(data.Location, "link");
  //       let photoUrl = data.Location;
  //       setimagelink(photoUrl);
  //       setLoading3(false);

  //       // Editor.insertEmbed(cursorLocation, "image", photoUrl);
  //       // resetUploader();
  //     }).on("httpUploadProgress", function (progress) {
  //       let uploaded = Math.round((progress.loaded / progress.total) * 100);
  //       setProgress(uploaded);
  //     });
  //   }
  // };

  const handleNotifTypes = (event) => {
    setnotification_type(event.target.value);
  };

  const handlePageTypes = (event) => {
    // console.log("event", event.target.value);
    setpage_type(event.target.value);
  };

  const filtermovie = (event) => {
    const newFilter = selectedMovie.filter((value) => (value.post_title || '').toLowerCase().includes((event || '').toLowerCase()));
    // console.log('channel', newFilter);
    newFilter.forEach((option) => {
        // console.log('option', option._id);
        if (option.post_title === event) {
         
            setmovie_id(option.id);
        }
    });
};


const filtertvshow = (event) => {
  const newFilter = selectedLiveTv.filter((value) => (value.post_title || '').toLowerCase().includes((event || '').toLowerCase()));
  // console.log('channel', newFilter);
  newFilter.forEach((option) => {
      // console.log('option', option._id);
      if (option.post_title === event) {
       
          settvshow_id(option.id);
      }
  });
};



const filtermagazine = (event) => {
  const newFilter = selectedMagazines.filter((value) => (value.post_title || '').toLowerCase().includes((event || '').toLowerCase()));
  // console.log('channel', newFilter);
  newFilter.forEach((option) => {
      // console.log('option', option._id);
      if (option.post_title === event) {
       
          setmagazine_id(option.id);
      }
  });
};

const filterradio = (event) => {
  const newFilter = selectedRadio.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
  // console.log('channel', newFilter);
  newFilter.forEach((option) => {
      // console.log('option', option._id);
      if (option.name === event) {
       
          setradio_id(option.id);
      }
  });
};




const filterlivetv = (event) => {
  const newFilter = selectedLiveTv.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
  // console.log('channel', newFilter);
  newFilter.forEach((option) => {
      // console.log('option', option._id);
      if (option.name === event) {
       
          setlivetv_id(option.id);
      }
  });
};



  const handleSubmit = async (e) => {
    e.preventDefault();
    const userToken = localStorage.getItem("userToken");
    setloading2(true);
    const radioObj = {
      notification_type,
      message,
      page_type,
      movie,
      movie_id,
      tvshows,
      tvshow_id,
      magazine,
      magazine_id,
      radio,
      radio_id,
      livetv,
      livetv_id
    };
    // console.log("radioObj", radioObj);

    fetch(`${rootURL}/cpanel_send_pushmessage`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(radioObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");

          setTimeout(() => {
            history("/pushmessage-manage/pushmessage-list");
          }, 3000);
        } else if (result.success === false) {
          setloading2(false);
          failedMessage(result.message);
        }
      })
      .catch((error) => {
        setloading2(false);
        failedMessage("Internal Server Error");
      })
      .finally(() => {
        setloading2(false);
      });
  };

  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress size={70} thickness={4} />
          </div>
        </>
      ) : (
        <>
          <Layout title="Add Push Notification" content="container">
            <Block.Head>
              <Block.HeadBetween>
                <Block.HeadContent>
                  <Block.Title tag="h2">Send Push Notification</Block.Title>
                  <nav>
                    <ol className="breadcrumb breadcrumb-arrow mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/pushmessage-manage/pushmessage-listt">
                          Push Notification
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Send Push Notification
                      </li>
                    </ol>
                  </nav>
                </Block.HeadContent>
              </Block.HeadBetween>
            </Block.Head>

            <Block>
              <Form onSubmit={handleSubmit}>
                <Row className="g-gs">
                  <Col xxl="9">
                    <div className="gap gy-4">
                      <div className="gap-col">
                        <Card className="card-gutter-md">
                          <Card.Body>
                            <Row className="g-gs">
                              <Col lg="12">
                                <div className="form-control-wrap">
                                  <FormControl fullWidth size="small">
                                    <Form.Label htmlFor="status_id">
                                      Notification Type *
                                    </Form.Label>
                                    <Select
                                      labelId="demo-select-small"
                                      id="demo-simple-select"
                                      value={notification_type ?? ""}
                                      label="Notification type"
                                      onChange={handleNotifTypes}
                                      required
                                    >
                                      {notificationtypes.map((types) => (
                                        <MenuItem key={types} value={types}>
                                          {types}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label>Message *</Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      as="textarea"
                                      rows="3"
                                      value={message}
                                      placeholder=""
                                      required
                                      onChange={(e) =>
                                        setmessage(e.target.value)
                                      }
                                    ></Form.Control>
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <div className="form-control-wrap">
                                  <FormControl fullWidth size="small">
                                    <Form.Label htmlFor="status_id">
                                      Page Type *
                                    </Form.Label>
                                    <Select
                                      labelId="demo-select-small"
                                      id="demo-simple-select"
                                      value={page_type ?? ""}
                                      label="Page type"
                                      onChange={handlePageTypes}
                                      required
                                    >
                                      {pageTypes.map((types) => (
                                        <MenuItem key={types} value={types}>
                                          {types}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </Col>

                              {page_type === "movies" ? (
                                <>
                                  <Col lg="12">
                                    <div className="form-control-wrap">
                                      <FormControl fullWidth size="small">
                                        <Form.Label htmlFor="status_id">
                                          Movies
                                        </Form.Label>
                                        <Autocomplete
                                          disablePortal
                                          id="combo-box-demo"
                                          value={movie}
                                          onChange={(event, newValue) => {
                                            filtermovie(newValue);
                                            setmovie(newValue)
                                          }}
                                          options={selectedMovie.map(
                                            (option) => option.post_title
                                          )}
                                          isOptionEqualToValue={(
                                            option,
                                            value
                                          ) => option.id === value.id}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Movie "
                                            />
                                          )}
                                        />
                                      </FormControl>
                                    </div>
                                  </Col>
                                </>
                              ) : (
                                ""
                              )}

                              {page_type === "tvshows" ? (
                                <>
                                  <Col lg="12">
                                    <div className="form-control-wrap">
                                      <FormControl fullWidth size="small">
                                        <Form.Label htmlFor="status_id">
                                          Tv show
                                        </Form.Label>
                                        <Autocomplete
                                          disablePortal
                                          id="combo-box-demo"
                                          value={movie}
                                          onChange={(event, newValue) => {
                                            settvshows(newValue);
                                            filtertvshow(newValue);
                                          }}
                                          options={selectedTvShow.map(
                                            (option) => option.post_title
                                          )}
                                          isOptionEqualToValue={(
                                            option,
                                            value
                                          ) => option.id === value.id}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Tv show "
                                            />
                                          )}
                                        />
                                      </FormControl>
                                    </div>
                                  </Col>
                                </>
                              ) : (
                                ""
                              )}

                              {page_type === "livetv" ? (
                                <>
                                  <Col lg="12">
                                    <div className="form-control-wrap">
                                      <FormControl fullWidth size="small">
                                        <Form.Label htmlFor="status_id">
                                          Live TV
                                        </Form.Label>
                                        <Autocomplete
                                          disablePortal
                                          id="combo-box-demo"
                                          value={livetv}
                                          onChange={(event, newValue) => {
                                            setlivetv(newValue);
                                            filterlivetv(newValue);
                                        
                                          }}
                                          options={selectedLiveTv.map(
                                            (option) => option.name
                                          )}
                                          isOptionEqualToValue={(
                                            option,
                                            value
                                          ) => option.id === value.id}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Live TV "
                                            />
                                          )}
                                        />
                                      </FormControl>
                                    </div>
                                  </Col>
                                </>
                              ) : (
                                ""
                              )}

                              {page_type === "radio" ? (
                                <>
                                  <Col lg="12">
                                    <div className="form-control-wrap">
                                      <FormControl fullWidth size="small">
                                        <Form.Label htmlFor="status_id">
                                          Audio
                                        </Form.Label>
                                        <Autocomplete
                                          disablePortal
                                          id="combo-box-demo"
                                          value={radio}
                                          onChange={(event, newValue) => {
                                            setradio(newValue);
                                            filterradio(newValue);
                                          }}
                                          options={selectedRadio.map(
                                            (option) => option.name
                                          )}
                                          isOptionEqualToValue={(
                                            option,
                                            value
                                          ) => option.id === value.id}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Audio"
                                            />
                                          )}
                                        />
                                      </FormControl>
                                    </div>
                                  </Col>
                                </>
                              ) : (
                                ""
                              )}

                              {page_type === "magazine" ? (
                                <>
                                  <Col lg="12">
                                    <div className="form-control-wrap">
                                      <FormControl fullWidth size="small">
                                        <Form.Label htmlFor="status_id">
                                          Magazine
                                        </Form.Label>
                                        <Autocomplete
                                          disablePortal
                                          id="combo-box-demo"
                                          value={radio}
                                          onChange={(event, newValue) => {
                                            filtermagazine(newValue);
                                            setmagazine(newValue);
                                         
                                          }}
                                          options={selectedMagazines.map(
                                            (option) => option.post_title
                                          )}
                                          isOptionEqualToValue={(
                                            option,
                                            value
                                          ) => option.id === value.id}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Magazine"
                                            />
                                          )}
                                        />
                                      </FormControl>
                                    </div>
                                  </Col>
                                </>
                              ) : (
                                ""
                              )}
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>

                      <div className="gap-col">
                        <ul className="d-flex align-items-center gap g-3">
                          <li>
                            <Button type="submit" variant="contained">
                              {loading2 ? "Saving...." : "Save"}
                            </Button>
                          </li>
                          <li>
                            <Link
                              to="/pushmessage-manage/pushmessage-list"
                              className="btn border-0"
                            >
                              Cancel
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Block>
          </Layout>
        </>
      )}
    </>
  );
}

export default AddPushMessage;
