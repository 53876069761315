import Swal from 'sweetalert2/src/sweetalert2.js';

const successMessage = (message) =>  {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: message,
      showConfirmButton: false,
      timer: 1500
    });
};

const failedMessage = message =>  {
    Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Oops...',
      text: message,
      showConfirmButton: false,
      timer: 2500
    });
};

export {successMessage, failedMessage}