import { useState, useEffect } from "react";
import { useQuill } from "react-quilljs";
import "../../assets/scss/libs/editors/quill.scss";

import AWS from "aws-sdk";
import { Card, Form, Row, Col } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/default";

import Block from "../../components/Block/Block";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
// import Stack from "@mui/material/Stack";
//import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs from "dayjs";
import { successMessage, failedMessage } from "../utilities/alert-config";
//import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
//import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import //Select,
//Quill,
//FileUpload,
//Tags,
//ImageUpload,
//DatePicker,
// Tinymce,
"../../components";
import {
  rootURL,
  awsID,
  awsBucketName,
  awsSECRET,
} from "../utilities/axios-config";
//import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const Input = styled("input")({
//   display: "none",
// });

function MagazineProfile() {
  const { id } = useParams();
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [magazine_title, setmagazine_title] = useState("");
  const [description, setdescription] = useState("");
  const [descriptiontwo, setdescriptiontwo] = useState("");
  const [recommended_articles, setrecommended_articles] = useState([]);
  const [all_recommended_articles, setallrecommended_articles] = useState([]);
  const [categorys, setcategorys] = useState([]);
  const [tageg, settageg] = useState([]);
  const [all_category, setallcategory] = useState([]);
  const [all_tag, setalltag] = useState([]);
  const [post_state_id, setpost_state_id] = useState("");
  const [poststatus, setpoststatus] = useState([]);
  const [country, setcountry] = useState([]);
  const [postmedate, setpostmedate] = useState("");
  const [posttofacebook, setposttofacebook] = useState(true);
  const [postotwitter, setpostotwitter] = useState(true);
  const [header, setheader] = useState(true);
  const [business, setbusiness] = useState(true);
  const [lifestyle, setlifestyle] = useState(true);
  const [technology, settechnology] = useState(true);
  const [trending_now, settrending_now] = useState(true);
  const [polit_id, setpolit_id] = useState(true);
  const [travel_id, settravel_id] = useState(true);
  const [articles_id, setarticles_id] = useState(true);
  const [industry_pulse_id, setindustry_pulse_id] = useState(true);
  const [food_id, setfood_id] = useState(true);
  const [sports_id, setsports_id] = useState(true);
  const [tv_id, settv_id] = useState(true);
  const [african_carib_id, setafrican_carib_id] = useState(true);
  const [postnotification, setpostnotification] = useState(true);
  // const [loading2, setloading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [progress, setProgress] = useState(0);
  const [mag_image, setmag_image] = useState(
    "/images/avatar/avatar-placeholder.jpg"
  );

  const { quill, quillRef } = useQuill();

  function create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxx4xxyxx".replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  // Open Dialog to select Image File
  const selectLocalImage = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      if (file) {
        const s3 = new AWS.S3({
          accessKeyId: awsID,
          secretAccessKey: awsSECRET,
        });

        const params = {
          Bucket: awsBucketName,
          Key: create_UUID() + file.name, // File name you want to save as in S3
          Body: file,
          ContentType: file.type,
          ACL: "public-read",
        };

        s3.upload(params, function (err, data) {
          if (err) {
            console.log(err, "failure");
          }
          // console.log("success");

          let photoUrl = data.Location;
          const range = quill.getSelection();
          quill.insertEmbed(range.index, "image", photoUrl);
        }).on("httpUploadProgress", function (progress) {
          //let uploaded = Math.round((progress.loaded / progress.total) * 100);
        });
      }
    };
  };

  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(descriptiontwo);
      quill.getModule("toolbar").addHandler("image", selectLocalImage);
      quill.on("text-change", (delta, oldDelta, source) => {
        setdescription(quill.root.innerHTML);
      });
    }
    // eslint-disable-next-line
  }, [quill, descriptiontwo]);

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    " Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Côte d'Ivoire",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    " Congo(Congo - Brazzaville)",
    " Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    " Czechia(Czech Republic)",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    " Equatorial Guinea",
    "Eritrea",
    "Estonia",
    " Eswatini(fmr. 'Swaziland')",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea - Bissau",
    "Guyana",
    "Haiti",
    " Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar(formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    " New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    " North Korea",
    " North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    " Saint Kitts and Nevis",
    "Saint Lucia",
    " Saint Vincent and the Grenadines",
    "Samoa",
    " San Marino",
    "Sao Tome and Principe",
    " Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    " Solomon Islands",
    "Somalia",
    "  South Africa",
    " South Korea",
    "South Sudan",
    "Spain",
    " Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    " Timor - Leste",
    "Togo",
    "Tonga",
    " Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    " United Kingdom",
    " United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const tableRecommends = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/magazineRecommendation`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setallrecommended_articles(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableCategory = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/magCategory`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setallcategory(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableTag = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/tag`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setalltag(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableStatus = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/poststatus`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setpoststatus(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableMagShow = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/magazineshow/${id}`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setdescription(row?.data?.post_content);
            setdescriptiontwo(row?.data?.post_content);
            setmagazine_title(row?.data?.post_title);
            const status = Number(row?.data?.post_state_id.toString());

            setpost_state_id(status);
            const today = new Date(row?.data?.post_date)
              .toISOString()
              .slice(0, 10);

            setpostmedate(today);

            if (
              row?.data?.country === false ||
              row?.data?.country === null ||
              row?.data?.country === []
            ) {
              setcountry([]);
            } else {
              setcountry(row?.data?.country ?? []);
            }

            if (
              row?.data?.recommended_articles === false ||
              row?.data?.recommended_articles === null ||
              row?.data?.recommended_articles === []
            ) {
              setrecommended_articles([]);
            } else {
              setrecommended_articles(row?.data?.recommended_articles ?? []);
            }

            if (
              row?.data?.categ === false ||
              row?.data?.categ === null ||
              row?.data?.categ === []
            ) {
              setcategorys([]);
            } else {
              setcategorys(row?.data?.categ ?? []);
            }

            if (
              row?.data?.tageg === false ||
              row?.data?.tageg === null ||
              row?.data?.tageg === []
            ) {
              settageg([]);
            } else {
              settageg(row?.data?.tageg ?? []);
            }

            if (row?.data?.posttofacebook === null) {
              setposttofacebook(false);
            } else if (row?.data?.posttofacebook === 1) {
              setposttofacebook(true);
            }
            if (row?.data?.postotwitter === null) {
              setpostotwitter(false);
            } else if (row?.data?.postotwitter === 1) {
              setpostotwitter(true);
            }

            if (row?.data?.header === null) {
              setheader(false);
            } else if (row?.data?.header === 1) {
              setheader(true);
            }

            if (row?.data?.bus_id === null) {
              setbusiness(false);
            } else if (row?.data?.bus_id === 1) {
              setbusiness(true);
            }

            if (row?.data?.life_id === null) {
              setlifestyle(false);
            } else if (row?.data?.life_id === 1) {
              setlifestyle(true);
            }

            if (row?.data?.tech_id === null) {
              settechnology(false);
            } else if (row?.data?.tech_id === 1) {
              settechnology(true);
            }

            if (row?.data?.trending_now === null) {
              settrending_now(false);
            } else if (row?.data?.trending_now === 1) {
              settrending_now(true);
            }

            if (row?.data?.polit_id === null) {
              setpolit_id(false);
            } else if (row?.data?.polit_id === 1) {
              setpolit_id(true);
            }

            if (row?.data?.travel_id === null) {
              settravel_id(false);
            } else if (row?.data?.travel_id === 1) {
              settravel_id(true);
            }

            if (row?.data?.articles_id === null) {
              setarticles_id(false);
            } else if (row?.data?.articles_id === 1) {
              setarticles_id(true);
            }

            if (row?.data?.industry_pulse_id === null) {
              setindustry_pulse_id(false);
            } else if (row?.data?.industry_pulse_id === 1) {
              setindustry_pulse_id(true);
            }

            if (row?.data?.food_id === null) {
              setfood_id(false);
            } else if (row?.data?.food_id === 1) {
              setfood_id(true);
            }

            if (row?.data?.sports_id === null) {
              setsports_id(false);
            } else if (row?.data?.sports_id === 1) {
              setsports_id(true);
            }

            if (row?.data?.tv_id === null) {
              settv_id(false);
            } else if (row?.data?.tv_id === 1) {
              settv_id(true);
            }

            if (row?.data?.african_carib_id === null) {
              setafrican_carib_id(false);
            } else if (row?.data?.african_carib_id === 1) {
              setafrican_carib_id(true);
            }

            if (row?.data?.postnotification === null) {
              setpostnotification(false);
            } else if (row?.data?.postnotification === 1) {
              setpostnotification(true);
            }

            setmag_image(row?.data?.mag_image);

            setLoading(false);
          } else if (row?.success === false) {
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    tableMagShow();
    tableRecommends();
    tableCategory();
    tableTag();
    tableStatus();

    // eslint-disable-next-line
  }, [id]);

  const handleRecommendedChange = (event) => {
    setrecommended_articles(event);
  };

  const handleCategoryChange = (event) => {
    setcategorys(event);
  };

  const handleTagChange = (event) => {
    settageg(event);
  };

  const handleStatus = (event) => {
    setpost_state_id(event.target.value);
  };

  const handleCountryChange = (event) => {
    setcountry(event);
  };

  // const handleChange = (content, delta, source, editor) => {
  //   //console.log(editor.getHTML());
  //   setdescription(editor.getHTML());
  // };

  const selectedFile = (e) => {
    const image = e.target.files[0];
    if (image) {
      setLoading3(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + image.name, // File name you want to save as in S3
        Body: image,
        ContentType: image.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading3(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setmag_image(photoUrl);
        setLoading3(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress) {
        let uploaded = Math.round((progress.loaded / progress.total) * 100);
        setProgress(uploaded);
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userToken = localStorage.getItem("userToken");
    // setloading2(true);

    const magObj = {
      id,
      magazine_title,
      description,
      recommended_articles,
      categorys,
      tageg,
      country,
      postmedate,
      posttofacebook,
      postotwitter,
      header,
      business,
      lifestyle,
      technology,
      trending_now,
      polit_id,
      travel_id,
      articles_id,
      industry_pulse_id,
      food_id,
      sports_id,
      tv_id,
      african_carib_id,
      post_state_id,
      mag_image,
      postnotification,
    };

    console.log("magObj", magObj);

    fetch(`${rootURL}/updatemagazine`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(magObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");
          //   console.log("data", result)
          setTimeout(() => {
            history("/magazine-manage/magazine-list");
            //history(`/user-manage/user-profile/${result?.data?.id}`);
          }, 3000);
        } else if (result.success === false) {
          // setloading2(false);
          failedMessage(result.message);
        }
      })
      .finally(() => {
        // setloading2(false);
      });
  };

  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress size={70} thickness={4} />
          </div>
        </>
      ) : (
        <>
          <Layout title="Add Product" content="container">
            <Block.Head>
              <Block.HeadBetween>
                <Block.HeadContent>
                  <Block.Title tag="h2">View Article</Block.Title>
                  <nav>
                    <ol className="breadcrumb breadcrumb-arrow mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/magazine-manage/magazine-list">
                          Magazine
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        View Article
                      </li>
                    </ol>
                  </nav>
                </Block.HeadContent>
                <Block.HeadContent>
                  <ul className="d-flex">
                    <li>
                      <Link
                        to="/magazine-manage/magazine-list"
                        className="btn btn-primary btn-md d-md-none"
                      >
                        {/* <Icon name="eye"/> */}
                        <span>Back</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/magazine-manage/magazine-list"
                        className="btn btn-primary d-none d-md-inline-flex"
                      >
                        {/* <Icon name="eye"/> */}
                        <span>Back</span>
                      </Link>
                    </li>
                  </ul>
                </Block.HeadContent>
              </Block.HeadBetween>
            </Block.Head>

            <Block>
              <Form onSubmit={handleSubmit}>
                <Row className="g-gs">
                  <Col xxl="9">
                    <div className="gap gy-4">
                      <div className="gap-col">
                        <Card className="card-gutter-md">
                          <Card.Body>
                            <Row className="g-gs">
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="vat-amount">
                                    Article Date *
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <input
                                      type="date"
                                      id="postmedate"
                                      name="postmedate"
                                      required
                                      value={postmedate}
                                      onChange={(newValue) => {
                                        setpostmedate(newValue.target.value);
                                      }}
                                    />
                                    {/* <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DemoContainer
                                        components={["DatePicker"]}
                                      >
                                        <DatePicker
                                          defaultValue={dayjs(new Date())}
                                          value={postmedate}
                                          onChange={(newValue) => {
                                            setpostmedate(newValue);
                                            console.log("newvalue", newValue);
                                          }}
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider> */}
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label>Cover</Form.Label>
                                  <div className="form-control-wrap">
                                    <div className="image-upload-wrap d-flex flex-column align-items-center">
                                      <div>Upload progress {progress}%</div>
                                      <div className="media media-huge border">
                                        <img
                                          id="image-result"
                                          className="w-100 h-100"
                                          src={mag_image}
                                          alt="avatar"
                                        />
                                      </div>
                                      <div className="pt-3">
                                        <input
                                          className="upload-image"
                                          data-target="image-result"
                                          id="change-avatar"
                                          type="file"
                                          max="1"
                                          hidden
                                          onChange={selectedFile}
                                        />
                                        <label
                                          htmlFor="change-avatar"
                                          className="btn btn-md btn-primary"
                                          disabled={loading3}
                                        >
                                          {loading3
                                            ? "Uploading ..."
                                            : "Upload"}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-note mt-3">
                                    Set the product thumbnail image. Only *.png,
                                    *.jpg and *.jpeg image files are accepted.
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="productname">
                                    Article Title *
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="magazine_title"
                                      placeholder="Article Title"
                                      disabled
                                      value={magazine_title}
                                      required
                                      onChange={(e) =>
                                        setmagazine_title(e.target.value)
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label>Description *</Form.Label>
                                  <div className="form-control-wrap">
                                    <div
                                      style={{ width: "100%", height: "100%",background: "#1C212E",color:"#fff" }}
                                    >
                                      <div ref={quillRef} />
                                    </div>
                                  </div>
                                </Form.Group>
                              </Col>
                              <br />

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="baseprice">
                                    Recommended Articles
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Autocomplete
                                      multiple
                                      fullWidth
                                      disabled
                                      id="checkboxes-tags-demo"
                                      value={recommended_articles}
                                      options={all_recommended_articles}
                                      onChange={(event, newValue) => {
                                        handleRecommendedChange(newValue);
                                      }}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) =>
                                        option.post_title
                                      }
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option.post_title}
                                        </li>
                                      )}
                                      isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Recommended Article"
                                          placeholder="Select Recommended Article"
                                        />
                                      )}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="discount-price">
                                    Category *
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Autocomplete
                                      multiple
                                      fullWidth
                                      id="checkboxes-tags-demo"
                                      value={categorys}
                                      disabled
                                      required
                                      options={all_category}
                                      onChange={(event, newValue) => {
                                        handleCategoryChange(newValue);
                                      }}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) => option.name}
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option.name}
                                        </li>
                                      )}
                                      isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Category"
                                          placeholder="Select Category"
                                        />
                                      )}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="tag">Tag</Form.Label>
                                  <div className="form-control-wrap">
                                    <Autocomplete
                                      multiple
                                      fullWidth
                                      id="checkboxes-tags-demo"
                                      value={tageg}
                                      disabled
                                      options={all_tag}
                                      onChange={(event, newValue) => {
                                        handleTagChange(newValue);
                                      }}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) => option.name}
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option.name}
                                        </li>
                                      )}
                                      isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Tag"
                                          placeholder="Select Tag"
                                        />
                                      )}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <div className="form-control-wrap">
                                  <FormControl fullWidth size="small">
                                    <Form.Label htmlFor="status_id">
                                      Status *
                                    </Form.Label>
                                    <Select
                                      labelId="demo-select-small"
                                      id="demo-simple-select"
                                      defaultValue=""
                                      disabled
                                      value={post_state_id ?? ""}
                                      label="Status"
                                      onChange={handleStatus}
                                      required
                                    >
                                      {poststatus.map((status) => (
                                        <MenuItem
                                          key={status?.id}
                                          value={status?.id ?? ""}
                                        >
                                          {loading === false ? status.name : ""}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="country">
                                    Countries not to see content
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Autocomplete
                                      multiple
                                      fullWidth
                                      id="checkboxes-tags-demo"
                                      value={country}
                                      disabled
                                      options={countries}
                                      onChange={(event, newValue) => {
                                        handleCountryChange(newValue);
                                      }}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) => option}
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option}
                                        </li>
                                      )}
                                      isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Country"
                                          placeholder="Select Country"
                                        />
                                      )}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="POST TO FACEBOOK"
                                  control={
                                    <Checkbox
                                      checked={posttofacebook}
                                      onChange={(e) => {
                                        setposttofacebook(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="POST TO TWITTER"
                                  control={
                                    <Checkbox
                                      checked={postotwitter}
                                      onChange={(e) => {
                                        setpostotwitter(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="HEADER"
                                  control={
                                    <Checkbox
                                      checked={header}
                                      onChange={(e) => {
                                        setheader(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="BUSINESS"
                                  control={
                                    <Checkbox
                                      checked={business}
                                      onChange={(e) => {
                                        setbusiness(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="LIFESTYLE"
                                  control={
                                    <Checkbox
                                      checked={lifestyle}
                                      onChange={(e) => {
                                        setlifestyle(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="SCIENCE & TECHNOLOGY"
                                  control={
                                    <Checkbox
                                      checked={technology}
                                      onChange={(e) => {
                                        settechnology(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="TRENDING NOW"
                                  control={
                                    <Checkbox
                                      checked={trending_now}
                                      onChange={(e) => {
                                        settrending_now(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="WORLD NEWS & POLITICS"
                                  control={
                                    <Checkbox
                                      checked={polit_id}
                                      onChange={(e) => {
                                        setpolit_id(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="TRAVEL"
                                  control={
                                    <Checkbox
                                      checked={travel_id}
                                      onChange={(e) => {
                                        settravel_id(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="HEALTH"
                                  control={
                                    <Checkbox
                                      checked={articles_id}
                                      onChange={(e) => {
                                        setarticles_id(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="INDUSTRY PULSE"
                                  control={
                                    <Checkbox
                                      checked={industry_pulse_id}
                                      onChange={(e) => {
                                        setindustry_pulse_id(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="FOOD"
                                  control={
                                    <Checkbox
                                      checked={food_id}
                                      onChange={(e) => {
                                        setfood_id(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="SPORTS"
                                  control={
                                    <Checkbox
                                      checked={sports_id}
                                      onChange={(e) => {
                                        setsports_id(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="MOVIES & TV"
                                  control={
                                    <Checkbox
                                      checked={tv_id}
                                      onChange={(e) => {
                                        settv_id(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="AFRICAN CARRIBEAN"
                                  control={
                                    <Checkbox
                                      checked={african_carib_id}
                                      onChange={(e) => {
                                        setafrican_carib_id(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="PUSH NOTIFICATION TO APP"
                                  control={
                                    <Checkbox
                                      checked={postnotification}
                                      onChange={(e) => {
                                        setpostnotification(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>

                      <div className="gap-col">
                        <ul className="d-flex align-items-center gap g-3">
                          {/* <li>
                            <Button type="submit" variant="contained">
                              {loading2 ? "Saving...." : "Save"}
                            </Button>
                          </li> */}
                          {/* <li>
                            <Link
                              to="/magazine-manage/magazine-list"
                              className="btn border-0"
                            >
                              Back
                            </Link>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </Col>
                  {/* <Col xxl="3">
                    <Card className="card-gutter-md">
                      <Card.Body>
                        <Row className="g-gs">
                         
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col> */}
                </Row>
              </Form>
            </Block>
          </Layout>
        </>
      )}
    </>
  );
}

export default MagazineProfile;
