import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Layout from "../../layout/default";
import AWS from "aws-sdk";
// import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Block from "../../components/Block/Block";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
// import ViewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
// import KanbanView from "./KanbanView";

// import Autocomplete from "@mui/material/Autocomplete";
import { successMessage, failedMessage } from "../utilities/alert-config";
import {
  rootURL,
  awsID,
  awsBucketName,
  awsSECRET,
} from "../utilities/axios-config";
import Autocomplete from "@mui/material/Autocomplete";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    fontWeight: "bold",
  },
  item: {
    padding: 5,
  },
  backgroundColor: {
    backgroundColor: "#2CB23B",
  },
}));

function KanbanAukiss() {
  const [items, setItems] = useState([]);
  //   const [tableData, setTableData] = useState([]);
  const [groups, setGroups] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  //const [indexValue, setIndexValue] = useState("");
  const themes = useTheme();
  const fullScreen = useMediaQuery(themes.breakpoints.down("md"));
  const classes = useStyles();
  const [label, setlabel] = useState("");
  const [theme, settheme] = useState("");
  const [assignee, setassignee] = useState([]);
  const [priority, setpriority] = useState("");
  const [title, settitle] = useState("");
  const [content, setcontent] = useState("");
  const [duedate, setduedate] = useState("");
  const [id, setid] = useState("");
  const [ids, setids] = useState("");
  const [comment, setcomment] = useState("");

  const [assigneeData, setassigneeData] = useState([]);
  const [picture, setpicture] = useState(
    "/images/avatar/avatar-placeholder.jpg"
  );
  const [loading5, setLoading5] = useState(false);
  const [progress3, setProgress3] = useState(false);
  // const [user_id, setuser_id] = useState("");
  const labelData = ["Open", "In Progress", "In Review", "Issues", "Completed"];
  const priorityData = ["High", "Medium", "Low"];
  const containerClass = "kanban-custom-board";

  function create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxx4xxyxx".replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const tableRecordAssigne = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/assme_cpanel`;

      //setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            //setItems(row?.data);
            setassigneeData(row?.data);
            // successMessage(row?.message);
            //setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            //setLoading(false);
          }
        })
        .finally(() => {
          // setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const tableRecords = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/all_kanbanboard_cpanel`;

      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            //setItems(row?.data);

            // row?.data.map((arr)=> {
            //   arr?.items.map((info)=>{

            //   })
            // })
            // console.log(row?.data);
            buildAndSave(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const selectedFile1add = (e) => {
    const image = e.target.files[0];
    if (image) {
      setLoading5(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + image.name, // File name you want to save as in S3
        Body: image,
        ContentType: image.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading5(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setpicture(photoUrl);
        setLoading5(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress3) {
        let uploaded = Math.round((progress3.loaded / progress3.total) * 100);
        setProgress3(uploaded);
      });
    }
  };

  const onSubmitBoard = (event) => {
    event.preventDefault();

    handleClose();

    const objboard = {
      label,
      theme,
      id,
      ids,
      title,
      content,
      assignee,
      duedate,
      priority,
      comment,
      picture,
      // user_id,
    };

    // console.log('obj',objboard);
    const userToken = localStorage.getItem("userToken");

    fetch(`${rootURL}/add_kanboard_cpanel`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objboard),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");
          // console.log('result',result);

          setTimeout(() => {
            window.location.reload();
            // tableRecords();
          }, 3000);
        } else if (result.success === false) {
          // setloading2(false);
          failedMessage(result.message);
        }
      })
      .catch((error) => {
        // setloading2(false);
        failedMessage("Internal Server Error");
      })
      .finally(() => {
        // setloading2(false);
      });
  };

  useEffect(() => {
    // Mock an API call.
    tableRecords();
    tableRecordAssigne();
    //buildAndSave(kanbanCustomData);
    // eslint-disable-next-line
  }, []);

  function buildAndSave(items) {
    const groups = {};

    for (let i = 0; i < Object.keys(items).length; ++i) {
      const currentGroup = items[i];

      groups[currentGroup.id] = i;
    }

    // Set the data.

    setItems(items);

    // Makes the groups searchable via their id.
    setGroups(groups);
  }

  const handleLabelChange = (event) => {
    setlabel(event.target.value);
    if (event.target.value === "Open") {
      settheme("kanban-light");
      setids("item-1");
    } else if (event.target.value === "In Progress") {
      settheme("kanban-primary");
      setids("item-2");
    } else if (event.target.value === "In Review") {
      settheme("kanban-warning");
      setids("item-3");
    } else if (event.target.value === "Issues") {
      settheme("kanban-danger");
      setids("item-4");
    } else if (event.target.value === "Completed") {
      settheme("kanban-success");
      setids("item-5");
    }
  };

  const handleAssigneeChange = (event) => {
    setassignee(event);
   
  };

  const handlePriorityChange = (event) => {
    setpriority(event.target.value);
  };

    const tableUpdateOrder = async (answer,destination) => {
     
      const userToken = localStorage.getItem("userToken");
      const data = {
        answer,
        destination
      };

      // console.log("data", data)

      fetch(`${rootURL}/move_kanboard_cpanel`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
          Accept: "application/form-data",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.success === true) {
            successMessage("Success");
           
            setTimeout(() => {
              window.location.reload();
            
            }, 3000);
          } else if (result.success === false) {
        
            failedMessage(result.message);
          }
        })
        .catch((error) => {
        
          failedMessage("Internal Server Error");
        })
        .finally(() => {
  
        });

    };

  return (
    <Layout title="Kanban Basic" content="container">
      <Block.Head page>
        <Block.HeadContent>
          <Block.Title>Aukiss Board</Block.Title>
          <Box
            display="flex"
            flexDirection="row-reverse"
            justifyContent="space-between"
          >
            <Button
              variant="contained"
              onClick={handleClickOpen}
              size="sm"
              className={classes.item}
            >
              Add Task
            </Button>
          </Box>
          {/* <Block.Text className="lead">Kanban board using <a href="https://github.com/atlassian/react-beautiful-dnd" target="_blank" rel="noreferrer">react-beautiful-dnd</a> plugin.</Block.Text> */}
        </Block.HeadContent>
      </Block.Head>

      <Block>
        <DragDropContext
          onDragEnd={(result) => {
            const { destination, source, type } = result;

            if (!destination) {
              return;
            }

            if (
              destination.droppableId === source.droppableId &&
              destination.index === source.index
            ) {
              return;
            }

            if ("group" === type) {
              const sourceIndex = source.index;
              const targetIndex = destination.index;

              const workValue = items.slice();
              const [deletedItem] = workValue.splice(sourceIndex, 1);
              workValue.splice(targetIndex, 0, deletedItem);

              buildAndSave(workValue);

              return;
            }

            const sourceDroppableIndex = groups[source.droppableId];
            const targetDroppableIndex = groups[destination.droppableId];
            const sourceItems = items[sourceDroppableIndex].items.slice();
            const targetItems =
              source.droppableId !== destination.droppableId
                ? items[targetDroppableIndex].items.slice()
                : sourceItems;

            // Pull the item from the source.
            const [deletedItem] = sourceItems.splice(source.index, 1);
            targetItems.splice(destination.index, 0, deletedItem);

            const workValue = items.slice();
            workValue[sourceDroppableIndex] = {
              ...items[sourceDroppableIndex],
              items: sourceItems,
            };
            workValue[targetDroppableIndex] = {
              ...items[targetDroppableIndex],
              items: targetItems,
            };

            setItems(workValue);
            tableUpdateOrder(targetItems,destination);
          }}
        >
          {loading ? (
            <CircularProgress size={20} thickness={4} />
          ) : (
            <>
              <Droppable droppableId="ROOT" type="group">
                {(provided) => (
                  <div
                    className={`kanban-container ${
                      containerClass ? containerClass : ""
                    }`}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {items.map((item, index) => (
                      <DroppableList key={item?.id?.toString()} index={index} {...item} />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </>
          )}
        </DragDropContext>
      </Block>

      <>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth
        >
          <div style={{ padding: "1rem 2rem" }}>
            <DialogTitle
              id="responsive-dialog-title"
              style={{ fontSize: "1rem" }}
            >
              Add Task
            </DialogTitle>
            <Form onSubmit={onSubmitBoard}>
              <DialogContent>
                <Form.Group controlId="formFile" className="mb-3">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Label *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={label}
                      required
                      label="Select Label"
                      onChange={handleLabelChange}
                    >
                      {labelData.map((label) => (
                        <MenuItem key={label} value={label}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <br /> <br />
                  <TextField
                    autoComplete="off"
                    type="text"
                    name="title"
                    variant="outlined"
                    id="title"
                    label="Title"
                    required
                    value={title}
                    onChange={(e) => {
                      settitle(e.target.value);
                      let ids = create_UUID();
                      setid(ids);
                    }}
                    fullWidth
                  />
                  <br />
                  <br />
                  <Form.Group className="form-group">
                    <Form.Label>Description *</Form.Label>
                    <div className="form-control-wrap">
                      <Form.Control
                        as="textarea"
                        rows="3"
                        value={content}
                        required
                        placeholder=""
                        onChange={(e) => setcontent(e.target.value)}
                      ></Form.Control>
                    </div>
                  </Form.Group>
                  <br />
                  <br />
                  <FormControl fullWidth>
                    {/* <InputLabel id="demo-simple-select-label">
                      Assignee *
                    </InputLabel> */}
                    <Autocomplete
                      multiple
                      fullWidth
                      id="checkboxes-tags-demo"
                      value={assignee}
                      required
                      options={assigneeData}
                      onChange={(event, newValue) => {
                        handleAssigneeChange(newValue);
                      }}
                      disableCloseOnSelect
                      getOptionLabel={(option) =>
                        option.firstName + "-" + option.lastName
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.firstName + "-" + option.lastName}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Assignee"
                          placeholder="Select Assignee"
                        />
                      )}
                    />
                    {/* <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={assignee}
                      required
                      label="Select Assignee"
                      onChange={handleAssigneeChange}
                    >
                      {assigneeData.map((label) => (
                        <MenuItem key={label?.id} value={label?.id}>
                          {label?.firstName+'-'+label?.lastName}
                        </MenuItem>
                      ))}
                    </Select> */}
                  </FormControl>
                  <br />
                  <br />
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="duedate">Due Date *</Form.Label>
                    <div className="form-control-wrap">
                      <input
                        type="date"
                        id="duedate"
                        name="duedate"
                        value={duedate}
                        onChange={(newValue) => {
                          setduedate(newValue.target.value);
                        }}
                      />
                    </div>
                  </Form.Group>
                  <br />
                  <br />
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Priority *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={priority}
                      required
                      label="Select Priority"
                      onChange={handlePriorityChange}
                    >
                      {priorityData.map((label) => (
                        <MenuItem key={label} value={label}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                  <Form.Group className="form-group">
                    <Form.Label>Comment </Form.Label>
                    <div className="form-control-wrap">
                      <Form.Control
                        as="textarea"
                        rows="3"
                        value={comment}
                        placeholder=""
                        onChange={(e) => setcomment(e.target.value)}
                      ></Form.Control>
                    </div>
                  </Form.Group>
                  <br />
                  <br />
                  <div className="image-upload-wrap d-flex flex-column align-items-center">
                    <div>Image </div>
                    {loading5 ? <div>Upload progress {progress3}%</div> : ""}
                    <div className="media media-huge border">
                      <img
                        id="image-result1add"
                        className="w-100 h-100"
                        src={picture}
                        alt="avatar"
                      />
                    </div>
                    <div className="pt-3">
                      <input
                        className="upload-image"
                        data-target="image-result1add"
                        id="change-avatar1add"
                        type="file"
                        max="1"
                        hidden
                        onChange={selectedFile1add}
                      />
                      <label
                        htmlFor="change-avatar1add"
                        className="btn btn-md btn-primary"
                        disabled={loading5}
                      >
                        {loading5 ? "Uploading ..." : "Upload"}
                      </label>
                    </div>
                  </div>
                </Form.Group>
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  className={classes.item}
                  autoFocus
                  onClick={handleClose}
                  color="error"
                >
                  Cancel
                </Button>
                <Button
                  autoFocus
                  variant="contained"
                  type="submit"
                  size="large"
                  className={classes.item}
                >
                  Add
                </Button>
              </DialogActions>
            </Form>
          </div>
        </Dialog>
      </>
    </Layout>
  );
}

// kanban drop list
function DroppableList({ id, items, label, theme }) {
  return (
    <>
      <Droppable droppableId={id?.toString()}>
        {(provided) => (
          <div
            className="kanban-item-wrap"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <div className={`kanban-board ${theme ? theme : ""}`}>
              <header className="kanban-board-header">
                <div className="kanban-title-board">
                  <div className="kanban-title-content">
                    <h6 className="title">{label}</h6>
                    <span className="count">{items.length}</span>
                  </div>
                </div>
              </header>
              <div className="kanban-drag">
                {items.map((item, index) => (
                  <Draggable
                    draggableId={item?.id?.toString() ?? ""}
                    index={index}
                    key={item?.id?.toString() ?? ""}
                  >
                    {(provided) => (
                      <div
                        className="kanban-item"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        {item?.title && (
                          <div className="kanban-item-title">
                            <h6 className="title">{item?.title}</h6>
                          </div>
                        )}
                        {item?.content && (
                          <div className="kanban-item-text">
                            <p>{item?.content}</p>
                          </div>
                        )}

                        {item?.assignee && (
                          <div className="kanban-item-text">
                            <p>{item?.assignee}</p>
                          </div>
                        )}

                        {item?.assignee_new && (
                          <div className="kanban-item-text">
                            <p>
                              {item?.assignee_new[0]?.firstName}{" "}
                              {item?.assignee_new[0]?.lastName}
                              {item?.assignee_new[1]?.firstName}{" "}
                              {item?.assignee_new[1]?.lastName}
                              {item?.assignee_new[2]?.firstName}{" "}
                              {item?.assignee_new[2]?.lastName}
                              {item?.assignee_new[3]?.firstName}{" "}
                              {item?.assignee_new[3]?.lastName}
                              {item?.assignee_new[4]?.firstName}{" "}
                              {item?.assignee_new[4]?.lastName}
                              {item?.assignee_new[5]?.firstName}{" "}
                              {item?.assignee_new[5]?.lastName}
                              {item?.assignee_new[6]?.firstName}{" "}
                              {item?.assignee_new[6]?.lastName}
                              {item?.assignee_new[7]?.firstName}{" "}
                              {item?.assignee_new[7]?.lastName}
                              {item?.assignee_new[8]?.firstName}{" "}
                              {item?.assignee_new[8]?.lastName}
                              {item?.assignee_new[9]?.firstName}{" "}
                              {item?.assignee_new[9]?.lastName}
                              {item?.assignee_new[10]?.firstName}{" "}
                              {item?.assignee_new[10]?.lastName}
                              {item?.assignee_new[11]?.firstName}{" "}
                              {item?.assignee_new[11]?.lastName}
                              {item?.assignee_new[12]?.firstName}{" "}
                              {item?.assignee_new[12]?.lastName}
                              {item?.assignee_new[13]?.firstName}{" "}
                              {item?.assignee_new[13]?.lastName}
                              {item?.assignee_new[14]?.firstName}{" "}
                              {item?.assignee_new[14]?.lastName}
                              {item?.assignee_new[15]?.firstName}{" "}
                              {item?.assignee_new[15]?.lastName}
                              {item?.assignee_new[16]?.firstName}{" "}
                              {item?.assignee_new[16]?.lastName}
                              {item?.assignee_new[17]?.firstName}{" "}
                              {item?.assignee_new[17]?.lastName}
                              {item?.assignee_new[18]?.firstName}{" "}
                              {item?.assignee_new[18]?.lastName}
                              {item?.assignee_new[19]?.firstName}{" "}
                              {item?.assignee_new[19]?.lastName}
                              {item?.assignee_new[20]?.firstName}{" "}
                              {item?.assignee_new[20]?.lastName}
                            </p>
                          </div>
                        )}

                        {item?.duedate && (
                          <div className="kanban-item-text">
                            <p>{item?.duedate}</p>
                          </div>
                        )}

                        {item?.priority && (
                          <div className="kanban-item-text">
                            <p>{item?.priority}</p>
                          </div>
                        )}
                        {/* <IconButton
                          aria-label="view"
                          color="success"
                          onClick={() => handleViewClick(item, index, label)}
                        >
                          <ViewIcon />
                        </IconButton> */}

                        <IconButton
                          aria-label="edit"
                          color="primary"
                          onClick={() => handleEditClick(item, index, label)}
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={() => handledeleteClick(item, index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            </div>
          </div>
        )}
      </Droppable>
    </>
  );
}

const handleEditClick = (row, index, label) => {
  if (label === "Open") {
    window.location.replace(`/apps/kanbanaukiss-edit/${row?.id}/${label}`);
  } else if (label === "In Progress") {
    const label = "InProgress";
    window.location.replace(`/apps/kanbanaukiss-edit/${row?.id}/${label}`);
  } else if (label === "In Review") {
    const label = "InReview";
    window.location.replace(`/apps/kanbanaukiss-edit/${row?.id}/${label}`);
  } else if (label === "Issues") {
    window.location.replace(`/apps/kanbanaukiss-edit/${row?.id}/${label}`);
  } else if (label === "Completed") {
    window.location.replace(`/apps/kanbanaukiss-edit/${row?.id}/${label}`);
  }
};

// const handleViewClick = (row, index, label) => {
//   if (label === "Open") {
//     window.location.replace(`/apps/kanbanaukiss-view/${row?.id}/${label}`);
//   } else if (label === "In Progress") {
//     const label = "InProgress";
//     window.location.replace(`/apps/kanbanaukiss-view/${row?.id}/${label}`);
//   } else if (label === "In Review") {
//     const label = "InReview";
//     window.location.replace(`/apps/kanbanaukiss-view/${row?.id}/${label}`);
//   } else if (label === "Issues") {
//     window.location.replace(`/apps/kanbanaukiss-view/${row?.id}/${label}`);
//   } else if (label === "Completed") {
//     window.location.replace(`/apps/kanbanaukiss-view/${row?.id}/${label}`);
//   }
// };

const handledeleteClick = (row, index) => {
  // console.log("log", row);
  const userToken = localStorage.getItem("userToken");

  fetch(`${rootURL}/kanbandelete`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
      Accept: "application/form-data",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(row),
  })
    .then((res) => res.json())
    .then((result) => {
      if (result.success === true) {
        successMessage("Deleted");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (result.success === false) {
        failedMessage("Failed");
      }
    })
    .catch((error) => {
      failedMessage(error);
    })
    .finally(() => {});
};

export default KanbanAukiss;
