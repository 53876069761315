import { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Player } from "video-react";
import AWS from "aws-sdk";
import { Card, Form, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Layout from "../../layout/default";
import { makeStyles } from "@mui/styles";
import Block from "../../components/Block/Block";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import "../movie-manage/movieplayer.scss";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
// import Stack from "@mui/material/Stack";
//import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
//import dayjs from "dayjs";
import { successMessage, failedMessage } from "../utilities/alert-config";
//import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
//import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
// import Form from 'react-bootstrap/Form';

//import //Select,
//Quill,
//FileUpload,
//Tags,
//ImageUpload,
//DatePicker,
// Tinymce,
// {Icon} from
// "../../components";

import {
  rootURL,
  awsID,
  awsBucketName,
  awsSECRET,
} from "../utilities/axios-config";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    fontWeight: "bold",
  },
  item: {
    padding: 5,
  },
  backgroundColor: {
    backgroundColor: "#2CB23B",
  },
}));

function AddSeries() {
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imageurl, setimageurl] = useState(
    "/images/avatar/avatar-placeholder.jpg"
  );
  const [trailler_url, settrailler_url] = useState("");
  const [hori_image, sethori_image] = useState(
    "/images/avatar/avatar-placeholder.jpg"
  );

  const [post_title, setpost_title] = useState("");
  const [post_excerpt, setpost_excerpt] = useState("");
  const [content_type, setcontent_type] = useState("");
  const contents = ["Paid", "Free"];
  const [catalog_visibility, setcatalog_visibility] = useState(false);
  const [ftseries_id, setftseries_id] = useState(false);
  const [main_header, setmain_header] = useState(false);
  const [imdb_id, setimdb_id] = useState("");
  const [tmdb_id, settmdb_id] = useState("");
  const [recommended_movies, setrecommended_movies] = useState([]);
  const [recommended_moviesData, setrecommended_moviesData] = useState([]);
  const [categ, setcateg] = useState([]);
  const [categData, setcategData] = useState([]);
  const [country, setcountry] = useState([]);
  const [scheduledate, setscheduledate] = useState("");
  const [schstatus, setschstatus] = useState(false);
  const [persons, setpersons] = useState([]);
  const [castdependants, setcastdependants] = useState([]);
  const [castname, setcastname] = useState("");
  const [cast, setcast] = useState({});
  const [character, setcharacter] = useState("");
  const [crewdependants, setcrewdependants] = useState([]);
  const [crewname, setcrewname] = useState("");
  const [crew, setcrew] = useState({});
  const [crewcharacter, setcrewcharacter] = useState("");
  const [post_state_id, setpost_state_id] = useState("");
  const [poststatus, setpoststatus] = useState([]);
  const [postnotification, setpostnotification] = useState(false);
  const [seasonData, setseasonData] = useState([]);
  const [title, settitle] = useState("");
  const [video_url, setvideo_url] = useState("");
  const [image, setimage] = useState("/images/avatar/avatar-placeholder.jpg");
  const [season2Data, setseason2Data] = useState([]);
  const [title2, settitle2] = useState("");
  const [video_url2, setvideo_url2] = useState("");
  const [image2, setimage2] = useState("/images/avatar/avatar-placeholder.jpg");
  const [season3Data, setseason3Data] = useState([]);
  const [title3, settitle3] = useState("");
  const [video_url3, setvideo_url3] = useState("");
  const [image3, setimage3] = useState("/images/avatar/avatar-placeholder.jpg");
  const [season4Data, setseason4Data] = useState([]);
  const [title4, settitle4] = useState("");
  const [video_url4, setvideo_url4] = useState("");
  const [image4, setimage4] = useState("/images/avatar/avatar-placeholder.jpg");
  const [season5Data, setseason5Data] = useState([]);
  const [title5, settitle5] = useState("");
  const [video_url5, setvideo_url5] = useState("");
  const [image5, setimage5] = useState("/images/avatar/avatar-placeholder.jpg");
  const [s2added, sets2added] = useState(false);
  const [s3added, sets3added] = useState(false);
  const [s4added, sets4added] = useState(false);
  const [s5added, sets5added] = useState(false);

  const [loading2, setloading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [loading6, setLoading6] = useState(false);
  const [loading7, setLoading7] = useState(false);
  const [loadingSeason2a, setloadingSeason2a] = useState(false);
  const [loadingSeason2b, setloadingSeason2b] = useState(false);

  const [loadingSeason3a, setloadingSeason3a] = useState(false);
  const [loadingSeason3b, setloadingSeason3b] = useState(false);

  const [loadingSeason4a, setloadingSeason4a] = useState(false);
  const [loadingSeason4b, setloadingSeason4b] = useState(false);

  const [loadingSeason5a, setloadingSeason5a] = useState(false);
  const [loadingSeason5b, setloadingSeason5b] = useState(false);

  const [progress, setProgress] = useState(0);
  const [progress2, setProgress2] = useState(0);
  const [progress3, setProgress3] = useState(0);
  const [progress4, setProgress4] = useState(0);
  const [progress5, setProgress5] = useState(0);
  const [progressSeason2a, setprogressSeason2a] = useState(0);
  const [progressSeason2b, setprogressSeason2b] = useState(0);

  const [progressSeason3a, setprogressSeason3a] = useState(0);
  const [progressSeason3b, setprogressSeason3b] = useState(0);

  const [progressSeason4a, setprogressSeason4a] = useState(0);
  const [progressSeason4b, setprogressSeason4b] = useState(0);

  const [progressSeason5a, setprogressSeason5a] = useState(0);
  const [progressSeason5b, setprogressSeason5b] = useState(0);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [indexValue, setIndexValue] = useState("");
  const [indexValue2, setIndexValue2] = useState("");
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [indexValue3, setIndexValue3] = useState("");

  const [openseason2, setOpenSeason2] = useState(false);
  const [openseason2b, setOpenSeason2b] = useState(false);
  const [indexValueSeason2, setIndexValueSeason2] = useState("");

  const [openseason3, setOpenSeason3] = useState(false);
  const [openseason3b, setOpenSeason3b] = useState(false);
  const [indexValueSeason3, setIndexValueSeason3] = useState("");

  const [openseason4, setOpenSeason4] = useState(false);
  const [openseason4b, setOpenSeason4b] = useState(false);
  const [indexValueSeason4, setIndexValueSeason4] = useState("");

  const [openseason5, setOpenSeason5] = useState(false);
  const [openseason5b, setOpenSeason5b] = useState(false);
  const [indexValueSeason5, setIndexValueSeason5] = useState("");
  const [cats_ids, setcats_ids] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  function create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxx4xxyxx".replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClickOpen3 = () => {
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleClickOpen4 = () => {
    setOpen4(true);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  const handleClickOpen5 = () => {
    setOpen5(true);
  };

  const handleClose5 = () => {
    setOpen5(false);
  };

  const handleClickOpen6 = () => {
    setOpen6(true);
  };

  const handleClose6 = () => {
    setOpen6(false);
  };

  const handleClickOpenSeason2 = () => {
    setOpenSeason2(true);
  };

  const handleCloseSeason2 = () => {
    setOpenSeason2(false);
  };

  const handleClickOpenSeason2b = () => {
    setOpenSeason2b(true);
  };

  const handleCloseSeason2b = () => {
    setOpenSeason2b(false);
  };

  // season 3
  const handleClickOpenSeason3 = () => {
    setOpenSeason3(true);
  };

  const handleCloseSeason3 = () => {
    setOpenSeason3(false);
  };

  const handleClickOpenSeason3b = () => {
    setOpenSeason3b(true);
  };

  const handleCloseSeason3b = () => {
    setOpenSeason3b(false);
  };

  // season 4
  const handleClickOpenSeason4 = () => {
    setOpenSeason4(true);
  };

  const handleCloseSeason4 = () => {
    setOpenSeason4(false);
  };

  const handleClickOpenSeason4b = () => {
    setOpenSeason4b(true);
  };

  const handleCloseSeason4b = () => {
    setOpenSeason4b(false);
  };

  // season 5
  const handleClickOpenSeason5 = () => {
    setOpenSeason5(true);
  };

  const handleCloseSeason5 = () => {
    setOpenSeason5(false);
  };

  const handleClickOpenSeason5b = () => {
    setOpenSeason5b(true);
  };

  const handleCloseSeason5b = () => {
    setOpenSeason5b(false);
  };

  const handleDeleteClick = (row, index) => {
    const newList = Object.assign([], castdependants);
    newList.splice(index, 1);
    setcastdependants(newList);
  };

  const handleEditClick = (row, index) => {
    // console.log("log", row)
    setcastname(row?.cast?.name);
    setcharacter(row?.character);
    handleClickOpen2();
    setIndexValue(index);
  };

  const handleDeleteClickCrew = (row, index) => {
    const newList = Object.assign([], crewdependants);
    newList.splice(index, 1);
    setcrewdependants(newList);
  };

  const handleEditClickCrew = (row, index) => {
    setcrewname(row?.crew?.name);
    setcrewcharacter(row?.crewcharacter);
    handleClickOpen4();
    setIndexValue2(index);
  };

  const handleEditClickSeason1 = (row, index) => {
    setimage(row?.image);
    settitle(row?.title);
    setvideo_url(row?.video_url);
    handleClickOpen6();
    setIndexValue3(index);
  };

  const handleDeleteClickSeason1 = (row, index) => {
    const newList = Object.assign([], seasonData);
    newList.splice(index, 1);
    setseasonData(newList);
  };

  const handleEditClickSeason2 = (row, index) => {
    setimage2(row?.image2);
    settitle2(row?.title2);
    setvideo_url2(row?.video_url2);
    handleClickOpenSeason2b();
    setIndexValueSeason2(index);
  };

  const handleDeleteClickSeason2 = (row, index) => {
    const newList = Object.assign([], season2Data);
    newList.splice(index, 1);
    setseason2Data(newList);
  };

  const handleEditClickSeason3 = (row, index) => {
    setimage3(row?.image3);
    settitle3(row?.title3);
    setvideo_url3(row?.video_url3);
    handleClickOpenSeason3b();
    setIndexValueSeason3(index);
  };

  const handleDeleteClickSeason3 = (row, index) => {
    const newList = Object.assign([], season3Data);
    newList.splice(index, 1);
    setseason3Data(newList);
  };

  const handleEditClickSeason4 = (row, index) => {
    setimage4(row?.image4);
    settitle4(row?.title4);
    setvideo_url4(row?.video_url4);
    handleClickOpenSeason4b();
    setIndexValueSeason4(index);
  };

  const handleDeleteClickSeason4 = (row, index) => {
    const newList = Object.assign([], season4Data);
    newList.splice(index, 1);
    setseason4Data(newList);
  };

  const handleEditClickSeason5 = (row, index) => {
    setimage5(row?.image5);
    settitle5(row?.title5);
    setvideo_url5(row?.video_url5);
    handleClickOpenSeason5b();
    setIndexValueSeason5(index);
  };

  const handleDeleteClickSeason5 = (row, index) => {
    const newList = Object.assign([], season5Data);
    newList.splice(index, 1);
    setseason5Data(newList);
  };

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    " Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Côte d'Ivoire",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    " Congo(Congo - Brazzaville)",
    " Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    " Czechia(Czech Republic)",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    " Equatorial Guinea",
    "Eritrea",
    "Estonia",
    " Eswatini(fmr. 'Swaziland')",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea - Bissau",
    "Guyana",
    "Haiti",
    " Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar(formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    " New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    " North Korea",
    " North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    " Saint Kitts and Nevis",
    "Saint Lucia",
    " Saint Vincent and the Grenadines",
    "Samoa",
    " San Marino",
    "Sao Tome and Principe",
    " Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    " Solomon Islands",
    "Somalia",
    "  South Africa",
    " South Korea",
    "South Sudan",
    "Spain",
    " Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    " Timor - Leste",
    "Togo",
    "Tonga",
    " Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    " United Kingdom",
    " United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const onSubmitSeason5 = (event) => {
    event.preventDefault();
    const season5 = "5";
    setseason5Data([
      ...season5Data,
      {
        image5,
        season5,
        title5,
        video_url5,
      },
    ]);
    handleCloseSeason5();
    sets5added(true);
  };

  const onSubmitSeason4 = (event) => {
    event.preventDefault();
    const season4 = "4";
    setseason4Data([
      ...season4Data,
      {
        image4,
        season4,
        title4,
        video_url4,
      },
    ]);
    handleCloseSeason4();
    sets4added(true);
  };

  const onSubmitSeason3 = (event) => {
    event.preventDefault();
    const season3 = "3";
    setseason3Data([
      ...season3Data,
      {
        image3,
        season3,
        title3,
        video_url3,
      },
    ]);
    handleCloseSeason3();
    sets3added(true);
  };

  const onSubmitSeason2 = (event) => {
    event.preventDefault();
    const season2 = "2";
    setseason2Data([
      ...season2Data,
      {
        image2,
        season2,
        title2,
        video_url2,
      },
    ]);
    handleCloseSeason2();
    sets2added(true);
  };

  const onSubmitSeason1 = (event) => {
    event.preventDefault();
    const season = "1";
    setseasonData([
      ...seasonData,
      {
        image,
        season,
        title,
        video_url,
      },
    ]);
    handleClose5();
  };

  const onSubmitEditSeason5 = (event) => {
    event.preventDefault();
    const season5 = "5";
    const newList = Object.assign([], season5Data);
    const data = { ...newList[indexValueSeason5] };

    data.image5 = image5 ?? "";
    data.season5 = season5;
    data.title5 = title5 ?? "";
    data.video_url5 = video_url5 ?? "";
    newList[indexValueSeason5] = data;
    setseason5Data(newList);
    sets5added(true);
    handleCloseSeason5b();
  };

  const onSubmitEditSeason4 = (event) => {
    event.preventDefault();
    const season4 = "4";
    const newList = Object.assign([], season4Data);
    const data = { ...newList[indexValueSeason4] };

    data.image4 = image4 ?? "";
    data.season4 = season4;
    data.title4 = title4 ?? "";
    data.video_url4 = video_url4 ?? "";
    newList[indexValueSeason4] = data;
    setseason4Data(newList);
    sets4added(true);
    handleCloseSeason4b();
  };

  const onSubmitEditSeason3 = (event) => {
    event.preventDefault();
    const season3 = "3";
    const newList = Object.assign([], season3Data);
    const data = { ...newList[indexValueSeason3] };

    data.image3 = image3 ?? "";
    data.season3 = season3;
    data.title3 = title3 ?? "";
    data.video_url3 = video_url3 ?? "";
    newList[indexValueSeason3] = data;
    setseason3Data(newList);
    sets3added(true);
    handleCloseSeason3b();
  };

  const onSubmitEditSeason2 = (event) => {
    event.preventDefault();
    const season2 = "2";
    const newList = Object.assign([], season2Data);
    const data = { ...newList[indexValueSeason2] };

    data.image2 = image2 ?? "";
    data.season2 = season2;
    data.title2 = title2 ?? "";
    data.video_url2 = video_url2 ?? "";
    newList[indexValueSeason2] = data;
    setseason2Data(newList);
    sets2added(true);
    handleCloseSeason2b();
  };

  const onSubmitEditSeason1 = (event) => {
    event.preventDefault();
    const season = "1";
    const newList = Object.assign([], seasonData);
    const data = { ...newList[indexValue3] };

    data.image = image ?? "";
    data.season = season;
    data.title = title ?? "";
    data.video_url = video_url ?? "";
    newList[indexValue3] = data;
    setseasonData(newList);
    handleClose6();
  };

  const onSubmitCrew = (event) => {
    event.preventDefault();
    setcrewdependants([
      ...crewdependants,
      {
        crew,
        crewcharacter,
      },
    ]);
    handleClose3();
  };

  const onSubmitCast = (event) => {
    event.preventDefault();

    setcastdependants([
      ...castdependants,
      {
        cast,
        character,
      },
    ]);

    handleClose();
  };

  const onSubmitCastEdit = (event) => {
    event.preventDefault();
    const newList = Object.assign([], castdependants);
    const data = { ...newList[indexValue] };

    data.cast.id = cast?.id ?? "";
    data.cast.name = cast?.name ?? "";
    data.cast.alsoknownas = cast?.alsoknownas ?? "";
    data.cast.birthday = cast?.birthday ?? "";
    data.cast.deathday = cast?.deathday ?? "";
    data.cast.person_info = cast?.person_info ?? "";
    data.cast.photo = cast?.photo ?? "";
    data.cast.place_of_birth = cast?.place_of_birth ?? "";
    data.character = character;
    newList[indexValue] = data;
    setcastdependants(newList);

    handleClose2();
  };

  const onSubmitCrewEdit = (event) => {
    event.preventDefault();
    const newList = Object.assign([], crewdependants);
    const data = { ...newList[indexValue2] };

    data.crew.id = crew?.id ?? "";
    data.crew.name = crew?.name ?? "";
    data.crew.alsoknownas = crew?.alsoknownas ?? "";
    data.crew.birthday = crew?.birthday ?? "";
    data.crew.deathday = crew?.deathday ?? "";
    data.crew.person_info = crew?.person_info ?? "";
    data.crew.photo = crew?.photo ?? "";
    data.crew.place_of_birth = crew?.place_of_birth ?? "";
    data.crewcharacter = crewcharacter;
    newList[indexValue2] = data;
    setcrewdependants(newList);

    handleClose4();
  };

  const handleCountryChange = (event) => {
    setcountry(event);
  };

  const handleCastChange = (event) => {
    setcastname(event);

    const newFilter = persons.filter((value) =>
      (value?.name || "").toLowerCase().includes((event || "").toLowerCase())
    );
    newFilter.forEach((option) => {
      if (option.name === event) {
        setcast({
          id: option?.id,
          name: option?.name,
          alsoknownas: option?.alsoknownas,
          birthday: option?.birthday,
          deathday: option?.deathday,
          person_info: option?.person_info,
          photo: option?.photo,
          place_of_birth: option?.place_of_birth,
        });
      }
    });
  };

  const handleCrewChange = (event) => {
    setcrewname(event);
    const newFilter = persons.filter((value) =>
      (value?.name || "").toLowerCase().includes((event || "").toLowerCase())
    );
    newFilter.forEach((option) => {
      if (option.name === event) {
        setcrew({
          id: option?.id,
          name: option?.name,
          alsoknownas: option?.alsoknownas,
          birthday: option?.birthday,
          deathday: option?.deathday,
          person_info: option?.person_info,
          photo: option?.photo,
          place_of_birth: option?.place_of_birth,
        });
      }
    });
  };

  const handleCategoryChange = (event) => {
    setcateg(event);
  };

  const handleRecommendedChange = (event) => {
    setrecommended_movies(event);
  };

  const tableStatus = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/poststatus`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setpoststatus(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableActors = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/actorsdata`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setpersons(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableRecommendation = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/movieRecommendation`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setrecommended_moviesData(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableCategory = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/tvCategory`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setcategData(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    tableStatus();
    tableRecommendation();
    tableCategory();
    tableActors();
  }, []);

  const handleStatus = (event) => {
    setpost_state_id(event.target.value);
    setschstatus(false);
    if (event.target.value === 4) {
      setschstatus(true);
    }
  };

  const handleContentType = (event) => {
    setcontent_type(event.target.value);
  };

  const selectedFileSeason2add = (e) => {
    const image = e.target.files[0];
    if (image) {
      setloadingSeason2a(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + image.name, // File name you want to save as in S3
        Body: image,
        ContentType: image.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setloadingSeason2a(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setimage2(photoUrl);
        setloadingSeason2a(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progressSeason2a) {
        let uploaded = Math.round(
          (progressSeason2a.loaded / progressSeason2a.total) * 100
        );
        setprogressSeason2a(uploaded);
      });
    }
  };

  const selectedFileSeason5add = (e) => {
    const image = e.target.files[0];
    if (image) {
      setloadingSeason5a(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + image.name, // File name you want to save as in S3
        Body: image,
        ContentType: image.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setloadingSeason5a(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setimage5(photoUrl);
        setloadingSeason5a(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progressSeason5a) {
        let uploaded = Math.round(
          (progressSeason5a.loaded / progressSeason5a.total) * 100
        );
        setprogressSeason5a(uploaded);
      });
    }
  };

  const selectedFileSeason4add = (e) => {
    const image = e.target.files[0];
    if (image) {
      setloadingSeason4a(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + image.name, // File name you want to save as in S3
        Body: image,
        ContentType: image.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setloadingSeason4a(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setimage4(photoUrl);
        setloadingSeason4a(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progressSeason4a) {
        let uploaded = Math.round(
          (progressSeason4a.loaded / progressSeason4a.total) * 100
        );
        setprogressSeason4a(uploaded);
      });
    }
  };

  const selectedFileSeason3add = (e) => {
    const image = e.target.files[0];
    if (image) {
      setloadingSeason3a(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + image.name, // File name you want to save as in S3
        Body: image,
        ContentType: image.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setloadingSeason3a(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setimage3(photoUrl);
        setloadingSeason3a(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progressSeason3a) {
        let uploaded = Math.round(
          (progressSeason3a.loaded / progressSeason3a.total) * 100
        );
        setprogressSeason3a(uploaded);
      });
    }
  };

  const selectedFile1add = (e) => {
    const image = e.target.files[0];
    if (image) {
      setLoading5(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + image.name, // File name you want to save as in S3
        Body: image,
        ContentType: image.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading5(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setimage(photoUrl);
        setLoading5(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress3) {
        let uploaded = Math.round((progress3.loaded / progress3.total) * 100);
        setProgress3(uploaded);
      });
    }
  };

  const selectedFile = (e) => {
    const image = e.target.files[0];
    if (image) {
      setLoading3(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + image.name, // File name you want to save as in S3
        Body: image,
        ContentType: image.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading3(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setimageurl(photoUrl);
        setLoading3(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress) {
        let uploaded = Math.round((progress.loaded / progress.total) * 100);
        setProgress(uploaded);
      });
    }
  };

  const selectedFileHoriImage = (e) => {
    const image = e.target.files[0];
    if (image) {
      setLoading4(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + image.name, // File name you want to save as in S3
        Body: image,
        ContentType: image.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading4(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        sethori_image(photoUrl);
        setLoading4(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress2) {
        let uploaded = Math.round((progress2.loaded / progress2.total) * 100);
        setProgress2(uploaded);
      });
    }
  };

  const selectedFileSeason2addVideo = (e) => {
    const video = e.target.files[0];
    if (video) {
      setloadingSeason2b(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + video.name, // File name you want to save as in S3
        Body: video,
        ContentType: video.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setloadingSeason2b(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setvideo_url2(photoUrl);
        setloadingSeason2b(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progressSeason2b) {
        let uploaded = Math.round(
          (progressSeason2b.loaded / progressSeason2b.total) * 100
        );
        setprogressSeason2b(uploaded);
      });
    }
  };

  const selectedFileSeason4addVideo = (e) => {
    const video = e.target.files[0];
    if (video) {
      setloadingSeason4b(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + video.name, // File name you want to save as in S3
        Body: video,
        ContentType: video.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setloadingSeason4b(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setvideo_url4(photoUrl);
        setloadingSeason4b(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progressSeason4b) {
        let uploaded = Math.round(
          (progressSeason4b.loaded / progressSeason4b.total) * 100
        );
        setprogressSeason4b(uploaded);
      });
    }
  };

  const selectedFileSeason5addVideo = (e) => {
    const video = e.target.files[0];
    if (video) {
      setloadingSeason5b(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + video.name, // File name you want to save as in S3
        Body: video,
        ContentType: video.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setloadingSeason5b(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setvideo_url5(photoUrl);
        setloadingSeason5b(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progressSeason5b) {
        let uploaded = Math.round(
          (progressSeason5b.loaded / progressSeason5b.total) * 100
        );
        setprogressSeason5b(uploaded);
      });
    }
  };

  const selectedFileSeason3addVideo = (e) => {
    const video = e.target.files[0];
    if (video) {
      setloadingSeason3b(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + video.name, // File name you want to save as in S3
        Body: video,
        ContentType: video.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setloadingSeason3b(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setvideo_url3(photoUrl);
        setloadingSeason3b(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progressSeason3b) {
        let uploaded = Math.round(
          (progressSeason3b.loaded / progressSeason3b.total) * 100
        );
        setprogressSeason3b(uploaded);
      });
    }
  };

  const selectedFile1addVideo = (e) => {
    const video = e.target.files[0];
    if (video) {
      setLoading7(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + video.name, // File name you want to save as in S3
        Body: video,
        ContentType: video.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading7(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setvideo_url(photoUrl);
        setLoading7(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress5) {
        let uploaded = Math.round((progress5.loaded / progress5.total) * 100);
        setProgress5(uploaded);
      });
    }
  };

  const selectedFileTraillerVideo = (e) => {
    const video = e.target.files[0];
    if (video) {
      setLoading6(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + video.name, // File name you want to save as in S3
        Body: video,
        ContentType: video.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading6(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        settrailler_url(photoUrl);
        setLoading6(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress4) {
        let uploaded = Math.round((progress4.loaded / progress4.total) * 100);
        setProgress4(uploaded);
      });
    }
  };

  function onDragEndSeason1(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const answer = Array.from(seasonData);
    const [removed] = answer.splice(result.source.index, 1);
    answer.splice(result.destination.index, 0, removed);

    setseasonData(answer);
  }

  function onDragEndSeason2(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const answer = Array.from(season2Data);
    const [removed] = answer.splice(result.source.index, 1);
    answer.splice(result.destination.index, 0, removed);

    setseason2Data(answer);
  }

  function onDragEndSeason3(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const answer = Array.from(season3Data);
    const [removed] = answer.splice(result.source.index, 1);
    answer.splice(result.destination.index, 0, removed);

    setseason3Data(answer);
  }

  function onDragEndSeason4(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const answer = Array.from(season4Data);
    const [removed] = answer.splice(result.source.index, 1);
    answer.splice(result.destination.index, 0, removed);

    setseason4Data(answer);
  }

  function onDragEndSeason5(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const answer = Array.from(season5Data);
    const [removed] = answer.splice(result.source.index, 1);
    answer.splice(result.destination.index, 0, removed);

    setseason5Data(answer);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userToken = localStorage.getItem("userToken");
    setloading2(true);
    // let mov = [];
    // let series = [];

    let p_cast = {
      castdependants: castdependants,
    };

    let p_crew = {
      crewdependants: crewdependants,
    };

    const movieObj = {
      imageurl,
      hori_image,
      trailler_url,
      post_title,
      post_excerpt,
      content_type,
      catalog_visibility,
      ftseries_id,
      recommended_movies,
      imdb_id,
      tmdb_id,
      categ,
      country,
      scheduledate,
      main_header,
      p_cast,
      p_crew,
      post_state_id,
      postnotification,
      seasonData,
      season2Data,
      season3Data,
      season4Data,
      season5Data,
      s2added,
      s3added,
      s4added,
      s5added,
      cats_ids
    };

    fetch(`${rootURL}/addseriescpanel`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(movieObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");

          setTimeout(() => {
            history("/series-manage/series-list");
          }, 3000);
        } else if (result.success === false) {
          setloading2(false);
          failedMessage(result.message);
        }
      })
      .catch((error) => {
        setloading2(false);
        failedMessage("Internal Server Error");
      })
      .finally(() => {
        setloading2(false);
      });
  };

  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress size={70} thickness={4} />
          </div>
        </>
      ) : (
        <>
          <Layout title="Add Series" content="container">
            <Block.Head>
              <Block.HeadBetween>
                <Block.HeadContent>
                  <Block.Title tag="h2">Add Series</Block.Title>
                  <nav>
                    <ol className="breadcrumb breadcrumb-arrow mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/series-manage/series-list">Series</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Add Series
                      </li>
                    </ol>
                  </nav>
                </Block.HeadContent>
                {/* <Block.HeadContent>
                    <ul className="d-flex">
                        <li>
                            <Link to="/actor-manage/actor-list" className="btn btn-primary btn-md d-md-none">
                                <Icon name="eye"/>
                                <span>Back</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/actor-manage/actor-list" className="btn btn-primary d-none d-md-inline-flex">
                                <Icon name="eye"/>
                                <span>Back</span>
                            </Link>
                        </li>
                    </ul>
                </Block.HeadContent> */}
              </Block.HeadBetween>
            </Block.Head>

            <Block>
              <Form onSubmit={handleSubmit}>
                <Row className="g-gs">
                  <Col xxl="12">
                    <div className="gap gy-4">
                      <div className="gap-col">
                        <Card className="card-gutter-md">
                          <Card.Body>
                            <Row className="g-gs">
                              <Col lg="4">
                                <Form.Group className="form-group">
                                  <Form.Label>Image</Form.Label>
                                  <div className="form-control-wrap">
                                    <div className="image-upload-wrap d-flex flex-column align-items-center">
                                      <div>Cover Image</div>
                                      {loading3 ? (
                                        <div>Upload progress {progress}%</div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="media media-huge border">
                                        <img
                                          id="image-result"
                                          className="w-100 h-100"
                                          src={imageurl}
                                          alt="avatar"
                                        />
                                      </div>
                                      <div className="pt-3">
                                        <input
                                          className="upload-image"
                                          data-target="image-result"
                                          id="change-avatar"
                                          type="file"
                                          max="1"
                                          hidden
                                          onChange={selectedFile}
                                        />
                                        <label
                                          htmlFor="change-avatar"
                                          className="btn btn-md btn-primary"
                                          disabled={loading3}
                                        >
                                          {loading3
                                            ? "Uploading ..."
                                            : "Upload"}
                                        </label>
                                      </div>
                                      <br />
                                    </div>
                                  </div>
                                  <div className="form-note mt-3">
                                    Set the article thumbnail image. Only *.png,
                                    *.jpg and *.jpeg image files are accepted.
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="4">
                                <Form.Group className="form-group">
                                  <Form.Label>Image</Form.Label>
                                  <div className="form-control-wrap">
                                    <div className="image-upload-wrap d-flex flex-column align-items-center">
                                      <div>Horizontal Image</div>
                                      {loading4 ? (
                                        <div>Upload progress {progress2}%</div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="media media-huge border">
                                        <img
                                          id="image-result2"
                                          className="w-100 h-100"
                                          src={hori_image}
                                          alt="avatar"
                                        />
                                      </div>
                                      <div className="pt-3">
                                        <input
                                          className="upload-image"
                                          data-target="image-result2"
                                          id="change-avatar2"
                                          type="file"
                                          max="1"
                                          hidden
                                          onChange={selectedFileHoriImage}
                                        />
                                        <label
                                          htmlFor="change-avatar2"
                                          className="btn btn-md btn-primary"
                                          disabled={loading4}
                                        >
                                          {loading4
                                            ? "Uploading ..."
                                            : "UploadHori"}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-note mt-3">
                                    Set the article thumbnail image. Only *.png,
                                    *.jpg and *.jpeg image files are accepted.
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="4">
                                <Form.Group className="form-group">
                                  <Form.Label>Video</Form.Label>
                                  <div className="form-control-wrap">
                                    <div className="image-upload-wrap d-flex flex-column align-items-center">
                                      <br />
                                      <div>Trailler Video</div>
                                      {loading6 ? (
                                        <div>Upload progress {progress4}%</div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="media media-huge border">
                                        <div className="player-wrapper">
                                          <Player>
                                            <source src={trailler_url} />
                                          </Player>
                                        </div>
                                      </div>
                                      <div className="pt-3">
                                        <input
                                          className="upload-image"
                                          data-target="image-result4"
                                          id="change-avatar4"
                                          type="file"
                                          max="1"
                                          hidden
                                          onChange={selectedFileTraillerVideo}
                                        />
                                        <label
                                          htmlFor="change-avatar4"
                                          className="btn btn-md btn-primary"
                                          disabled={loading6}
                                        >
                                          {loading6
                                            ? "Uploading ..."
                                            : "UploadTrailler"}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-note mt-3">
                                    Set the movie thumbnail video.
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="post_title">
                                    Title *
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="post_title"
                                      placeholder="Title"
                                      value={post_title}
                                      required
                                      onChange={(e) =>
                                        setpost_title(e.target.value)
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label>Description *</Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      as="textarea"
                                      rows="3"
                                      value={post_excerpt}
                                      required
                                      placeholder=""
                                      onChange={(e) =>
                                        setpost_excerpt(e.target.value)
                                      }
                                    ></Form.Control>
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="6">
                                <div className="form-control-wrap">
                                  <FormControl fullWidth size="small">
                                    <Form.Label htmlFor="content_type">
                                      Content Type
                                    </Form.Label>
                                    <Select
                                      labelId="demo-select-small"
                                      id="demo-simple-select"
                                      value={content_type ?? ""}
                                      label="Status"
                                      onChange={handleContentType}
                                    >
                                      {contents.map((type) => (
                                        <MenuItem key={type} value={type}>
                                          {loading === false ? type : ""}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </Col>

                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="imdb_id">
                                    Imdb ID
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="imdb_id"
                                      placeholder="Imdb ID"
                                      value={imdb_id}
                                      onChange={(e) =>
                                        setimdb_id(e.target.value)
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="tmdb_id">
                                    Tmdb ID
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="tmdb_id"
                                      placeholder="Tmdb ID"
                                      value={tmdb_id}
                                      onChange={(e) =>
                                        settmdb_id(e.target.value)
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="baseprice">
                                    Recommended Series
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Autocomplete
                                      multiple
                                      fullWidth
                                      id="checkboxes-tags-demo"
                                      value={recommended_movies}
                                      options={recommended_moviesData}
                                      onChange={(event, newValue) => {
                                        handleRecommendedChange(newValue);
                                      }}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) =>
                                        option.post_title
                                      }
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option.post_title}
                                        </li>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Recommended Series"
                                          placeholder="Select Recommended Series"
                                        />
                                      )}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="discount-price">
                                    Category *
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Autocomplete
                                      multiple
                                      fullWidth
                                      id="checkboxes-tags-demo"
                                      value={categ}
                                      required
                                      options={categData}
                                      onChange={(event, newValue) => {
                                        handleCategoryChange(newValue);
                                      }}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) => option.name}
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option.name}
                                        </li>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Category"
                                          placeholder="Select Category"
                                        />
                                      )}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="country">
                                    Countries not to see content
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Autocomplete
                                      multiple
                                      fullWidth
                                      id="checkboxes-tags-demo"
                                      value={country}
                                      options={countries}
                                      onChange={(event, newValue) => {
                                        handleCountryChange(newValue);
                                      }}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) => option}
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option}
                                        </li>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Country"
                                          placeholder="Select Country"
                                        />
                                      )}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="6">
                                <div className="form-control-wrap">
                                  <FormControl fullWidth size="small">
                                    <Form.Label htmlFor="status_id">
                                      Status *
                                    </Form.Label>
                                    <Select
                                      labelId="demo-select-small"
                                      id="demo-simple-select"
                                      value={post_state_id ?? ""}
                                      label="Status"
                                      onChange={handleStatus}
                                      required
                                    >
                                      {poststatus.map((status) => (
                                        <MenuItem
                                          key={status.id}
                                          value={status.id}
                                        >
                                          {loading === false ? status.name : ""}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </Col>

                              {schstatus ? (
                                <Col lg="6">
                                  <Form.Group className="form-group">
                                    <Form.Label htmlFor="scheduledate">
                                      Schedule Date
                                    </Form.Label>
                                    <div className="form-control-wrap">
                                      <input
                                        type="date"
                                        id="scheduledate"
                                        name="scheduledate"
                                        value={scheduledate}
                                        onChange={(newValue) => {
                                          setscheduledate(
                                            newValue.target.value
                                          );
                                        }}
                                      />
                                    </div>
                                  </Form.Group>
                                </Col>
                              ) : (
                                ""
                              )}

                              <Col lg="6">
                                <FormControlLabel
                                  label="MAIN HEADER"
                                  control={
                                    <Checkbox
                                      checked={main_header}
                                      onChange={(e) => {
                                        setmain_header(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="CATALOG VISIBILITY"
                                  control={
                                    <Checkbox
                                      checked={catalog_visibility}
                                      onChange={(e) => {
                                        setcatalog_visibility(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="FEATURED"
                                  control={
                                    <Checkbox
                                      checked={ftseries_id}
                                      onChange={(e) => {
                                        setftseries_id(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>
                              <Col lg="6">
                                <FormControlLabel
                                  label="NEWLY ADDED CONTENT"
                                  control={
                                    <Checkbox
                                      checked={cats_ids}
                                      onChange={(e) => {
                                        setcats_ids(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="POST NOTIFICATION TO THE APP"
                                  control={
                                    <Checkbox
                                      checked={postnotification}
                                      onChange={(e) => {
                                        setpostnotification(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="12">
                                <Box
                                  display="flex"
                                  flexDirection="row-reverse"
                                  justifyContent="space-between"
                                >
                                  <Button
                                    variant="contained"
                                    onClick={handleClickOpen}
                                    size="sm"
                                    className={classes.item}
                                  >
                                    Add Cast
                                  </Button>
                                </Box>

                                <br />
                                <Grid
                                  container
                                  spacing={4}
                                  justify="flex-start"
                                >
                                  <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                      <Table aria-label="caption table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              #
                                            </TableCell>

                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Cast
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Character
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Actions
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>

                                        <TableBody>
                                          {castdependants.map((row, index) => (
                                            <TableRow key={index}>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {index + 1}
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {row?.cast?.name ?? ""}
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {row?.character ?? ""}
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                <IconButton
                                                  aria-label="edit"
                                                  color="primary"
                                                  onClick={() =>
                                                    handleEditClick(row, index)
                                                  }
                                                >
                                                  <EditIcon />
                                                </IconButton>

                                                <IconButton
                                                  aria-label="delete"
                                                  color="error"
                                                  onClick={() =>
                                                    handleDeleteClick(
                                                      row,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                </Grid>
                              </Col>

                              <Col lg="12">
                                <Box
                                  display="flex"
                                  flexDirection="row-reverse"
                                  justifyContent="space-between"
                                >
                                  <Button
                                    variant="contained"
                                    onClick={handleClickOpen3}
                                    size="sm"
                                    className={classes.item}
                                  >
                                    Add Crew
                                  </Button>
                                </Box>

                                <br />
                                <Grid
                                  container
                                  spacing={4}
                                  justify="flex-start"
                                >
                                  <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                      <Table aria-label="caption table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              #
                                            </TableCell>

                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Crew
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Character
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Actions
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>

                                        <TableBody>
                                          {crewdependants.map((row, index) => (
                                            <TableRow key={index}>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {index + 1}
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {row?.crew?.name ?? ""}
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {row?.crewcharacter ?? ""}
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                <IconButton
                                                  aria-label="edit"
                                                  color="primary"
                                                  onClick={() =>
                                                    handleEditClickCrew(
                                                      row,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <EditIcon />
                                                </IconButton>

                                                <IconButton
                                                  aria-label="delete"
                                                  color="error"
                                                  onClick={() =>
                                                    handleDeleteClickCrew(
                                                      row,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                </Grid>
                              </Col>

                              {/* season 1 table here */}
                              <Col lg="12">
                                <Box
                                  display="flex"
                                  flexDirection="row-reverse"
                                  justifyContent="space-between"
                                >
                                  <Button
                                    variant="contained"
                                    onClick={handleClickOpen5}
                                    size="sm"
                                    className={classes.item}
                                  >
                                    Add Season 1
                                  </Button>
                                </Box>

                                <br />
                                <Grid
                                  container
                                  spacing={4}
                                  justify="flex-start"
                                >
                                  <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                      <Table aria-label="caption table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              #
                                            </TableCell>

                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Title
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Image
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Video
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Season 1
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Actions
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <DragDropContext
                                          onDragEnd={onDragEndSeason1}
                                        >
                                          <Droppable droppableId="SeasonOne">
                                            {(provided, snapshot) => (
                                              <TableBody
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                style={{
                                                  backgroundColor:
                                                    snapshot.isDraggingOver
                                                      ? "white"
                                                      : "white",
                                                }}
                                              >
                                                {seasonData.map(
                                                  (row, index) => (
                                                    <Draggable
                                                      draggableId={(
                                                        index + 1
                                                      ).toString()}
                                                      key={index + 1}
                                                      index={index}
                                                    >
                                                      {(provided) => (
                                                        <TableRow
                                                          key={index + 1}
                                                          sx={{
                                                            "&:last-child td, &:last-child th":
                                                              {
                                                                border: 0,
                                                              },
                                                          }}
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                        >
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            {index + 1}
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            {row?.title ?? ""}
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            <Avatar
                                                              alt={
                                                                row?.image ??
                                                                "Series Cover"
                                                              }
                                                              src={
                                                                row?.image ?? ""
                                                              }
                                                              sx={{
                                                                width: 36,
                                                                height: 36,
                                                              }}
                                                            />
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            <Player>
                                                              <source
                                                                src={video_url}
                                                              />
                                                            </Player>
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            {row?.season ?? ""}
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            <IconButton
                                                              aria-label="edit"
                                                              color="primary"
                                                              onClick={() =>
                                                                handleEditClickSeason1(
                                                                  row,
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <EditIcon />
                                                            </IconButton>

                                                            <IconButton
                                                              aria-label="delete"
                                                              color="error"
                                                              onClick={() =>
                                                                handleDeleteClickSeason1(
                                                                  row,
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <DeleteIcon />
                                                            </IconButton>
                                                          </TableCell>
                                                        </TableRow>
                                                      )}
                                                    </Draggable>
                                                  )
                                                )}
                                                {provided.placeholder}
                                              </TableBody>
                                            )}
                                          </Droppable>
                                        </DragDropContext>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                </Grid>
                              </Col>

                              {/* season 2 table here */}

                              <Col lg="12">
                                <Box
                                  display="flex"
                                  flexDirection="row-reverse"
                                  justifyContent="space-between"
                                >
                                  <Button
                                    variant="contained"
                                    onClick={handleClickOpenSeason2}
                                    size="sm"
                                    className={classes.item}
                                  >
                                    Add Season 2
                                  </Button>
                                </Box>

                                <br />
                                <Grid
                                  container
                                  spacing={4}
                                  justify="flex-start"
                                >
                                  <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                      <Table aria-label="caption table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              #
                                            </TableCell>

                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Title
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Image
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Video
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Season 2
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Actions
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <DragDropContext
                                          onDragEnd={onDragEndSeason2}
                                        >
                                          <Droppable droppableId="SeasonTwo">
                                            {(provided, snapshot) => (
                                              <TableBody
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                style={{
                                                  backgroundColor:
                                                    snapshot.isDraggingOver
                                                      ? "white"
                                                      : "white",
                                                }}
                                              >
                                                {season2Data.map(
                                                  (row, index) => (
                                                    <Draggable
                                                      draggableId={(
                                                        index + 1
                                                      ).toString()}
                                                      key={index + 1}
                                                      index={index}
                                                    >
                                                      {(provided) => (
                                                        <TableRow
                                                          key={index + 1}
                                                          sx={{
                                                            "&:last-child td, &:last-child th":
                                                              {
                                                                border: 0,
                                                              },
                                                          }}
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                        >
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            {index + 1}
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            {row?.title2 ?? ""}
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            <Avatar
                                                              alt={
                                                                row?.image2 ??
                                                                "Series2 Cover"
                                                              }
                                                              src={
                                                                row?.image2 ??
                                                                ""
                                                              }
                                                              sx={{
                                                                width: 36,
                                                                height: 36,
                                                              }}
                                                            />
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            <Player>
                                                              <source
                                                                src={video_url2}
                                                              />
                                                            </Player>
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            {row?.season2 ?? ""}
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            <IconButton
                                                              aria-label="edit"
                                                              color="primary"
                                                              onClick={() =>
                                                                handleEditClickSeason2(
                                                                  row,
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <EditIcon />
                                                            </IconButton>

                                                            <IconButton
                                                              aria-label="delete"
                                                              color="error"
                                                              onClick={() =>
                                                                handleDeleteClickSeason2(
                                                                  row,
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <DeleteIcon />
                                                            </IconButton>
                                                          </TableCell>
                                                        </TableRow>
                                                      )}
                                                    </Draggable>
                                                  )
                                                )}
                                                {provided.placeholder}
                                              </TableBody>
                                            )}
                                          </Droppable>
                                        </DragDropContext>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                </Grid>
                              </Col>

                              {/* season 3 table here */}

                              <Col lg="12">
                                <Box
                                  display="flex"
                                  flexDirection="row-reverse"
                                  justifyContent="space-between"
                                >
                                  <Button
                                    variant="contained"
                                    onClick={handleClickOpenSeason3}
                                    size="sm"
                                    className={classes.item}
                                  >
                                    Add Season 3
                                  </Button>
                                </Box>

                                <br />
                                <Grid
                                  container
                                  spacing={4}
                                  justify="flex-start"
                                >
                                  <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                      <Table aria-label="caption table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              #
                                            </TableCell>

                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Title
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Image
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Video
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Season 3
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Actions
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <DragDropContext
                                          onDragEnd={onDragEndSeason3}
                                        >
                                          <Droppable droppableId="SeasonThree">
                                            {(provided, snapshot) => (
                                              <TableBody
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                style={{
                                                  backgroundColor:
                                                    snapshot.isDraggingOver
                                                      ? "white"
                                                      : "white",
                                                }}
                                              >
                                                {season3Data.map(
                                                  (row, index) => (
                                                    <Draggable
                                                      draggableId={(
                                                        index + 1
                                                      ).toString()}
                                                      key={index + 1}
                                                      index={index}
                                                    >
                                                      {(provided) => (
                                                        <TableRow
                                                          key={index + 1}
                                                          sx={{
                                                            "&:last-child td, &:last-child th":
                                                              {
                                                                border: 0,
                                                              },
                                                          }}
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                        >
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            {index + 1}
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            {row?.title3 ?? ""}
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            <Avatar
                                                              alt={
                                                                row?.image3 ??
                                                                "Series3 Cover"
                                                              }
                                                              src={
                                                                row?.image3 ??
                                                                ""
                                                              }
                                                              sx={{
                                                                width: 36,
                                                                height: 36,
                                                              }}
                                                            />
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            <Player>
                                                              <source
                                                                src={video_url3}
                                                              />
                                                            </Player>
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            {row?.season3 ?? ""}
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            <IconButton
                                                              aria-label="edit"
                                                              color="primary"
                                                              onClick={() =>
                                                                handleEditClickSeason3(
                                                                  row,
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <EditIcon />
                                                            </IconButton>

                                                            <IconButton
                                                              aria-label="delete"
                                                              color="error"
                                                              onClick={() =>
                                                                handleDeleteClickSeason3(
                                                                  row,
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <DeleteIcon />
                                                            </IconButton>
                                                          </TableCell>
                                                        </TableRow>
                                                      )}
                                                    </Draggable>
                                                  )
                                                )}
                                                {provided.placeholder}
                                              </TableBody>
                                            )}
                                          </Droppable>
                                        </DragDropContext>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                </Grid>
                              </Col>

                              {/* season 4 table here */}

                              <Col lg="12">
                                <Box
                                  display="flex"
                                  flexDirection="row-reverse"
                                  justifyContent="space-between"
                                >
                                  <Button
                                    variant="contained"
                                    onClick={handleClickOpenSeason4}
                                    size="sm"
                                    className={classes.item}
                                  >
                                    Add Season 4
                                  </Button>
                                </Box>

                                <br />
                                <Grid
                                  container
                                  spacing={4}
                                  justify="flex-start"
                                >
                                  <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                      <Table aria-label="caption table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              #
                                            </TableCell>

                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Title
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Image
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Video
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Season 4
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Actions
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <DragDropContext
                                          onDragEnd={onDragEndSeason4}
                                        >
                                          <Droppable droppableId="SeasonFour">
                                            {(provided, snapshot) => (
                                              <TableBody
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                style={{
                                                  backgroundColor:
                                                    snapshot.isDraggingOver
                                                      ? "white"
                                                      : "white",
                                                }}
                                              >
                                                {season4Data.map(
                                                  (row, index) => (
                                                    <Draggable
                                                      draggableId={(
                                                        index + 1
                                                      ).toString()}
                                                      key={index + 1}
                                                      index={index}
                                                    >
                                                      {(provided) => (
                                                        <TableRow
                                                          key={index + 1}
                                                          sx={{
                                                            "&:last-child td, &:last-child th":
                                                              {
                                                                border: 0,
                                                              },
                                                          }}
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                        >
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            {index + 1}
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            {row?.title4 ?? ""}
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            <Avatar
                                                              alt={
                                                                row?.image4 ??
                                                                "Series3 Cover"
                                                              }
                                                              src={
                                                                row?.image4 ??
                                                                ""
                                                              }
                                                              sx={{
                                                                width: 36,
                                                                height: 36,
                                                              }}
                                                            />
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            <Player>
                                                              <source
                                                                src={video_url4}
                                                              />
                                                            </Player>
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            {row?.season4 ?? ""}
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            <IconButton
                                                              aria-label="edit"
                                                              color="primary"
                                                              onClick={() =>
                                                                handleEditClickSeason4(
                                                                  row,
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <EditIcon />
                                                            </IconButton>

                                                            <IconButton
                                                              aria-label="delete"
                                                              color="error"
                                                              onClick={() =>
                                                                handleDeleteClickSeason4(
                                                                  row,
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <DeleteIcon />
                                                            </IconButton>
                                                          </TableCell>
                                                        </TableRow>
                                                      )}
                                                    </Draggable>
                                                  )
                                                )}
                                                {provided.placeholder}
                                              </TableBody>
                                            )}
                                          </Droppable>
                                        </DragDropContext>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                </Grid>
                              </Col>

                              {/* season 5 table here */}

                              <Col lg="12">
                                <Box
                                  display="flex"
                                  flexDirection="row-reverse"
                                  justifyContent="space-between"
                                >
                                  <Button
                                    variant="contained"
                                    onClick={handleClickOpenSeason5}
                                    size="sm"
                                    className={classes.item}
                                  >
                                    Add Season 5
                                  </Button>
                                </Box>

                                <br />
                                <Grid
                                  container
                                  spacing={4}
                                  justify="flex-start"
                                >
                                  <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                      <Table aria-label="caption table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              #
                                            </TableCell>

                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Title
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Image
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Video
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Season 5
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={
                                                classes.tableHeaderCell
                                              }
                                            >
                                              Actions
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <DragDropContext
                                          onDragEnd={onDragEndSeason5}
                                        >
                                          <Droppable droppableId="SeasonFour">
                                            {(provided, snapshot) => (
                                              <TableBody
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                style={{
                                                  backgroundColor:
                                                    snapshot.isDraggingOver
                                                      ? "white"
                                                      : "white",
                                                }}
                                              >
                                                {season5Data.map(
                                                  (row, index) => (
                                                    <Draggable
                                                      draggableId={(
                                                        index + 1
                                                      ).toString()}
                                                      key={index + 1}
                                                      index={index}
                                                    >
                                                      {(provided) => (
                                                        <TableRow
                                                          key={index + 1}
                                                          sx={{
                                                            "&:last-child td, &:last-child th":
                                                              {
                                                                border: 0,
                                                              },
                                                          }}
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                        >
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            {index + 1}
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            {row?.title5 ?? ""}
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            <Avatar
                                                              alt={
                                                                row?.image5 ??
                                                                "Series5 Cover"
                                                              }
                                                              src={
                                                                row?.image5 ??
                                                                ""
                                                              }
                                                              sx={{
                                                                width: 36,
                                                                height: 36,
                                                              }}
                                                            />
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            <Player>
                                                              <source
                                                                src={video_url5}
                                                              />
                                                            </Player>
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            {row?.season5 ?? ""}
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            <IconButton
                                                              aria-label="edit"
                                                              color="primary"
                                                              onClick={() =>
                                                                handleEditClickSeason5(
                                                                  row,
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <EditIcon />
                                                            </IconButton>

                                                            <IconButton
                                                              aria-label="delete"
                                                              color="error"
                                                              onClick={() =>
                                                                handleDeleteClickSeason5(
                                                                  row,
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <DeleteIcon />
                                                            </IconButton>
                                                          </TableCell>
                                                        </TableRow>
                                                      )}
                                                    </Draggable>
                                                  )
                                                )}
                                                {provided.placeholder}
                                              </TableBody>
                                            )}
                                          </Droppable>
                                        </DragDropContext>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                </Grid>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>

                      <div className="gap-col">
                        <ul className="d-flex align-items-center gap g-3">
                          <li>
                            <Button type="submit" variant="contained">
                              {loading2 ? "Saving...." : "Save"}
                            </Button>
                          </li>
                          <li>
                            <Link
                              to="/series-manage/series-list"
                              className="btn border-0"
                            >
                              Cancel
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  {/* <Col xxl="3">
                    <Card className="card-gutter-md">
                      <Card.Body>
                        <Row className="g-gs">
                          <Col className="col-12">
                           
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col> */}
                </Row>
              </Form>
              {/* cast Add */}
              <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{ fontSize: "1rem" }}
                  >
                    Add Cast
                  </DialogTitle>
                  <Form onSubmit={onSubmitCast}>
                    <DialogContent>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Autocomplete
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          value={castname}
                          onChange={(event, newValue) => {
                            handleCastChange(newValue);
                          }}
                          options={persons.map((option) => option.name)}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Cast"
                              placeholder="Select Cast"
                            />
                          )}
                        />
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="character"
                          variant="outlined"
                          id="character"
                          label="Character"
                          value={character}
                          onChange={(e) => setcharacter(e.target.value)}
                          fullWidth
                        />
                      </Form.Group>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.item}
                        autoFocus
                        onClick={handleClose}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.item}
                      >
                        Add Cast
                      </Button>
                    </DialogActions>
                  </Form>
                </div>
              </Dialog>

              {/* cast Edit */}

              <Dialog
                fullScreen={fullScreen}
                open={open2}
                onClose={handleClose2}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{ fontSize: "1rem" }}
                  >
                    Edit Cast
                  </DialogTitle>
                  <Form onSubmit={onSubmitCastEdit}>
                    <DialogContent>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Autocomplete
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          value={castname}
                          onChange={(event, newValue) => {
                            handleCastChange(newValue);
                          }}
                          options={persons.map((option) => option.name)}
                          isOptionEqualToValue={(option, value) =>
                            option?.id === value?.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Cast"
                              placeholder="Select Cast"
                            />
                          )}
                        />
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="character"
                          variant="outlined"
                          id="character"
                          label="Character"
                          value={character}
                          onChange={(e) => setcharacter(e.target.value)}
                          fullWidth
                        />
                      </Form.Group>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.item}
                        autoFocus
                        onClick={handleClose2}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.item}
                      >
                        Update Cast
                      </Button>
                    </DialogActions>
                  </Form>
                </div>
              </Dialog>

              {/* crew Add */}
              <Dialog
                fullScreen={fullScreen}
                open={open3}
                onClose={handleClose3}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{ fontSize: "1rem" }}
                  >
                    Add Crew
                  </DialogTitle>
                  <Form onSubmit={onSubmitCrew}>
                    <DialogContent>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Autocomplete
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          value={crewname}
                          onChange={(event, newValue) => {
                            handleCrewChange(newValue);
                          }}
                          options={persons.map((option) => option.name)}
                          isOptionEqualToValue={(option, value) =>
                            option?.id === value?.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Crew"
                              placeholder="Select Crew"
                            />
                          )}
                        />
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="crewcharacter"
                          variant="outlined"
                          id="crewcharacter"
                          label="Character"
                          value={crewcharacter}
                          onChange={(e) => setcrewcharacter(e.target.value)}
                          fullWidth
                        />
                      </Form.Group>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.item}
                        autoFocus
                        onClick={handleClose3}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.item}
                      >
                        Add Crew
                      </Button>
                    </DialogActions>
                  </Form>
                </div>
              </Dialog>

              {/* crew Edit */}

              <Dialog
                fullScreen={fullScreen}
                open={open4}
                onClose={handleClose4}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{ fontSize: "1rem" }}
                  >
                    Edit Crew
                  </DialogTitle>
                  <Form onSubmit={onSubmitCrewEdit}>
                    <DialogContent>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Autocomplete
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          value={crewname}
                          onChange={(event, newValue) => {
                            handleCrewChange(newValue);
                          }}
                          options={persons.map((option) => option.name)}
                          isOptionEqualToValue={(option, value) =>
                            option?.id === value?.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Crew"
                              placeholder="Select Crew"
                            />
                          )}
                        />
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="crewcharacter"
                          variant="outlined"
                          id="crewcharacter"
                          label="Character"
                          value={crewcharacter}
                          onChange={(e) => setcrewcharacter(e.target.value)}
                          fullWidth
                        />
                      </Form.Group>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.item}
                        autoFocus
                        onClick={handleClose4}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.item}
                      >
                        Update Crew
                      </Button>
                    </DialogActions>
                  </Form>
                </div>
              </Dialog>

              {/* Season Add */}
              <Dialog
                fullScreen={fullScreen}
                open={open5}
                onClose={handleClose5}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{ fontSize: "1rem" }}
                  >
                    Add Season 1
                  </DialogTitle>
                  <Form onSubmit={onSubmitSeason1}>
                    <DialogContent>
                      <Form.Group controlId="formFile" className="mb-3">
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="title"
                          variant="outlined"
                          required
                          id="title"
                          label="Title"
                          value={title}
                          onChange={(e) => settitle(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <div>Image *</div>
                          {loading5 ? (
                            <div>Upload progress {progress3}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <img
                              id="image-result1add"
                              className="w-100 h-100"
                              src={image}
                              alt="avatar"
                            />
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result1add"
                              id="change-avatar1add"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedFile1add}
                            />
                            <label
                              htmlFor="change-avatar1add"
                              className="btn btn-md btn-primary"
                              disabled={loading5}
                            >
                              {loading5 ? "Uploading ..." : "Upload"}
                            </label>
                          </div>
                        </div>
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="video_url"
                          variant="outlined"
                          id="video_url"
                          label="Video Link"
                          value={video_url}
                          onChange={(e) => setvideo_url(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <center>OR</center>
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <br />
                          <div>Video</div>
                          {loading7 ? (
                            <div>Upload progress {progress5}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <div className="player-wrapper">
                              <Player>
                                <source src={video_url} />
                              </Player>
                            </div>
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result1addvideo"
                              id="change-avatar1addvideo"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedFile1addVideo}
                            />
                            <label
                              htmlFor="change-avatar1addvideo"
                              className="btn btn-md btn-primary"
                              disabled={loading7}
                            >
                              {loading7 ? "Uploading ..." : "UploadVideo"}
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.item}
                        autoFocus
                        onClick={handleClose5}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.item}
                      >
                        Add
                      </Button>
                    </DialogActions>
                  </Form>
                </div>
              </Dialog>

              {/* Season Edit */}
              <Dialog
                fullScreen={fullScreen}
                open={open6}
                onClose={handleClose6}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{ fontSize: "1rem" }}
                  >
                    Edit Season 1
                  </DialogTitle>
                  <Form onSubmit={onSubmitEditSeason1}>
                    <DialogContent>
                      <Form.Group controlId="formFile" className="mb-3">
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="title"
                          variant="outlined"
                          required
                          id="title"
                          label="Title"
                          value={title}
                          onChange={(e) => settitle(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <div>Image *</div>
                          {loading5 ? (
                            <div>Upload progress {progress3}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <img
                              id="image-result1edit"
                              className="w-100 h-100"
                              src={image}
                              alt="avatar"
                            />
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result1edit"
                              id="change-avatar1edit"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedFile1add}
                            />
                            <label
                              htmlFor="change-avatar1edit"
                              className="btn btn-md btn-primary"
                              disabled={loading5}
                            >
                              {loading5 ? "Uploading ..." : "Upload"}
                            </label>
                          </div>
                        </div>
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="video_url"
                          variant="outlined"
                          id="video_url"
                          label="Video Link"
                          value={video_url}
                          onChange={(e) => setvideo_url(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <center>OR</center>
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <br />
                          <div>Video</div>
                          {loading7 ? (
                            <div>Upload progress {progress5}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <div className="player-wrapper">
                              <Player>
                                <source src={video_url} />
                              </Player>
                            </div>
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result1editvideo"
                              id="change-avatar1editvideo"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedFile1addVideo}
                            />
                            <label
                              htmlFor="change-avatar1editvideo"
                              className="btn btn-md btn-primary"
                              disabled={loading7}
                            >
                              {loading7 ? "Uploading ..." : "UploadVideo"}
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.item}
                        autoFocus
                        onClick={handleClose6}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.item}
                      >
                        Update
                      </Button>
                    </DialogActions>
                  </Form>
                </div>
              </Dialog>

              {/* Season2 Add */}
              <Dialog
                fullScreen={fullScreen}
                open={openseason2}
                onClose={handleClickOpenSeason2}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{ fontSize: "1rem" }}
                  >
                    Add Season 2
                  </DialogTitle>
                  <Form onSubmit={onSubmitSeason2}>
                    <DialogContent>
                      <Form.Group controlId="formFile" className="mb-3">
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="title2"
                          variant="outlined"
                          required
                          id="title2"
                          label="Title"
                          value={title2}
                          onChange={(e) => settitle2(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <div>Image *</div>
                          {loadingSeason2a ? (
                            <div>Upload progress {progressSeason2a}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <img
                              id="image-result2add"
                              className="w-100 h-100"
                              src={image2}
                              alt="avatar"
                            />
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result2add"
                              id="change-avatar2add"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedFileSeason2add}
                            />
                            <label
                              htmlFor="change-avatar2add"
                              className="btn btn-md btn-primary"
                              disabled={loadingSeason2a}
                            >
                              {loadingSeason2a ? "Uploading ..." : "Upload"}
                            </label>
                          </div>
                        </div>
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="video_url2"
                          variant="outlined"
                          id="video_url2"
                          label="Video Link"
                          value={video_url2}
                          onChange={(e) => setvideo_url2(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <center>OR</center>
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <br />
                          <div>Video</div>
                          {loadingSeason2b ? (
                            <div>Upload progress {progressSeason2b}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <div className="player-wrapper">
                              <Player>
                                <source src={video_url2} />
                              </Player>
                            </div>
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result2addvideo"
                              id="change-avatar2addvideo"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedFileSeason2addVideo}
                            />
                            <label
                              htmlFor="change-avatar2addvideo"
                              className="btn btn-md btn-primary"
                              disabled={loadingSeason2b}
                            >
                              {loadingSeason2b
                                ? "Uploading ..."
                                : "UploadVideo"}
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.item}
                        autoFocus
                        onClick={handleCloseSeason2}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.item}
                      >
                        Add
                      </Button>
                    </DialogActions>
                  </Form>
                </div>
              </Dialog>

              {/* Season2 Edit */}
              <Dialog
                fullScreen={fullScreen}
                open={openseason2b}
                onClose={handleCloseSeason2b}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{ fontSize: "1rem" }}
                  >
                    Edit Season 2
                  </DialogTitle>
                  <Form onSubmit={onSubmitEditSeason2}>
                    <DialogContent>
                      <Form.Group controlId="formFile" className="mb-3">
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="title2"
                          variant="outlined"
                          required
                          id="title2"
                          label="Title"
                          value={title2}
                          onChange={(e) => settitle2(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <div>Image *</div>
                          {loadingSeason2a ? (
                            <div>Upload progress {progressSeason2a}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <img
                              id="image-result2edit"
                              className="w-100 h-100"
                              src={image2}
                              alt="avatar"
                            />
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result2edit"
                              id="change-avatar2edit"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedFileSeason2add}
                            />
                            <label
                              htmlFor="change-avatar2edit"
                              className="btn btn-md btn-primary"
                              disabled={loadingSeason2a}
                            >
                              {loadingSeason2a ? "Uploading ..." : "Upload"}
                            </label>
                          </div>
                        </div>
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="video_url2"
                          variant="outlined"
                          id="video_url2"
                          label="Video Link"
                          value={video_url2}
                          onChange={(e) => setvideo_url2(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <center>OR</center>
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <br />
                          <div>Video</div>
                          {loadingSeason2b ? (
                            <div>Upload progress {progressSeason2b}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <div className="player-wrapper">
                              <Player>
                                <source src={video_url2} />
                              </Player>
                            </div>
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result2editvideo"
                              id="change-avatar2editvideo"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedFileSeason2addVideo}
                            />
                            <label
                              htmlFor="change-avatar2editvideo"
                              className="btn btn-md btn-primary"
                              disabled={loadingSeason2b}
                            >
                              {loadingSeason2b
                                ? "Uploading ..."
                                : "UploadVideo"}
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.item}
                        autoFocus
                        onClick={handleCloseSeason2b}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.item}
                      >
                        Update
                      </Button>
                    </DialogActions>
                  </Form>
                </div>
              </Dialog>

              {/* Season3 Add */}
              <Dialog
                fullScreen={fullScreen}
                open={openseason3}
                onClose={handleClickOpenSeason3}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{ fontSize: "1rem" }}
                  >
                    Add Season 3
                  </DialogTitle>
                  <Form onSubmit={onSubmitSeason3}>
                    <DialogContent>
                      <Form.Group controlId="formFile" className="mb-3">
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="title3"
                          variant="outlined"
                          required
                          id="title3"
                          label="Title"
                          value={title3}
                          onChange={(e) => settitle3(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <div>Image *</div>
                          {loadingSeason3a ? (
                            <div>Upload progress {progressSeason3a}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <img
                              id="image-result3add"
                              className="w-100 h-100"
                              src={image3}
                              alt="avatar"
                            />
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result3add"
                              id="change-avatar3add"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedFileSeason3add}
                            />
                            <label
                              htmlFor="change-avatar3add"
                              className="btn btn-md btn-primary"
                              disabled={loadingSeason3a}
                            >
                              {loadingSeason3a ? "Uploading ..." : "Upload"}
                            </label>
                          </div>
                        </div>
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="video_url3"
                          variant="outlined"
                          id="video_url3"
                          label="Video Link"
                          value={video_url3}
                          onChange={(e) => setvideo_url3(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <center>OR</center>
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <br />
                          <div>Video</div>
                          {loadingSeason3b ? (
                            <div>Upload progress {progressSeason3b}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <div className="player-wrapper">
                              <Player>
                                <source src={video_url3} />
                              </Player>
                            </div>
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result3addvideo"
                              id="change-avatar3addvideo"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedFileSeason3addVideo}
                            />
                            <label
                              htmlFor="change-avatar3addvideo"
                              className="btn btn-md btn-primary"
                              disabled={loadingSeason3b}
                            >
                              {loadingSeason3b
                                ? "Uploading ..."
                                : "UploadVideo"}
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.item}
                        autoFocus
                        onClick={handleCloseSeason3}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.item}
                      >
                        Add
                      </Button>
                    </DialogActions>
                  </Form>
                </div>
              </Dialog>

              {/* Season3 Edit */}
              <Dialog
                fullScreen={fullScreen}
                open={openseason3b}
                onClose={handleCloseSeason3b}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{ fontSize: "1rem" }}
                  >
                    Edit Season 3
                  </DialogTitle>
                  <Form onSubmit={onSubmitEditSeason3}>
                    <DialogContent>
                      <Form.Group controlId="formFile" className="mb-3">
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="title3"
                          variant="outlined"
                          required
                          id="title3"
                          label="Title"
                          value={title3}
                          onChange={(e) => settitle3(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <div>Image *</div>
                          {loadingSeason3a ? (
                            <div>Upload progress {progressSeason3a}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <img
                              id="image-result3edit"
                              className="w-100 h-100"
                              src={image3}
                              alt="avatar"
                            />
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result3edit"
                              id="change-avatar3edit"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedFileSeason3add}
                            />
                            <label
                              htmlFor="change-avatar3edit"
                              className="btn btn-md btn-primary"
                              disabled={loadingSeason3a}
                            >
                              {loadingSeason3a ? "Uploading ..." : "Upload"}
                            </label>
                          </div>
                        </div>
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="video_url3"
                          variant="outlined"
                          id="video_url3"
                          label="Video Link"
                          value={video_url3}
                          onChange={(e) => setvideo_url3(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <center>OR</center>
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <br />
                          <div>Video</div>
                          {loadingSeason3b ? (
                            <div>Upload progress {progressSeason3b}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <div className="player-wrapper">
                              <Player>
                                <source src={video_url3} />
                              </Player>
                            </div>
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result3editvideo"
                              id="change-avatar3editvideo"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedFileSeason3addVideo}
                            />
                            <label
                              htmlFor="change-avatar3editvideo"
                              className="btn btn-md btn-primary"
                              disabled={loadingSeason3b}
                            >
                              {loadingSeason3b
                                ? "Uploading ..."
                                : "UploadVideo"}
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.item}
                        autoFocus
                        onClick={handleCloseSeason3b}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.item}
                      >
                        Update
                      </Button>
                    </DialogActions>
                  </Form>
                </div>
              </Dialog>

              {/* Season4 Add */}
              <Dialog
                fullScreen={fullScreen}
                open={openseason4}
                onClose={handleClickOpenSeason4}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{ fontSize: "1rem" }}
                  >
                    Add Season 4
                  </DialogTitle>
                  <Form onSubmit={onSubmitSeason4}>
                    <DialogContent>
                      <Form.Group controlId="formFile" className="mb-3">
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="title4"
                          variant="outlined"
                          required
                          id="title4"
                          label="Title"
                          value={title4}
                          onChange={(e) => settitle4(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <div>Image *</div>
                          {loadingSeason4a ? (
                            <div>Upload progress {progressSeason4a}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <img
                              id="image-result4add"
                              className="w-100 h-100"
                              src={image4}
                              alt="avatar"
                            />
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result4add"
                              id="change-avatar4add"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedFileSeason4add}
                            />
                            <label
                              htmlFor="change-avatar4add"
                              className="btn btn-md btn-primary"
                              disabled={loadingSeason4a}
                            >
                              {loadingSeason4a ? "Uploading ..." : "Upload"}
                            </label>
                          </div>
                        </div>
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="video_url4"
                          variant="outlined"
                          id="video_url4"
                          label="Video Link"
                          value={video_url4}
                          onChange={(e) => setvideo_url4(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <center>OR</center>
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <br />
                          <div>Video</div>
                          {loadingSeason4b ? (
                            <div>Upload progress {progressSeason4b}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <div className="player-wrapper">
                              <Player>
                                <source src={video_url4} />
                              </Player>
                            </div>
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result4addvideo"
                              id="change-avatar4addvideo"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedFileSeason4addVideo}
                            />
                            <label
                              htmlFor="change-avatar4addvideo"
                              className="btn btn-md btn-primary"
                              disabled={loadingSeason4b}
                            >
                              {loadingSeason4b
                                ? "Uploading ..."
                                : "UploadVideo"}
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.item}
                        autoFocus
                        onClick={handleCloseSeason4}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.item}
                      >
                        Add
                      </Button>
                    </DialogActions>
                  </Form>
                </div>
              </Dialog>

              {/* Season4 Edit */}
              <Dialog
                fullScreen={fullScreen}
                open={openseason4b}
                onClose={handleCloseSeason4b}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{ fontSize: "1rem" }}
                  >
                    Edit Season 4
                  </DialogTitle>
                  <Form onSubmit={onSubmitEditSeason4}>
                    <DialogContent>
                      <Form.Group controlId="formFile" className="mb-3">
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="title4"
                          variant="outlined"
                          required
                          id="title4"
                          label="Title"
                          value={title4}
                          onChange={(e) => settitle4(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <div>Image *</div>
                          {loadingSeason4a ? (
                            <div>Upload progress {progressSeason4a}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <img
                              id="image-result4edit"
                              className="w-100 h-100"
                              src={image4}
                              alt="avatar"
                            />
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result4edit"
                              id="change-avatar4edit"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedFileSeason4add}
                            />
                            <label
                              htmlFor="change-avatar4edit"
                              className="btn btn-md btn-primary"
                              disabled={loadingSeason4a}
                            >
                              {loadingSeason4a ? "Uploading ..." : "Upload"}
                            </label>
                          </div>
                        </div>
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="video_url4"
                          variant="outlined"
                          id="video_url4"
                          label="Video Link"
                          value={video_url4}
                          onChange={(e) => setvideo_url4(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <center>OR</center>
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <br />
                          <div>Video</div>
                          {loadingSeason4b ? (
                            <div>Upload progress {progressSeason4b}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <div className="player-wrapper">
                              <Player>
                                <source src={video_url4} />
                              </Player>
                            </div>
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result4editvideo"
                              id="change-avatar4editvideo"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedFileSeason4addVideo}
                            />
                            <label
                              htmlFor="change-avatar4editvideo"
                              className="btn btn-md btn-primary"
                              disabled={loadingSeason4b}
                            >
                              {loadingSeason4b
                                ? "Uploading ..."
                                : "UploadVideo"}
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.item}
                        autoFocus
                        onClick={handleCloseSeason4b}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.item}
                      >
                        Update
                      </Button>
                    </DialogActions>
                  </Form>
                </div>
              </Dialog>

              {/* Season5 Add */}
              <Dialog
                fullScreen={fullScreen}
                open={openseason5}
                onClose={handleClickOpenSeason5}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{ fontSize: "1rem" }}
                  >
                    Add Season 5
                  </DialogTitle>
                  <Form onSubmit={onSubmitSeason5}>
                    <DialogContent>
                      <Form.Group controlId="formFile" className="mb-3">
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="title5"
                          variant="outlined"
                          required
                          id="title5"
                          label="Title"
                          value={title5}
                          onChange={(e) => settitle5(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <div>Image *</div>
                          {loadingSeason5a ? (
                            <div>Upload progress {progressSeason5a}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <img
                              id="image-result5add"
                              className="w-100 h-100"
                              src={image5}
                              alt="avatar"
                            />
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result5add"
                              id="change-avatar5add"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedFileSeason5add}
                            />
                            <label
                              htmlFor="change-avatar5add"
                              className="btn btn-md btn-primary"
                              disabled={loadingSeason5a}
                            >
                              {loadingSeason5a ? "Uploading ..." : "Upload"}
                            </label>
                          </div>
                        </div>
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="video_url5"
                          variant="outlined"
                          id="video_url5"
                          label="Video Link"
                          value={video_url5}
                          onChange={(e) => setvideo_url5(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <center>OR</center>
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <br />
                          <div>Video</div>
                          {loadingSeason5b ? (
                            <div>Upload progress {progressSeason5b}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <div className="player-wrapper">
                              <Player>
                                <source src={video_url5} />
                              </Player>
                            </div>
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result5addvideo"
                              id="change-avatar5addvideo"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedFileSeason5addVideo}
                            />
                            <label
                              htmlFor="change-avatar5addvideo"
                              className="btn btn-md btn-primary"
                              disabled={loadingSeason5b}
                            >
                              {loadingSeason5b
                                ? "Uploading ..."
                                : "UploadVideo"}
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.item}
                        autoFocus
                        onClick={handleCloseSeason5}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.item}
                      >
                        Add
                      </Button>
                    </DialogActions>
                  </Form>
                </div>
              </Dialog>

              {/* Season5 Edit */}
              <Dialog
                fullScreen={fullScreen}
                open={openseason5b}
                onClose={handleCloseSeason5b}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{ fontSize: "1rem" }}
                  >
                    Edit Season 5
                  </DialogTitle>
                  <Form onSubmit={onSubmitEditSeason5}>
                    <DialogContent>
                      <Form.Group controlId="formFile" className="mb-3">
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="title5"
                          variant="outlined"
                          required
                          id="title5"
                          label="Title"
                          value={title5}
                          onChange={(e) => settitle5(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <div>Image *</div>
                          {loadingSeason5a ? (
                            <div>Upload progress {progressSeason5a}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <img
                              id="image-result5edit"
                              className="w-100 h-100"
                              src={image5}
                              alt="avatar"
                            />
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result5edit"
                              id="change-avatar5edit"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedFileSeason5add}
                            />
                            <label
                              htmlFor="change-avatar5edit"
                              className="btn btn-md btn-primary"
                              disabled={loadingSeason5a}
                            >
                              {loadingSeason5a ? "Uploading ..." : "Upload"}
                            </label>
                          </div>
                        </div>
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="video_url5"
                          variant="outlined"
                          id="video_url5"
                          label="Video Link"
                          value={video_url5}
                          onChange={(e) => setvideo_url5(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <center>OR</center>
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <br />
                          <div>Video</div>
                          {loadingSeason5b ? (
                            <div>Upload progress {progressSeason5b}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <div className="player-wrapper">
                              <Player>
                                <source src={video_url5} />
                              </Player>
                            </div>
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result5editvideo"
                              id="change-avatar5editvideo"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedFileSeason5addVideo}
                            />
                            <label
                              htmlFor="change-avatar5editvideo"
                              className="btn btn-md btn-primary"
                              disabled={loadingSeason5b}
                            >
                              {loadingSeason5b
                                ? "Uploading ..."
                                : "UploadVideo"}
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.item}
                        autoFocus
                        onClick={handleCloseSeason5b}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.item}
                      >
                        Update
                      </Button>
                    </DialogActions>
                  </Form>
                </div>
              </Dialog>
            </Block>
          </Layout>
        </>
      )}
    </>
  );
}

export default AddSeries;
