import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon } from "../../components";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
// import FormControl from "@mui/material/FormControl";
import { rootURL, PayStackLiveKey } from "../utilities/axios-config";
import { successMessage, failedMessage } from "../utilities/alert-config";
// import Autocomplete from "@mui/material/Autocomplete";
// import TextField from "@mui/material/TextField";

function FundTransferAdd() {
  const history = useNavigate();
  const [type, settype] = useState("mobile_money");
  const [name, setname] = useState("");
  const [account_number, setaccount_number] = useState("");
  const [bank_code, setbank_code] = useState("MTN");
  const [reason, setreason] = useState("");
  const [amounts, setamount] = useState(0);
  const [bcodeData, setbcodeData] = useState([]);
  const TypeData = ["mobile_money"];
  const currency = "GHS";
  const source = "balance";

  // const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const tableRecords = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${PayStackLiveKey}`,
          "Content-Type": "application/json",
        },
      };
      let url = "https://api.paystack.co/bank?currency=GHS&type=mobile_money";

      //setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.status === true) {
            // console.log("status", row);

            setbcodeData(row?.data);
            // successMessage(row?.message);
            //setLoading(false);
          } else if (row?.status === false) {
            // failedMessage(row?.message);
            //setLoading(false);
          }
        })
        .finally(() => {
          // setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleTypes = (event) => {
    settype(event.target.value);
  };

  const handleBankCode = (event) => {
    setbank_code(event.target.value);
  };

  const sendToAukiss = async (
    recipient,
    reference,
    transfer_code,
    message,
    status
  ) => {
    try {
      const userToken = localStorage.getItem("userToken");
      setLoading2(true);

      const tagObj = {
        source,
        reason,
        amounts,
        recipient,
        reference,
        type,
        name,
        account_number,
        bank_code,
        currency,
        transfer_code,
        message,
        status,
      };

      fetch(`${rootURL}/add_fundtransfer_cpanel`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
          Accept: "application/form-data",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tagObj),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.success === true) {
            let title =
              message +
              " " +
              "Kindly check the status of transaction using the status button after 1 minutes";
            successMessage(title);

            setTimeout(() => {
              history("/fundtransfer/fundtransfer-list");
            }, 5000);
          } else if (result.success === false) {
            setLoading2(false);
            failedMessage(result.message);
          }
        })
        .catch((error) => {
          setLoading2(false);
          failedMessage("Internal Server Error");
        })
        .finally(() => {
          setLoading2(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const initiateTransfer = async (recipient) => {
    try {
      const reference = Math.floor(Math.random() * 1000000000 + 1);
      console.log("reference", reference);
      const amount = amounts * 100;
      console.log("amount", amount)
      const tagObj = {
        source,
        reason,
        amount,
        recipient,
        reference,
      };

      fetch("https://api.paystack.co/transfer", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${PayStackLiveKey}`,
          Accept: "application/form-data",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tagObj),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.status === true) {
            sendToAukiss(
              recipient,
              reference,
              result?.data?.transfer_code,
              result?.message,
              result?.data?.status
            );
          } else if (result.status === false) {
            setLoading2(false);
            failedMessage(result.message);
          }
        })
        .catch((error) => {
          setLoading2(false);
          failedMessage("Internal Server Error");
        })
        .finally(() => {
          setLoading2(false);
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading2(true);
    const tagObj = {
      type,
      name,
      account_number,
      bank_code,
      currency,
    };

    fetch("https://api.paystack.co/transferrecipient", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${PayStackLiveKey}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tagObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === true) {
          console.log("row", result);
          initiateTransfer(result?.data?.recipient_code);
        } else if (result.status === false) {
          setLoading2(false);
          failedMessage(result.message);
        }
      })
      .catch((error) => {
        setLoading2(false);
        failedMessage("Internal Server Error");
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  // grabs the id form the url and loads the corresponding data
  useEffect(() => {
    tableRecords();
  }, []);

  return (
    <Layout title="Send Money" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            {/* <div className="d-flex flex-column flex-md-row align-items-md-center">
                        <Media size="huge" shape="circle" variant={theme && theme}>
                            {
                                <Image src={avatar} staticImage thumbnail alt="user"/> 
                              
                            }
                        </Media>
                        <div className="mt-3 mt-md-0 ms-md-3">
                            <h3 className="title mb-1">{user.name}</h3>
                            <span className="small">{user.role}</span>
                            <ul className="nk-list-option pt-1">
                                <li><Icon name="map-pin"></Icon><span className="small">{user.address}</span></li>
                                <li><Icon name="building"></Icon><span className="small">{user.company}</span></li>
                            </ul>
                        </div>
                    </div> */}
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex gap g-2">
              <li className="d-none d-md-block">
                <Link
                  to={`/fundtransfer/fundtransfer-list`}
                  className="btn btn-soft btn-primary"
                >
                  <Icon name="user"></Icon>
                  <span>FundTransfer List</span>
                </Link>
              </li>
              <li className="d-md-none">
                <Link
                  to={`/fundtransfer/fundtransfer-list`}
                  className="btn btn-soft btn-primary btn-icon"
                >
                  <Icon name="user"></Icon>
                </Link>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">Send Money</h4>
              <Form onSubmit={handleSubmit}>
                <Row className="g-3">
                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="user_name">Type *</Form.Label>
                      <div className="form-control-wrap">
                        <Select
                          labelId="demo-select-small"
                          id="demo-simple-select"
                          value={type ?? ""}
                          label="Type"
                          onChange={handleTypes}
                          required
                        >
                          {TypeData.map((types) => (
                            <MenuItem key={types} value={types}>
                              {types}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="user_name">Bank Code *</Form.Label>
                      <div className="form-control-wrap">
                        <Select
                          labelId="demo-select-small"
                          id="demo-simple-select"
                          value={bank_code ?? ""}
                          label="Bank Code"
                          onChange={handleBankCode}
                          required
                        >
                          {bcodeData.map((types) => (
                            <MenuItem key={types?.code} value={types?.code}>
                              {types?.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="firstName">Name *</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="Name"
                          value={name}
                          placeholder="Name"
                          required
                          onChange={(e) => setname(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="lastName">
                        Account Number *
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="number"
                          id="account_number"
                          value={account_number}
                          placeholder="Account Number"
                          required
                          onChange={(e) => setaccount_number(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="firstName">Reason *</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="Name"
                          value={reason}
                          placeholder="Reason"
                          required
                          onChange={(e) => setreason(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="lastName">Amount *</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="number"
                          id="amounts"
                          value={amounts}
                          placeholder="Amount"
                          required
                          onChange={(e) => setamount(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {loading2 ? "Sending...." : "Send"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  );
}

export default FundTransferAdd;
