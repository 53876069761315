import { useEffect, useState, useRef, useMemo } from "react";
import { Dropdown, Form, Badge } from "react-bootstrap";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import SimpleBar from "simplebar-react";
import { makeStyles } from "@mui/styles";
import Pusher from "pusher-js";
import Button from "@mui/material/Button";
//Button
//import * as PusherPushNotifications from "@pusher/push-notifications-web";
import Layout from "../../../layout/default";
import { useTheme } from "@mui/material/styles";
import {
  rootURL,
  awsID,
  awsBucketName,
  awsSECRET,
} from "../../utilities/axios-config";
import CircularProgress from "@mui/material/CircularProgress";
import WelcomeChat from "../chat/WelcomeChat";
import Picker from "emoji-picker-react";
import { BsEmojiSmileFill } from "react-icons/bs";
import { failedMessage } from "../../utilities/alert-config";
// successMessage
import {
  ChatList,
  CustomDropdownMenu,
  CustomDropdownToggle,
  Icon,
  Image,
  MediaGroup,
  Media,
  MediaText,
  Message,
  //ChatMedia,
} from "../../../components";
import "./chat.scss";
import audioUrl from "../../../assets/images/message.mp3";

import { Break } from "../../../utilities";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import TextField from "@mui/material/TextField";

import FormControl from "@mui/material/FormControl";

// import InputLabel from "@mui/material/InputLabel";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    fontWeight: "bold",
  },
  item: {
    padding: 5,
  },
  backgroundColor: {
    backgroundColor: "#2CB23B",
  },
}));

function ChatsPage() {
  const history = useNavigate();
  const [sidebar, setSidebar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [showEmojiPicker, setshowEmojiPicker] = useState(false);
  const [active, setActive] = useState("");
  const firstName = localStorage.getItem("userFirstName");
  const lastName = localStorage.getItem("userLastName");
  const photo = localStorage.getItem("userPhoto");
  const roleName = localStorage.getItem("roleName");
  const [searchText, setSearchText] = useState("");
  const [localSearch, setLocalSearch] = useState("");
  const [loading, setLoading] = useState(false);
  console.log(setLoading);
  const [messages, setMessages] = useState([]);
  const [chatUserListData, setchatUserListData] = useState([]);
  let selectedchatDefault = localStorage.getItem("selectedChat");
  let ssch = JSON.parse(selectedchatDefault);
  const [selectedChatUser, setselectedChatUser] = useState(ssch ?? {});
  let welcomedafult = localStorage.getItem("welcome");
  const [welcomeLoading, setwelcomeLoading] = useState(welcomedafult ?? false);
  const [message, setmessage] = useState("");
  const [picture, setpicture] = useState("");
  const [receiver, setreceiver] = useState(ssch?.user_id ?? "");
  const [loading2, setloading2] = useState(false);
  const [user_name, setuser_name] = useState("");
  let defaultPhoto = "/images/avatar/c.jpg";
  const bottomRef = useRef(null);
  const [open, setOpen] = useState(false);
  //const [indexValue, setIndexValue] = useState("");
  const themes = useTheme();
  const fullScreen = useMediaQuery(themes.breakpoints.down("md"));
  const [userData, setuserData] = useState([]);
  const classes = useStyles();
  const [usernew_id, setusernew_id] = useState("");
  // let allMessages = [];

  const tableRecordsNew = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/all_assuser_cpanel`;

      //setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            //setItems(row?.data);
            setuserData(row?.data);
            // successMessage(row?.message);
            //setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            //setLoading(false);
          }
        })
        .finally(() => {
          // setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  // toggle chat profiel class
  const chatProfileClasses = classNames({
    "nk-chat-profile": true,
    "show-profile": sidebar,
    "toggle-collapsed": window.innerWidth < Break.xxl,
  });

  // toggle chat parent class
  const parentClasses = classNames({
    "nk-chat": true,
    "profile-shown": sidebar && window.innerWidth > Break.xxl,
  });

  // toggle chat body class
  const chatBodyClasses = classNames({
    "nk-chat-body": true,
    "show-chat": mobileView,
  });

  const windowResize = () => {
    if (window.innerWidth > Break.xxl) {
      setSidebar(true);
    } else {
      setSidebar(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitNewUser = (event) => {
    event.preventDefault();
    handleClose();

    const userToken = localStorage.getItem("userToken");

    const radioObj = {
      usernew_id,
    };

    fetch(`${rootURL}/addusertochat`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(radioObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          history('/apps/chats');
          tableRecords();
          
        } else if (result.success === false) {
          failedMessage(result.message);
        }
      })
      .catch((error) => {
        failedMessage("Internal Server Error");
      })
      .finally(() => {});

  };

  // chat profile handle
  const handleChatToggle = () => {
    setSidebar(!sidebar);
  };

  const tableRecords = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/all_chatcontact_cpanel`;
      if (searchText) {
        url += `?searchtext=${searchText}`;
      }

      //setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            // console.log("row", row?.data)
            setchatUserListData(row?.data);

            // successMessage(row?.message);
            //setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            //setLoading(false);
          }
        })
        .finally(() => {
          //setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  // const MessageRecords = async () => {
  //   try {
  //     const userToken = localStorage.getItem("userToken");

  //     const radioObj = {
  //       receiver,
  //     };
  //     //setloading2(true);
  //     fetch(`${rootURL}/all_chatmessage_cpanel`, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${userToken}`,
  //         Accept: "application/form-data",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(radioObj),
  //     })
  //       .then((res) => res.json())
  //       .then((result) => {
  //         if (result.success === true) {
  //           setMessages(result?.data);
  //           //setloading2(false);
  //         } else if (result.success === false) {
  //           failedMessage(result.message);
  //           //setloading2(false);
  //         }
  //       })
  //       .catch((error) => {
  //         failedMessage("Internal Server Error");
  //         //setloading2(false);
  //       })
  //       .finally(() => {});
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const MessageRecordsNew = async (receiver) => {
    try {
      const userToken = localStorage.getItem("userToken");

      const radioObj = {
        receiver,
      };

      setloading2(true);
      fetch(`${rootURL}/all_chatmessage_cpanel`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
          Accept: "application/form-data",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(radioObj),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.success === true) {
            setMessages(result?.data);
            setloading2(false);
          } else if (result.success === false) {
            failedMessage(result.message);
            setloading2(false);
          }
        })
        .catch((error) => {
          failedMessage("Internal Server Error");
          setloading2(false);
        })
        .finally(() => {});
    } catch (err) {
      console.log(err);
    }
  };

  function dateConvert(date) {
    const now = new Date(date);
    const hoursAndMinutes = now.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return hoursAndMinutes;
  }

  // handle chat list toggle
  const handleChatListToggle = (ev, row) => {
    setActive(ev);
    setwelcomeLoading(true);
    setselectedChatUser(row);
    setreceiver(row?.user_id);
    setpicture("");
    localStorage.setItem("welcome", true);
    localStorage.setItem("selectedChat", JSON.stringify(row));
    MessageRecordsNew(row?.user_id);

    if (window.innerWidth < Break.md) {
      setMobileView(true);
    }
  };

  const handleEmojiPickerHideShow = () => {
    setshowEmojiPicker(!showEmojiPicker);
  };

  const handleEmojiClick = (event, emojiObject) => {
    let msg = message;
    msg += emojiObject.emoji;
    setmessage(msg);
  };

  // hide showing chat
  const handleChatListHide = () => {
    setMobileView(false);
  };

  const handleSendPictureMsg = async (picture) => {
    const userToken = localStorage.getItem("userToken");

    const radioObj = {
      message,
      receiver,
      picture,
    };

    fetch(`${rootURL}/chatmessage`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(radioObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          // MessageRecords();
        } else if (result.success === false) {
          failedMessage(result.message);
        }
      })
      .catch((error) => {
        failedMessage("Internal Server Error");
      })
      .finally(() => {});
  };

  const handleNameChange = (event) => {
    setuser_name(event);

    const newFilter = userData.filter((value) =>
      (value?.first_name + "-" + value?.last_name + "---" + value?.email || "")
        .toLowerCase()
        .includes((event || "").toLowerCase())
    );
    newFilter.forEach((option) => {
      if (
        option?.first_name + "-" + option?.last_name + "---" + option?.email ===
        event
      ) {
        // console.log("event", option?.id);
        setusernew_id(option?.id);
      }
    });
  };

  function create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxx4xxyxx".replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  const selectedFile = (e) => {
    const image = e.target.files[0];
    if (image) {
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + image.name, // File name you want to save as in S3
        Body: image,
        ContentType: image.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        handleSendPictureMsg(photoUrl);
        //setpicture(photoUrl);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress) {
        let uploaded = Math.round((progress.loaded / progress.total) * 100);
        console.log(uploaded);
      });
    }
  };

  function playSound() {
    const audio = new Audio(audioUrl);
    audio.play();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    handleSendMsg(message);
    setmessage("");
  };

  const handleSendMsg = async (message) => {
    const userToken = localStorage.getItem("userToken");

    const radioObj = {
      message,
      receiver,
      picture,
    };

    fetch(`${rootURL}/chatmessage`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(radioObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          playSound();
        } else if (result.success === false) {
          failedMessage(result.message);
        }
      })
      .catch((error) => {
        failedMessage("Internal Server Error");
      })
      .finally(() => {});
  };
  useEffect(() => {
    window.addEventListener("resize", windowResize);
    windowResize();
    return () => {
      window.removeEventListener("resize", windowResize);
    };
  }, []);


  const debounce = () => {
    let timeoutID;
    return (e) => {
      setLocalSearch(e.target.value);
      clearTimeout(timeoutID);
      timeoutID = setTimeout(() => {
        setSearchText(e.target.value);
        // only make api call after user finish typing
      }, 1000);
    };
  };

  const handleSearch = useMemo(() => debounce(), []);

  useEffect(() => {
    tableRecords();
    // MessageRecords();
    const selectedchatDefault = localStorage.getItem("selectedChat");
    const ssch = JSON.parse(selectedchatDefault);
    MessageRecordsNew(ssch?.user_id);
    tableRecordsNew();
    // eslint-disable-next-line
  }, [searchText]);


  useEffect(() => {
    const userToken = localStorage.getItem("userToken");
    if (userToken) {
      // Pusher.logToConsole = true;
      const pusher = new Pusher("0945475062e41158e7f6", {
        cluster: "eu",
      });
      const channel = pusher.subscribe("my-channel");
      channel.bind("my-event", function (data) {
        // console.log("data chanel", data);
        // MessageRecords();
        let selectedchatDefault = localStorage.getItem("selectedChat");
        let ssch = JSON.parse(selectedchatDefault);
        // console.log("ssch?.user_id", ssch?.user_id)
        MessageRecordsNew(ssch?.user_id);

        // 👇️ scroll to bottom every time messages change
        
      });
    }
    // eslint-disable-next-line
  }, []);

 

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    // eslint-disable-next-line
  }, [messages]);

  return (
    <Layout content="stretch" title="Message | Chats">
      <div className={parentClasses} id="chatWrap">
        <div className="nk-chat-aside">
          <div className="nk-aside-heading pt-4">
            <div className="gap-col">
              <h2 className="title">Chats</h2>
            </div>
            <div className="gap-col">
              <Dropdown>
                <Dropdown.Toggle
                  size="sm"
                  as={CustomDropdownToggle}
                  className="btn btn-sm btn-icon btn-zoom me-n1"
                >
                  <Icon name="more-h"></Icon>
                </Dropdown.Toggle>
                <Dropdown.Menu as={CustomDropdownMenu} align="end">
                  <div className="dropdown-content py-1">
                    <ul className="link-list is-compact">
                      <li onClick={handleClickOpen}>
                        <a href="#new-chat">
                          <em className="icon ni ni-plus"></em>
                          <span> New Chat</span>
                        </a>
                      </li>
                      {/* <li>
                        <a href="#new-contact">
                          <em className="icon ni ni-user"></em>
                          <span>New Contact</span>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <SimpleBar className="nk-chat-aside-scroll">
            <div className="nk-aside-content py-2">
              <Form.Group className="form-group">
                <div className="form-control-wrap">
                  <div className="form-control-icon end">
                    <Icon name="search"></Icon>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    // className="rounded-pill"
                    id="searchChat"
                    placeholder="Search contacts"
                    value={localSearch}
                    onChange={handleSearch}
                  />
                </div>
              </Form.Group>
            </div>
            <ChatList>
              {loading ? (
                <CircularProgress size={20} thickness={4} />
              ) : (
                <>
                  {chatUserListData.map((item, index) => {
                    return (
                      <ChatList.Item
                        key={item.id}
                        onClick={() => handleChatListToggle(index, item)}
                        className={active === index ? "active" : ""}
                      >
                        <MediaGroup>
                          <Media shape="circle" middle>
                            <Image src={item.avatar ?? defaultPhoto} alt={item.user_id} />
                          </Media>
                          <MediaText>
                            <span className="title">
                              <span>
                                {item.firstName} {item.lastName}
                              </span>
                              <span className="date">
                                {dateConvert(item.last_seen)}
                              </span>
                            </span>
                            <span className="text smaller">
                              <span>{item.description}</span>
                              {item.status && (
                                <Badge bg="success" pill>
                                  {/* {item.status} */}
                                </Badge>
                              )}
                            </span>
                          </MediaText>
                        </MediaGroup>
                      </ChatList.Item>
                    );
                  })}
                </>
              )}
            </ChatList>
          </SimpleBar>
        </div>
        {welcomeLoading ? (
          <>
            <div className={chatBodyClasses} id="chatBody">
              <div className="nk-chat-head">
                <ul className="nk-chat-head-tools me-1 ms-n1 d-md-none">
                  <li>
                    <Button
                      size="sm"
                      variant="zoom"
                      className="btn-icon toggle-close-chat"
                      onClick={handleChatListHide}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </li>
                </ul>
                <MediaGroup>
                  <Media shape="circle" middle>
                    <Image
                      src={selectedChatUser?.avatar ?? defaultPhoto}
                      alt=""
                    />
                  </Media>
                  <MediaText>
                    <span className="title">
                      <span>
                        {selectedChatUser.firstName ?? "System"}{" "}
                        {selectedChatUser.lastName ?? "Admin"}
                      </span>
                    </span>
                    <span className="text smaller">
                      <span>{selectedChatUser?.statusOnline ?? ""}</span>
                    </span>
                  </MediaText>
                </MediaGroup>
                <ul className="nk-chat-head-tools gap gx-md-2 me-n2">
                  {/* call and video is commented for now */}
                  {/* <li>
                                <Button size="md" variant="zoom" className="btn-icon">
                                    <Icon name="call"></Icon>
                                </Button>
                            </li>
                            <li>
                                <Button size="md" variant="zoom" className="btn-icon">
                                    <Icon name="video"></Icon>
                                </Button>
                            </li> */}
                  <li>
                    <Button
                      size="md"
                      variant="zoom"
                      className={`btn-icon toggle-chat-profile ${
                        sidebar ? "active" : "inActive"
                      }`}
                      onClick={handleChatToggle}
                    >
                      <Icon name="info-fill"></Icon>
                    </Button>
                  </li>
                </ul>
              </div>
              <SimpleBar className="nk-chat-panel">
                <div className="nk-message-wrap">
                  {loading2 ? (
                    <CircularProgress size={20} thickness={4} />
                  ) : (
                    <>
                      {messages.map((row) => (
                        <>
                          {row.isYou === "isYou" ? (
                            <Message
                              key={row?.id}
                              avatar={row?.sender_user?.photo ?? defaultPhoto}
                              isYou
                            >
                              <ul key={row?.id} className="nk-message-bubbles">
                                <li key={row?.id}>
                                  <div className="nk-message-bubble">
                                    <div className="nk-message-text">
                                      {row?.message == null
                                        ? row?.messaged
                                        : row?.message}
                                    </div>

                                    <Image src={row?.picture} alt="" />

                                    <ul className="nk-message-meta">
                                      <li>{dateConvert(row?.updated_at)}</li>
                                      <li>{row?.status}</li>
                                    </ul>
                                  </div>
                                  <Message.Actions row={row} />
                                </li>
                              </ul>
                            </Message>
                          ) : (
                            <Message
                              key={row?.id}
                              avatar={row?.sender_user?.photo ?? defaultPhoto}
                              isMe
                            >
                              <ul key={row?.id} className="nk-message-bubbles">
                                <li key={row?.id}>
                                  <div className="nk-message-bubble">
                                    <div className="nk-message-text">
                                      {row?.message == null
                                        ? row?.messaged
                                        : row?.message}
                                    </div>

                                    <Image src={row?.picture} alt="" />

                                    <ul className="nk-message-meta">
                                      <li>{dateConvert(row?.updated_at)}</li>
                                      <li>{row?.status}</li>
                                    </ul>
                                  </div>

                                  {/* <Message.Actions /> */}
                                </li>
                              </ul>
                            </Message>
                          )}
                        </>
                      ))}
                    </>
                  )}
                  <div ref={bottomRef} />
                </div>
              </SimpleBar>
              <div className="nk-chat-form">
                <div className="nk-chat-input">
                  <ul className="nk-chat-input-btns">
                    {/* <li>
                      <Button
                        size="sm"
                        variant="zoom"
                        className="btn-icon text-light"
                      >
                        <Icon name="mic"></Icon>
                      </Button>
                    </li> */}
                    <li>
                      <input
                        className="upload-image"
                        data-target="image-result"
                        id="change-avatar"
                        type="file"
                        max="1"
                        hidden
                        onChange={selectedFile}
                      />
                      <label
                        htmlFor="change-avatar"
                        className="btn-icon text-light"
                      >
                        <Icon name="clip"></Icon>
                      </label>
                    </li>

                    <li className="emoji">
                      {/* <Button
                        size="sm"
                        variant="zoom"
                        className="btn-icon text-light"
                      > */}
                      <BsEmojiSmileFill
                        onClick={handleEmojiPickerHideShow}
                        color="#F2BC17"
                      />
                      {showEmojiPicker && (
                        <Picker onEmojiClick={handleEmojiClick} width="25em" />
                      )}
                      {/* </Button> */}
                    </li>
                  </ul>
                  <Form onSubmit={handleSubmit}>
                    {/* <div className="nk-chat-input-field"> */}
                    {/* <div className="form-control-wrap"> */}
                    {/* <Col className="col-12"> */}
                    <Form.Control
                      id="message"
                      value={message}
                      as="textarea"
                      rows="3"
                      size="lg"
                      type="text"
                      placeholder="Start typing ...."
                      className="form-control-plaintext"
                      required
                      onChange={(e) => setmessage(e.target.value)}
                    />
                    {/* </Col> */}
                    {/* </div> */}
                    {/* </div> */}
                    <div className="nk-chat-input-send">
                      <Button
                        size="sm"
                        variant="zoom"
                        type="submit"
                        className="btn-icon text-light"
                      >
                        <Icon name="send" size="md"></Icon>
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>

              {window.innerWidth < Break.xxl && sidebar && (
                <div onClick={handleChatToggle} className="nk-overlay"></div>
              )}
              <SimpleBar className={chatProfileClasses} data-overlay>
                <div className="nk-aside-content py-4 border-bottom border-light position-relative">
                  <MediaGroup column className="text-center py-2">
                    <Media size="big" shape="circle" middle>
                      <Image src={photo ?? defaultPhoto} alt="" />
                    </Media>
                    <MediaText>
                      <h3 className="mb-1">
                        {firstName} {lastName}
                      </h3>
                      <span className="text smaller">
                        <span>{roleName}</span>
                      </span>
                    </MediaText>
                  </MediaGroup>
                  <Dropdown className="position-absolute top-0 end-0 p-4">
                    <Dropdown.Toggle
                      size="sm"
                      as={CustomDropdownToggle}
                      className="btn btn-sm btn-zoom btn-icon"
                    >
                      <Icon name="more-h"></Icon>
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomDropdownMenu} align="end">
                      <div className="dropdown-content py-1">
                        <ul className="link-list is-compact">
                          <li>
                            <a href="/#">
                              <Icon name="bell-off"></Icon>
                              <span>Mute</span>
                            </a>
                          </li>
                          <li>
                            <a href="/#">
                              <Icon name="user-cross"></Icon>
                              <span>Block</span>
                            </a>
                          </li>
                          <li>
                            <a href="/#">
                              <Icon name="alert"></Icon>
                              <span>Report</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="nk-aside-content py-4">
                  <div className="gap gy-3">
                    <div className="gap-col">
                      <h5 className="title">Options</h5>
                      <ul className="link-list link-list-base-light is-compact">
                        {/* <li>
                                            <a href="/#">
                                                <Icon name="edit"></Icon>
                                                <span>Edit Nickname</span>
                                            </a>
                                        </li> */}
                        <li>
                          <a href="/#">
                            <Icon name="search"></Icon>
                            <span>Search In Conversation</span>
                          </a>
                        </li>
                        {/* <li>
                                            <a href="/#">
                                                <Icon name="color-palette"></Icon>
                                                <span>Change Color</span>
                                            </a>
                                        </li> */}
                        <li>
                          <a href="/#">
                            <Icon name="bell-off"></Icon>
                            <span>Mute Notifications</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* <div className="gap-col">
                                    <h5 className="title">Files</h5>
                                    <ul className="link-list link-list-base-light is-compact">
                                        <li>
                                            <a href="/#">
                                                <Icon name="folder-list"></Icon>
                                                <span>Data_Component.zip</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#">
                                                <Icon name="file-pdf"></Icon>
                                                <span>Checkout_responsive.pdf</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#">
                                                <Icon name="img"></Icon>
                                                <span>Commercial.jpeg</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}
                    {/* <div className="gap-col">
                                    <h5 className="title mb-3">Media</h5>
                                    <ChatMedia className="gap g-2 list4col">
                                        {mediaData.map((item) =>
                                            <ChatMedia.Item key={item.id} src={item.src} icon={item.icon} />
                                        )}
                                    </ChatMedia>
                                </div> */}
                  </div>
                </div>
              </SimpleBar>
            </div>
          </>
        ) : (
          <>
            <WelcomeChat />
          </>
        )}

        <>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            fullWidth
          >
            <div style={{ padding: "1rem 2rem" }}>
              <DialogTitle
                id="responsive-dialog-title"
                style={{ fontSize: "1rem" }}
              >
                Add User to Messaging
              </DialogTitle>
              <Form onSubmit={onSubmitNewUser}>
                <DialogContent>
                  <Form.Group controlId="formFile" className="mb-3">
                    <FormControl fullWidth>
                      {/* <InputLabel id="demo-simple-select-label">
                        User *
                      </InputLabel> */}

                      <Autocomplete
                        fullWidth
                        disablePortal
                        id="combo-box-demo"
                        value={user_name}
                        required
                        onChange={(event, newValue) => {
                          handleNameChange(newValue);
                        }}
                        options={userData.map(
                          (option) =>
                            option.first_name +
                            "-" +
                            option.last_name +
                            "---" +
                            option.email
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="User"
                            placeholder=""
                          />
                        )}
                      />

                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </FormControl>
                  </Form.Group>
                </DialogContent>
                <DialogActions
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    className={classes.item}
                    autoFocus
                    onClick={handleClose}
                    color="error"
                  >
                    Cancel
                  </Button>
                  <Button
                    autoFocus
                    variant="contained"
                    type="submit"
                    size="large"
                    className={classes.item}
                  >
                    Add
                  </Button>
                </DialogActions>
              </Form>
            </div>
          </Dialog>
        </>
      </div>
    </Layout>
  );
}

export default ChatsPage;
