import { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AWS from "aws-sdk";
import { Card, Form, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../layout/default";
import Avatar from "@mui/material/Avatar";
import Block from "../../components/Block/Block";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from "@mui/material/useMediaQuery";
// import Stack from "@mui/material/Stack";
//import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
//import dayjs from "dayjs";
import { successMessage, failedMessage } from "../utilities/alert-config";
//import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
//import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import //Select,
//Quill,
//FileUpload,
//Tags,
//ImageUpload,
//DatePicker,
// Tinymce,
"../../components";
import {
  rootURL,
  awsID,
  awsBucketName,
  awsSECRET,
} from "../utilities/axios-config";
// import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const Input = styled("input")({
//   display: "none",
// });

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    fontWeight: "bold",
  },
  item: {
    padding: 5,
  },
  backgroundColor: {
    backgroundColor: "#2CB23B",
  },
}));

function RadioAdd() {
  const history = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [name, setname] = useState("");
  const [author, setauthor] = useState("");
  // const [radio_type_id, setradio_type_id] = useState("");
  const [radioTypes, setradioTypes] = useState([]);
  const [image, setimage] = useState("/images/avatar/avatar-placeholder.jpg");
  const [description, setdescription] = useState("");
  const [type, settype] = useState("");
  const [link, setlink] = useState("");
  const [radio_category, setradio_category] = useState([]);
  const [p_cast, setp_cast] = useState([]);
  const [country, setcountry] = useState([]);
  const [content_type, setcontent_type] = useState("");
  const [post_state_id, setpost_state_id] = useState("3");
  const [persons, setpersons] = useState([]);
  const [categoryData, setcategoryData] = useState([]);
  const [poststatus, setpoststatus] = useState([]);
  const contents = ["Paid", "Free"];
  const audioTypes = ["youtube", "mp3", "mp4", "other"];
  const musicType = ["Single", "Multiple"];
  const [musicListData, setmusicListData] = useState([]);
  const [musictype, setmusictype] = useState("Single");
  const [postnotification, setpostnotification] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [scheduledate, setscheduledate] = useState("");
  const [audiodate, setaudiodate] = useState("");
  const [schstatus, setschstatus] = useState(false);
  const [radio_new_type, setradio_new_type] = useState([]);

  //const today = new Date().toISOString().slice(0, 10);

  const [loading2, setloading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [loading6, setLoading6] = useState(false);
  const [loading7, setLoading7] = useState(false);
  const [loading8, setLoading8] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progress2, setProgress2] = useState(0);
  const [progress3, setProgress3] = useState(0);
  const [progress4, setProgress4] = useState(0);
  const [progress5, setProgress5] = useState(0);
  const [progress6, setProgress6] = useState(0);
  const [listtitle, setlisttitle] = useState("");
  const [listauthor, setlistauthor] = useState("");
  const [listimage, setlistimage] = useState("");
  const [listlink, setlistlink] = useState("");
  const [indexValue, setIndexValue] = useState("");

  const roleId = localStorage.getItem("role");

  function create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxx4xxyxx".replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    " Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Côte d'Ivoire",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    " Congo(Congo - Brazzaville)",
    " Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    " Czechia(Czech Republic)",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    " Equatorial Guinea",
    "Eritrea",
    "Estonia",
    " Eswatini(fmr. 'Swaziland')",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea - Bissau",
    "Guyana",
    "Haiti",
    " Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar(formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    " New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    " North Korea",
    " North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    " Saint Kitts and Nevis",
    "Saint Lucia",
    " Saint Vincent and the Grenadines",
    "Samoa",
    " San Marino",
    "Sao Tome and Principe",
    " Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    " Solomon Islands",
    "Somalia",
    "  South Africa",
    " South Korea",
    "South Sudan",
    "Spain",
    " Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    " Timor - Leste",
    "Togo",
    "Tonga",
    " Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    " United Kingdom",
    " United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const tableStatus = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/poststatus`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setpoststatus(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableActors = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/actorsdata`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setpersons(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableRadioType = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/all_radiostype_cpanel`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setradioTypes(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableRadioCategory = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/all_radioscategory_cpanel`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setcategoryData(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    tableStatus();
    tableActors();
    tableRadioType();
    tableRadioCategory();
  }, []);

  const handleStatus = (event) => {
    console.log("event", event.target.value);
    setpost_state_id(event.target.value);
    setschstatus(false);
    if (event.target.value === 4) {
      setschstatus(true);
    }
  };

  // const handleTypes = (event) => {
  //   setradio_type_id(event.target.value);
  // };
  const handleMusicTypes = (event) => {
    setmusictype(event.target.value);
  };

  const handleAudioTypes = (event) => {
    settype(event.target.value);
  };

  const handleContentType = (event) => {
    setcontent_type(event.target.value);
  };

  const handleRadioChange = (event) => {
    setradio_category(event);
  };


  const handleAudioRowChange = (event) => {
    // console.log("event", event)
    setradio_new_type(event);
  };


  const handlePersonsChange = (event) => {
    setp_cast(event);
  };

  const handleCountryChange = (event) => {
    setcountry(event);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const onSubmitAudio = (event) => {
    event.preventDefault();
    setmusicListData([
      ...musicListData,
      {
        listtitle,
        listauthor,
        listimage,
        listlink,
      },
    ]);
    handleClose();
  };

  const onSubmitAudioEdit = (event) => {
    event.preventDefault();
    const newList = Object.assign([], musicListData);
    const data = { ...newList[indexValue] };

    data.listtitle = listtitle ?? "";
    data.listauthor = listauthor;
    data.listimage = listimage ?? "";
    data.listlink = listlink ?? "";
    newList[indexValue] = data;
    setmusicListData(newList);
    handleClose2();
  };

  const handleEditClick = (row, index) => {
    // console.log("log", row)
    setlisttitle(row?.listtitle);
    setlistauthor(row?.listauthor);
    setlistimage(row?.listimage);
    setlistlink(row?.listlink);
    handleClickOpen2();
    setIndexValue(index);
  };

  const handleDeleteClick = (row, index) => {
    const newList = Object.assign([], musicListData);
    newList.splice(index, 1);
    setmusicListData(newList);
  };

  const selectedFileAudio = (e) => {
    const audio = e.target.files[0];
    if (audio) {
      setLoading4(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + audio.name, // File name you want to save as in S3
        Body: audio,
        ContentType: audio.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading4(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setlink(photoUrl);
        setLoading4(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress2) {
        let uploaded = Math.round((progress2.loaded / progress2.total) * 100);
        setProgress2(uploaded);
      });
    }
  };

  const selectedFile = (e) => {
    const image = e.target.files[0];
    if (image) {
      setLoading3(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + image.name, // File name you want to save as in S3
        Body: image,
        ContentType: image.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading3(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setimage(photoUrl);
        setLoading3(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress) {
        let uploaded = Math.round((progress.loaded / progress.total) * 100);
        setProgress(uploaded);
      });
    }
  };

  const selectedListImage = (e) => {
    const image = e.target.files[0];
    if (image) {
      setLoading5(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + image.name, // File name you want to save as in S3
        Body: image,
        ContentType: image.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading5(false);
        }

        let photoUrl = data.Location;
        setlistimage(photoUrl);
        setLoading5(false);
      }).on("httpUploadProgress", function (progress3) {
        let uploaded = Math.round((progress3.loaded / progress3.total) * 100);
        setProgress3(uploaded);
      });
    }
  };

  const selectedListImage2 = (e) => {
    const image = e.target.files[0];
    if (image) {
      setLoading7(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + image.name, // File name you want to save as in S3
        Body: image,
        ContentType: image.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading7(false);
        }

        let photoUrl = data.Location;
        setlistimage(photoUrl);
        setLoading7(false);
      }).on("httpUploadProgress", function (progress5) {
        let uploaded = Math.round((progress5.loaded / progress5.total) * 100);
        setProgress5(uploaded);
      });
    }
  };

  const selectedListLink = (e) => {
    const audio = e.target.files[0];
    if (audio) {
      setLoading6(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + audio.name, // File name you want to save as in S3
        Body: audio,
        ContentType: audio.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading6(false);
        }

        let photoUrl = data.Location;
        setlistlink(photoUrl);
        setLoading6(false);
      }).on("httpUploadProgress", function (progress4) {
        let uploaded = Math.round((progress4.loaded / progress4.total) * 100);
        setProgress4(uploaded);
      });
    }
  };

  const selectedListLink2 = (e) => {
    const audio = e.target.files[0];
    if (audio) {
      setLoading8(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + audio.name, // File name you want to save as in S3
        Body: audio,
        ContentType: audio.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading8(false);
        }

        let photoUrl = data.Location;
        setlistlink(photoUrl);
        setLoading8(false);
      }).on("httpUploadProgress", function (progress6) {
        let uploaded = Math.round((progress6.loaded / progress6.total) * 100);
        setProgress6(uploaded);
      });
    }
  };

  function onDragEndAudio(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const answer = Array.from(musicListData);
    const [removed] = answer.splice(result.source.index, 1);
    answer.splice(result.destination.index, 0, removed);

    setmusicListData(answer);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userToken = localStorage.getItem("userToken");
    setloading2(true);
    const radioObj = {
      name,
      image,
      author,
      link,
      description,
      post_state_id,
      type,
      content_type,
      radio_category,
      p_cast,
      country,
      postnotification,
      musictype,
      musicListData,
      scheduledate,
      audiodate,
      radio_new_type
    };
    // console.log("radioObj", radioObj);

    fetch(`${rootURL}/addradiocpanel`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(radioObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");

          setTimeout(() => {
            history("/radio-manage/radio-list");
          }, 3000);
        } else if (result.success === false) {
          setloading2(false);
          failedMessage(result.message);
        }
      })
      .catch((error) => {
        setloading2(false);
        failedMessage("Internal Server Error");
      })
      .finally(() => {
        setloading2(false);
      });
  };

  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress size={70} thickness={4} />
          </div>
        </>
      ) : (
        <>
          <Layout title="Add Radio" content="container">
            <Block.Head>
              <Block.HeadBetween>
                <Block.HeadContent>
                  <Block.Title tag="h2">Add Audio</Block.Title>
                  <nav>
                    <ol className="breadcrumb breadcrumb-arrow mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/radio-manage/radio-list">Audio</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Add Audio
                      </li>
                    </ol>
                  </nav>
                </Block.HeadContent>
                {/* <Block.HeadContent>
                    <ul className="d-flex">
                        <li>
                            <Link to="/ecommerce/products" className="btn btn-primary btn-md d-md-none">
                                <Icon name="eye"/>
                                <span>View</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/ecommerce/products" className="btn btn-primary d-none d-md-inline-flex">
                                <Icon name="eye"/>
                                <span>View Products</span>
                            </Link>
                        </li>
                    </ul>
                </Block.HeadContent> */}
              </Block.HeadBetween>
            </Block.Head>

            <Block>
              <Form onSubmit={handleSubmit}>
                <Row className="g-gs">
                  <Col xxl="9">
                    <div className="gap gy-4">
                      <div className="gap-col">
                        <Card className="card-gutter-md">
                          <Card.Body>
                            <Row className="g-gs">
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label>Cover</Form.Label>
                                  <div className="form-control-wrap">
                                    <div className="image-upload-wrap d-flex flex-column align-items-center">
                                      {loading3 ? (
                                        <div>Upload progress {progress}%</div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="media media-huge border">
                                        <img
                                          id="image-result"
                                          className="w-100 h-100"
                                          src={image}
                                          alt="avatar"
                                        />
                                      </div>
                                      <div className="pt-3">
                                        <input
                                          className="upload-image"
                                          data-target="image-result"
                                          id="change-avatar"
                                          type="file"
                                          max="1"
                                          hidden
                                          onChange={selectedFile}
                                        />
                                        <label
                                          htmlFor="change-avatar"
                                          className="btn btn-md btn-primary"
                                          disabled={loading3}
                                        >
                                          {loading3
                                            ? "Uploading ..."
                                            : "Upload"}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-note mt-3">
                                    Set the article thumbnail image. Only *.png,
                                    *.jpg and *.jpeg image files are accepted.
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="audiodate">
                                    Audio Date
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <input
                                      type="date"
                                      id="audiodate"
                                      name="audiodate"
                                      value={audiodate}
                                      onChange={(newValue) => {
                                        setaudiodate(newValue.target.value);
                                      }}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="productname">
                                    Title *
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="name"
                                      placeholder="Title"
                                      value={name}
                                      required
                                      onChange={(e) => setname(e.target.value)}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="productname">
                                    Author *
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="author"
                                      placeholder="Author"
                                      value={author}
                                      required
                                      onChange={(e) =>
                                        setauthor(e.target.value)
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="baseprice">
                                    Audio Row Types *
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Autocomplete
                                      multiple
                                      fullWidth
                                      id="checkboxes-tags-demo"
                                      value={radio_new_type}
                                      options={radioTypes}
                                      required
                                      onChange={(event, newValue) => {
                                        handleAudioRowChange(newValue);
                                      }}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) => option?.name}
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option?.name}
                                        </li>
                                      )}
                                      isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Audio Row Types"
                                          placeholder="Select Audio Row Types"
                                        />
                                      )}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              {/* <Col lg="12">
                                <div className="form-control-wrap">
                                  <FormControl fullWidth size="small">
                                    <Form.Label htmlFor="status_id">
                                      Row Type *
                                    </Form.Label>
                                    <Select
                                      labelId="demo-select-small"
                                      id="demo-simple-select"
                                      value={radio_type_id ?? ""}
                                      label="row type"
                                      onChange={handleTypes}
                                      required
                                    >
                                      {radioTypes.map((types) => (
                                        <MenuItem
                                          key={types.id}
                                          value={types.id}
                                        >
                                          {loading === false ? types.name : ""}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </Col> */}

                              <Col lg="12">
                                <div className="form-control-wrap">
                                  <FormControl fullWidth size="small">
                                    <Form.Label htmlFor="status_id">
                                      Music Type *
                                    </Form.Label>
                                    <Select
                                      labelId="demo-select-small"
                                      id="demo-simple-select"
                                      value={musictype ?? ""}
                                      label="Music type"
                                      onChange={handleMusicTypes}
                                      required
                                    >
                                      {musicType.map((types) => (
                                        <MenuItem key={types} value={types}>
                                          {types ?? ""}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </Col>

                              {musictype === "Single" ? (
                                <>
                                  <Col lg="12">
                                    <Form.Group className="form-group">
                                      <Form.Label htmlFor="productname">
                                        Link
                                      </Form.Label>
                                      <div className="form-control-wrap">
                                        <Form.Control
                                          type="text"
                                          id="link"
                                          placeholder="Link"
                                          value={link}
                                          onChange={(e) =>
                                            setlink(e.target.value)
                                          }
                                        />
                                      </div>
                                    </Form.Group>
                                  </Col>

                                  <Col lg="12">
                                    <Form.Group className="form-group">
                                      <Form.Label>Radio Audio</Form.Label>
                                      <div className="form-control-wrap">
                                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                                          {loading4 ? (
                                            <div>
                                              Upload progress {progress2}%
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                       
                                          <div className="pt-3">
                                            <input
                                              className="upload-image"
                                              data-target="image-result2"
                                              id="change-avatar2"
                                              type="file"
                                              max="1"
                                              hidden
                                              onChange={selectedFileAudio}
                                            />
                                            <label
                                              htmlFor="change-avatar2"
                                              className="btn btn-md btn-primary"
                                              disabled={loading4}
                                            >
                                              {loading4
                                                ? "Uploading ..."
                                                : "Upload"}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-note mt-3">
                                        Set the audio.
                                      </div>
                                    </Form.Group>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  {/* multiple */}

                                  <Col lg="12">
                                    <Box
                                      display="flex"
                                      flexDirection="row-reverse"
                                      justifyContent="space-between"
                                    >
                                      <Button
                                        variant="contained"
                                        onClick={handleClickOpen}
                                        size="sm"
                                        className={classes.item}
                                      >
                                        Add Audio
                                      </Button>
                                    </Box>

                                    <br />
                                    <Grid
                                      container
                                      spacing={4}
                                      justify="flex-start"
                                    >
                                      <Grid item xs={12} sm={6} md={4} lg={12}>
                                        <TableContainer component={Paper}>
                                          <Table aria-label="caption table">
                                            <TableHead>
                                              <TableRow>
                                                <TableCell
                                                  align="left"
                                                  className={
                                                    classes.tableHeaderCell
                                                  }
                                                >
                                                  #
                                                </TableCell>

                                                <TableCell
                                                  align="left"
                                                  className={
                                                    classes.tableHeaderCell
                                                  }
                                                >
                                                  Title
                                                </TableCell>
                                                <TableCell
                                                  align="left"
                                                  className={
                                                    classes.tableHeaderCell
                                                  }
                                                >
                                                  Author
                                                </TableCell>

                                                <TableCell
                                                  align="left"
                                                  className={
                                                    classes.tableHeaderCell
                                                  }
                                                >
                                                  Image
                                                </TableCell>

                                                <TableCell
                                                  align="left"
                                                  className={
                                                    classes.tableHeaderCell
                                                  }
                                                >
                                                  Link
                                                </TableCell>
                                                <TableCell
                                                  align="left"
                                                  className={
                                                    classes.tableHeaderCell
                                                  }
                                                >
                                                  Actions
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <DragDropContext
                                              onDragEnd={onDragEndAudio}
                                            >
                                              <Droppable droppableId="AudioOne">
                                                {(provided, snapshot) => (
                                                  <TableBody
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    style={{
                                                      backgroundColor:
                                                        snapshot.isDraggingOver
                                                          ? "white"
                                                          : "white",
                                                    }}
                                                  >
                                                    {musicListData.map(
                                                      (row, index) => (
                                                        <Draggable
                                                          draggableId={(
                                                            index + 1
                                                          ).toString()}
                                                          key={index + 1}
                                                          index={index}
                                                        >
                                                          {(provided) => (
                                                            <TableRow
                                                              key={index + 1}
                                                              sx={{
                                                                "&:last-child td, &:last-child th":
                                                                  {
                                                                    border: 0,
                                                                  },
                                                              }}
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                            >
                                                              <TableCell
                                                                component="th"
                                                                scope="row"
                                                              >
                                                                {index + 1}
                                                              </TableCell>
                                                              <TableCell
                                                                component="th"
                                                                scope="row"
                                                              >
                                                                {row?.listtitle ??
                                                                  ""}
                                                              </TableCell>
                                                              <TableCell
                                                                component="th"
                                                                scope="row"
                                                              >
                                                                {row?.listauthor ??
                                                                  ""}
                                                              </TableCell>
                                                              <TableCell
                                                                component="th"
                                                                scope="row"
                                                              >
                                                                <Avatar
                                                                  alt={
                                                                    row?.listimage ??
                                                                    "audio image"
                                                                  }
                                                                  src={
                                                                    row?.listimage ??
                                                                    ""
                                                                  }
                                                                  sx={{
                                                                    width: 36,
                                                                    height: 36,
                                                                  }}
                                                                />
                                                              </TableCell>

                                                              <TableCell
                                                                component="th"
                                                                scope="row"
                                                              >
                                                                {row?.listlink ??
                                                                  ""}
                                                              </TableCell>

                                                              <TableCell
                                                                component="th"
                                                                scope="row"
                                                              >
                                                                <IconButton
                                                                  aria-label="edit"
                                                                  color="primary"
                                                                  onClick={() =>
                                                                    handleEditClick(
                                                                      row,
                                                                      index
                                                                    )
                                                                  }
                                                                >
                                                                  <EditIcon />
                                                                </IconButton>

                                                                <IconButton
                                                                  aria-label="delete"
                                                                  color="error"
                                                                  onClick={() =>
                                                                    handleDeleteClick(
                                                                      row,
                                                                      index
                                                                    )
                                                                  }
                                                                >
                                                                  <DeleteIcon />
                                                                </IconButton>
                                                              </TableCell>
                                                            </TableRow>
                                                          )}
                                                        </Draggable>
                                                      )
                                                    )}
                                                    {provided.placeholder}
                                                  </TableBody>
                                                )}
                                              </Droppable>
                                            </DragDropContext>
                                          </Table>
                                        </TableContainer>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                </>
                              )}

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label>Description *</Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      as="textarea"
                                      rows="3"
                                      value={description}
                                      placeholder=""
                                      required
                                      onChange={(e) =>
                                        setdescription(e.target.value)
                                      }
                                    ></Form.Control>
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="6">
                                <div className="form-control-wrap">
                                  <FormControl fullWidth size="small">
                                    <Form.Label htmlFor="status_id">
                                      Audio Type *
                                    </Form.Label>
                                    <Select
                                      labelId="demo-select-small"
                                      id="demo-simple-select"
                                      value={type ?? ""}
                                      label="Audio type"
                                      onChange={handleAudioTypes}
                                      required
                                    >
                                      {audioTypes.map((types) => (
                                        <MenuItem key={types} value={types}>
                                          {loading === false ? types : ""}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </Col>

                              <Col lg="6">
                                <div className="form-control-wrap">
                                  <FormControl fullWidth size="small">
                                    <Form.Label htmlFor="content_type">
                                      Content Type
                                    </Form.Label>
                                    <Select
                                      labelId="demo-select-small"
                                      id="demo-simple-select"
                                      value={content_type ?? ""}
                                      label="Status"
                                      onChange={handleContentType}
                                    >
                                      {contents.map((type) => (
                                        <MenuItem key={type} value={type}>
                                          {loading === false ? type : ""}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="baseprice">
                                    Category
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Autocomplete
                                      multiple
                                      fullWidth
                                      id="checkboxes-tags-demo"
                                      value={radio_category}
                                      options={categoryData}
                                      onChange={(event, newValue) => {
                                        handleRadioChange(newValue);
                                      }}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) => option?.name}
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option?.name}
                                        </li>
                                      )}
                                      isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Category"
                                          placeholder="Select Category"
                                        />
                                      )}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              {roleId === "3" ? (
                                <Col lg="12">
                                  <Form.Group className="form-group">
                                    <Form.Label htmlFor="baseprice">
                                      Celebrity Profile
                                    </Form.Label>
                                    <div className="form-control-wrap">
                                      <Autocomplete
                                        multiple
                                        fullWidth
                                        id="checkboxes-tags-demo"
                                        value={p_cast}
                                        options={persons}
                                        onChange={(event, newValue) => {
                                          handlePersonsChange(newValue);
                                        }}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) =>
                                          option?.name
                                        }
                                        renderOption={(
                                          props,
                                          option,
                                          { selected }
                                        ) => (
                                          <li {...props}>
                                            <Checkbox
                                              icon={icon}
                                              checkedIcon={checkedIcon}
                                              style={{ marginRight: 8 }}
                                              checked={selected}
                                            />
                                            {option?.name}
                                          </li>
                                        )}
                                        isOptionEqualToValue={(option, value) =>
                                          option.id === value.id
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Celebrity Profile"
                                            placeholder="Select Celebrity Profile"
                                          />
                                        )}
                                      />
                                    </div>
                                  </Form.Group>
                                </Col>
                              ) : (
                                ""
                              )}

                              {roleId === "3" ? (
                                <Col lg="12">
                                  <Form.Group className="form-group">
                                    <Form.Label htmlFor="baseprice">
                                      Country
                                    </Form.Label>
                                    <div className="form-control-wrap">
                                      <Autocomplete
                                        multiple
                                        fullWidth
                                        id="checkboxes-tags-demo"
                                        value={country}
                                        options={countries}
                                        onChange={(event, newValue) => {
                                          handleCountryChange(newValue);
                                        }}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option}
                                        renderOption={(
                                          props,
                                          option,
                                          { selected }
                                        ) => (
                                          <li {...props}>
                                            <Checkbox
                                              icon={icon}
                                              checkedIcon={checkedIcon}
                                              style={{ marginRight: 8 }}
                                              checked={selected}
                                            />
                                            {option}
                                          </li>
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Country"
                                            placeholder="Select Country"
                                          />
                                        )}
                                      />
                                    </div>
                                  </Form.Group>
                                </Col>
                              ) : (
                                ""
                              )}

                              {roleId === "3" ? (
                                <Col lg="6">
                                  <FormControlLabel
                                    label="POST NOTIFICATION TO THE APP"
                                    control={
                                      <Checkbox
                                        checked={postnotification}
                                        onChange={(e) => {
                                          setpostnotification(e.target.checked);
                                        }}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                      />
                                    }
                                  />
                                </Col>
                              ) : (
                                ""
                              )}

                              {roleId === "3" ? (
                                <Col lg="12">
                                  <div className="form-control-wrap">
                                    <FormControl fullWidth size="small">
                                      <Form.Label htmlFor="status_id">
                                        Status *
                                      </Form.Label>
                                      <Select
                                        labelId="demo-select-small"
                                        id="demo-simple-select"
                                        value={post_state_id ?? ""}
                                        label="Status"
                                        onChange={handleStatus}
                                        required
                                      >
                                        {poststatus.map((status) => (
                                          <MenuItem
                                            key={status.id}
                                            value={status.id}
                                          >
                                            {loading === false
                                              ? status.name
                                              : ""}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {schstatus ? (
                                <Col lg="6">
                                  <Form.Group className="form-group">
                                    <Form.Label htmlFor="scheduledate">
                                      Schedule Date
                                    </Form.Label>
                                    <div className="form-control-wrap">
                                      <input
                                        type="date"
                                        id="scheduledate"
                                        name="scheduledate"
                                        value={scheduledate}
                                        onChange={(newValue) => {
                                          setscheduledate(
                                            newValue.target.value
                                          );
                                        }}
                                      />
                                    </div>
                                  </Form.Group>
                                </Col>
                              ) : (
                                ""
                              )}
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>

                      <div className="gap-col">
                        <ul className="d-flex align-items-center gap g-3">
                          <li>
                            <Button type="submit" variant="contained">
                              {loading2 ? "Saving...." : "Save"}
                            </Button>
                          </li>
                          <li>
                            <Link
                              to="/radio-manage/radio-list"
                              className="btn border-0"
                            >
                              Cancel
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  {/* <Col xxl="3">
                    <Card className="card-gutter-md">
                      <Card.Body>
                        <Row className="g-gs">
                          <Col className="col-12">
                           
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col> */}
                </Row>
              </Form>

              <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClickOpen}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{ fontSize: "1rem" }}
                  >
                    Add Audio
                  </DialogTitle>
                  <Form onSubmit={onSubmitAudio}>
                    <DialogContent>
                      <Form.Group controlId="formFile" className="mb-3">
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="listtitle"
                          variant="outlined"
                          required
                          id="listtitle"
                          label="Title"
                          value={listtitle}
                          onChange={(e) => setlisttitle(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="listauthor"
                          variant="outlined"
                          required
                          id="listauthor"
                          label="Author"
                          value={listauthor}
                          onChange={(e) => setlistauthor(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <div>Image *</div>
                          {loading5 ? (
                            <div>Upload progress {progress3}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <img
                              id="image-result2add"
                              className="w-100 h-100"
                              src={listimage}
                              alt="avatar"
                            />
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result2add"
                              id="change-avatar2add"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedListImage}
                            />
                            <label
                              htmlFor="change-avatar2add"
                              className="btn btn-md btn-primary"
                              disabled={loading5}
                            >
                              {loading5 ? "Uploading ..." : "Upload"}
                            </label>
                          </div>
                        </div>
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="listlink"
                          variant="outlined"
                          id="listlink"
                          label="Audio Link"
                          value={listlink}
                          onChange={(e) => setlistlink(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <center>OR</center>
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <br />
                          <div>Audio</div>
                          {loading6 ? (
                            <div>Upload progress {progress4}%</div>
                          ) : (
                            ""
                          )}
                          {/* <div className="media media-huge border">
                            <div className="player-wrapper">
                              <Player>
                                <source src={video_url2} />
                              </Player>
                            </div>
                          </div> */}
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result2addvideo"
                              id="change-avatar2addvideo"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedListLink}
                            />
                            <label
                              htmlFor="change-avatar2addvideo"
                              className="btn btn-md btn-primary"
                              disabled={loading6}
                            >
                              {loading6 ? "Uploading ..." : "UploadAudio"}
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.item}
                        autoFocus
                        onClick={handleClose}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.item}
                      >
                        Add
                      </Button>
                    </DialogActions>
                  </Form>
                </div>
              </Dialog>

              {/* Edit Radio */}

              <Dialog
                fullScreen={fullScreen}
                open={open2}
                onClose={handleClickOpen2}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{ fontSize: "1rem" }}
                  >
                    Edit Audio
                  </DialogTitle>
                  <Form onSubmit={onSubmitAudioEdit}>
                    <DialogContent>
                      <Form.Group controlId="formFile" className="mb-3">
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="listtitle"
                          variant="outlined"
                          required
                          id="listtitle"
                          label="Title"
                          value={listtitle}
                          onChange={(e) => setlisttitle(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="listauthor"
                          variant="outlined"
                          required
                          id="listauthor"
                          label="Author"
                          value={listauthor}
                          onChange={(e) => setlistauthor(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <div>Image *</div>
                          {loading7 ? (
                            <div>Upload progress {progress5}%</div>
                          ) : (
                            ""
                          )}
                          <div className="media media-huge border">
                            <img
                              id="image-result3add"
                              className="w-100 h-100"
                              src={listimage}
                              alt="avatar"
                            />
                          </div>
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result3add"
                              id="change-avatar3add"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedListImage2}
                            />
                            <label
                              htmlFor="change-avatar3add"
                              className="btn btn-md btn-primary"
                              disabled={loading7}
                            >
                              {loading7 ? "Uploading ..." : "Upload"}
                            </label>
                          </div>
                        </div>
                        <br /> <br />
                        <TextField
                          autoComplete="off"
                          type="text"
                          name="listlink"
                          variant="outlined"
                          id="listlink"
                          label="Audio Link"
                          value={listlink}
                          onChange={(e) => setlistlink(e.target.value)}
                          fullWidth
                        />
                        <br /> <br />
                        <center>OR</center>
                        <br /> <br />
                        <div className="image-upload-wrap d-flex flex-column align-items-center">
                          <br />
                          <div>Audio</div>
                          {loading8 ? (
                            <div>Upload progress {progress6}%</div>
                          ) : (
                            ""
                          )}
                          {/* <div className="media media-huge border">
                            <div className="player-wrapper">
                              <Player>
                                <source src={video_url2} />
                              </Player>
                            </div>
                          </div> */}
                          <div className="pt-3">
                            <input
                              className="upload-image"
                              data-target="image-result4addvideo"
                              id="change-avatar4addvideo"
                              type="file"
                              max="1"
                              hidden
                              onChange={selectedListLink2}
                            />
                            <label
                              htmlFor="change-avatar4addvideo"
                              className="btn btn-md btn-primary"
                              disabled={loading8}
                            >
                              {loading8 ? "Uploading ..." : "UploadAudio"}
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.item}
                        autoFocus
                        onClick={handleClose2}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        size="large"
                        className={classes.item}
                      >
                        Update
                      </Button>
                    </DialogActions>
                  </Form>
                </div>
              </Dialog>
            </Block>
          </Layout>
        </>
      )}
    </>
  );
}

export default RadioAdd;
