import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon } from "../../components";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
// import FormControl from "@mui/material/FormControl";
import { rootURL } from "../utilities/axios-config";
import { successMessage, failedMessage } from "../utilities/alert-config";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

function AddWebLandingPage() {
  const history = useNavigate();
  const [user_id, setuser_id] = useState("");
  const [user_name, setuser_name] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [userData, setuserData] = useState([]);

  // const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const tableRecords = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/all_assuser_cpanel`;

      //setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            //setItems(row?.data);
            setuserData(row?.data);
            // successMessage(row?.message);
            //setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            //setLoading(false);
          }
        })
        .finally(() => {
          // setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleNameChange = (event) => {
    setuser_name(event);
    // console.log("event", event);

    const newFilter = userData.filter((value) =>
      (value?.first_name + "-" + value?.last_name + "---" + value?.email || "")
        .toLowerCase()
        .includes((event || "").toLowerCase())
    );
    newFilter.forEach((option) => {
      if (
        option?.first_name + "-" + option?.last_name + "---" + option?.email ===
        event
      ) {
        // console.log("event", option?.id);
        setuser_id(option?.id);
        setfirstName(option?.first_name);
        setlastName(option?.last_name);
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userToken = localStorage.getItem("userToken");
    setLoading2(true);
    const tagObj = {
      user_id,
      firstName,
      lastName,
    };

    fetch(`${rootURL}/add_assignee_cpanel`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tagObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");
          //   console.log("data", result)
          setTimeout(() => {
            history(`/assignee-manage/assignee-list`);
          }, 3000);
        } else if (result.success === false) {
          setLoading2(false);
          failedMessage(result.message);
        }
      })
      .catch((error) => {
        setLoading2(false);
        failedMessage("Internal Server Error");
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  // grabs the id form the url and loads the corresponding data
  useEffect(() => {
    tableRecords();
  }, []);

  return (
    <Layout title="Add Assignee" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            {/* <div className="d-flex flex-column flex-md-row align-items-md-center">
                        <Media size="huge" shape="circle" variant={theme && theme}>
                            {
                                <Image src={avatar} staticImage thumbnail alt="user"/> 
                              
                            }
                        </Media>
                        <div className="mt-3 mt-md-0 ms-md-3">
                            <h3 className="title mb-1">{user.name}</h3>
                            <span className="small">{user.role}</span>
                            <ul className="nk-list-option pt-1">
                                <li><Icon name="map-pin"></Icon><span className="small">{user.address}</span></li>
                                <li><Icon name="building"></Icon><span className="small">{user.company}</span></li>
                            </ul>
                        </div>
                    </div> */}
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex gap g-2">
              <li className="d-none d-md-block">
                <Link
                  to={`/assignee-manage/assignee-manage`}
                  className="btn btn-soft btn-primary"
                >
                  <Icon name="user"></Icon>
                  <span>Assignee List</span>
                </Link>
              </li>
              <li className="d-md-none">
                <Link
                  to={`/assignee-manage/assignee-list`}
                  className="btn btn-soft btn-primary btn-icon"
                >
                  <Icon name="user"></Icon>
                </Link>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">Add Assignee</h4>
              <Form onSubmit={handleSubmit}>
                <Row className="g-3">
                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="user_name">User *</Form.Label>
                      <div className="form-control-wrap">
                        <Autocomplete
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          value={user_name}
                          required
                          onChange={(event, newValue) => {
                            handleNameChange(newValue);
                          }}
                          options={userData.map(
                            (option) =>
                              option.first_name +
                              "-" +
                              option.last_name +
                              "---" +
                              option.email
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="User"
                              placeholder="Select User"
                            />
                          )}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="firstName">First Name *</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="firstName"
                          value={firstName}
                          placeholder="firstName"
                          required
                          onChange={(e) => setfirstName(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="lastName">Last Name *</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="lastName"
                          value={lastName}
                          placeholder="lastName"
                          required
                          onChange={(e) => setlastName(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {loading2 ? "Saving...." : "Save"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  );
}

export default AddWebLandingPage;
