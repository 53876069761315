import { useState, useEffect } from "react";
import AWS from "aws-sdk";
import { Card, Form, Row, Col } from "react-bootstrap";
import { Link, useNavigate,useParams } from "react-router-dom";
import Layout from "../../layout/default";

import Block from "../../components/Block/Block";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
// import Stack from "@mui/material/Stack";
//import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
//import dayjs from "dayjs";
import { successMessage, failedMessage } from "../utilities/alert-config";
//import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
//import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import //Select,
//Quill,
//FileUpload,
//Tags,
//ImageUpload,
//DatePicker,
// Tinymce,
"../../components";
import {
  rootURL,
  awsID,
  awsBucketName,
  awsSECRET,
} from "../utilities/axios-config";
// import Checkbox from "@mui/material/Checkbox";
 import TextField from "@mui/material/TextField";
 import Autocomplete from "@mui/material/Autocomplete";
 import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const Input = styled("input")({
//   display: "none",
// });

function LiveTVEdit() {
  const { id } = useParams();
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [name, setname] = useState("");
  const [image, setimage] = useState("/images/avatar/avatar-placeholder.jpg");
  const [description, setdescription] = useState("");
  const [type, settype] = useState("");
  const [link, setlink] = useState("");
  const [country, setcountry] = useState([]);
  const [content_type, setcontent_type] = useState("");
  const [post_state_id, setpost_state_id] = useState("");
  const [poststatus, setpoststatus] = useState([]);
  const contents = ["Paid", "Free"];
  const audioTypes = ["youtube", "mp4", "m3u8"];
  const [postnotification, setpostnotification] = useState(false);
  const [scheduledate, setscheduledate] = useState("");
  const [schstatus, setschstatus] = useState(false);
  const [category, setcategory] = useState([]);
  const [categData, setcategData] = useState([]);

  //const today = new Date().toISOString().slice(0, 10);

  const [loading2, setloading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progress2, setProgress2] = useState(0);

  const tableRadioShow = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/livetvshowcpanel/${id}`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setname(row?.data?.name ?? "");
            setimage(row?.data?.image ?? "");
            setdescription(row?.data?.description ?? "");
            settype(row?.data?.type ?? "");
            setlink(row?.data?.link ?? "");
            setcontent_type(row?.data?.content_type ?? "");
            const status = +Number(row?.data?.post_state_id.toString());
            setpost_state_id(status);

            if (
              row?.data?.country === false ||
              row?.data?.country === null ||
              row?.data?.country === []
            ) {
              setcountry([]);
            } else {
              setcountry(row?.data?.country ?? []);
            }
            

            if (
              row?.data?.category === false ||
              row?.data?.category === null ||
              row?.data?.category === []
            ) {
              setcategory([]);
            } else {
            
              setcategory(row?.data?.category ?? []);
              
            }

            if (row?.data?.postnotification === null) {
                setpostnotification(false);
              } else if (row?.data?.postnotification === 1) {
                setpostnotification(true);
              } else {
                setpostnotification(false);
              }

              if(row?.data?.scheduledate === null){
                setscheduledate("")
              }else{
                 const todaynew = new Date(row?.data?.scheduledate)
                .toISOString()
                .slice(0, 10);
               setscheduledate(todaynew ?? "");
               setschstatus(true);
              }

            setLoading(false);
          } else if (row?.success === false) {
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };


  function create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxx4xxyxx".replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }



  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    " Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Côte d'Ivoire",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    " Congo(Congo - Brazzaville)",
    " Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    " Czechia(Czech Republic)",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    " Equatorial Guinea",
    "Eritrea",
    "Estonia",
    " Eswatini(fmr. 'Swaziland')",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea - Bissau",
    "Guyana",
    "Haiti",
    " Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar(formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    " New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    " North Korea",
    " North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    " Saint Kitts and Nevis",
    "Saint Lucia",
    " Saint Vincent and the Grenadines",
    "Samoa",
    " San Marino",
    "Sao Tome and Principe",
    " Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    " Solomon Islands",
    "Somalia",
    "  South Africa",
    " South Korea",
    "South Sudan",
    "Spain",
    " Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    " Timor - Leste",
    "Togo",
    "Tonga",
    " Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    " United Kingdom",
    " United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const tableStatus = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/poststatus`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setpoststatus(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableCategory = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/tvCategory`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setcategData(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };


 


 

  useEffect(() => {
    tableCategory();
    tableStatus();
    tableRadioShow();
   
    // eslint-disable-next-line
  }, [id]);

  const handleStatus = (event) => {
    setpost_state_id(event.target.value);
    setschstatus(false);
    if (event.target.value === 4) {
      setschstatus(true);
    }
  };



  const handleAudioTypes = (event) => {
    settype(event.target.value);
  };

  const handleContentType = (event) => {
    setcontent_type(event.target.value);
  };





  const handleCountryChange = (event) => {
    setcountry(event);
  };

  const handleCategoryChange = (event) => {
    setcategory(event);
  };


  const selectedFileAudio = (e) => {
    const audio = e.target.files[0];
    if (audio) {
      setLoading4(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + audio.name, // File name you want to save as in S3
        Body: audio,
        ContentType: audio.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading4(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setlink(photoUrl);
        setLoading4(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress2) {
        let uploaded = Math.round((progress2.loaded / progress2.total) * 100);
        setProgress2(uploaded);
      });
    }
  };

  const selectedFile = (e) => {
    const image = e.target.files[0];
    if (image) {
      setLoading3(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + image.name, // File name you want to save as in S3
        Body: image,
        ContentType: image.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading3(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setimage(photoUrl);
        setLoading3(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress) {
        let uploaded = Math.round((progress.loaded / progress.total) * 100);
        setProgress(uploaded);
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userToken = localStorage.getItem("userToken");
    setloading2(true);
   
    const radioObj = {
      id,
      name,
      image,
      link,
      description,
      post_state_id,
      type,
      content_type,
      country,
      postnotification,
      scheduledate,
      category
    };
    // console.log('radioObj', radioObj)

    fetch(`${rootURL}/updatelivetvcpanel`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(radioObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");

          setTimeout(() => {
            history("/livetv-manage/livetv-list");
          }, 3000);
        } else if (result.success === false) {
          setloading2(false);
          failedMessage(result.message);
        }
      })
      .catch((error) => {
        setloading2(false);
        failedMessage("Internal Server Error");
      })
      .finally(() => {
        setloading2(false);
      });
  };

  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress size={70} thickness={4} />
          </div>
        </>
      ) : (
        <>
          <Layout title="Edit LiveTV" content="container">
            <Block.Head>
              <Block.HeadBetween>
                <Block.HeadContent>
                  <Block.Title tag="h2">Edit LiveTV</Block.Title>
                  <nav>
                    <ol className="breadcrumb breadcrumb-arrow mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/livetv-manage/livetv-list">LiveTV</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Edit LiveTV
                      </li>
                    </ol>
                  </nav>
                </Block.HeadContent>
                {/* <Block.HeadContent>
                    <ul className="d-flex">
                        <li>
                            <Link to="/ecommerce/products" className="btn btn-primary btn-md d-md-none">
                                <Icon name="eye"/>
                                <span>View</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/ecommerce/products" className="btn btn-primary d-none d-md-inline-flex">
                                <Icon name="eye"/>
                                <span>View Products</span>
                            </Link>
                        </li>
                    </ul>
                </Block.HeadContent> */}
              </Block.HeadBetween>
            </Block.Head>

            <Block>
              <Form onSubmit={handleSubmit}>
                <Row className="g-gs">
                  <Col xxl="9">
                    <div className="gap gy-4">
                      <div className="gap-col">
                        <Card className="card-gutter-md">
                          <Card.Body>
                            <Row className="g-gs">
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label>Cover</Form.Label>
                                  <div className="form-control-wrap">
                                    <div className="image-upload-wrap d-flex flex-column align-items-center">
                                      {loading3 ? (
                                        <div>Upload progress {progress}%</div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="media media-huge border">
                                        <img
                                          id="image-result"
                                          className="w-100 h-100"
                                          src={image}
                                          alt="avatar"
                                        />
                                      </div>
                                      <div className="pt-3">
                                        <input
                                          className="upload-image"
                                          data-target="image-result"
                                          id="change-avatar"
                                          type="file"
                                          max="1"
                                          hidden
                                          onChange={selectedFile}
                                        />
                                        <label
                                          htmlFor="change-avatar"
                                          className="btn btn-md btn-primary"
                                          disabled={loading3}
                                        >
                                          {loading3
                                            ? "Uploading ..."
                                            : "Upload"}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-note mt-3">
                                    Set the article thumbnail image. Only *.png,
                                    *.jpg and *.jpeg image files are accepted.
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="productname">
                                    Title *
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="name"
                                      placeholder="Title"
                                      value={name}
                                      required
                                      onChange={(e) => setname(e.target.value)}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                             

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="productname">
                                    Link
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="link"
                                      placeholder="Link"
                                      value={link}
                                      onChange={(e) => setlink(e.target.value)}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label>LiveTV video</Form.Label>
                                  <div className="form-control-wrap">
                                    <div className="image-upload-wrap d-flex flex-column align-items-center">
                                      {loading4 ? (
                                        <div>Upload progress {progress2}%</div>
                                      ) : (
                                        ""
                                      )}
                                      {/* <div className="media media-huge border">
                                        <img
                                          id="image-result"
                                          className="w-100 h-100"
                                          src={}
                                          alt="avatar"
                                        />
                                      </div> */}
                                      <div className="pt-3">
                                        <input
                                          className="upload-image"
                                          data-target="image-result2"
                                          id="change-avatar2"
                                          type="file"
                                          max="1"
                                          hidden
                                          onChange={selectedFileAudio}
                                        />
                                        <label
                                          htmlFor="change-avatar2"
                                          className="btn btn-md btn-primary"
                                          disabled={loading4}
                                        >
                                          {loading4
                                            ? "Uploading ..."
                                            : "Upload"}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-note mt-3">
                                    Set the video.
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label>Description *</Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      as="textarea"
                                      rows="3"
                                      value={description}
                                      placeholder=""
                                      required
                                      onChange={(e) =>
                                        setdescription(e.target.value)
                                      }
                                    ></Form.Control>
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="6">
                                <div className="form-control-wrap">
                                  <FormControl fullWidth size="small">
                                    <Form.Label htmlFor="status_id">
                                      Video Type *
                                    </Form.Label>
                                    <Select
                                      labelId="demo-select-small"
                                      id="demo-simple-select"
                                      value={type ?? ""}
                                      label="Video type"
                                      onChange={handleAudioTypes}
                                      required
                                    >
                                      {audioTypes.map((types) => (
                                        <MenuItem key={types} value={types}>
                                          {loading === false ? types : ""}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </Col>

                              <Col lg="6">
                                <div className="form-control-wrap">
                                  <FormControl fullWidth size="small">
                                    <Form.Label htmlFor="content_type">
                                      Content Type
                                    </Form.Label>
                                    <Select
                                      labelId="demo-select-small"
                                      id="demo-simple-select"
                                      value={content_type ?? ""}
                                      label="Status"
                                      onChange={handleContentType}
                                    >
                                      {contents.map((type) => (
                                        <MenuItem key={type} value={type}>
                                          {loading === false ? type : ""}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </Col>

                             

                             

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="baseprice">
                                    Country
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Autocomplete
                                      multiple
                                      fullWidth
                                      id="checkboxes-tags-demo"
                                      value={country}
                                      options={countries}
                                      onChange={(event, newValue) => {
                                        handleCountryChange(newValue);
                                      }}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) => option}
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option}
                                        </li>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Country"
                                          placeholder="Select Country"
                                        />
                                      )}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                {/* <Form.Group className="form-group">
                                  <Form.Label htmlFor="baseprice">
                                    Category
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Autocomplete
                                      multiple
                                      fullWidth
                                      id="checkboxes-tags-demo"
                                      value={categorys}
                                      options={categData}
                                      onChange={(event, newValue) => {
                                        handleCategoryChange(newValue);
                                      }}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) => option?.name}
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option?.name}
                                        </li>
                                      )}
                                      isOptionEqualToValue={(option, value) =>
                                        option?.name === value?.name
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Category"
                                          placeholder="Select Category"
                                        />
                                      )}
                                    />
                                  </div>
                                </Form.Group> */}

                                  <Form.Group className="form-group">
                                  <Form.Label htmlFor="discount-price">
                                    Category *
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Autocomplete
                                      multiple
                                      fullWidth
                                      id="checkboxes-categorys-demo"
                                      value={category}
                                      required
                                      options={categData}
                                      onChange={(event, newValue) => {
                                        handleCategoryChange(newValue);
                                      }}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) => option.name}
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option?.name}
                                        </li>
                                      )}
                                      isOptionEqualToValue={(option, value) =>
                                        option?.id === value?.id
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Category"
                                          placeholder="Select Category"
                                        />
                                      )}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="POST NOTIFICATION TO THE APP"
                                  control={
                                    <Checkbox
                                      checked={postnotification}
                                      onChange={(e) => {
                                        setpostnotification(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                            

                              <Col lg="12">
                                <div className="form-control-wrap">
                                  <FormControl fullWidth size="small">
                                    <Form.Label htmlFor="status_id">
                                      Status *
                                    </Form.Label>
                                    <Select
                                      labelId="demo-select-small"
                                      id="demo-simple-select"
                                      value={post_state_id ?? ""}
                                      label="Status"
                                      onChange={handleStatus}
                                      required
                                    >
                                      {poststatus.map((status) => (
                                        <MenuItem
                                          key={status.id}
                                          value={status.id}
                                        >
                                          {loading === false ? status.name : ""}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </Col>


                              {schstatus ? (
                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="scheduledate">
                                    Schedule Date
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <input
                                      type="date"
                                      id="scheduledate"
                                      name="scheduledate"
                                      value={scheduledate}
                                      onChange={(newValue) => {
                                        setscheduledate(newValue.target.value);
                                      }}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              ) : (
                                ""
                              )}

                            </Row>
                          </Card.Body>
                        </Card>
                      </div>

                      <div className="gap-col">
                        <ul className="d-flex align-items-center gap g-3">
                          <li>
                            <Button type="submit" variant="contained">
                              {loading2 ? "Updating...." : "Update"}
                            </Button>
                          </li>
                          <li>
                            <Link
                              to="/livetv-manage/livetv-list"
                              className="btn border-0"
                            >
                              Cancel
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  {/* <Col xxl="3">
                    <Card className="card-gutter-md">
                      <Card.Body>
                        <Row className="g-gs">
                          <Col className="col-12">
                           
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col> */}
                </Row>
              </Form>
            </Block>
          </Layout>
        </>
      )}
    </>
  );
}

export default LiveTVEdit;
