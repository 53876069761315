import React, { useState, useEffect, useMemo } from "react";
import { Card, Dropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import Swal from "sweetalert2/src/sweetalert2.js";
import {
  MediaGroup,
  Media,
  MediaText,
  Image,
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
  LinkListItem,
} from "../../components";
import { toInitials } from "../../utilities";
import { rootURL } from "../utilities/axios-config";
// import { successMessage, failedMessage } from "../utilities/alert-config";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";

function RadioCategoryList() {
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [localSearch, setLocalSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(200);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleDelete = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Once deleted, You won't be able to revert this Category! ${row?.name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        Swal.fire("Deleted", "You successfully deleted this file", "success");
        const userToken = localStorage.getItem("userToken");
        const config = {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        };
        setLoading(true);
        fetch(`${rootURL}/radiocategoryremove/${row?.id}`, config)
          .then((row) => row.json())
          .then((row) => {
            if (row?.success === true) {
              tableRecords();

              setLoading(false);
            } else if (row?.success === false) {
              setLoading(false);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        Swal.fire("Cancelled", "Your data is still intact", "info");
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      setLoading(true);
      fetch(`${rootURL}/allradiocategory?page=${newPage}`, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setTableData(row?.data?.data);
            setPage(row?.data?.current_page);
            setCount(row?.data?.total);
            setRowsPerPage(row?.data?.per_page);

            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      setLoading(true);
      fetch(
        `${rootURL}/allradiocategory?per_page=${event.target.value}`,
        config
      )
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setTableData(row?.data?.data);
            setPage(row?.data?.current_page);
            setCount(row?.data?.total);
            setRowsPerPage(row?.data?.per_page);

            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableRecords = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/allradiocategory`;
      if (searchText) {
        url += `?searchtext=${searchText}`;
      }

      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setTableData(row?.data?.data);
            setPage(row?.data?.current_page);
            setCount(row?.data?.total);
            setRowsPerPage(row?.data?.per_page);

            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const debounce = () => {
    let timeoutID;
    return (e) => {
      setLocalSearch(e.target.value);
      clearTimeout(timeoutID);
      timeoutID = setTimeout(() => {
        setSearchText(e.target.value);
        // only make api call after user finish typing
      }, 1000);
    };
  };

  const handleSearch = useMemo(() => debounce(), []);

  useEffect(() => {
    tableRecords();
    // eslint-disable-next-line
  }, [searchText]);

  return (
    <Layout title="Category List" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Category List</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/radiocategory-manage/radiocategory-list">
                    Category Manage
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Category
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                {/* <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={handleShowModal}
                >
                  <Icon name="plus" />
                  <span>Add</span>
                </Button> */}
                <Link
                  to={`/radiocategory-manage/radiocategory-add`}
                  className="btn btn-soft btn-primary"
                >
                  <Icon name="user"></Icon>
                  <span>Add Category</span>
                </Link>
              </li>
              {/* <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleShowModal}
                >
                  <Icon name="plus" />
                  <span>Add User</span>
                </Button>
              </li> */}
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          <div className="data-table-wrapper">
            <div className="data-table-top">
              <div className="data-table-search">
                <input
                  className="form-control"
                  placeholder="Search by name"
                  value={localSearch}
                  type="text"
                  onChange={handleSearch}
                />
              </div>
              <div className="data-table-action-wrap">
                {/* {actions && <Export data={data} />}
                <div className="data-table-select">
                    <select 
                        className="form-select" 
                        onChange={(e) => setShowItemPerPage(e.target.value)}
                        value={showItemPerPage}
                        >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="25">25</option>
                    </select>
                    <span className="text">Per page</span>
                </div> */}
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {loading ? (
                      <TableCell component="th" colSpan="4" align="center">
                        {" "}
                        <CircularProgress size={20} thickness={4} />
                        {"LOADING...... "}
                      </TableCell>
                    ) : (
                      <>
                        <TableCell align="left">NAME</TableCell>
                        {/* <TableCell align="center">SLUG</TableCell> */}
                        <TableCell align="center">CREATED ON</TableCell>
                        <TableCell align="center">ACTION</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <MediaGroup>
                          <Media
                            size="md"
                            shape="circle"
                            variant={row.theme && row.theme}
                          >
                            {row?.photo ? (
                              <Image src={row?.photo} staticImage />
                            ) : (
                              <span className="smaller fw-medium">
                                {toInitials(row?.name)}
                              </span>
                            )}
                          </Media>
                          <MediaText>
                            {row?.name}
                            {/* <span className="small text">{row?.name}</span> */}
                          </MediaText>
                        </MediaGroup>
                      </TableCell>

                      {/* <TableCell component="th" scope="row">
                        {row?.name}
                      </TableCell> */}
                      {/* <TableCell align="center">{row?.slug}</TableCell> */}
                      <TableCell align="center">{row?.created_at}</TableCell>

                      <TableCell align="center">
                        {" "}
                        <span
                          className={`badge text-bg-${
                            row.status === 1
                              ? "success-soft"
                              : row?.status === "Pending"
                              ? "warning-soft"
                              : row?.status === 0
                              ? "secondary-soft"
                              : "primary-soft"
                          }`}
                        >
                          {row.status
                            ? row.status === 1
                              ? "Active"
                              : "Inactive"
                            : "General"}
                        </span>
                      </TableCell>
                      <TableCell>
                        <div className="text-end w-100">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              className="dropdown-menu-sm"
                              as={CustomDropdownMenu}
                              align="end"
                            >
                              <div className="dropdown-content py-1">
                                <LinkList className="link-list-hover-bg-primary link-list-md">
                                  <LinkListItem
                                    to={`/radiocategory-manage/radiocategory-edit/${row.id}`}
                                  >
                                    <Icon name="edit"></Icon>
                                    <span>Edit</span>
                                  </LinkListItem>

                                  <Button onClick={() => handleDelete(row)}>
                                    <Icon name="trash"></Icon>
                                    <span>Delete</span>
                                  </Button>
                                </LinkList>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="data-table-bottom">
              <TablePagination
                rowsPerPageOptions={[50, 100, 150, 200, 250]}
                component="div"
                count={count}
                page={!count || count <= 0 ? 0 : page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </Card>
      </Block>
    </Layout>
  );
}

export default RadioCategoryList;
