import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, Row, Col, Form } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon } from "../../components";
import CircularProgress from "@mui/material/CircularProgress";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
// import FormControl from "@mui/material/FormControl";
import { rootURL, PayStackLiveKey } from "../utilities/axios-config";
import { successMessage, failedMessage } from "../utilities/alert-config";
// import Autocomplete from "@mui/material/Autocomplete";
// import TextField from "@mui/material/TextField";

function FundTransferStatus() {
  const { id } = useParams();
  //   const history = useNavigate();
  const [type, settype] = useState("mobile_money");
  const [name, setname] = useState("");
  const [account_number, setaccount_number] = useState("");
  const [bank_code, setbank_code] = useState("MTN");
  const [reason, setreason] = useState("");
  const [fee_charged, setfee_charged] = useState("");
  const [recipient_code, setrecipient_code] = useState("");
  const [currency, setcurrency] = useState("");
  const [reference, setreference] = useState("");
  const [transfer_code, settransfer_code] = useState("");
  const [status, setstatus] = useState("");
  const [message, setmessage] = useState("");
  const [created_at, setcreated_at] = useState("");
  const [createdBy, setcreatedBy] = useState("");
  const [amounts, setamount] = useState(0);

  // const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const sendToAukiss = async (fee_charged, row, status, message) => {
    try {
      const userToken = localStorage.getItem("userToken");
      setLoading2(true);
      const radioObj = {
        id,
        fee_charged,
        status,
        message,
      };
      // console.log('radioObj', radioObj)

      fetch(`${rootURL}/update_fundtransfer_cpanel`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${userToken}`,
          Accept: "application/form-data",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(radioObj),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.success === true) {
            successMessage("Success");
            settype(row?.type);
            setname(row?.name);
            setaccount_number(row?.account_number);
            setbank_code(row?.bank_code);
            setreason(row?.reason);
            setfee_charged(fee_charged);
            setamount(row?.amount);
            setcurrency(row?.currency);
            setrecipient_code(row?.recipient_code);
            setreference(row?.reference);
            settransfer_code(row?.transfer_code);
            setstatus(status);
            setmessage(message);
            setcreated_at(row?.created_at);
            let user = row?.user?.first_name + " " + row?.user?.last_name;
            setcreatedBy(user);
          } else if (result.success === false) {
            setLoading2(false);
            failedMessage(result.message);
          }
        })
        .catch((error) => {
          setLoading2(false);
          failedMessage("Internal Server Error");
        })
        .finally(() => {
          setLoading2(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchTransfer = async (row) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${PayStackLiveKey}`,
          "Content-Type": "application/json",
        },
      };
      let url = `https://api.paystack.co/transfer/${row?.transfer_code}`;

      fetch(url, config)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === true) {
            sendToAukiss(
              result?.data?.fee_charged / 100,
              row,
              result?.data?.status,
              result?.message
            );
          } else if (result.status === false) {
            setLoading2(false);
            failedMessage(result.message);
          }
        })
        .catch((error) => {
          setLoading2(false);
          failedMessage("Internal Server Error");
        })
        .finally(() => {
          setLoading2(false);
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  const tableRecords = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/view_fundtransfer_cpanel/${id}`;

      setLoading2(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            // console.log("row", row);
            fetchTransfer(row?.data);
            setLoading2(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading2(false);
          }
        })
        .finally(() => {
          setLoading2(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  // grabs the id form the url and loads the corresponding data
  useEffect(() => {
    tableRecords();
    // eslint-disable-next-line
  }, []);

  return (
    <Layout title="View Money Status" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent></Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex gap g-2">
              <li className="d-none d-md-block">
                <Link
                  to={`/fundtransfer/fundtransfer-list`}
                  className="btn btn-soft btn-primary"
                >
                  <Icon name="user"></Icon>
                  <span>FundTransfer List</span>
                </Link>
              </li>
              <li className="d-md-none">
                <Link
                  to={`/fundtransfer/fundtransfer-list`}
                  className="btn btn-soft btn-primary btn-icon"
                >
                  <Icon name="user"></Icon>
                </Link>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        {loading2 ? (
          <CircularProgress size={20} thickness={4} />
        ) : (
          <Card className="card-gutter-md">
            <Card.Body>
              <div className="bio-block">
                <h4 className="bio-block-title mb-4">View Money Status</h4>
                <Form onSubmit={handleSubmit}>
                  <Row className="g-3">
                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="firstName">Type </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="type"
                            value={type}
                            placeholder="Type"
                            disabled
                            onChange={(e) => settype(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="firstName">Bank Code</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="bank_code"
                            value={bank_code}
                            placeholder="bank_code"
                            disabled
                            onChange={(e) => setbank_code(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="firstName">
                          Fee Charged{" "}
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="fee_charged"
                            value={fee_charged}
                            placeholder="fee_charged"
                            disabled
                            onChange={(e) => setfee_charged(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="lastName">Amount</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="number"
                            id="amounts"
                            value={amounts}
                            placeholder="Amount"
                            disabled
                            onChange={(e) => setamount(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="firstName">Name </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="Name"
                            value={name}
                            placeholder="Name"
                            disabled
                            onChange={(e) => setname(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="lastName">
                          Account Number
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="number"
                            id="account_number"
                            value={account_number}
                            placeholder="Account Number"
                            disabled
                            onChange={(e) => setaccount_number(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="firstName">Reason </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="Name"
                            value={reason}
                            placeholder="Reason"
                            disabled
                            onChange={(e) => setreason(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="lastName">
                          Recipient Code
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="recipient_code"
                            value={recipient_code}
                            placeholder="recipient_code"
                            disabled
                            onChange={(e) => setrecipient_code(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="lastName">Currency</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="currency"
                            value={currency}
                            placeholder="currency"
                            disabled
                            onChange={(e) => setcurrency(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="lastName">Reference</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="reference"
                            value={reference}
                            placeholder="reference"
                            disabled
                            onChange={(e) => setreference(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="lastName">
                          Transfer_code
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="transfer_code"
                            value={transfer_code}
                            placeholder="transfer_code"
                            disabled
                            onChange={(e) => settransfer_code(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="lastName">Status</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="status"
                            value={status}
                            placeholder="status"
                            disabled
                            onChange={(e) => setstatus(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="lastName">Message</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="message"
                            value={message}
                            placeholder="message"
                            disabled
                            onChange={(e) => setmessage(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="lastName">Created_At</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="created_at"
                            value={created_at}
                            placeholder="created_at"
                            disabled
                            onChange={(e) => setcreated_at(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="lastName">createdBy</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="createdBy"
                            value={createdBy}
                            placeholder="createdBy"
                            disabled
                            onChange={(e) => setcreatedBy(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    {/* <Col lg="12">
                    <Button variant="primary" type="submit" disabled>
                      {loading2 ? "Sending...." : "Send"}
                    </Button>
                  </Col> */}
                  </Row>
                </Form>
              </div>
            </Card.Body>
          </Card>
        )}
      </Block>
    </Layout>
  );
}

export default FundTransferStatus;
