import React, { useState, useEffect } from "react";
import Layout from "../../layout/default";
import {
  Row,
  Col,
  Card,
  Dropdown,
  Table,
  // Tooltip,
  // OverlayTrigger,
  // ListGroup,
  Button,
} from "react-bootstrap";
import {
  Media,
  MediaGroup,
  MediaText,
  Icon,
  // ChartLabel,
  // ChartLegend,
  // CustomDropdownMenu,
  CustomDropdownToggle,
  OverlineTitle,
  // Timeline,
  // WorldMap,
  Image,
} from "../../components";
import { rootURL } from "../utilities/axios-config";
import CircularProgress from "@mui/material/CircularProgress";
//   import { ChartBar, ChartLine, ChartDoughnut } from "../../components/Chart/Charts";
// import { Colors } from "../../utilities/index";
// import hexRGB from "../../utilities/hexRGB";

// // Most Played Content
// let mostPlayedContentData = [
//   {
//     id: "uid01",
//     theme: "dark",
//     icon: "github-circle",
//     website: "github.com",
//     visitor: "3.4K",
//     revenue: "$3.877",
//     sale: "267",
//     conversion: "4.7%",
//   },
//   {
//     id: "uid02",
//     theme: "info",
//     icon: "twitter",
//     website: "twitter.com",
//     visitor: "2.5K",
//     revenue: "$3.426",
//     sale: "265",
//     conversion: "4.4%",
//   },
//   {
//     id: "uid03",
//     theme: "danger",
//     icon: "google",
//     website: "google.com",
//     visitor: "2.0K",
//     revenue: "$2.444",
//     sale: "264",
//     conversion: "4.7%",
//   },
//   {
//     id: "uid04",
//     theme: "info",
//     icon: "vimeo",
//     website: "vimeo.com",
//     visitor: "1.9K",
//     revenue: "$2.877",
//     sale: "261",
//     conversion: "3.6%",
//   },
//   {
//     id: "uid05",
//     theme: "warning",
//     icon: "digital-ocean",
//     website: "digital-ocean.com",
//     visitor: "1.8K",
//     revenue: "$2.870",
//     sale: "260",
//     conversion: "3.4%",
//   },
// ];







function MusicChart() {
  const [loading, setLoading] = useState(false);
  const [topchart, settopchart] = useState([]);
  const [topalbums, settopalbums] = useState([]);
  const [topsingles, settopsingles] = useState([]);
  const [topradios, settopradios] = useState([]);

  const tableRecords = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/musicmatrics`;

      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            console.log("topchats", row);

            settopchart((prevState) => {
              // Create a new array to avoid direct mutations
              const newData = row?.data?.topchats?.map((arr) => ({
                id: arr?.content?.liveradio?.id,
                theme: "dark",
                icon: arr?.content?.liveradio?.image,
                website: arr?.content?.liveradio?.name,
                visitor: arr?.total_clicks,
              }));
              // Merge the new data with the previous state, ensuring no duplicates
              const combinedData = [...prevState, ...newData];

              // Use a Set to filter out duplicate entries by ID
              const uniqueData = Array.from(
                new Set(combinedData.map((a) => a.id))
              ).map((id) => combinedData.find((a) => a.id === id));

              return uniqueData;
            });

            // albums
            settopalbums((prevState) => {
              // Create a new array to avoid direct mutations
              const newData = row?.data?.topalbums?.map((arr) => ({
                id: arr?.liveradio,
                theme: "dark",
                icon: arr?.liveradio_image,
                website: arr?.liveradio_name,
                visitor: arr?.total_clicks,
              }));
              // Merge the new data with the previous state, ensuring no duplicates
              const combinedData = [...prevState, ...newData];

              // Use a Set to filter out duplicate entries by ID
              const uniqueData = Array.from(
                new Set(combinedData.map((a) => a.id))
              ).map((id) => combinedData.find((a) => a.id === id));

              return uniqueData;
            });
            // singles
            settopsingles((prevState) => {
              // Create a new array to avoid direct mutations
              const newData = row?.data?.topsingles?.map((arr) => ({
                id: arr?.liveradio,
                theme: "dark",
                icon: arr?.liveradio_image,
                website: arr?.liveradio_name,
                visitor: arr?.total_clicks,
              }));
              // Merge the new data with the previous state, ensuring no duplicates
              const combinedData = [...prevState, ...newData];

              // Use a Set to filter out duplicate entries by ID
              const uniqueData = Array.from(
                new Set(combinedData.map((a) => a.id))
              ).map((id) => combinedData.find((a) => a.id === id));

              return uniqueData;
            });


            // top radio stations topradios
            settopradios((prevState) => {
              // Create a new array to avoid direct mutations
              const newData = row?.data?.topradios?.map((arr) => ({
                id: arr?.content?.liveradio?.id,
                theme: "dark",
                icon: arr?.content?.liveradio?.image,
                website: arr?.content?.liveradio?.name,
                visitor: arr?.total_clicks,
              }));
              // Merge the new data with the previous state, ensuring no duplicates
              const combinedData = [...prevState, ...newData];

              // Use a Set to filter out duplicate entries by ID
              const uniqueData = Array.from(
                new Set(combinedData.map((a) => a.id))
              ).map((id) => combinedData.find((a) => a.id === id));

              return uniqueData;
            });

            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    tableRecords();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress size={50} thickness={8} />
          </div>
        ) : (
          <>
            <Layout title="Analytics">
              <Row className="g-gs">
                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <div className="card-title">
                            <h4 className="title mb-1">
                              Congratulations Smith!
                            </h4>
                            <p className="small">Best seller of the month</p>
                          </div>
                          <div className="my-3">
                            <div className="amount h2 fw-bold text-primary">
                              $42.5k
                            </div>
                            <div className="smaller">
                              You have done 69.5% more sales today.
                            </div>
                          </div>
                          <Button href="#" size="sm" variant="primary">
                            View Sales
                          </Button>
                        </div>
                        <div className="d-none d-sm-block d-xl-none d-xxl-block me-md-5 me-xxl-0">
                          <Image src="/images/award/a.png" alt="" />
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Top Charts</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">source</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topchart.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                  <Image src={item?.icon} staticImage />
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>

                {/* <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Most Played Content</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">source</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {mostPlayedContentData.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                  <Icon name={item.icon}></Icon>
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col> */}

                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Top Singles</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">source</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topsingles.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                   <Image src={item?.icon} staticImage />
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>

                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Top Albums</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">source</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topalbums.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                  <Image src={item?.icon} staticImage />
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>

                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Top Radio Stations</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">source</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topradios.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                   <Image src={item?.icon} staticImage />
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>
              </Row>
            </Layout>
          </>
        )}
      </>
    </>
  );
}

export default MusicChart;
