import React, { useState, useEffect } from "react";
import Layout from "../../layout/default";
import {
  Row,
  Col,
  Card,
  Dropdown,
  Table,
  Tooltip,
  OverlayTrigger,
  ListGroup,
} from "react-bootstrap";
import {

  Media,
  MediaGroup,
  MediaText,
  Icon,
  Image,
  // ChartLabel,
  ChartLegend,
  CustomDropdownMenu,
  CustomDropdownToggle,
  OverlineTitle,
  // Timeline,
  WorldMap,
  Pureknob,
} from "../../components";
import {
  ChartBar,
  ChartLine,
  ChartDoughnut,
  ChartRadar,
} from "../../components/Chart/Charts";
import { Colors } from "../../utilities/index";
import hexRGB from "../../utilities/hexRGB";
import CircularProgress from "@mui/material/CircularProgress";
import { rootURL } from "../utilities/axios-config";

// Sessions Device

// audience overview chart
let audienceOverviewChart = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "April",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  ticksValue: "k",
  borderDash: [8, 4],
  datasets: [
    {
      tension: 0.4,
      borderWidth: 2,
      borderColor: Colors.warning,
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: Colors.warning,
      label: "New visits",
      data: [28, 60, 30, 65, 60, 95, 90, 0, 0, 0, 0, 0],
    },
    {
      tension: 0.4,
      borderWidth: 2,
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      borderColor: Colors.info,
      pointHoverBackgroundColor: Colors.info,
      label: "Unique visits",
      data: [70, 44, 49, 78, 39, 49, 39, 0, 0, 0, 0, 0],
    },
  ],
};

// total users
// let totalUsers = {
//   size: 120,
//   value: 78,
//   angleOffset: -0.5,
//   angleStart: 0.5,
//   angleEnd: 0.5,
//   colorFg: Colors.green
// };

// malefemale
// let malefemale = {
//   labels: ["Male", "Female"],
//   datasets: [
//     {
//       backgroundColor: [Colors.pink, Colors.cyan],
//       data: [187, 100],
//       hoverOffset: 4,
//     },
//   ],
// };

let svgWorldMap = {
  colorMax: "#B1C7EF",
  colorMin: "#D4DEEF",
  colorNoData: "#F3F4F6",
  data: {
    data: {
      visitor: {
        name: "Total Sessions",
        format: "{0}",
        thousandSeparator: ",",
        thresholdMax: 50000,
        thresholdMin: 1000,
      },
      change: {
        name: "Changes This Week",
        format: "{0} %",
      },
    },
    applyData: "visitor",
    values: {
      AF: {
        visitor: 587,
        change: 4.73,
      },
      AL: {
        visitor: 4583,
        change: 11.09,
      },
      DZ: {
        visitor: 4293,
        change: 10.01,
      },
      AO: {
        visitor: 4408,
        change: 26.49,
      },
      AG: {
        visitor: 16702,
        change: 3.26,
      },
      AR: {
        visitor: 14467,
        change: 13.83,
      },
      AM: {
        visitor: 3861,
        change: 9.22,
      },
      AU: {
        visitor: 55707,
        change: 7.39,
      },
      AT: {
        visitor: 47290,
        change: 5.72,
      },
      AZ: {
        visitor: 4141,
        change: 6.29,
      },
      BS: {
        visitor: 31255,
        change: 2.21,
      },
      BH: {
        visitor: 24029,
        change: 6.32,
      },
      BD: {
        visitor: 1602,
        change: 9.79,
      },
      BB: {
        visitor: 17859,
        change: 7.83,
      },
      BY: {
        visitor: 5760,
        change: 14.69,
      },
      BE: {
        visitor: 43582,
        change: 5.3,
      },
      BZ: {
        visitor: 4806,
        change: -0.64,
      },
      BJ: {
        visitor: 830,
        change: 4.92,
      },
      BT: {
        visitor: 2903,
        change: 8.01,
      },
      BO: {
        visitor: 3353,
        change: 7.3,
      },
      BA: {
        visitor: 5149,
        change: 7.04,
      },
      BW: {
        visitor: 7877,
        change: 8.34,
      },
      BR: {
        visitor: 9895,
        change: 13.74,
      },
      BN: {
        visitor: 29712,
        change: 10.31,
      },
      BG: {
        visitor: 8064,
        change: 7.58,
      },
      BF: {
        visitor: 664,
        change: 8.11,
      },
      BI: {
        visitor: 312,
        change: 4.85,
      },
      KH: {
        visitor: 1389,
        change: 8.76,
      },
      CM: {
        visitor: 1401,
        change: 2.94,
      },
      CA: {
        visitor: 45077,
        change: 6.27,
      },
      XK: {
        visitor: 3880,
        change: 6.23,
      },
      CV: {
        visitor: 3237,
        change: 4.91,
      },
      CF: {
        visitor: 387,
        change: 7.65,
      },
      TD: {
        visitor: 810,
        change: -0.71,
      },
      CL: {
        visitor: 15070,
        change: 9.66,
      },
      CN: {
        visitor: 8643,
        change: 6.5,
      },
      CO: {
        visitor: 6273,
        change: 9.21,
      },
      KM: {
        visitor: 788,
        change: 3.09,
      },
      CG: {
        visitor: 1958,
        change: 6.66,
      },
      CR: {
        visitor: 11685,
        change: -0.77,
      },
      HR: {
        visitor: 13138,
        change: 6.7,
      },
      CY: {
        visitor: 24976,
        change: 5.65,
      },
      CZ: {
        visitor: 20152,
        change: 8.9,
      },
      CD: {
        visitor: 478,
        change: 2.32,
      },
      DK: {
        visitor: 56444,
        change: 4.97,
      },
      DJ: {
        visitor: 1989,
        change: 4.52,
      },
      DM: {
        visitor: 7921,
        change: -3.63,
      },
      DO: {
        visitor: 7375,
        change: 3.67,
      },
      EC: {
        visitor: 6098,
        change: 2.21,
      },
      EG: {
        visitor: 2501,
        change: -32.17,
      },
      SV: {
        visitor: 4400,
        change: 4.06,
      },
      GQ: {
        visitor: 12727,
        change: 2.65,
      },
      ER: {
        visitor: 980,
        change: 13.88,
      },
      EE: {
        visitor: 19840,
        change: 11.47,
      },
      ET: {
        visitor: 873,
        change: 8.82,
      },
      FM: {
        visitor: 3200,
        change: 1.45,
      },
      FJ: {
        visitor: 5740,
        change: 7.44,
      },
      FI: {
        visitor: 46017,
        change: 5.75,
      },
      FR: {
        visitor: 39869,
        change: 4.35,
      },
      GA: {
        visitor: 7972,
        change: 6.96,
      },
      GM: {
        visitor: 480,
        change: 1.45,
      },
      GE: {
        visitor: 4099,
        change: 5.86,
      },
      DE: {
        visitor: 44550,
        change: 5.44,
      },
      GH: {
        visitor: 1663,
        change: 7.18,
      },
      GR: {
        visitor: 18637,
        change: 4.26,
      },
      GD: {
        visitor: 10360,
        change: 5.1,
      },
      GT: {
        visitor: 4472,
        change: 7.84,
      },
      GN: {
        visitor: 749,
        change: 11.88,
      },
      GW: {
        visitor: 794,
        change: 13.43,
      },
      GY: {
        visitor: 4710,
        change: 3.24,
      },
      HT: {
        visitor: 784,
        change: 3.96,
      },
      HN: {
        visitor: 2766,
        change: 4.66,
      },
      HK: {
        visitor: 46109,
        change: 6.01,
      },
      HU: {
        visitor: 15531,
        change: 3.16,
      },
      IS: {
        visitor: 70332,
        change: 16.46,
      },
      IN: {
        visitor: 1983,
        change: 13.35,
      },
      ID: {
        visitor: 3876,
        change: 7.53,
      },
      IR: {
        visitor: 5305,
        change: 5.53,
      },
      IQ: {
        visitor: 5088,
        change: 12.24,
      },
      IE: {
        visitor: 70638,
        change: 9.02,
      },
      IL: {
        visitor: 40258,
        change: 8.24,
      },
      IT: {
        visitor: 31984,
        change: 4.31,
      },
      CI: {
        visitor: 1617,
        change: 8.14,
      },
      JM: {
        visitor: 5048,
        change: 2.0,
      },
      JP: {
        visitor: 38440,
        change: -1.4,
      },
      JO: {
        visitor: 5678,
        change: 2.31,
      },
      KZ: {
        visitor: 8841,
        change: 18.57,
      },
      KE: {
        visitor: 1702,
        change: 9.66,
      },
      KI: {
        visitor: 1721,
        change: 6.42,
      },
      KW: {
        visitor: 26005,
        change: 5.6,
      },
      KG: {
        visitor: 1144,
        change: 7.19,
      },
      LA: {
        visitor: 2543,
        change: 5.2,
      },
      LV: {
        visitor: 15547,
        change: 10.98,
      },
      LB: {
        visitor: 11408,
        change: 2.73,
      },
      LS: {
        visitor: 1425,
        change: 12.94,
      },
      LR: {
        visitor: 729,
        change: -2.12,
      },
      LY: {
        visitor: 4859,
        change: 67.32,
      },
      LT: {
        visitor: 16730,
        change: 12.14,
      },
      LU: {
        visitor: 105803,
        change: 3.94,
      },
      MO: {
        visitor: 77451,
        change: 10.1,
      },
      MK: {
        visitor: 5474,
        change: 5.51,
      },
      MG: {
        visitor: 448,
        change: 11.5,
      },
      MW: {
        visitor: 324,
        change: 10.17,
      },
      MY: {
        visitor: 9813,
        change: 4.68,
      },
      MV: {
        visitor: 12527,
        change: 5.17,
      },
      ML: {
        visitor: 811,
        change: 5.62,
      },
      MT: {
        visitor: 27250,
        change: 8.83,
      },
      MH: {
        visitor: 3625,
        change: 8.21,
      },
      MR: {
        visitor: 1318,
        change: 5.15,
      },
      MU: {
        visitor: 9794,
        change: 1.88,
      },
      MX: {
        visitor: 9304,
        change: 5.64,
      },
      MD: {
        visitor: 2280,
        change: 19.51,
      },
      MN: {
        visitor: 3640,
        change: -0.75,
      },
      ME: {
        visitor: 7647,
        change: 8.8,
      },
      MA: {
        visitor: 3151,
        change: 4.89,
      },
      MZ: {
        visitor: 429,
        change: 9.5,
      },
      MM: {
        visitor: 1264,
        change: 4.41,
      },
      NA: {
        visitor: 5413,
        change: 14.96,
      },
      NR: {
        visitor: 8575,
        change: 9.6,
      },
      NP: {
        visitor: 834,
        change: 14.45,
      },
      NL: {
        visitor: 48346,
        change: 5.89,
      },
      NZ: {
        visitor: 41593,
        change: 6.51,
      },
      NI: {
        visitor: 2207,
        change: 2.57,
      },
      NE: {
        visitor: 440,
        change: 6.29,
      },
      NG: {
        visitor: 1994,
        change: -9.68,
      },
      NO: {
        visitor: 74941,
        change: 6.07,
      },
      OM: {
        visitor: 17973,
        change: 0.64,
      },
      PK: {
        visitor: 1541,
        change: 6.95,
      },
      PW: {
        visitor: 17096,
        change: 0.27,
      },
      PA: {
        visitor: 15089,
        change: 5.35,
      },
      PG: {
        visitor: 2861,
        change: 2.51,
      },
      PY: {
        visitor: 4260,
        change: 6.47,
      },
      PE: {
        visitor: 6199,
        change: 8.93,
      },
      PH: {
        visitor: 2976,
        change: 0.78,
      },
      PL: {
        visitor: 13822,
        change: 11.37,
      },
      PT: {
        visitor: 21161,
        change: 3.68,
      },
      PR: {
        visitor: 30488,
        change: -0.98,
      },
      QA: {
        visitor: 60804,
        change: 4.39,
      },
      RO: {
        visitor: 10757,
        change: 13.18,
      },
      RU: {
        visitor: 10608,
        change: 19.19,
      },
      RW: {
        visitor: 772,
        change: 5.2,
      },
      KN: {
        visitor: 16296,
        change: 1.9,
      },
      LC: {
        visitor: 9607,
        change: 1.99,
      },
      VC: {
        visitor: 7270,
        change: 3.99,
      },
      WS: {
        visitor: 4253,
        change: 5.93,
      },
      SM: {
        visitor: 47406,
        change: 3.8,
      },
      ST: {
        visitor: 1785,
        change: 5.7,
      },
      SA: {
        visitor: 21120,
        change: 3.95,
      },
      SN: {
        visitor: 1038,
        change: 8.73,
      },
      RS: {
        visitor: 5899,
        change: 8.71,
      },
      SC: {
        visitor: 15686,
        change: 2.97,
      },
      SL: {
        visitor: 492,
        change: -5.88,
      },
      SG: {
        visitor: 57713,
        change: 4.48,
      },
      SK: {
        visitor: 17664,
        change: 6.73,
      },
      SI: {
        visitor: 23654,
        change: 9.17,
      },
      SB: {
        visitor: 2081,
        change: 1.18,
      },
      SO: {
        visitor: 486,
        change: 6.38,
      },
      ZA: {
        visitor: 6180,
        change: 16.25,
      },
      KR: {
        visitor: 29891,
        change: 8.56,
      },
      SS: {
        visitor: 228,
        change: -18.73,
      },
      ES: {
        visitor: 28359,
        change: 6.31,
      },
      LK: {
        visitor: 4085,
        change: 6.95,
      },
      SD: {
        visitor: 1428,
        change: -1.91,
      },
      SR: {
        visitor: 5746,
        change: 0.76,
      },
      SZ: {
        visitor: 3915,
        change: 15.19,
      },
      SE: {
        visitor: 53218,
        change: 3.39,
      },
      CH: {
        visitor: 80591,
        change: 0.35,
      },
      TW: {
        visitor: 24577,
        change: 9.03,
      },
      TJ: {
        visitor: 824,
        change: 2.54,
      },
      TZ: {
        visitor: 1034,
        change: 5.58,
      },
      TH: {
        visitor: 6591,
        change: 10.39,
      },
      TL: {
        visitor: 2104,
        change: 1.19,
      },
      TG: {
        visitor: 611,
        change: 4.24,
      },
      TO: {
        visitor: 4177,
        change: 5.56,
      },
      TT: {
        visitor: 15769,
        change: -3.46,
      },
      TN: {
        visitor: 3496,
        change: -5.24,
      },
      TR: {
        visitor: 10512,
        change: -2.82,
      },
      TM: {
        visitor: 6643,
        change: 3.59,
      },
      TV: {
        visitor: 3638,
        change: 2.24,
      },
      UG: {
        visitor: 699,
        change: 1.04,
      },
      UA: {
        visitor: 2583,
        change: 17.46,
      },
      AE: {
        visitor: 37226,
        change: 5.21,
      },
      GB: {
        visitor: 39735,
        change: -1.96,
      },
      US: {
        visitor: 59501,
        change: 3.37,
      },
      UY: {
        visitor: 16722,
        change: 11.02,
      },
      UZ: {
        visitor: 1491,
        change: -28.81,
      },
      VU: {
        visitor: 3094,
        change: 6.51,
      },
      VE: {
        visitor: 6684,
        change: -12.16,
      },
      VN: {
        visitor: 2354,
        change: 8.37,
      },
      YE: {
        visitor: 551,
        change: -23.23,
      },
      ZM: {
        visitor: 1480,
        change: 18.11,
      },
      ZW: {
        visitor: 1176,
        change: 5.78,
      },
    },
  },
};

let contentWatchedData = [
  {
    id: "uid01",
    theme: "dark",
    icon: "github-circle",
    website: "Saw X",
    visitor: "3.4K",
    // revenue: "$3.877",
    // sale: "267",
    // conversion: "4.7%",
  },
  {
    id: "uid02",
    theme: "info",
    icon: "twitter",
    website: "Gold: A Journey with Idris Elba",
    visitor: "2.5K",
    // revenue: "$3.426",
    // sale: "265",
    // conversion: "4.4%",
  },
  {
    id: "uid03",
    theme: "danger",
    icon: "google",
    website: "The Hive",
    visitor: "2.0K",
    // revenue: "$2.444",
    // sale: "264",
    // conversion: "4.7%",
  },
  {
    id: "uid04",
    theme: "info",
    icon: "vimeo",
    website: "Talk 360",
    visitor: "1.9K",
    // revenue: "$2.877",
    // sale: "261",
    // conversion: "3.6%",
  },
  {
    id: "uid05",
    theme: "warning",
    icon: "digital-ocean",
    website: "Chicks",
    visitor: "1.8K",
    // revenue: "$2.870",
    // sale: "260",
    // conversion: "3.4%",
  },
];

let userstWatchedData = [
  {
    id: "uid01",
    theme: "dark",
    icon: "github-circle",
    website: "Richard Danquah",
    visitor: "3.4K",
    // revenue: "$3.877",
    // sale: "267",
    // conversion: "4.7%",
  },
  {
    id: "uid02",
    theme: "info",
    icon: "twitter",
    website: "Lincoln Smith",
    visitor: "2.5K",
    // revenue: "$3.426",
    // sale: "265",
    // conversion: "4.4%",
  },
  {
    id: "uid03",
    theme: "danger",
    icon: "google",
    website: "Ryan Elcock",
    visitor: "2.0K",
    // revenue: "$2.444",
    // sale: "264",
    // conversion: "4.7%",
  },
  {
    id: "uid04",
    theme: "info",
    icon: "vimeo",
    website: "Raphael Gabah",
    visitor: "1.9K",
    // revenue: "$2.877",
    // sale: "261",
    // conversion: "3.6%",
  },
  {
    id: "uid05",
    theme: "warning",
    icon: "digital-ocean",
    website: "Jennifer Abena Assan",
    visitor: "1.8K",
    // revenue: "$2.870",
    // sale: "260",
    // conversion: "3.4%",
  },
];

// topseriesData
// let topseriesData = [
//   {
//     id: "uid01",
//     theme: "dark",
//     icon: "github-circle",
//     website: "Saw X",
//     visitor: "3.4K",
//     // revenue: "$3.877",
//     // sale: "267",
//     // conversion: "4.7%",
//   },
//   {
//     id: "uid02",
//     theme: "info",
//     icon: "twitter",
//     website: "Gold: A Journey with Idris Elba",
//     visitor: "2.5K",
//     // revenue: "$3.426",
//     // sale: "265",
//     // conversion: "4.4%",
//   },
//   {
//     id: "uid03",
//     theme: "danger",
//     icon: "google",
//     website: "The Hive",
//     visitor: "2.0K",
//     // revenue: "$2.444",
//     // sale: "264",
//     // conversion: "4.7%",
//   },
//   {
//     id: "uid04",
//     theme: "info",
//     icon: "vimeo",
//     website: "Talk 360",
//     visitor: "1.9K",
//     // revenue: "$2.877",
//     // sale: "261",
//     // conversion: "3.6%",
//   },
//   {
//     id: "uid05",
//     theme: "warning",
//     icon: "digital-ocean",
//     website: "Chicks",
//     visitor: "1.8K",
//     // revenue: "$2.870",
//     // sale: "260",
//     // conversion: "3.4%",
//   },
// ];



// effectiveness Chart
let effectivenessChart = {
  labels: [
    "AdSense",
    "Facebook",
    "Video ads",
    "Pop-ups",
    "Email marketing",
    "Banners",
  ],
  borderDash: [8, 4],
  yAxis: false,
  xAxis: false,
  ticksNumberLabel: false,
  datasets: [
    {
      borderWidth: 2,
      borderColor: Colors.primary,
      backgroundColor: hexRGB(Colors.primary, 0.2),
      pointBorderColor: Colors.white,
      pointBackgroundColor: Colors.primary,
      pointHoverBackgroundColor: Colors.primary,
      label: "# of Votes",
      data: [80, 70, 56, 91, 86, 80],
    },
  ],
};

// top referrals for the month
let topreferralsData = [
  {
    id: "uid01",
    theme: "dark",
    icon: "github-circle",
    website: "Samuel Mensah",
    visitor: "3.4K",
    revenue: "$3.877",
    // sale: "267",
    // conversion: "4.7%",
  },
  {
    id: "uid02",
    theme: "info",
    icon: "twitter",
    website: "Richard Danquah",
    visitor: "2.5K",
    revenue: "$3.426",
    // sale: "265",
    // conversion: "4.4%",
  },
  {
    id: "uid03",
    theme: "danger",
    icon: "google",
    website: "Mary Manu",
    visitor: "2.0K",
    revenue: "$2.444",
    // sale: "264",
    // conversion: "4.7%",
  },
  {
    id: "uid04",
    theme: "info",
    icon: "vimeo",
    website: "Michael Badu",
    visitor: "1.9K",
    revenue: "$2.877",
    // sale: "261",
    // conversion: "3.6%",
  },
  {
    id: "uid05",
    theme: "warning",
    icon: "digital-ocean",
    website: "Eno",
    visitor: "1.8K",
    revenue: "$2.870",
    // sale: "260",
    // conversion: "3.4%",
  },
];

function AukissChart() {
  const [registereduser, setregisteredUser] = useState({});
  const [male, setMale] = useState(0);
  const [female, setFemale] = useState(0);
  const [mobileSes, setMobileSes] = useState(0);
  const [webSes, setwebSes] = useState(0);
  const [tvSes, settvSes] = useState(0);
  const [salesOverview, setsalesOverview] = useState(0);
  const [moviesview, setmoviesview] = useState(0);
  const [seriesview, setseriesview] = useState(0);
  const [livetvsview, setlivetvsview] = useState(0);
  const [magazineview, setmagazineview] = useState(0);
  const [topmagazineDataInfo, setTopmagazineDataInfo] = useState([]);
  const [topmovieDataInfo, settopmovieDataInfo] = useState([]);
  const [topseriesDataInfo, settopseriesDataInfo] = useState([]);

  const [loading, setLoading] = useState(false);

  const tableRecords = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/aukissmatrics`;

      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            // console.log("data", row?.data);
            setMale(row?.data?.maleUsers);
            setFemale(row?.data?.femaleUsers);
            setMobileSes(row?.data?.mobleSessions);
            setwebSes(row?.data?.webSessions);
            settvSes(row?.data?.tvSessions);
            setsalesOverview(row?.data?.salesoverview);
            setmoviesview(row?.data?.moviesview);
            setseriesview(row?.data?.seriesview);
            setlivetvsview(row?.data?.livetvview);
            setmagazineview(row?.data?.magazineview);
            setTopmagazineDataInfo((prevState) => {
              // Create a new array to avoid direct mutations
              const newData = row?.data?.topmagazines?.map((arr) => ({
                id: arr?.content?.magazine?.id,
                theme: "info",
                icon: arr?.content?.magazine?.mag_image,
                website: arr?.content?.magazine?.post_title,
                visitor: arr?.total_clicks,
              }));
            
              // Merge the new data with the previous state, ensuring no duplicates
              const combinedData = [...prevState, ...newData];
            
              // Use a Set to filter out duplicate entries by ID
              const uniqueData = Array.from(new Set(combinedData.map(a => a.id)))
                                      .map(id => combinedData.find(a => a.id === id));
            
              return uniqueData;
            });

            settopmovieDataInfo((prevState) => {
              // Create a new array to avoid direct mutations
              const newData = row?.data?.topmovies?.map((arr) => ({
                id: arr?.content?.movie?.id,
                theme: "dark",
                icon: arr?.content?.movie?.image,
                website: arr?.content?.movie?.post_title,
                visitor: arr?.total_clicks,
              }));
            
              // Merge the new data with the previous state, ensuring no duplicates
              const combinedData = [...prevState, ...newData];
            
              // Use a Set to filter out duplicate entries by ID
              const uniqueData = Array.from(new Set(combinedData.map(a => a.id)))
                                      .map(id => combinedData.find(a => a.id === id));
            
              return uniqueData;
            });

            
            settopseriesDataInfo((prevState) => {
              // Create a new array to avoid direct mutations
              const newData = row?.data?.topseries?.map((arr) => ({
                id: arr?.content?.tvshow?.id,
                theme: "dark",
                icon: arr?.content?.tvshow?.image,
                website: arr?.content?.tvshow?.post_title,
                visitor: arr?.total_clicks,
              }));
            
              // Merge the new data with the previous state, ensuring no duplicates
              const combinedData = [...prevState, ...newData];
            
              // Use a Set to filter out duplicate entries by ID
              const uniqueData = Array.from(new Set(combinedData.map(a => a.id)))
                                      .map(id => combinedData.find(a => a.id === id));
            
              return uniqueData;
            });

            setregisteredUser({
              size: 120,
              value: row?.data?.registeredUsers,
              angleOffset: -0.5,
              angleStart: 0.5,
              angleEnd: 0.5,
              colorFg: Colors.green,
            });

            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    tableRecords();

    // eslint-disable-next-line
  }, []);

  let registeredmalefemale = {
    labels: ["Male", "Female"],
    datasets: [
      {
        backgroundColor: [Colors.cyan, Colors.pink],
        data: [male, female],
        hoverOffset: 4,
      },
    ],
  };

  let sessionsDevice = {
    labels: ["Mobile", "Web", "TV"],
    datasets: [
      {
        backgroundColor: [Colors.info, Colors.yellow, Colors.green],
        data: [mobileSes, webSes, tvSes],
        hoverOffset: 4,
      },
    ],
  };

  // sales overview chart
  let salesOverviewChart = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "April",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    ticksValue: " $",
    borderDash: [8, 4],
    datasets: [
      {
        tension: 0.4,
        borderWidth: 2,
        borderColor: Colors.pink,
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        pointHoverBackgroundColor: Colors.pink,
        label: "Sales",
        data: salesOverview,
      },
    ],
  };

  // page viewership
  let pageviewershipOverviewChart = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    ticksValue: "k",
    borderDash: [8, 4],
    xGridColor: Colors.white,
    yGridColor: Colors.gray100,
    xGridBorderColor: Colors.gray100,
    yGridBorderColor: Colors.white,
    datasets: [
      {
        tension: 0.4,
        borderWidth: 2,
        borderColor: Colors.red,
        pointBorderColor: Colors.white,
        pointBackgroundColor: Colors.red,
        pointHoverBackgroundColor: Colors.red,
        backgroundColor: hexRGB(Colors.red, 0.2),
        label: "Movies",
        fill: true,
        data: moviesview,
      },
      {
        tension: 0.4,
        borderWidth: 2,
        pointBorderColor: Colors.white,
        pointBackgroundColor: Colors.success,
        borderColor: Colors.success,
        pointHoverBackgroundColor: Colors.success,
        backgroundColor: hexRGB(Colors.success, 0.2),
        label: "Series",
        fill: true,
        data: seriesview,
      },
      {
        tension: 0.4,
        borderWidth: 2,
        pointBorderColor: Colors.white,
        pointBackgroundColor: Colors.warning,
        borderColor: Colors.warning,
        pointHoverBackgroundColor: Colors.warning,
        label: "Live TV",
        borderDash: [8, 4],
        data: livetvsview,
      },
      {
        tension: 0.4,
        borderWidth: 2,
        pointBorderColor: Colors.white,
        pointBackgroundColor: Colors.info,
        borderColor: Colors.info,
        pointHoverBackgroundColor: Colors.info,
        label: "Magazine",
        borderDash: [8, 4],
        data: magazineview,
      },
    ],
  };

  return (
    <>
      <>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress size={50} thickness={8} />
          </div>
        ) : (
          <>
            <Layout title="Analytics">
              <Row className="g-gs">
                <Col sm="4">
                  <Card className="h-100">
                    <Card.Body>
                      <div className="card-title-group align-items-start">
                        <div className="card-title">
                          <h4 className="title">Sales</h4>
                        </div>
                        <Media size="sm" shape="circle" variant="primary-soft">
                          <Icon name="user-alt-fill"></Icon>
                        </Media>
                      </div>
                      <div className="mt-2 mb-4">
                        {/* <div className="amount h1">$40</div> */}
                      </div>
                      <div className="nk-chart-analytics-session">
                        <ChartBar data={salesOverviewChart} />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>

                <Col md="4">
                  <Card className="h-100">
                    <Card.Body>
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Sessions Device</h5>
                        </div>
                        <div className="card-tools">
                          <OverlayTrigger
                            placement="left"
                            overlay={
                              <Tooltip id="tooltip-another">
                                Sessions from device
                              </Tooltip>
                            }
                          >
                            <em className="icon-hint icon ni ni-help-fill"></em>
                          </OverlayTrigger>
                        </div>
                      </div>
                      <div className="nk-chart-analytics-session-device mt-4">
                        <ChartDoughnut data={sessionsDevice} />
                      </div>
                      <ChartLegend.Group className="justify-content-around pt-4 flex-wrap gap g-2">
                        <ChartLegend symbol="info">Mobile</ChartLegend>
                        <ChartLegend symbol="primary">Web</ChartLegend>
                        <ChartLegend symbol="success">TV</ChartLegend>
                      </ChartLegend.Group>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body>
                      <div className="card-title-group flex-wrap">
                        <div className="card-title">
                          <h5 className="title">Audience Overview</h5>
                        </div>
                        <ChartLegend.Group className="gap gx-3 align-items-center">
                          <div className="gap-col">
                            <ChartLegend
                              className="chart-legend-small"
                              symbol="warning"
                            >
                              New visits
                            </ChartLegend>
                          </div>
                          <div className="gap-col">
                            <ChartLegend
                              className="chart-legend-small"
                              symbol="info"
                            >
                              Unique visits
                            </ChartLegend>
                          </div>
                        </ChartLegend.Group>
                      </div>
                      <div className="nk-chart-analytics-audience-overview mt-3">
                        <ChartLine data={audienceOverviewChart} />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xxl="3">
                  <Card className="h-100">
                    <Card.Body className="text-center">
                      <div className="amount h4 mb-3">
                        {registereduser?.value}
                      </div>
                      <p className="small mt-3">Total Users</p>
                      <Pureknob
                        data={registereduser}
                        className="nk-chart-ecommerce-knob"
                      />
                    </Card.Body>
                  </Card>
                </Col>

                <Col xxl="3">
                  <Card className="h-100">
                    <Card.Body>
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Percentage Male / Female</h5>
                        </div>
                        <div className="card-tools">
                          <OverlayTrigger
                            placement="left"
                            overlay={
                              <Tooltip id="tooltip-another">
                                Percentage Male / Female
                              </Tooltip>
                            }
                          >
                            <em className="icon-hint icon ni ni-help-fill"></em>
                          </OverlayTrigger>
                        </div>
                      </div>
                      <div className="nk-chart-analytics-session-device mt-4">
                        <ChartDoughnut data={registeredmalefemale} />
                      </div>
                      <ChartLegend.Group className="justify-content-around pt-4 flex-wrap gap g-2">
                        <ChartLegend symbol="info">Male</ChartLegend>
                        <ChartLegend symbol="pink">Female</ChartLegend>
                      </ChartLegend.Group>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xxl="6">
                  <Card className="h-100">
                    <Card.Body>
                      <div className="card-title-group">
                        <div className="card-title">
                          <h4 className="title mb-0">Page Views Overview</h4>
                        </div>
                      </div>

                      <div className="nk-chart-marketing-campaign-overview mb-3">
                        <ChartLine data={pageviewershipOverviewChart} />
                      </div>
                      <ChartLegend.Group className="justify-content-center flex-wrap gap gy-2 gx-4">
                        <div className="gap-col">
                          <ChartLegend
                            className="chart-legend-small"
                            symbol="pink"
                          >
                            Movies
                          </ChartLegend>
                        </div>
                        <div className="gap-col">
                          <ChartLegend
                            className="chart-legend-small"
                            symbol="success"
                          >
                            Series
                          </ChartLegend>
                        </div>
                        <div className="gap-col">
                          <ChartLegend
                            className="chart-legend-small"
                            symbol="warning"
                          >
                            Live TV
                          </ChartLegend>
                        </div>
                        <div className="gap-col">
                          <ChartLegend
                            className="chart-legend-small"
                            symbol="info"
                          >
                            Magazine
                          </ChartLegend>
                        </div>
                      </ChartLegend.Group>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xxl="4">
                  <Card className="h-100">
                    <div className="card-body">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Web Sessions by Region</h5>
                        </div>
                        <div className="card-tools">
                          <OverlayTrigger
                            placement="left"
                            overlay={
                              <Tooltip id="tooltip-another">
                                Web Sessions from region
                              </Tooltip>
                            }
                          >
                            <em className="icon-hint icon ni ni-help-fill"></em>
                          </OverlayTrigger>
                        </div>
                      </div>
                      <div className="nk-chart-analytics-session-region-map mt-3 mx-auto">
                        <WorldMap id="web-session-map" data={svgWorldMap} />
                      </div>
                      <div className="list-group-heading d-flex align-items-center justify-content-between">
                        <h6 className="title">Top Region</h6>
                        <h6 className="title">Sessions</h6>
                      </div>
                      <ListGroup className="list-group-borderless list-group-sm">
                        <ListGroup.Item className="d-flex justify-content-between align-items-center smaller">
                          <span className="title">Ghana</span>
                          <span className="text">8,465</span>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between align-items-center smaller">
                          <span className="title">United Kingdom</span>
                          <span className="text">6,423</span>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between align-items-center smaller">
                          <span className="title">Canada</span>
                          <span className="text">5,764</span>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between align-items-center smaller">
                          <span className="title">Germany</span>
                          <span className="text">1,374</span>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between align-items-center smaller">
                          <span className="title">Bangladesh</span>
                          <span className="text">890</span>
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </Card>
                </Col>

                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Content Views</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">content</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {contentWatchedData.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                  <Icon name={item.icon}></Icon>
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>

                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Users Content Views</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">users</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">content</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {userstWatchedData.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                  <Icon name={item.icon}></Icon>
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>

                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Top Movies</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">source</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topmovieDataInfo.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                  <Image src={item?.icon} staticImage />
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>

                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Top Series</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">source</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topseriesDataInfo.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                  <Image src={item?.icon} staticImage />
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>

                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Top Magazine</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">source</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topmagazineDataInfo.map((item) => (
                          <tr key={item?.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                   <Image src={item?.icon} staticImage />
                                  {/* <Icon name={item.icon}></Icon> */}
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>

                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body>
                      <div className="card-title-group align-items-start">
                        <div className="card-title">
                          <h4 className="title mb-0">Effectiveness</h4>
                          <span className="small">
                            User involment by ads type
                          </span>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                            <Dropdown.Menu as={CustomDropdownMenu} align="end">
                              <Dropdown.Header className="py-2">
                                <h6 className="mb-0">Quick Actions</h6>
                              </Dropdown.Header>
                              <Dropdown.Divider className="mt-0" />
                              <Dropdown.Item>New Ticket</Dropdown.Item>
                              <Dropdown.Item>New Customer</Dropdown.Item>
                              <Dropdown.Item>New Contact</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="nk-chart-marketing-effectiveness mt-3">
                        <ChartRadar data={effectivenessChart} />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Referral For Month</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">Users</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topreferralsData.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                  <Icon name={item.icon}></Icon>
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>
              </Row>
            </Layout>
          </>
        )}
      </>
    </>
  );
}

export default AukissChart;
