import { useState, useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon } from "../../components";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
// import FormControl from "@mui/material/FormControl";
import { rootURL } from "../utilities/axios-config";
import { successMessage, failedMessage } from "../utilities/alert-config";

function MovieCategoryAdd() {
  const history = useNavigate();
  const [name, setname] = useState("");

  //const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userToken = localStorage.getItem("userToken");
    setLoading2(true);
    let slug = name;
    let description = name;
    const tagObj = {
      name,
      slug,
      description
    };
   

    fetch(`${rootURL}/addtvcategorypost`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tagObj)
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");
          //   console.log("data", result)
          setTimeout(() => {
            history(`/movie-manage/movie-category`);
          }, 3000);

        } else if (result.success === false) {
          setLoading2(false);
          failedMessage(result.message);
        }
      })
      .catch((error) => {
        setLoading2(false);
        failedMessage("Internal Server Error");
      })
      .finally(() => {
        setLoading2(false);
      })  

   
  };

  // grabs the id form the url and loads the corresponding data
  useEffect(() => {}, []);

  return (
    <Layout title="Category Add" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            {/* <div className="d-flex flex-column flex-md-row align-items-md-center">
                        <Media size="huge" shape="circle" variant={theme && theme}>
                            {
                                <Image src={avatar} staticImage thumbnail alt="user"/> 
                              
                            }
                        </Media>
                        <div className="mt-3 mt-md-0 ms-md-3">
                            <h3 className="title mb-1">{user.name}</h3>
                            <span className="small">{user.role}</span>
                            <ul className="nk-list-option pt-1">
                                <li><Icon name="map-pin"></Icon><span className="small">{user.address}</span></li>
                                <li><Icon name="building"></Icon><span className="small">{user.company}</span></li>
                            </ul>
                        </div>
                    </div> */}
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex gap g-2">
              <li className="d-none d-md-block">
                <Link
                  to={`/tvcategory-manage/tvcategory-list`}
                  className="btn btn-soft btn-primary"
                >
                  <Icon name="user"></Icon>
                  <span>Category List</span>
                </Link>
              </li>
              <li className="d-md-none">
                <Link
                  to={`/movie-manage/movie-category`}
                  className="btn btn-soft btn-primary btn-icon"
                >
                  <Icon name="user"></Icon>
                </Link>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">Add Category</h4>
              <Form onSubmit={handleSubmit}>
                <Row className="g-3">
                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="first_name">Name *</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="name"
                          value={name}
                          placeholder="Name"
                          required
                          onChange={(e) => setname(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {loading2 ? "Saving...." : "Save"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  );
}

export default MovieCategoryAdd;
